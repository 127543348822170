<template>
  <div>
    <div class="container-group">
      <!-- Título -->
      <b-form-group
        :label="`Título de la ${$getVisibleNames(
          'evaluations2.rubric',
          false,
          'Pauta'
        )}`"
        label-for="input-name"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="4"
        class="m-0 p-0"
      >
        <b-form-input
          id="input-name"
          v-model="$v.rubric.title.$model"
          :state="validateState('title')"
          aria-describedby="input-name-feedback"
          size="sm"
        ></b-form-input>
        <b-form-invalid-feedback id="input-name-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>

      <!-- Descripción -->
      <b-form-group
        :label="`Descripción de la ${$getVisibleNames(
          'evaluations2.rubric',
          false,
          'Pauta'
        )}`"
        label-for="input-description"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="4"
        class="m-0 p-0"
      >
        <div
          v-b-tooltip.v-secondary="
            `Editar Descripción de la ${$getVisibleNames(
              'evaluations2.rubric',
              false,
              'Pauta'
            )}`
          "
          class="rubric-form-description rich-text-content mb-2"
          v-html="$v.rubric.description.$model"
          @click="$bvModal.show(`edit-rubric-description-modal-${rubric.id}`)"
        ></div>
        <b-modal
          :id="`edit-rubric-description-modal-${rubric.id}`"
          :title="`Editar Descripción de la ${$getVisibleNames(
            'evaluations2.rubric',
            false,
            'Pauta'
          )}`"
          size="lg"
          hide-footer
          no-enforce-focus
        >
          <NewRichTextEditor
            :Object="rubric"
            :Text="rubric.description"
            @save="patchRubric"
            @close="$bvModal.hide(`edit-rubric-description-modal-${rubric.id}`)"
          ></NewRichTextEditor>
        </b-modal>
        <b-form-invalid-feedback id="input-description-feedback"
          >Este campo es opcional.</b-form-invalid-feedback
        >
      </b-form-group>
      <!-- Tipo  -->
      <b-form-group
        v-if="!rubric.rubric_type_default"
        :label="`${$getVisibleNames(
          'evaluations2.rubricpreset',
          false,
          'Modelo de Pauta'
        )}`"
        label-for="select-type"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="4"
        class="m-0 p-0"
      >
        <div class="d-flex">
          <div class="d-block w-100">
            <b-form-select
              id="select-type"
              value-field="id"
              text-field="label"
              v-model="$v.rubric_type_default.preset.$model"
              @change="rubricTypePresetChoice"
              :state="validateStateType('preset')"
              :options="rubricPresetsOptions"
              aria-describedby="select-type-feedback"
              size="sm"
            ></b-form-select>
            <b-form-invalid-feedback id="select-type-feedback"
              >Este campo es obligatorio.</b-form-invalid-feedback
            >
          </div>
          <div class="ml-1 mr-1 container-btn-presets" v-if="isNaN(rubric.id)">
            <b-button
              v-if="rubric_type_default.preset != null"
              size="sm"
              class="ml-1 p-0"
              variant="none"
              v-b-tooltip.top.noninteractive.v-secondary="
                `Previsualizar ${$getVisibleNames(
                  'evaluations2.rubricpreset',
                  false,
                  'Modelo de Pauta'
                )}`
              "
              @click="
                $bvModal.show(`modal-view-rubric-preset-container-${test_id}`)
              "
            >
              <b-icon class="btn-actions" icon="eye" scale="1"></b-icon>
            </b-button>
            <button-add
              v-if="user && (user.groups.includes(1) || user.is_superuser)"
              size="sm"
              class="ml-1 p-0"
              v-b-tooltip.top.noninteractive.v-secondary="
                `Crear ${$getVisibleNames(
                  'evaluations2.rubricpreset',
                  false,
                  'Modelo de Pauta'
                )}`
              "
              @click="
                $bvModal.show(`modal-created-rubric-preset-form-${test_id}`)
              "
            ></button-add>
          </div>
        </div>
      </b-form-group>
      <!-- Nivel de Logro -->
      <b-form-group
        :label="`${$getVisibleNames(
          'evaluations.observationachievement',
          false,
          'Niveles de Logro'
        )} por`"
        label-for="show_percentage_sign"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="4"
        class="m-0 p-0"
      >
        <b-form-select
          id="show_percentage_sign"
          v-model="$v.rubric.show_percentage_sign.$model"
          :state="validateState('show_percentage_sign')"
          :options="showPercentageSigns"
          value-field="value"
          text-field="label"
          aria-describedby="show_percentage_sign-feedback"
          size="sm"
        ></b-form-select>
        <b-form-invalid-feedback id="show_percentage_sign-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        :label="`Agregar Descriptor a ${$getVisibleNames(
          'evaluations.observationachievement',
          false,
          'Niveles de Logro'
        )}`"
        label-for="allow_criteria_performance_level_input"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="4"
        class="m-0 p-0"
      >
        <b-form-select
          id="allow_criteria_performance_level_input"
          v-model="$v.rubric.allow_criteria_performance_level_input.$model"
          :state="validateState('allow_criteria_performance_level_input')"
          :options="showPerformanceLevelInput"
          value-field="value"
          text-field="label"
          aria-describedby="allow_criteria_performance_level_input-feedback"
          size="sm"
        ></b-form-select>
        <b-form-invalid-feedback
          id="allow_criteria_performance_level_input-feedback"
          >Este campo es obligatorio.</b-form-invalid-feedback
        >
      </b-form-group>
      <b-form-group
        v-if="
          evaluativeAgent &&
          (['duoc_coevaluacion_evaluative_agent'].includes(
            evaluativeAgent.internal_use_id
          ) ||
            $equals(evaluativeAgent.name, 'Coevaluación'))
        "
        label-for="input-evaluatee_can_see_coevaluator"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="4"
        class="m-0 p-0"
      >
        <template #label>
          <div class="block-title-container">
            <div>Mostrar Coevaluador</div>
            <div class="ml-2">
              <InfoTooltip
                :tooltip_text="`Muestra al evaluado quién fue la persona que lo evaluó.`"
              ></InfoTooltip>
            </div>
          </div>
        </template>
        <div>
          <b-form-checkbox
            class="mt-2"
            size="md"
            v-model="rubric.evaluatee_can_see_coevaluator"
          >
          </b-form-checkbox>
        </div>
      </b-form-group>
      <b-form-group
        v-if="
          evaluativeAgent &&
          (['duoc_coevaluacion_evaluative_agent'].includes(
            evaluativeAgent.internal_use_id
          ) ||
            $equals(evaluativeAgent.name, 'Coevaluación'))
        "
        label-for="input-evaluatee_can_see_coevaluator"
        label-cols="0"
        label-cols-sm="4"
        label-cols-md="4"
        class="m-0 p-0"
      >
        <template #label>
          <div class="block-title-container">
            <div>Tipo de coevaluación</div>
            <div class="ml-2" v-if="!existEvaluateeTest">
              <InfoTooltip
                :tooltip_text="`Al seleccionar múltiple, permite seleccionar varios coevaluados aunque se repitan en una coevaluación.`"
              ></InfoTooltip>
            </div>
          </div>
        </template>
        <div v-if="!existEvaluateeTest">
          <b-form-select
            class="mt-1"
            id="select-type"
            value-field="id"
            text-field="value"
            v-model="$v.rubric.coevaluation_type.$model"
            :state="validateState('coevaluation_type')"
            :options="rubric_coevaluation_type_choices"
            aria-describedby="select-type-feedback"
            size="sm"
          ></b-form-select>
          <b-form-invalid-feedback id="select-type-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </div>
        <div v-else class="ml-2 mt-2">
          {{
            (
              rubric_coevaluation_type_choices.find(
                (x) => x.id == rubric.coevaluation_type
              ) || {}
            ).value || ""
          }}
        </div>
      </b-form-group>
      <template v-if="institution && institution.internal_use_id != 'duoc_uc'">
        <b-form-group
          v-if="
            evaluativeAgent &&
            test &&
            test.student_can_auto_evaluate &&
            (['duoc_autoevaluacion_evaluative_agent'].includes(
              evaluativeAgent.internal_use_id
            ) ||
              $equals(evaluativeAgent.name, 'Autoevaluación'))
          "
          label-for="input-evaluate_autoevaluation"
          label-cols="0"
          label-cols-sm="4"
          label-cols-md="4"
          class="m-0 p-0"
        >
          <template #label>
            <div class="title-method">Seleccione el método:</div>
          </template>
        </b-form-group>
        <b-form-group
          v-if="
            evaluativeAgent &&
            test &&
            test.student_can_auto_evaluate &&
            (['duoc_autoevaluacion_evaluative_agent'].includes(
              evaluativeAgent.internal_use_id
            ) ||
              $equals(evaluativeAgent.name, 'Autoevaluación'))
          "
          label-for="input-evaluate_autoevaluation"
          label-cols="0"
          label-cols-sm="4"
          label-cols-md="4"
          class="m-0 p-0"
        >
          <template #label>
            <div class="block-title-container">
              <b-icon icon="dot" class="mt-1"></b-icon>
              <div>Método de autoevaluación congruente (Mac)</div>
              <div class="ml-2">
                <InfoTooltip
                  :tooltip_text="`La nota depende de la coincidencia de notas del Evaluador y el que se autoevalúa.`"
                ></InfoTooltip>
              </div>
            </div>
          </template>
          <div>
            <b-form-checkbox
              class="mt-2"
              size="md"
              v-model="rubric.evaluate_autoevaluation"
              @change="changeEvaluateeAutoevaluation"
            >
            </b-form-checkbox>
          </div>
        </b-form-group>
        <b-form-group
          v-if="
            evaluativeAgent &&
            !rubric.evaluate_autoevaluation &&
            ((test &&
              test.student_can_auto_evaluate &&
              (['duoc_autoevaluacion_evaluative_agent'].includes(
                evaluativeAgent.internal_use_id
              ) ||
                $equals(evaluativeAgent.name, 'Autoevaluación'))) ||
              ['duoc_coevaluacion_evaluative_agent'].includes(
                evaluativeAgent.internal_use_id
              ) ||
              $equals(evaluativeAgent.name, 'Coevaluación'))
          "
          label-for="input-student_weighing"
          label-cols="0"
          label-cols-sm="4"
          label-cols-md="4"
          class="m-0 p-0"
        >
          <template #label>
            <div class="block-title-container">
              <b-icon
                v-if="
                  evaluativeAgent &&
                  !rubric.evaluate_autoevaluation &&
                  (['duoc_autoevaluacion_evaluative_agent'].includes(
                    evaluativeAgent.internal_use_id
                  ) ||
                    $equals(evaluativeAgent.name, 'Autoevaluación'))
                "
                icon="dot"
                class="mt-1"
              ></b-icon>
              <div>Método de calificación ponderado</div>
              <div class="ml-2">
                <InfoTooltip
                  :tooltip_text="`El porcentaje indica la ponderación de la calificación que se autoasigna el estudiante, donde 0 es sin relevancia y 100 su máxima.`"
                ></InfoTooltip>
              </div>
            </div>
          </template>
          <b-form-input
            id="input-student_weighing"
            v-model="$v.rubric.student_weighing.$model"
            :state="validateState('student_weighing')"
            aria-describedby="input-student_weighing-feedback"
            size="sm"
            type="number"
          ></b-form-input>
          <div
            v-if="
              rubric.student_weighing >= 0 && rubric.student_weighing <= 100
            "
            class="mb-2 alert-student-weighing"
          >
            El estudiante posee un peso en la ponderación de
            <strong>{{ rubric.student_weighing }}%</strong> en la evaluación, lo
            que significa que el profesor tiene el
            <strong>{{ 100 - rubric.student_weighing }}%</strong> restante.
          </div>
          <b-form-invalid-feedback id="input-student_weighing-feedback">
            <span
              v-if="
                rubric.student_weighing > 100 || rubric.student_weighing < 0
              "
            >
              Este campo debe ser un valor entre 0 y 100.
            </span>
            <span v-else> Este campo debe ser un número entero positivo. </span>
          </b-form-invalid-feedback>
        </b-form-group>
      </template>
      <b-modal
        :id="`modal-view-rubric-preset-container-${test_id}`"
        :title="`Tipos de Instrumentos`"
        size="xl"
        hide-footer
      >
        <RubricPresetPreview
          :rubric_preset_id="rubric_type_default.preset"
        ></RubricPresetPreview>
      </b-modal>
      <b-modal
        :id="`modal-created-rubric-preset-form-${test_id}`"
        :title="`Crear ${$getVisibleNames(
          'evaluations2.rubricpreset',
          false,
          'Modelo de Pauta'
        )}`"
        size="lg"
        hide-footer
        no-enforce-focus
      >
        <!-- :RubricPreset="
            rubric_presets.find((x) => x.id == rubric_type_default.preset)
          " -->
        <RubricPresetForm
          :show_rubric_preset="true"
          @created="createdRubricPreset"
        ></RubricPresetForm>
      </b-modal>
      <div class="container-buttons">
        <b-button class="mr-1" size="sm" @click="saveRubric">Guardar</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { generateUniqueId, toast } from "../../../utils/utils";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import {
  required,
  minValue,
  maxValue,
  integer,
} from "vuelidate/lib/validators";

export default {
  name: "NewRubricForm",
  components: {
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    RubricPresetPreview: () =>
      import("@/components/new-evaluations/Rubrics/RubricPresetPreview"),
    RubricPresetForm: () =>
      import(
        "@/components/new-evaluations/Rubrics/RubricsPreset/RubricPresetForm"
      ),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    test_id: {
      type: Number,
      required: true,
    },
    Rubric: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          title: "",
          description: "",
          test: this.test_id,
          show_percentage_sign: true,
          allow_criteria_performance_level_input: true,
          rubric_type_default: null,
          student_weighing: 0,
          coevaluation_type: 1,
          evaluate_autoevaluation: false,
          evaluatee_can_see_coevaluator: false,
        };
      },
    },
  },
  data() {
    return {
      rubric: { ...this.Rubric },
      rubric_type_default: {
        id: generateUniqueId(),
        rubric: this.Rubric.id,
        preset: null,
      },
      rubric_coevaluation_type_choices: [
        {
          id: 1,
          value: "Coevaluación simple",
        },
        {
          id: 2,
          value: "Coevaluación múltiple",
        },
      ],
      showPercentageSigns: [
        {
          value: true,
          label: "Porcentaje",
        },
        {
          value: false,
          label: "Puntaje",
        },
      ],
      showPerformanceLevelInput: [
        {
          value: true,
          label: "Mostrar Descriptores",
        },
        {
          value: false,
          label: "Ocultar Descriptores",
        },
      ],
    };
  },
  validations: {
    rubric: {
      title: { required },
      description: {},
      coevaluation_type: {},
      show_percentage_sign: {},
      allow_criteria_performance_level_input: {},
      student_weighing: {
        required,
        minValue: minValue(0),
        integer,
        maxValue: maxValue(100),
      },
    },
    rubric_type_default: {
      preset: { required },
    },
    validationGroup: ["rubric", "rubric_type_default"],
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      user: "getUser",
      rubric_presets: names.RUBRIC_PRESETS,
      evaluatee_tests: names.EVALUATEE_TESTS,
      rubric_preset_units: names.RUBRIC_PRESET_UNITS,
      evaluatee_rubrics: names.EVALUATEE_RUBRICS,
      instrument_types: names.NEW_TEST_TYPES,
      new_tests: names.NEW_TESTS,
    }),
    test() {
      return this.new_tests.find((x) => x.id == this.test_id);
    },
    evaluativeAgent() {
      if (!this.test) return null;
      return this.instrument_types.find(
        (x) => x.id == this.test.evaluative_agent
      );
    },
    rubricPresetsOptions() {
      return this.rubric_presets.map((x) => ({
        ...x,
        label:
          x.title +
          " - " +
          this.$getVisibleNames(
            "evaluations.observationachievement",
            true,
            "Niveles de Logro"
          ) +
          ": " +
          this.rubric_preset_units.filter((a) => a.rubric_preset == x.id)
            .length,
      }));
    },
    existEvaluateeTest() {
      if (
        this.evaluatee_tests.filter(
          (x) =>
            x.test == this.test_id &&
            (x.start_time != null ||
              x.is_corrected ||
              x.is_finished ||
              x.is_published)
        ).length > 0
      )
        return true;
      else return false;
    },
    evaluateeRubrics() {
      return this.evaluatee_rubrics.filter(
        (x) => x.rubric == this.rubric.id && x.coevaluators.length > 1
      );
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.rubric[name];
      return $dirty ? !$error : null;
    },
    validateStateType(name) {
      const { $dirty, $error } = this.$v.rubric_type_default[name];
      return $dirty ? !$error : null;
    },
    changeEvaluateeAutoevaluation(value) {
      if (value) {
        this.rubric.student_weighing = 0;
      }
    },
    patchRubric(object, text) {
      this.rubric.description = text;
    },
    rubricTypePresetChoice(value) {
      const rubric_preset = this.rubric_presets.find((x) => x.id == value);
      if (rubric_preset) {
        this.rubric.show_percentage_sign = rubric_preset.show_percentage_sign;
        this.rubric.allow_criteria_performance_level_input =
          rubric_preset.allow_criteria_performance_level_input;
      }
    },
    createdRubricPreset(response) {
      this.rubric_type_default.preset = response.id;
      this.$bvModal.hide(`modal-created-rubric-preset-form-${this.test_id}`);
    },
    saveRubric() {
      this.$v.validationGroup.$touch();
      if (this.$v.validationGroup.$anyError) {
        return;
      }
      if (
        this.evaluateeRubrics.length > 0 &&
        this.rubric.coevaluation_type == 1
      ) {
        this.$swal({
          title: `<p>¿Está seguro de que desea guardar la ${this.$getVisibleNames(
            "evaluations2.rubric",
            false,
            "Pauta"
          )} siendo que existen <b>múltiples coevaluados asignados</b>?</p>`,
          text: "¡Esta acción no se podrá revertir y los coevaluados se restablecerán!",
          type: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            if (!isNaN(this.rubric.id))
              this.$restful
                .Get(
                  `/evaluations2/unassign_rubric_coevaluators/?rubric=${this.rubric.id}`
                )
                .then(() => {
                  this.$store
                    .dispatch(names.FETCH_EVALUATEE_RUBRICS, {
                      test_id: this.test_id,
                    })
                    .then(() => {
                      if (isNaN(this.rubric.id)) this.createRubric();
                      else this.updateRubric();
                      this.$emit("reset_rubric_coevaluators");
                      toast("Evaluados Desasignados Satisfactoriamente");
                    });
                });
          }
        });
      } else {
        if (isNaN(this.rubric.id)) this.createRubric();
        else this.updateRubric();
      }
    },
    createRubric() {
      this.$store
        .dispatch(names.POST_NEW_RUBRIC_INSTRUMENT, this.rubric)
        .then((response) => {
          this.rubric = response;
          this.rubric_type_default.rubric = response.id;
          toast(
            this.$getVisibleNames("evaluations2.rubric", false, "Pauta") +
              " creado."
          );
          this.$store.dispatch(names.FETCH_NEW_TEST, {
            test_id: this.test_id,
            forceFetch: true,
          });
          if (response.rubric_type_default == null)
            this.createRubricTypeDefault();
          else this.updateRubricTypeDefault();
        });
    },
    updateRubric() {
      this.$store
        .dispatch(names.UPDATE_NEW_RUBRIC_INSTRUMENT, this.rubric)
        .then((response) => {
          this.rubric = response;
          this.rubric_type_default.rubric = response.id;
          toast(
            this.$getVisibleNames("evaluations2.rubric", false, "Pauta") +
              " editado."
          );
          if (response.rubric_type_default == null)
            this.createRubricTypeDefault();
          else this.updateRubricTypeDefault();
        });
    },
    createRubricTypeDefault() {
      this.$restful
        .Post("/evaluations2/rubric-type-default/", this.rubric_type_default)
        .then((response) => {
          this.rubric_preset_units
            .filter((x) => x.rubric_preset == response.preset)
            .forEach((element) => {
              this.$store.dispatch(names.POST_NEW_RUBRIC_ACHIEVEMENT, {
                title: element.title,
                description: element.description,
                order: element.order,
                score: element.score,
                rubric: response.rubric,
              });
            });
          this.$store.commit(
            "MUTATE_CHANGE_NEW_RUBRIC_TYPE_BY_NEW_RUBRIC",
            response
          );
          this.rubric_type_default = response;
          this.$emit("closeModal", this.rubric);
        });
    },
    updateRubricTypeDefault() {
      this.$restful
        .Put(
          `/evaluations2/rubric-type-default/${this.rubric_type_default.id}/`,
          this.rubric_type_default
        )
        .then((response) => {
          this.$store.commit(
            "MUTATE_CHANGE_NEW_RUBRIC_TYPE_BY_NEW_RUBRIC",
            response
          );
          this.rubric_type_default = response;
          this.$emit("closeModal", this.rubric);
        });
    },
    fetchRubricTypeDefault() {
      if (this.rubric.rubric_type_default != null) {
        this.$restful
          .Get(
            `/evaluations2/rubric-type-default/${this.rubric.rubric_type_default}/`
          )
          .then((response) => {
            this.rubric_type_default = response;
          });
      }
    },
  },
  mounted() {
    if (this.institution && this.institution.internal_use_id == "duoc_uc") {
      this.rubric.evaluate_autoevaluation = false;
      this.rubric.student_weighing = 100;
    }
    if (
      this.test &&
      !this.test.student_can_auto_evaluate &&
      this.evaluativeAgent &&
      (["duoc_autoevaluacion_evaluative_agent"].includes(
        this.evaluativeAgent.internal_use_id
      ) ||
        this.$equals(this.evaluativeAgent.name, "Autoevaluación"))
    ) {
      this.rubric.student_weighing = 0;
      this.rubric.evaluate_autoevaluation = false;
    }
  },
  created() {
    this.fetchRubricTypeDefault();
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.alert-student-weighing {
  font-size: 10pt;
}
.title-method {
  text-decoration: underline;
}
.block-title-container {
  display: flex;
  justify-content: space-between;
}
.container-btn-presets {
  display: flex;
  flex-direction: row;
}
.container-group {
  margin-top: 0%;
}
.container-buttons {
  display: flex;
  justify-content: right;
}
.rubric-form-description {
  width: calc(100%);
  border: 1px solid #ced4da;
  border-radius: 4px;
  font-size: 0.875rem;
  min-height: calc(1.5em + 0.5rem + 2px);
  height: auto;
  max-height: 200px;
  overflow: auto;
  padding: 0.25rem 1.2rem;
  line-height: 1.5;
}
.rubric-form-description >>> p {
  margin-bottom: 0.7rem !important;
}
</style>