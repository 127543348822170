var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-4 mt-3"},[_c('div',{staticClass:"text-left"},[_c('span',{staticClass:"title-evaluative-situation"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationsituation", false, "Situación Evaluativa" ))+" ")])]),_c('div',{staticClass:"container-evaluative-situation mt-3"},[(_vm.matter_evaluation != null && _vm.show_by_matter_evaluation)?_vm._l((_vm.evaluation_situations),function(evaluative_situation){return _c('div',{key:'test-type-' + evaluative_situation.id,staticClass:"container-card mr-3 mb-3"},[_c('div',{staticClass:"secundary-cell"},[(
              _vm.matter_evaluation.evaluation_situations.includes(
                evaluative_situation.id
              )
            )?[_vm._v("X")]:_vm._e()],2),_c('div',{staticClass:"primary-cell"},[_vm._v(" "+_vm._s(evaluative_situation.name.toUpperCase())+" ")])])}):(_vm.evaluation_id == null && _vm.test)?_vm._l((_vm.evaluation_situations),function(evaluative_situation){return _c('div',{key:'test-type-' + evaluative_situation.id,staticClass:"container-card mr-3 mb-3"},[_c('div',{staticClass:"secundary-cell"},[(_vm.test.evaluation_situation == evaluative_situation.id)?[_vm._v("X")]:_vm._e()],2),_c('div',{staticClass:"primary-cell"},[_vm._v(" "+_vm._s(evaluative_situation.name.toUpperCase())+" ")])])}):_vm._l((_vm.evaluation_situations),function(evaluative_situation){return _c('div',{key:'test-type-' + evaluative_situation.id,staticClass:"container-card mr-3 mb-3"},[_c('div',{staticClass:"secundary-cell"},[(
              _vm.evaluation_situations_by_tests.includes(evaluative_situation.id)
            )?[_vm._v("X")]:_vm._e()],2),_c('div',{staticClass:"primary-cell"},[_vm._v(" "+_vm._s(evaluative_situation.name.toUpperCase())+" ")])])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }