<template>
  <div>
    <b-form-group
      label-cols="0"
      label-cols-sm="3"
      label-for="weighing"
      class="label m-0"
    >
      <template #label>
        <div class="weighing-label">
          <div class="label-content">Ingresar datos por:</div>
        </div>
      </template>
      <div id="toggle_in_weeks">
        <div class="d-flex">
          <div class="toggle-types noprint">
            <span
              class="label-content"
              :class="{
                'font-weight-bold': oldMatter.toggle_in_weeks,
              }"
              >Semana</span
            >
            <b-button
              class="check-type"
              :class="{ 'check-type-disabled': oldMatter.is_closed }"
              variant="primary"
              @click="changeToggleInWeeks"
            >
              <b-icon
                icon="circle-fill"
                class="icon-check"
                :class="{
                  'icon-check-active': !oldMatter.toggle_in_weeks,
                  'icon-check-disabled': oldMatter.is_closed,
                }"
                scale="0.7"
              ></b-icon>
            </b-button>
            <span
              class="label-content"
              :class="{
                'font-weight-bold': !oldMatter.toggle_in_weeks,
              }"
              >Total</span
            >
          </div>
        </div>
      </div>
    </b-form-group>
    <!-- Horas Pedagógicas -->
    <div
      v-if="
        oldMatterHoursgFilter.filter((x) => x.uses_module_minutes == true)
          .length > 0
      "
      class="mt-2"
    >
      <div class="d-flex">
        <div
          v-if="
            (institution && institution.internal_use_id == 'duoc_uc') ||
            $debug_change_duoc
          "
          class="mr-3"
        >
          <b-input-group append="%" size="sm" style="width: 90px">
            <b-form-input
              class="mt-0"
              v-model="pedagogical_weighing"
              min="1"
              max="100"
              disabled
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="align-self-center"><strong>Horas Pedagógicas:</strong></div>
      </div>
    </div>
    <b-table-simple
      v-if="
        oldMatterHoursgFilter.filter((x) => x.uses_module_minutes == true)
          .length > 0
      "
      borderless
      small
      responsive
    >
      <b-thead>
        <b-th class="w-25"></b-th>
        <b-th class="w-75"></b-th>
        <b-th class="text-center">
          <div>
            +
            <InfoTooltip
              class="info-icon"
              :tooltip_text="`Incluir en ${$getVisibleNames(
                'manual.total_pedagogical_hours',
                true,
                'Total de Horas Pedagógicas'
              )}`"
            >
            </InfoTooltip>
          </div>
        </b-th>
        <b-th class="text-center">
          <div>
            %
            <InfoTooltip
              class="info-icon"
              :tooltip_text="`Porcentaje de asistencia requerido`"
            >
            </InfoTooltip>
          </div>
        </b-th>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="hour in oldMatterHoursgFilter.filter(
            (x) => x.uses_module_minutes == true
          )"
          :key="hour.id"
        >
          <b-td class="p-0">
            <b-form-group
              :label-for="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              label-class="label-matter mt-1"
            >
              <template v-slot:label>
                <!-- <b-icon
                  v-b-tooltip.v-secondary.noninteractive="
                    `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                      'mesh.egressprofilematter',
                      true,
                      'Asignaturas'
                    ).toLowerCase()}`
                  "
                  icon="exclamation-triangle"
                  style="margin-bottom: 0.1rem"
                ></b-icon> -->
                <span
                  v-if="
                    !oldMatter.is_closed &&
                    !(
                      (institution &&
                        institution.internal_use_id == 'duoc_uc') ||
                      $debug_change_duoc
                    )
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                      'mesh.egressprofilematter',
                      true,
                      'Asignaturas'
                    ).toLowerCase()}`
                  "
                  >⚠️</span
                >
                {{ hour.name }}
              </template>
            </b-form-group>
            <!-- <div class="mt-1 label-matter">{{ hour.name }}:</div> -->
          </b-td>
          <b-td class="p-0">
            <b-form-input
              v-if="oldMatter.toggle_in_weeks"
              :disabled="oldMatter.is_closed"
              type="number"
              :id="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              v-model="hour.tem_hours"
              @update="
                (value) => {
                  if (parseFloat(value) < 0 || value == null || value == '')
                    hour.tem_hours = 0;
                  else if (parseFloat(value) >= 0)
                    hour.hours =
                      parseFloat(value) * parseFloat(oldMatter.weeks);
                }
              "
              :aria-describedby="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-input
              v-else
              :id="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              :disabled="oldMatter.is_closed"
              type="number"
              v-model="hour.hours"
              @update="
                (value) => {
                  if (parseFloat(value) < 0 || value == null || value == '')
                    hour.hours = 0;
                  else if (parseFloat(value) >= 0) {
                    if (oldMatter.weeks == 0 || parseFloat(value) == 0)
                      hour.tem_hours = 0;
                    else
                      hour.tem_hours = parseFloat(
                        (
                          parseFloat(value) / parseFloat(oldMatter.weeks)
                        ).toFixed(2)
                      );
                  }
                }
              "
              :aria-describedby="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-invalid-feedback
              :id="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              class="mt-0 mb-1"
              >Este campo no debe estar vacío y no debe ser menor a
              0.</b-form-invalid-feedback
            >
          </b-td>
          <b-td class="p-0">
            <b-form-checkbox
              :id="`checkbox-${hour.uses_module_minutes}-input-${hour.id}`"
              :disabled="oldMatter.is_closed"
              class="ml-2"
              size="lg"
              v-model="hour.counts_towards_credits"
            ></b-form-checkbox>
          </b-td>
          <b-td class="p-0"
            ><b-form-input
              class="m-0"
              :disabled="oldMatter.is_closed"
              :id="`attendance_requirement-${hour.uses_module_minutes}-input-${hour.id}`"
              v-model="hour.attendance_requirement"
              @update="
                (value) => {
                  if (parseFloat(value) < 0 || value == null || value == '')
                    hour.attendance_requirement = 0;
                  else if (parseFloat(value) > 100)
                    hour.attendance_requirement = 100;
                }
              "
              style="min-width: 5rem"
              :aria-describedby="`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-invalid-feedback
              :id="`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`"
              class="mt-0 mb-1"
              >Este campo no debe estar vacío y no debe ser menor a
              0.</b-form-invalid-feedback
            ></b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-form-group
      label-cols="0"
      label-cols-sm="4"
      label-for="total_hours"
      class="label-matter mb-0"
    >
      <template #label>
        <div class="font-size-label">
          {{
            $getVisibleNames(
              "manual.total_pedagogical_hours",
              true,
              "Total de Horas Pedagógicas"
            )
          }}
        </div>
      </template>
      <div>
        <b-badge
          id="total_hours"
          class="total-hours-value mt-1"
          variant="light"
        >
          {{ totalPedagogical | Round }}
        </b-badge>
      </div>
    </b-form-group>
    <b-form-group
      label-cols="0"
      label-cols-sm="4"
      label-for="total_hours"
      class="label mb-0"
      v-if="profileType"
    >
      <template #label>
        <div class="font-size-label">
          {{
            $getVisibleNames(
              "manual.total_pedagogical_hours_to_chronological",
              true,
              "Total de Horas Pedagógicas (En Cronológicas)"
            )
          }}
          <span>
            <InfoTooltip
              v-if="profileType"
              class="info-icon"
              :tooltip_text="`${$getVisibleNames(
                'manual.total_pedagogical_hours',
                true,
                'Total de Horas Pedagógicas'
              )}(${totalPedagogical}) * Cantidad de Minutos por Hora Pedagógica(${
                profileType ? profileType.module_minutes : 0
              }/60)= ${totalPedagogicalToChronological}`"
            >
            </InfoTooltip>
          </span>
        </div>
      </template>
      <div>
        <b-badge
          id="total_hours"
          class="total-hours-value mt-1"
          variant="light"
          v-if="profileType"
        >
          {{ totalPedagogicalToChronological }}
        </b-badge>
      </div>
    </b-form-group>
    <!-- Horas Cronológicas -->
    <div
      v-if="
        oldMatterHoursgFilter.filter((x) => x.uses_module_minutes == false)
          .length > 0
      "
      class="mt-2"
    >
      <div class="d-flex">
        <div
          v-if="
            (institution && institution.internal_use_id == 'duoc_uc') ||
            $debug_change_duoc
          "
          class="mr-3"
        >
          <b-input-group append="%" size="sm" style="width: 90px">
            <b-form-input
              :key="key_chronological_weighing"
              class="mt-0"
              v-model="chronological_weighing"
              type="number"
              @input="changeChronological"
              :disabled="oldMatter.is_closed"
              min="0"
              max="99"
            ></b-form-input>
          </b-input-group>
        </div>
        <div class="align-self-center">
          <strong>Horas Cronológicas:</strong>
        </div>
      </div>
    </div>
    <b-table-simple
      borderless
      small
      responsive
      v-if="
        oldMatterHoursgFilter.filter((x) => x.uses_module_minutes == false)
          .length > 0
      "
    >
      <b-thead>
        <b-th class="w-25"></b-th>
        <b-th class="w-75"></b-th>
        <b-th class="text-center">
          <div>
            +
            <InfoTooltip
              class="info-icon"
              :tooltip_text="`Incluir en ${$getVisibleNames(
                'manual.total_chronological_hours',
                true,
                'Total de Horas Cronológicas'
              )}`"
            >
            </InfoTooltip>
          </div>
        </b-th>
        <b-th class="text-center">
          <div>
            %
            <InfoTooltip
              class="info-icon"
              :tooltip_text="`Porcentaje de asistencia requerido`"
            >
            </InfoTooltip>
          </div>
        </b-th>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="hour in oldMatterHoursgFilter.filter(
            (x) => x.uses_module_minutes == false
          )"
          :key="hour.id"
        >
          <b-td class="p-0">
            <b-form-group
              :label-for="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              label-class="label-matter mt-2"
            >
              <template v-slot:label>
                <!-- <b-icon
                  v-b-tooltip.v-secondary.noninteractive="
                    `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                      'mesh.egressprofilematter',
                      true,
                      'Asignaturas'
                    ).toLowerCase()}`
                  "
                  icon="exclamation-triangle"
                  style="margin-bottom: 0.1rem"
                ></b-icon> -->
                <span
                  v-if="
                    !oldMatter.is_closed &&
                    !(
                      (institution &&
                        institution.internal_use_id == 'duoc_uc') ||
                      $debug_change_duoc
                    )
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(
                      'mesh.egressprofilematter',
                      true,
                      'Asignaturas'
                    ).toLowerCase()}`
                  "
                  >⚠️</span
                >
                {{ hour.name }}
              </template>
            </b-form-group>
          </b-td>
          <b-td class="p-0">
            <b-form-input
              v-if="oldMatter.toggle_in_weeks"
              :key="key_chronological_hours"
              :id="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              v-model="hour.tem_hours"
              type="number"
              :disabled="oldMatter.is_closed"
              @update="updateTemHours(hour)"
              :aria-describedby="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-input
              v-else
              :key="key_chronological_hours"
              :id="`hour-${hour.uses_module_minutes}-input-${hour.id}`"
              v-model="hour.hours"
              type="number"
              :disabled="oldMatter.is_closed"
              @update="updateHours(hour)"
              :aria-describedby="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-invalid-feedback
              :id="`input-hour-${hour.uses_module_minutes}-feedback-${hour.id}`"
              class="mt-0 mb-1"
              >Este campo no debe estar vacío y no debe ser menor a
              0.</b-form-invalid-feedback
            >
          </b-td>
          <b-td class="p-0">
            <b-form-checkbox
              :id="`checkbox-${hour.uses_module_minutes}-input-${hour.id}`"
              :disabled="oldMatter.is_closed"
              class="ml-2"
              size="lg"
              v-model="hour.counts_towards_credits"
            ></b-form-checkbox>
          </b-td>
          <b-td class="p-0">
            <b-form-input
              class="m-0"
              :id="`attendance_requirement-${hour.uses_module_minutes}-input-${hour.id}`"
              :disabled="oldMatter.is_closed"
              v-model="hour.attendance_requirement"
              style="min-width: 5rem"
              @update="
                (value) => {
                  if (parseFloat(value) < 0 || value == null || value == '')
                    hour.attendance_requirement = 0;
                  else if (parseFloat(value) > 100)
                    hour.attendance_requirement = 100;
                }
              "
              :aria-describedby="`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`"
              size="sm"
            >
            </b-form-input>
            <b-form-invalid-feedback
              :id="`attendance_requirement-${hour.uses_module_minutes}-feedback-${hour.id}`"
              class="mt-0 mb-1"
              >Este campo no debe estar vacío y no debe ser menor a
              0.</b-form-invalid-feedback
            ></b-td
          >
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-form-group
      label-cols="0"
      label-cols-sm="4"
      label-for="total_hours"
      class="label mb-0"
    >
      <template #label>
        <div class="font-size-label">
          {{
            $getVisibleNames(
              "manual.total_chronological_hours",
              true,
              "Total de Horas Cronológicas"
            )
          }}
        </div>
      </template>
      <div>
        <b-badge
          id="total_hours"
          class="total-hours-value mt-1"
          variant="light"
        >
          {{ totalChronological | Round }}
        </b-badge>
      </div>
    </b-form-group>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "MatterTimeAllocationComponent",
  components: {
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    allows_crud: {
      type: Boolean,
      default: true,
    },
    oldMatter: {
      type: Object,
    },
    profileType: {},
  },
  data() {
    return {
      old_matter_hours: [],
      pedagogical_weighing: 60,
      chronological_weighing: 40,
      key_chronological_weighing: 0,
      key_chronological_hours: 0,
      check_weighing: true,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      time_allocations: names.TIME_ALLOCATIONS,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      studyEnvironmentsTimeAllocation: names.STUDY_ENVIRONMENTS_TIME_ALLOCATION,
    }),
    timeAllocationsIds() {
      const time_allocation_ids = [
        ...new Set(
          this.studyEnvironmentsTimeAllocation
            .filter((x) =>
              this.oldMatter.study_environments.includes(x.study_environment)
            )
            .map((x) => x.time_allocation)
        ),
      ];
      return time_allocation_ids;
    },
    oldMatterHoursgFilter() {
      if (this.oldMatter && this.oldMatter.study_environments.length > 0)
        return this.old_matter_hours.filter(
          (x) =>
            this.timeAllocationsIds.includes(x.time_allocation) ||
            !x.has_study_environments ||
            x.hours > 0 ||
            x.tem_hours > 0
        );
      else return this.old_matter_hours;
    },
    totalPedagogical() {
      let count = 0;
      this.old_matter_hours
        .filter((x) => x.uses_module_minutes == true)
        .forEach((element) => {
          if (
            element.counts_towards_credits &&
            element.hours &&
            element.hours >= 0
          ) {
            count += parseFloat(element.hours);
          }
        });
      return count;
    },
    totalPedagogicalToChronological() {
      if (!this.profileType) return 0;
      else {
        if (
          this.institution &&
          parseInt(this.institution.hour_decimal_places) === 0
        ) {
          return parseFloat(
            Math.round(
              this.totalPedagogical *
                (parseFloat(this.profileType.module_minutes) / 60)
            )
          );
        } else
          return parseFloat(
            (
              this.totalPedagogical *
              (parseFloat(this.profileType.module_minutes) / 60)
            ).toFixed(
              this.institution &&
                parseInt(this.institution.hour_decimal_places) > 0
                ? parseInt(this.institution.hour_decimal_places)
                : 2
            )
          );
      }
    },
    totalChronological() {
      let count = 0;
      this.old_matter_hours
        .filter((x) => x.uses_module_minutes == false)
        .forEach((element) => {
          if (
            element.counts_towards_credits &&
            element.hours &&
            element.hours >= 0
          ) {
            count += parseFloat(element.hours);
          }
        });
      return count;
    },
  },
  methods: {
    updateTemHours(hour) {
      if (
        parseFloat(hour.tem_hours) < 0 ||
        hour.tem_hours == null ||
        hour.tem_hours == ""
      ) {
        hour.tem_hours = 0;
        this.key_chronological_hours++;
      } else if (parseFloat(hour.tem_hours) >= 0) {
        hour.hours =
          parseFloat(hour.tem_hours) * parseFloat(this.oldMatter.weeks);
        this.changeWeighing(hour);
      }
    },
    updateHours(hour) {
      if (
        parseFloat(hour.hours) < 0 ||
        hour.hours == null ||
        hour.hours == ""
      ) {
        hour.hours = 0;
        this.key_chronological_hours++;
      } else if (parseFloat(hour.hours) >= 0) {
        if (this.oldMatter.weeks == 0 || parseFloat(hour.hours) == 0) {
          hour.tem_hours = 0;
          this.key_chronological_hours++;
        } else {
          hour.tem_hours = parseFloat(
            (parseFloat(hour.hours) / parseFloat(this.oldMatter.weeks)).toFixed(
              2
            )
          );
          this.changeWeighing(hour);
        }
      }
    },
    changeWeighing(hour) {
      if (
        hour.internal_use_id &&
        hour.internal_use_id == "duoc_autonomous_work_hours" &&
        !this.check_weighing &&
        ((this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc)
      ) {
        if (this.profileType && parseFloat(this.totalPedagogical) > 0) {
          const pedagogical_in_chronological =
            this.totalPedagogicalToChronological;
          this.chronological_weighing = Math.round(
            (parseFloat(hour.hours) /
              (parseFloat(hour.hours) +
                parseFloat(pedagogical_in_chronological))) *
              100
          );
        }
      }
    },
    changeChronological(chronological_value) {
      if (parseFloat(chronological_value) > 100) {
        this.chronological_weighing = 99;
        this.key_chronological_weighing++;
      } else if (parseFloat(chronological_value) < 0) {
        this.chronological_weighing = 0;
        this.key_chronological_weighing++;
      } else if (
        !this.check_weighing &&
        parseFloat(chronological_value) > 0 &&
        parseFloat(chronological_value) < 100
      ) {
        if (
          (this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc
        )
          if (this.profileType) {
            if (parseFloat(this.totalPedagogical) > 0) {
              const index = this.old_matter_hours.findIndex(
                (x) => x.internal_use_id == "duoc_autonomous_work_hours"
              );
              if (index != -1) {
                const pedagogical_in_chronological = Math.round(
                  this.totalPedagogicalToChronological
                );
                const hour = Math.round(
                  (chronological_value * pedagogical_in_chronological) /
                    (100 - chronological_value)
                );
                this.old_matter_hours[index].hours = parseFloat(hour);
                if (this.oldMatter.weeks > 0)
                  this.old_matter_hours[index].tem_hours = Math.round(
                    parseFloat(hour) / parseFloat(this.oldMatter.weeks)
                  );
              }
            }
          }
      }
    },
    changeToggleInWeeks() {
      if (!this.oldMatter.is_closed)
        this.oldMatter.toggle_in_weeks = !this.oldMatter.toggle_in_weeks;
    },
  },
  mounted() {
    this.old_matter_hours = [];
    if (isNaN(this.oldMatter.id)) {
      if (this.institution && this.institution.internal_use_id != "duoc_uc")
        this.oldMatter.toggle_in_weeks = true;
      this.time_allocations.forEach((element, index) => {
        this.old_matter_hours.push({
          id: generateUniqueId(),
          time_allocation: element.id,
          name: element.name,
          order: element.order,
          matter: generateUniqueId(),
          counts_towards_credits: element.counts_towards_credits,
          has_study_environments: element.has_study_environments,
          internal_use_id: element.internal_use_id,
          uses_module_minutes: element.uses_module_minutes,
          attendance_requirement: element.attendance_requirement
            ? element.attendance_requirement
            : 0,
          hours: 0,
          tem_hours: 0,
        });
        if (this.time_allocations.length == index + 1)
          this.$emit("updated", this.old_matter_hours);
      });
    } else if (!isNaN(this.oldMatter.id)) {
      this.$store
        .dispatch(names.FETCH_MATTER_TIME_ALLOCATIONS, {
          matter_id: this.oldMatter.id,
        })
        .then((response) => {
          this.time_allocations.forEach((element, index) => {
            const matter_hour = response.find(
              (x) =>
                x.matter == this.oldMatter.id && x.time_allocation == element.id
            );
            if (matter_hour)
              this.old_matter_hours.push({
                id: matter_hour.id,
                time_allocation: matter_hour.time_allocation,
                name: element.name,
                order: element.order,
                uses_module_minutes: element.uses_module_minutes,
                has_study_environments: element.has_study_environments,
                internal_use_id: element.internal_use_id,
                matter: matter_hour.matter,
                counts_towards_credits: matter_hour.counts_towards_credits,
                attendance_requirement: matter_hour.attendance_requirement,
                hours: matter_hour.hours,
                tem_hours:
                  parseFloat(this.oldMatter.weeks) > 0
                    ? parseFloat(
                        (
                          parseFloat(matter_hour.hours) /
                          parseFloat(this.oldMatter.weeks)
                        ).toFixed(2)
                      )
                    : 0,
              });
            else
              this.old_matter_hours.push({
                id: generateUniqueId(),
                time_allocation: element.id,
                name: element.name,
                order: element.order,
                matter: this.oldMatter.id,
                counts_towards_credits: element.counts_towards_credits,
                has_study_environments: element.has_study_environments,
                internal_use_id: element.internal_use_id,
                uses_module_minutes: element.uses_module_minutes,
                attendance_requirement: element.attendance_requirement
                  ? element.attendance_requirement
                  : 0,
                hours: 0,
                tem_hours: 0,
              });
            if (this.time_allocations.length == index + 1)
              this.$emit("updated", this.old_matter_hours);
          });
        });
    }
  },
  watch: {
    "oldMatter.weeks": function () {
      this.old_matter_hours.forEach((x) => {
        if (
          this.oldMatter.weeks == 0 ||
          this.oldMatter.weeks == "" ||
          this.oldMatter.weeks == null
        ) {
          x.tem_hours = 0;
          x.hours = 0;
        } else {
          x.hours = parseFloat(x.tem_hours) * parseFloat(this.oldMatter.weeks);
        }
      });
    },
    totalPedagogical(total_pedagogical) {
      if (
        (this.institution && this.institution.internal_use_id == "duoc_uc") ||
        this.$debug_change_duoc
      )
        if (this.profileType) {
          if (parseFloat(total_pedagogical) > 0) {
            const index = this.old_matter_hours.findIndex(
              (x) => x.internal_use_id == "duoc_autonomous_work_hours"
            );
            if (index != -1) {
              const pedagogical_in_chronological = Math.round(
                total_pedagogical *
                  (parseFloat(this.profileType.module_minutes) / 60)
              );
              if (!this.check_weighing) {
                const hour = Math.round(
                  (this.chronological_weighing * pedagogical_in_chronological) /
                    this.pedagogical_weighing
                );
                this.old_matter_hours[index].hours = hour;
                if (this.oldMatter.weeks > 0)
                  this.old_matter_hours[index].tem_hours = Math.round(
                    parseFloat(hour) / parseFloat(this.oldMatter.weeks)
                  );
              }
              // Cuando entra al componente para cargar la ponderación correspondiente.
              if (this.check_weighing && !isNaN(this.oldMatter.id)) {
                this.chronological_weighing = Math.round(
                  (parseFloat(this.old_matter_hours[index].hours) /
                    (parseFloat(this.old_matter_hours[index].hours) +
                      parseFloat(pedagogical_in_chronological))) *
                    100
                );
                this.check_weighing = false;
              }
            }
          }
        }
    },
    chronological_weighing(new_value) {
      if (parseFloat(new_value) >= 0 && parseFloat(new_value) < 100) {
        this.pedagogical_weighing = 100 - parseFloat(new_value);
      } else if (new_value == "") this.pedagogical_weighing = 100;
    },
  },
  created() {},
};
</script>

<style scoped>
.label-content {
  font-size: var(--secondary-font-size);
}
.toggle-types {
  display: flex;
  float: right;
  padding: 0.3em 11.1em;
  border-radius: 3px;
}
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.check-type-disabled {
  background: #6c757d !important;
  border: 1px solid #6c757d;
  margin-inline: 0.7em;
  height: 1.12em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.icon-check {
  color: white;
  transform: translate(-69%, -50%);
  transition: 0.2s;
}
.icon-check-disabled {
  color: rgb(190, 190, 190) !important;
}
.icon-check-active {
  transform: translate(15%, -50%) !important;
}
.form-group >>> .label-matter {
  margin-top: 0.2rem;
  padding-right: 10px !important;
  font-size: var(--secondary-font-size);
}
#total_hours {
  font-size: var(--primary-font-size);
}
.font-size-label {
  font-size: var(--secondary-font-size) !important;
}
.total-hours-value {
  min-width: 60px;
  font-weight: normal;
  margin-left: 2px;
}
.info-icon {
  position: relative;
  vertical-align: top;
  height: 12px;
  width: 12px;
}
@media (max-width: 991px) {
  .info-icon {
    margin-left: 0;
  }
}
</style>