<template>
  <div>
    <div
      class="table-container"
      :class="{ 'table-container-odd': !isPairNumberTableHeader }"
    >
      <!-- Fila 1 -->
      <!-- Asignatura -->
      <div class="table-cell d-flex" v-if="matter">
        <div class="primary-cell">NOMBRE</div>
        <div class="secundary-cell">
          <strong>{{ matter.name }}</strong>
        </div>
      </div>
      <!-- CODE -->
      <div class="table-cell d-flex" v-if="matter">
        <div class="primary-cell">
          {{
            $getVisibleNames(
              "manual.matter_code",
              false,
              "Código"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell">
          <strong>{{ matter.code }}</strong>
        </div>
      </div>
      <!-- Facultad -->
      <div class="table-cell d-flex" v-if="faculty">
        <div class="primary-cell">
          {{
            $getVisibleNames("mesh.faculty", false, "FACULTAD").toUpperCase()
          }}
        </div>
        <div class="secundary-cell">
          <strong>{{ faculty.name }}</strong>
        </div>
      </div>
      <!-- Carrera -->
      <div class="table-cell d-flex" v-if="career">
        <div class="primary-cell">
          {{ $getVisibleNames("mesh.career", false, "Programa").toUpperCase() }}
        </div>
        <div class="secundary-cell">
          <strong>{{ career.name }}</strong>
        </div>
      </div>
      <!-- Perfil de Egreso -->
      <div class="table-cell d-flex" v-if="egressProfile">
        <div class="primary-cell">
          {{
            $getVisibleNames(
              "mesh.egressprofile",
              false,
              "Perfil de Egreso"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell">
          <strong>{{ egressProfile.name }}</strong>
        </div>
      </div>
      <!-- Fila 2 -->
      <!-- Tipo -->
      <div class="table-cell d-flex">
        <div class="primary-cell">TIPO</div>
        <div class="secundary-cell">
          <div v-if="type">
            <span>{{ type.value }}</span>
          </div>
        </div>
      </div>
      <!-- Ponderación -->
      <div class="table-cell d-flex">
        <div class="primary-cell">PONDERACIÓN</div>
        <div class="secundary-cell">
          <span v-if="evaluation">
            <template v-if="evaluation.type == 1">
              {{ evaluation.weighing }}%
            </template>
            <template v-else> N/A</template>
          </span>
        </div>
      </div>
      <!-- Fila 3 -->
      <!-- Creador -->
      <div class="table-cell d-flex" v-if="evaluation && evaluation.scope == 3">
        <div class="primary-cell">CREADO POR</div>
        <div class="secundary-cell name-creator">
          <span v-if="evaluation"> {{ user(evaluation.created_by) }} </span>
        </div>
      </div>
      <!-- Fecha de Creación -->
      <div class="table-cell d-flex" v-if="evaluation && evaluation.scope == 3">
        <div class="primary-cell">FECHA DE CREACIÓN</div>
        <div class="secundary-cell create-date">
          <span v-if="evaluation">{{
            evaluation.creation_date | FormatToDateTime
          }}</span>
        </div>
      </div>
      <!-- Evaluadores -->
      <!-- <div class="table-cell d-flex">
        <div class="primary-cell">EVALUADORES (Profesor(es))</div>
        <div class="secundary-cell">
          <template v-if="evaluation">
            <template v-for="evaluator in evaluation.evaluators">
              <span :key="evaluator" class="evaluators">{{
                user(evaluator)
              }}</span>
            </template>
          </template>
        </div>
      </div> -->
      <!-- Categoría -->
      <!-- <div class="table-cell d-flex">
        <div class="primary-cell">CATEGORÍA</div>
        <div class="secundary-cell">
          <span v-if="categorie">{{ categorie.value }}</span>
        </div>
      </div> -->
      <!-- Scope -->
      <div
        v-if="evaluation && (evaluation.scope == 1 || evaluation.scope == 7)"
        class="table-cell d-flex"
      >
        <div class="primary-cell">{{ dinamicHeader.toUpperCase() }}</div>
        <div class="secundary-cell create-date">
          <span v-if="evaluation">{{ findScopedObjId }}</span>
        </div>
      </div>
      <!-- Fecha de Edición -->
      <!-- <div class="table-cell d-flex">
        <div class="primary-cell">FECHA DE EDICIÓN</div>
        <div class="secundary-cell">
          <span v-if="evaluation">{{
            evaluation.update_date | FormatToDateTime
          }}</span>
        </div>
      </div> -->
    </div>
    <!-- Descripción -->
    <div
      v-if="evaluation && evaluation.description"
      class="table-cell-description"
    >
      <div class="primary-cell-description">DESCRIPCIÓN</div>
      <div class="secundary-cell-description">
        <div class="rich-text-content" v-html="evaluation.description"></div>
      </div>
    </div>
    <!-- Archivos Adjuntos -->
    <div
      v-if="(evaluation && evaluation.files.length > 0) || allows_crud"
      class="table-cell-description"
    >
      <div class="primary-cell-description">
        ARCHIVOS ADJUNTOS
        <button-add
          v-if="allows_crud"
          class="add-button"
          :title="`Agregar Archivo Adjunto`"
          v-b-tooltip.v-secondary.top.noninteractive="`Agregar Archivo Adjunto`"
          @click="
            $bvModal.show(`modal-select-file-evaluation-${evaluation_id}`)
          "
          size="sm"
        >
        </button-add>
      </div>
      <div class="secundary-cell-description table-left">
        <div v-if="evaluation">
          <FileContainer
            :Files="evaluation.files"
            :allows_crud="allows_crud"
          ></FileContainer>
        </div>
      </div>
    </div>
    <b-modal
      :id="`modal-select-file-evaluation-${evaluation_id}`"
      title="Seleccionar Archivos"
      hide-footer
    >
      <FileSelector @created="slotCreatedFile"></FileSelector>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";

export default {
  name: "HeaderEvaluation",
  components: {
    FileContainer: () => import("@/components/reusable/FileContainer"),
    FileSelector: () => import("@/components/reusable/FileSelector"),
  },
  props: {
    evaluation_id: {
      required: true,
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      faculties: names.FACULTIES,
      careers: names.CAREERS,
      egress_profiles: names.EGRESS_PROFILES,
      evaluations: names.NEW_EVALUATIONS,
      sections: names.SECTIONS,
      egress_profile_matters: names.EGRESS_PROFILES_MATTERS,
      matters: names.MATTERS,
      users: names.USERS,
      types: names.NEW_EVALUATION_TYPES,
      categories: names.NEW_EVALUATION_CATEGORIES,
      campuses: names.CAMPUSES,
    }),
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    numberTableHeader() {
      let column = 2; // Tipo, Ponderación
      if (this.matter) column += 2; // Asignatura, Codigo,
      if (this.faculty) column += 1; // Facultad,
      if (this.career) column += 1; // Carrera
      if (this.egressProfile) column += 1; // Perfil de Egreso
      if (this.evaluation && this.evaluation.scope == 3) column += 2; // Creaddor, Fecha de Creación
      if (
        this.evaluation &&
        (this.evaluation.scope == 1 || this.evaluation.scope == 7)
      )
        column += 1; // Scope
      return column;
    },
    isPairNumberTableHeader() {
      return this.numberTableHeader % 2 == 0;
    },
    dinamicHeader() {
      if (this.evaluation && this.evaluation.scope == 1)
        return this.$getVisibleNames("teaching.section", false, "Sección");
      else if (this.evaluation && this.evaluation.scope == 7)
        return this.$getVisibleNames("mesh.campus", false, "Sede");
      else return "";
    },
    findScopedObjId() {
      if (this.evaluation && this.evaluation.scope == 1)
        return (
          (this.sections.find((x) => x.id == this.evaluation.scope_id) || {})
            .name || ""
        );
      else if (this.evaluation && this.evaluation.scope == 7)
        return (
          (this.campuses.find((x) => x.id == this.evaluation.scope_id) || {})
            .name || ""
        );
      else return "";
    },
    section() {
      if (
        this.evaluation &&
        this.evaluation.scope == 1 &&
        this.evaluation.scope_id != null
      )
        return this.sections.find((x) => x.id == this.evaluation.scope_id);
      else return null;
    },
    egress_profile_matter() {
      if (
        this.evaluation &&
        this.evaluation.scope == 1 &&
        this.evaluation.scope_id != null &&
        this.section &&
        this.section.egress_profile_matter != null
      ) {
        return this.egress_profile_matters.find(
          (x) => x.id == this.section.egress_profile_matter
        );
      } else if (
        this.evaluation &&
        this.evaluation.scope == 2 &&
        this.evaluation.scope_id != null
      )
        return this.egress_profile_matters.find(
          (x) => x.id == this.evaluation.scope_id
        );
      else return null;
    },
    matter() {
      if (this.evaluation && [1, 2, 3].includes(this.evaluation.scope)) {
        if (this.evaluation.scope == 3 && this.evaluation.scope_id != null)
          return this.matters.find((x) => x.id == this.evaluation.scope_id);
        else if (
          this.evaluation.scope == 2 &&
          this.evaluation.scope_id != null &&
          this.egress_profile_matter
        ) {
          return this.matters.find(
            (x) => x.id == this.egress_profile_matter.matter
          );
        } else if (
          this.evaluation.scope == 1 &&
          this.evaluation.scope_id != null &&
          this.section
        ) {
          if (this.section.matter != null)
            return this.matters.find((x) => x.id == this.section.matter);
          else if (
            this.section.egress_profile_matter != null &&
            this.egress_profile_matter
          ) {
            return this.matters.find(
              (x) => x.id == this.egress_profile_matter.matter
            );
          }
        }
        return null;
      } else return null;
    },
    egressProfile() {
      if (
        this.evaluation &&
        this.evaluation.scope == 4 &&
        this.evaluation.scope_id != null
      ) {
        return this.egress_profiles.find(
          (x) => x.id == this.evaluation.scope_id
        );
      } else if (this.egress_profile_matter) {
        return this.egress_profiles.find(
          (x) => x.id == this.egress_profile_matter.egress_profile
        );
      } else return null;
    },
    career() {
      if (
        this.evaluation &&
        this.evaluation.scope == 5 &&
        this.evaluation.scope_id != null
      ) {
        return this.careers.find((x) => x.id == this.evaluation.scope_id);
      } else if (this.egressProfile) {
        return this.careers.find((x) => x.id == this.egressProfile.career);
      } else return null;
    },
    faculty() {
      if (
        this.evaluation &&
        this.evaluation.scope == 6 &&
        this.evaluation.scope_id != null
      ) {
        return this.faculties.find((x) => x.id == this.evaluation.scope_id);
      } else if (this.career) {
        return this.faculties.find((x) => x.id == this.career.faculty);
      } else return null;
    },
    type() {
      if (!this.evaluation) return null;
      return this.types.find((x) => x.id == this.evaluation.type);
    },
    categorie() {
      if (!this.evaluation) return null;
      return this.categories.find((x) => x.id == this.evaluation.category);
    },
  },
  methods: {
    user(user_id) {
      const user = this.users.find((x) => x.id == user_id);
      if (user) return user.first_name + " " + user.last_name;
      else return null;
    },
    slotCreatedFile(file) {
      if (file) {
        const files = this.evaluation.files.concat([file.id]);
        if (!isNaN(this.evaluation.id)) {
          this.$store.dispatch(names.PATCH_NEW_EVALUATION, {
            new_evaluation_id: this.evaluation.id,
            item: {
              files: files,
            },
          });
        }
      }
      this.$bvModal.hide(`modal-select-file-evaluation-${this.evaluation_id}`);
    },
  },
  watch: {},
  mounted() {},
  created() {
    this.$store
      .dispatch(names.FETCH_NEW_EVALUATION, this.evaluation_id)
      .then((evaluation) => {
        if (evaluation.scope == 1) {
          if (evaluation.scope_id != null) {
            this.$store
              .dispatch(names.FETCH_SECTION, evaluation.scope_id)
              .then((section) => {
                if (section.matter != null) {
                  this.$store.dispatch(names.FETCH_MATTER, section.matter);
                } else if (section.egress_profile_matter != null) {
                  this.$store
                    .dispatch(
                      names.FETCH_EGRESS_PROFILE_MATTER,
                      section.egress_profile_matter
                    )
                    .then((egress_profile_matter) => {
                      this.$store
                        .dispatch(
                          names.FETCH_EGRESS_PROFILE,
                          egress_profile_matter.egress_profile
                        )
                        .then((egress_profile) => {
                          this.$store
                            .dispatch(names.FETCH_CAREER, egress_profile.career)
                            .then((career) => {
                              this.$store.dispatch(
                                names.FETCH_FACULTY,
                                career.faculty
                              );
                            });
                        });
                      this.$store.dispatch(
                        names.FETCH_MATTER,
                        egress_profile_matter.matter
                      );
                    });
                }
              });
          }
        } else if (evaluation.scope == 2) {
          if (evaluation.scope_id != null) {
            this.$store
              .dispatch(names.FETCH_EGRESS_PROFILE_MATTER, evaluation.scope_id)
              .then((egress_profile_matter) => {
                this.$store
                  .dispatch(
                    names.FETCH_EGRESS_PROFILE,
                    egress_profile_matter.egress_profile
                  )
                  .then((egress_profile) => {
                    this.$store
                      .dispatch(names.FETCH_CAREER, egress_profile.career)
                      .then((career) => {
                        this.$store.dispatch(
                          names.FETCH_FACULTY,
                          career.faculty
                        );
                      });
                  });
                this.$store.dispatch(
                  names.FETCH_MATTER,
                  egress_profile_matter.matter
                );
              });
          }
        } else if (evaluation.scope == 3) {
          if (evaluation.scope_id != null) {
            this.$store.dispatch(names.FETCH_MATTER, evaluation.scope_id);
          }
        } else if (evaluation.scope == 4) {
          if (evaluation.scope_id != null) {
            this.$store
              .dispatch(names.FETCH_EGRESS_PROFILE, evaluation.scope_id)
              .then((egress_profile) => {
                this.$store
                  .dispatch(names.FETCH_CAREER, egress_profile.career)
                  .then((career) => {
                    this.$store.dispatch(names.FETCH_FACULTY, career.faculty);
                  });
              });
          }
        } else if (evaluation.scope == 5) {
          if (evaluation.scope_id != null) {
            this.$store
              .dispatch(names.FETCH_CAREER, evaluation.scope_id)
              .then((career) => {
                this.$store.dispatch(names.FETCH_FACULTY, career.faculty);
              });
          }
        } else if (evaluation.scope == 6) {
          if (evaluation.scope_id != null) {
            this.$store.dispatch(names.FETCH_FACULTY, evaluation.scope_id);
          }
        }

        this.$store.dispatch(names.FETCH_USER, evaluation.created_by);
        // this.$store.dispatch(names.FETCH_ALT_USERS, {
        //   user_ids: evaluation.evaluators,
        // });
      });
    this.$store.dispatch(names.FETCH_NEW_EVALUATION_TYPES);
    this.$store.dispatch(names.FETCH_NEW_EVALUATION_CATEGORIES);
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.table-container-odd > :last-child {
  grid-column: span 2; /* Hace que el último hijo ocupe todas las columnas disponibles */
  display: flex;
  flex-direction: column;
}
.table-container-odd > :last-child .primary-cell,
.table-container-odd > :last-child .secundary-cell {
  width: 100%;
}
.table-container-odd > :last-child .primary-cell {
  justify-content: center;
}
.badge-profile {
  background: #007bff;
  color: white;
  border: 1px solid #c2c2c2;
  padding: 5px;
}
.add-button {
  color: white;
}
.name-creator {
  color: black;
  font-weight: bold;
}
.table-cell-description {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 10pt;
}
.primary-cell-description {
  height: 100%;
  width: 100%;
  padding-left: 2em;
  align-items: center;
  background-color: var(--kl-menu-color) !important;
  color: white;
  font-weight: bold;
  text-align: center;
}
.secundary-cell-description {
  padding: 5px;
  width: 100%;
}
.table-left {
  display: flex;
  justify-content: left;
}
.table-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 10pt;
  gap: 0px;
}
.table-cell {
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.primary-cell {
  height: 100%;
  width: 40%;
  display: flex;
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-items: center;
  background-color: var(--kl-menu-color) !important;
  color: white;
  font-weight: bold;
  text-align: start;
}
.secundary-cell {
  padding: 4px;
  width: 60%;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* font-weight: 500; */
}
.evaluators {
  vertical-align: middle;
}

@media (max-width: 1100px) {
  .table-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 750px) {
  .table-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .table-container-odd > :last-child {
    grid-column: span 1; /* Hace que el último hijo ocupe todas las columnas disponibles */
    display: grid;
    grid-template-columns: repeat(
      1,
      1fr
    ); /* Cada elemento ocupa una fila completa */
  }

  /* Estilos para primary-cell y secundary-cell del último hijo */
  .table-container-odd > :last-child .primary-cell,
  .table-container-odd > :last-child .secundary-cell {
    width: 100%; /* Ocupa todo el ancho disponible */
  }
}
@media print {
  .table-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .table-container-odd > :last-child {
    display: grid !important;
    grid-column: span 2;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .table-container-odd > :last-child .primary-cell {
    grid-column: span 2;
  }
  .table-container-odd > :last-child .secundary-cell {
    padding-left: 10px !important;
    grid-column: span 2;
    justify-content: left;
  }
}
</style>
