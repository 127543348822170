<template>
  <div class="px-1">
    <h4 class="mt-4 mb-0">
      {{ $getVisibleNames("mesh.egressprofile", false, "Perfil de Egreso") }}
      <AlternativeNameButton
        :table_name="'mesh.egressprofile'"
        :allows_crud="allows_crud"
      ></AlternativeNameButton>
      <template v-if="egressProfile">
        <template v-if="allows_crud">
          <button-edit
            v-can="'mesh.change_egressprofile'"
            @click="
              $bvModal.show(`modal-edit-egressprofile-${egressProfile.id}`)
            "
            v-b-tooltip.v-secondary.noninteractive="
              `Editar ${$getVisibleNames(
                'mesh.egressprofile',
                false,
                'Perfil de Egreso'
              )}`
            "
          ></button-edit>
        </template>
        <b-modal
          :id="`modal-edit-egressprofile-${egressProfile.id}`"
          hide-footer
          :title="`Editar ${$getVisibleNames(
            'mesh.egressprofile',
            false,
            'Perfil de Egreso'
          )}`"
          size="xl"
        >
          <EgressProfileForm
            :EgressProfile="egressProfile"
            :career_id="egressProfile.career"
            :show_selected_career="false"
            :show_delete_button="false"
            @updated="
              $bvModal.hide(`modal-edit-egressprofile-${egressProfile.id}`)
            "
          ></EgressProfileForm>
        </b-modal>
      </template>
      <template
        v-if="
          (isClosedEgressProfile && allows_crud) ||
          (isClosedEgressProfile && egressProfile && egressProfile.is_closed)
        "
      >
        <b-button
          class="noprint"
          size="sm"
          variant="none"
          v-b-tooltip.v-secondary.noninteractive="
            `Cambiar el estado de la ${$getVisibleNames(
              'mesh.egressprofile',
              false,
              'Perfil de Egreso'
            )}`
          "
          @click="changeStateEgressProfile()"
        >
          <template v-if="egressProfile && egressProfile.is_closed">
            <b-icon class="btn-actions" icon="lock" scale="1"></b-icon>
          </template>
          <template v-else>
            <b-icon class="btn-actions" icon="unlock" scale="1"></b-icon>
          </template>
        </b-button>
      </template>
      <!-- <EgressProfileLinkage
          :egress_profile_id="egress_profile_id"
          :allows_crud="allows_crud"
        ></EgressProfileLinkage> -->
      <!-- Perfil de Egreso -->
    </h4>
    <div class="d-flex">
      <template>
        <div class="ml-auto mr-0 noprint">
          <b-button
            class="mb-2 ml-3 noprint"
            size="sm"
            @click="
              redirectToMatterProgramByEgressProfileView(egress_profile_id)
            "
            ><b-icon icon="box-arrow-up-right"></b-icon> EVALUACIONES</b-button
          >
          <b-button
            v-if="
              (institution && institution.internal_use_id == 'duoc_uc') ||
              $debug_change_duoc
            "
            variant="secondary"
            size="sm"
            class="mb-2 ml-3 noprint"
            :disabled="disabled_button_excel"
            @click="DownloadExcel()"
          >
            XLSX Formato Creación Plan y Asignatura
          </b-button>
        </div>
      </template>
      <template
        v-if="
          (institution && institution.internal_use_id == 'duoc_uc') ||
          (institution && institution.internal_use_id == 'unab') ||
          $debug_change_duoc ||
          $debug_change_unab
        "
      >
        <div class="noprint">
          <b-button
            variant="secondary"
            size="sm"
            class="mb-2 ml-3 noprint"
            @click="DownloadDocument()"
          >
            Formato de salida de
            {{
              $getVisibleNames(
                "mesh.egressprofile",
                false,
                "Perfil de Egreso"
              ).toLowerCase()
            }}
          </b-button>
        </div>
      </template>
    </div>
    <EgressProfile
      :egress_profile_id="egress_profile_id"
      :allows_crud="allows_crud"
    ></EgressProfile>

    <MeshCustomFab
      v-if="egress_profile_id"
      :egress_profile_id="egress_profile_id"
      :show_egress_profile="false"
      class="noprint"
    ></MeshCustomFab>
  </div>
</template>

<script>
// import { URL } from "@/utils/globals";
import { mapGetters } from "vuex";
import * as names from "@/store/names";

export default {
  name: "EgressProfileView",
  components: {
    EgressProfile: () => import("./EgressProfile"),
    EgressProfileForm: () =>
      import("@/components/mesh/EgressProfile/EgressProfileForm"),
    MeshCustomFab: () => import("../MeshCustomFab"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
    // EgressProfileLinkage: () => import("./EgressProfileLinkage"),
  },
  data() {
    return {
      egress_profile_id: Number(this.$route.params.egress_profile_id),
      disabled_button_excel: false,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      egressProfiles: names.EGRESS_PROFILES,
      user: "getUser",
      user_position: names.USER_POSITION,
      careers: names.CAREERS,
    }),
    egressProfile() {
      if (!this.egress_profile_id) return [];
      return this.egressProfiles.find((x) => x.id == this.egress_profile_id);
    },
    career() {
      if (!this.egressProfile) return null;
      return this.careers.find((x) => x.id == this.egressProfile.career);
    },
    isClosedEgressProfile() {
      if (
        this.user.is_superuser ||
        this.$hasObjectPermission(
          "special.close_egress_profile",
          "special",
          this.egress_profile_id
        )
      ) {
        return true;
      } else return false;
    },
    allows_crud() {
      if (
        this.career &&
        this.egressProfile &&
        !this.egressProfile.is_closed &&
        ((this.user_position &&
          [1, 2].includes(this.user_position.position) &&
          [1, 2].includes(this.user.groups[0])) ||
          this.user.is_superuser ||
          this.$hasObjectPermission(
            "mesh.faculty",
            "change",
            this.career.faculty
          ) ||
          this.$hasObjectPermission(
            "mesh.career",
            "change",
            this.egressProfile.career
          ))
      ) {
        const has_permission = this.$hasPermissions(
          "mesh.change_egressprofile"
        );
        if (has_permission) {
          this.$store.commit(names.MUTATE_VISIBILITY_CRUD, true);
          return has_permission;
        } else {
          return false;
        }
      } else {
        this.$store.commit(names.MUTATE_VISIBILITY_CRUD, false);
        return false;
      }
    },
  },
  methods: {
    redirectToMatterProgramByEgressProfileView() {
      const url = this.$router.resolve({
        name: "MatterProgramByEgressProfileView",
        params: {
          egress_profile_id: this.egress_profile_id,
        },
      }).href;
      window.open(url, "_blank");
    },
    DownloadDocument() {
      this.$restful
        .Get(
          `/teaching/word_report_egress_profile_output/?egress_profile=${this.egress_profile_id}`
        )
        .then((response) => {
          // URL + `/teaching/word_report_egress_profile_output/?egress_profile=${this.egress_profile_id}`,
          if (response && response.file_url)
            window.open(response.file_url, "_blank");
        });
    },
    DownloadExcel() {
      this.disabled_button_excel = true;
      this.$restful
        .Get(
          `/mesh/excel_report_duoc_academic_record/?egress_profile=${this.egress_profile_id}`
        )
        .then((response) => {
          // URL + `/teaching/word_report_egress_profile_output/?egress_profile=${this.egress_profile_id}`,
          if (response && response.file_url) {
            this.disabled_button_excel = false;
            window.open(response.file_url, "_blank");
          }
        });
    },
    changeStateEgressProfile() {
      const payload = {
        egress_profile_id: this.egressProfile.id,
        item: {
          is_closed: !this.egressProfile.is_closed,
        },
      };
      this.$store
        .dispatch(names.PATCH_EGRESS_PROFILE, payload)
        .then((response) => {
          if (!response.is_closed)
            this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, true);
          else
            this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, false);
        });
    },
  },
  mounted() {
    this.$store.commit(names.MUTATE_PRINT_ORIENTATION, false);
    this.$store.commit(names.MUTATE_PRINT_ZOOM_SCALE, 1);
  },
  beforeDestroy() {
    this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, false);
    this.$store.commit(names.MUTATE_DELETE_RULES_FILTER_SELECTOR);
    this.$store.commit(names.MUTATE_DELETE_FILTER_INTELLIGENCE);
    this.$store.commit(names.RESET_PRINT_ZOOM_SCALE);
    this.$store.commit(names.RESET_PRINT_ORIENTATION);
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
  },
  created() {
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.egress_profile_id)
      .then((egress_profile) => {
        this.$nextTick(() => {
          if (!egress_profile.is_closed)
            this.$store.commit(names.MUTATE_SHOW_INTELLIGENCE_SIDEBAR, true);
          this.$store.commit(names.MUTATE_RULES_FILTER_SELECTOR, {
            visible_intelligence_options: [1, 7],
            disabled_selected_egress_profile: true,
          });
          this.$store.commit(names.MUTATE_FILTER_INTELLIGENCE, {
            egress_profile_id: this.egress_profile_id,
            intelligence_option: 1,
          });
        });
        this.$store.dispatch(names.FETCH_CAREER, egress_profile.career);
        this.$store.commit(
          names.MUTATE_TITLE_OF_THE_PRINT,
          `${this.$getVisibleNames(
            "mesh.egressprofile",
            false,
            "Perfil De Egreso"
          )} ${egress_profile.name}`
        );
      });
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES_MATTERS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_MATTERS, {
      egress_profile_id: this.egress_profile_id,
    });
    this.$store.dispatch(names.FETCH_COURSES_WRAPPER, {
      egress_profile_id: this.egress_profile_id,
    });
  },
};
</script>

<style scoped>
</style>
