<template>
  <div
    :class="{
      'unselectable-text':
        (user && user.groups.includes(7)) || preview_evaluation,
    }"
  >
    <draggable
      v-model="testSegments"
      :disabled="!allows_crud"
      v-bind="dragOptions"
      class="draggable-segment"
      group="segment"
      handle=".handle-segment"
    >
      <transition-group type="transition" name="flip-list">
        <template>
          <div
            class="segment-container"
            :class="{ 'segment-container-min-heigth': allows_crud }"
            v-for="segment in testSegments"
            :key="segment.id"
          >
            <div
              class="title-top-segment"
              v-if="allows_crud || segment.title.trim() != ''"
            >
              <div
                v-if="allows_crud"
                class="question-move px-1 handle-segment"
                :class="{ 'grabbable-cursor': allows_crud }"
              >
                <b-icon
                  class="move-icon"
                  icon="arrows-expand"
                  scale="1.3"
                ></b-icon>
              </div>
              <div class="w-100 right-container-segment">
                <div
                  class="pl-2 w-100 title-segment rich-text-content"
                  v-b-tooltip.top.noninteractive.v-secondary="
                    `${
                      allows_crud && !segment.is_locked ? 'Editar título' : ''
                    }`
                  "
                  :class="{
                    'title-container': allows_crud && !segment.is_locked,
                  }"
                  @click="showModalSegment(segment)"
                  v-html="segment.title"
                ></div>
                <div
                  class="ml-0 title-segment"
                  :class="{
                    'container-buttons-allow-cruds':
                      allows_crud || test.manual_is_valid,
                  }"
                >
                  <div
                    v-b-tooltip.top.noninteractive.v-secondary="
                      test.manual_is_valid
                        ? `La ${$getVisibleNames(
                            'evaluations2.testsegment',
                            false,
                            'Segmento'
                          )} se encuentra desbloqueada y cerrada.`
                        : `Bloquear ${$getVisibleNames(
                            'evaluations2.testsegment',
                            false,
                            'Segmento'
                          )}`
                    "
                  >
                    <b-button
                      v-if="
                        (!segment.is_locked &&
                          allows_crud &&
                          user &&
                          !user.groups.includes(6)) ||
                        (test.manual_is_valid &&
                          !segment.is_locked &&
                          !preview_evaluation &&
                          user &&
                          !user.groups.includes(6))
                      "
                      variant="primary"
                      size="sm"
                      class="lock-btn p-1 mr-1 noprint"
                      :disabled="test.manual_is_valid"
                      @click="changeStateSegment(segment)"
                    >
                      <b-icon-unlock-fill></b-icon-unlock-fill>
                    </b-button>
                  </div>
                  <div
                    v-b-tooltip.top.noninteractive.v-secondary="
                      test.manual_is_valid
                        ? `La ${$getVisibleNames(
                            'evaluations2.testsegment',
                            false,
                            'Segmento'
                          )} se encuentra bloqueada y cerrada.`
                        : `Desbloquear ${$getVisibleNames(
                            'evaluations2.testsegment',
                            false,
                            'Segmento'
                          )}`
                    "
                  >
                    <b-button
                      v-if="
                        (segment.is_locked &&
                          allows_crud &&
                          user &&
                          !user.groups.includes(6)) ||
                        (test.manual_is_valid &&
                          segment.is_locked &&
                          !preview_evaluation &&
                          user &&
                          !user.groups.includes(6))
                      "
                      variant="primary"
                      size="sm"
                      class="lock-btn p-1 mr-1 noprint"
                      :disabled="test.manual_is_valid"
                      @click="changeStateSegment(segment)"
                    >
                      <b-icon-lock-fill></b-icon-lock-fill>
                    </b-button>
                  </div>
                  <b-button
                    variant="danger"
                    class="delete-btn"
                    v-if="allows_crud && !segment.is_locked"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Eliminar ${$getVisibleNames(
                        'evaluations2.testsegment',
                        false,
                        'Segmento'
                      )}`
                    "
                    @click="askForDeleteSegment(segment.id)"
                  >
                    <b-icon-trash></b-icon-trash>
                  </b-button>
                </div>
              </div>
              <b-modal
                :id="`edit-title-segment-modal-${segment.id}-${instrument_id}-${container_instrument_id}`"
                title="Redactar enunciado del título."
                size="lg"
                hide-footer
              >
                <NewRichTextEditor
                  :Object="segment"
                  :Text="segment.title"
                  :hide_title="true"
                  :permit_blank="true"
                  @save="patchSegmentTitle"
                  @close="
                    $bvModal.hide(
                      `edit-title-segment-modal-${segment.id}-${instrument_id}-${container_instrument_id}`
                    )
                  "
                ></NewRichTextEditor>
              </b-modal>
            </div>
            <QuestionsContainer
              :ref="`segment-${segment.id}-${instrument_id}`"
              :container_instrument_id="container_instrument_id"
              :isLoading="is_loading"
              :segment_id="segment.id"
              :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
              :instrument_id="instrument_id"
              :allows_crud="allows_crud && !segment.is_locked"
              :preview_evaluation="preview_evaluation"
              :checked_score="checked_score"
              :checked_weightning="checked_weightning"
              :evaluatee_view="evaluatee_view"
              :evaluator_view="evaluator_view"
              :is_running_test="is_running_test"
              :finished_test="finished_test"
              :user_id="user_id"
              :evaluation_situations="evaluation_situations"
              :closedEvaluationScore="closedEvaluationScore"
              :is_published="is_published"
            ></QuestionsContainer>
          </div>
        </template>
      </transition-group>
    </draggable>
    <div class="container-buttons">
      <b-button
        v-if="
          allows_crud &&
          test &&
          !test.segment_creation_is_locked &&
          (!evaluationSituation ||
            (evaluationSituation &&
              ![
                'duoc_ejecucion_practica_test',
                'duoc_entrega_de_encargo_test',
                'duoc_presentacion_test',
              ].includes(evaluationSituation.internal_use_id)))
        "
        variant="info"
        size="sm"
        @click="
          $bvModal.show(
            `modal-created-segment-${instrument_id}-${container_instrument_id}`
          )
        "
      >
        + Crear
        {{ $getVisibleNames("evaluations2.testsegment", false, "Segmento") }}
      </b-button>
    </div>
    <b-modal
      :id="`modal-created-segment-${instrument_id}-${container_instrument_id}`"
      :title="`Crear ${$getVisibleNames(
        'evaluations2.testsegment',
        false,
        'Segmento'
      )}`"
      hide-footer
      size="lg"
    >
      <NewRichTextEditor
        :Text="''"
        @save="createSegment"
        :hide_title="true"
        :permit_blank="true"
        @close="
          $bvModal.hide(
            `modal-created-segment-${instrument_id}-${container_instrument_id}`
          )
        "
      ></NewRichTextEditor>
    </b-modal>
    <QuestionsContainer
      :ref="`segment-null-${instrument_id}`"
      :container_instrument_id="container_instrument_id"
      :isLoading="is_loading"
      :instrument_id="instrument_id"
      :evaluation_criteria_micro_ids="evaluation_criteria_micro_ids"
      :allows_crud="allows_crud"
      :preview_evaluation="preview_evaluation"
      :checked_score="checked_score"
      :checked_weightning="checked_weightning"
      :evaluatee_view="evaluatee_view"
      :evaluator_view="evaluator_view"
      :is_running_test="is_running_test"
      :finished_test="finished_test"
      :user_id="user_id"
      :evaluation_situations="evaluation_situations"
      :closedEvaluationScore="closedEvaluationScore"
      :is_published="is_published"
    ></QuestionsContainer>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";

export default {
  name: "BodyInsrument",
  components: {
    draggable,
    QuestionsContainer: () => import("./Questions/QuestionsContainer"),
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
  },
  props: {
    instrument_id: {
      required: true,
      type: Number,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    container_instrument_id: {
      type: String,
    },
    checked_score: {
      type: Boolean,
    },
    checked_weightning: {
      type: Boolean,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    is_running_test: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
    // para mostrar el resultado del evaluado al profesor.
    user_id: {
      type: Number,
      required: false,
    },
    // para restringir que el evaluador pueda colocar notas fuera del periodo actual con respecto al de la sección.
    closedEvaluationScore: {
      type: Boolean,
      default: false,
    },
    // Para que solo uno de los componentes pida las peticiones y el otro solo sirva para el imprimir todos los test en la misma línea.
    // y eso se ejecutaria solo si es !evaluatee_view && !evaluator_view
    requests_within_the_component: {
      type: Boolean,
      default: true,
    },
    evaluation_situations: {
      type: Array,
    },
    is_published: {
      type: Boolean,
    },
  },
  data() {
    return {
      is_loading: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      test_segments: names.NEW_TEST_SEGMENTS,
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      filling_questions: names.NEW_FILLING_QUESTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
      tests: names.NEW_TESTS,
    }),
    test() {
      return this.tests.find((x) => x.id == this.instrument_id);
    },
    evaluationSituation() {
      if (!this.test) return null;
      return this.evaluation_situations.find(
        (x) => x.id == this.test.evaluation_situation
      );
    },
    testSegments: {
      get() {
        return this.test_segments
          .filter((x) => x.test == this.instrument_id)
          .sort((a, b) => a.order - b.order);
      },
      set(list) {
        list.forEach((element, index) => {
          if (!isNaN(element.id)) {
            let payload = {
              new_test_segment_id: element.id,
              item: {
                order: index + 1,
              },
            };
            this.$store.dispatch(names.PATCH_NEW_TEST_SEGMENT, payload);
          }
        });
      },
    },
    questions() {
      let list = [];
      list = list.concat(
        this.redactionQuestions.map((question) => ({
          ...question,
          question_type: 1,
        }))
      );
      list = list.concat(
        this.multipleChoiceQuestions.map((question) => ({
          ...question,
          question_type: 2,
        }))
      );
      // list = list.concat(
      //   this.fillingQuestions.map((question) => ({
      //     ...question,
      //     question_type: 3,
      //   }))
      // );
      list = list.concat(
        this.matchingQuestions.map((question) => ({
          ...question,
          question_type: 4,
        }))
      );
      return list.sort((a, b) => a.order - b.order);
    },
    redactionQuestions() {
      return this.redaction_questions.filter(
        (x) => x.test == this.instrument_id
      );
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter(
        (x) => x.test == this.instrument_id
      );
    },
    fillingQuestions() {
      return this.filling_questions.filter((x) => x.test == this.instrument_id);
    },
    matchingQuestions() {
      return this.matching_questions.filter(
        (x) => x.test == this.instrument_id
      );
    },
    dragOptions() {
      return {
        animation: 0,
        group: "question",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  methods: {
    changeStateSegment(segment) {
      const payload = {
        new_test_segment_id: segment.id,
        item: {
          is_locked: !segment.is_locked,
        },
      };
      this.$store.dispatch(names.PATCH_NEW_TEST_SEGMENT, payload);
    },
    // filteredItems(segment) {
    //   return this.questions.filter((item) => item.segment == segment);
    // },
    showModalSegment(segment) {
      if (this.allows_crud && !segment.is_locked)
        this.$bvModal.show(
          `edit-title-segment-modal-${segment.id}-${this.instrument_id}-${this.container_instrument_id}`
        );
    },
    createSegment(text) {
      this.$store.dispatch(names.POST_NEW_TEST_SEGMENT, {
        title: text,
        order:
          this.testSegments.length > 0
            ? this.testSegments[this.testSegments.length - 1].order + 1
            : 1,
        test: this.instrument_id,
      });
    },
    patchSegmentTitle(segment, text) {
      const payload = {
        new_test_segment_id: segment.id,
        item: {
          title: text,
        },
      };
      this.$store.dispatch(names.PATCH_NEW_TEST_SEGMENT, payload);
    },
    askForDeleteSegment(segment_id) {
      this.$swal({
        title: `<p>¿Está seguro de que desea eliminar el <b>${this.$getVisibleNames(
          "evaluations2.testsegment",
          false,
          "Segmento"
        )} y sus Preguntas</b>?</p>`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
        showConfirmButton:
          this.questions.filter((x) => x.segment == segment_id).length != 0
            ? true
            : false,
        showCloseButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: `Eliminar conjunto`,
        cancelButtonText:
          this.questions.filter((x) => x.segment == segment_id).length != 0
            ? `Solo ${this.$getVisibleNames(
                "evaluations2.testsegment",
                false,
                "Segmento"
              )}`
            : `Eliminar ${this.$getVisibleNames(
                "evaluations2.testsegment",
                false,
                "Segmento"
              )}`,
      }).then((result) => {
        // Eliminar Conjunto
        if (result.value) {
          this.deleteSegmentGroup(segment_id);
        } else if (result.dismiss == "cancel") {
          this.$store.dispatch(names.DELETE_NEW_TEST_SEGMENT, segment_id);
        }
      });
    },
    async deleteSegmentGroup(segment_id) {
      for await (let redaction_quesiton of this.redaction_questions.filter(
        (x) => x.test == this.instrument_id && x.segment == segment_id
      )) {
        await this.$store.dispatch(
          names.DELETE_NEW_REDACTION_QUESTION,
          redaction_quesiton.id
        );
      }
      for await (let multiple_choice_quesiton of this.multiple_choice_questions.filter(
        (x) => x.test == this.instrument_id && x.segment == segment_id
      )) {
        await this.$store.dispatch(
          names.DELETE_NEW_MULTIPLE_CHOICE_QUESTION,
          multiple_choice_quesiton.id
        );
      }
      for await (let filling_question of this.filling_questions.filter(
        (x) => x.test == this.instrument_id && x.segment == segment_id
      )) {
        await this.$store.dispatch(
          names.DELETE_NEW_FILLING_QUESTION,
          filling_question.id
        );
      }
      for await (let matching_question of this.matching_questions.filter(
        (x) => x.test == this.instrument_id && x.segment == segment_id
      )) {
        await this.$store.dispatch(
          names.DELETE_NEW_MATCHING_QUESTION,
          matching_question.id
        );
      }
      await this.$store.dispatch(names.DELETE_NEW_TEST_SEGMENT, segment_id);
    },
    scrollToQuestion(payload) {
      let segmentRef =
        this.$refs[`segment-${payload.segment_id}-${this.instrument_id}`];
      if (payload.segment_id != null) segmentRef = segmentRef[0];
      if (segmentRef && typeof segmentRef.scrollIntoView === "function") {
        segmentRef.scrollIntoView(payload); // Llamar al método del hijo
      }
    },
  },
  created() {
    if (
      (this.requests_within_the_component &&
        !this.evaluatee_view &&
        !this.evaluator_view) ||
      this.evaluatee_view ||
      this.evaluator_view
    ) {
      this.$store.dispatch(names.FETCH_NEW_TEST_SEGMENTS, {
        test_id: this.instrument_id,
      });
      this.$store.dispatch(names.FETCH_NEW_TEST_DESCRIPTORS, {
        test_id: this.instrument_id,
      });
      this.$store
        .dispatch(names.FETCH_NEW_REDACTION_QUESTIONS, {
          test_id: this.instrument_id,
        })
        .then(() => {
          this.$store
            .dispatch(names.FETCH_NEW_MULTIPLE_CHOICE_QUESTIONS, {
              test_id: this.instrument_id,
            })
            .then(() => {
              this.$store
                .dispatch(names.FETCH_NEW_FILLING_QUESTIONS, {
                  test_id: this.instrument_id,
                })
                .then(() => {
                  this.$store
                    .dispatch(names.FETCH_NEW_MATCHING_QUESTIONS, {
                      test_id: this.instrument_id,
                    })
                    .then(() => {
                      this.is_loading = false;
                      this.$emit("is_fetch_completed");
                    });
                });
            });
        });
    }
    if (
      !this.requests_within_the_component &&
      !this.evaluatee_view &&
      !this.evaluator_view
    )
      this.is_loading = false;
  },
};
</script>
<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.container-buttons-allow-cruds {
  display: flex;
  padding: 5px;
}
.unselectable-text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.lock-btn {
  border: 1px;
  max-height: 30px;
  width: 27px;
}
.delete-btn {
  border: 1px;
  max-height: 30px;
  padding: 2px;
  width: 27px;
  transition: 0.3s all;
}
.delete-btn:hover {
  background-color: #e45d6b;
  transition: 0.3s all;
  transform: scale(1.1);
}
.container-buttons {
  display: flex;
  justify-content: left;
}
.title-top-segment {
  display: flex;
  margin-top: 4px;
  border-bottom: 1px solid #c9c9c9;
  min-height: 40px;
}
.title-segment {
  background: white;
}
.segment-container {
  border-radius: 7px;
  margin: 10px 0px 0px 0px;
  padding: 5px;
  box-shadow: 0px 2px 8px -3px var(--secondary-color);
  margin-bottom: 2em;
  overflow: hidden;
  background: #ececec;
}
.segment-container-min-heigth {
  min-height: 60px;
}
.right-container-segment {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}
.title-container:hover {
  background: #eee;
  cursor: pointer;
}
.move-icon {
  margin: auto;
  cursor: grab;
}
.question-move:hover {
  box-shadow: 0px 4px 12px -2px var(--secondary-color);
  background-color: var(--kl-menu-color);
  color: white;
}
.question-move {
  background: var(--primary-color);
  /* height: 100%; */
  width: 25px;
  /* align-self: center; */
  display: flex;
}
.grabbable-cursor {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable-cursor:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
@media print {
  .title-top-segment {
    border-bottom: none;
  }
}
</style>
