<template>
  <div>
    <SentenceForm
      :first_content="rubric_criteria.action"
      :second_content="rubric_criteria.content"
      :third_content="rubric_criteria.condition"
      :full_sentence="rubric_criteria.full_sentence"
      :verb="rubric_criteria.verb"
      third_content_comment
      @update="slotUpdateSentence"
    ></SentenceForm>
    <b-form-group
      v-if="
        evaluation_criteria_micro_ids.length > 0 &&
        !selected_evaluation_criteria_micro
      "
      :label="
        $getVisibleNames(
          'teaching.evaluationcriteriamicro',
          false,
          'Criterio de Evaluación Micro'
        )
      "
      label-for="evaluation_criteria_micro"
      label-cols="4"
      label-cols-sm="3"
    >
      <!-- Selector para Múltiples IL -->
      <b-form-select
        v-if="institution && institution.internal_use_id != 'duoc_uc'"
        id="evaluation_criterias_micro"
        v-model="$v.rubric_criteria.evaluation_criterias_micro.$model"
        aria-describedby="evaluation_criterias_micro-feedback"
        :state="validateState('evaluation_criterias_micro')"
        size="sm"
        multiple
        :select-size="
          evaluationCriteriaMicros.length < 2
            ? 2
            : evaluationCriteriaMicros.length > 10
            ? 10
            : evaluationCriteriaMicros.length
        "
      >
        <!-- <template #first>
          <b-form-select-option :value="null">
            --- Seleccione una de las opciones ---
          </b-form-select-option>
        </template> -->
        <b-form-select-option-group
          v-for="item in studyUnits"
          :key="item.id"
          :label="
            item.competence_unit_order != 999
              ? 'N°' +
                item.competence_unit_order +
                '.' +
                item.order +
                ': ' +
                item.name
              : 'N°' + item.order + ': ' + item.name
          "
        >
          <b-form-select-option
            v-for="ecm in evaluationCriteriaMicros.filter(
              (x) => x.study_unit == item.id
            )"
            :value="ecm.id"
            :key="ecm.id"
            :title="ecm.name"
          >
            {{
              truncateText(
                item.competence_unit_order != 999
                  ? item.competence_unit_order +
                      "." +
                      item.order +
                      "." +
                      ecm.order +
                      " " +
                      ecm.full_sentence
                  : item.order + "." + ecm.order + " " + ecm.full_sentence,
                120
              )
            }}
          </b-form-select-option>
        </b-form-select-option-group>
      </b-form-select>

      <!-- Selector para sólo 1 IL -->
      <b-form-select
        v-if="institution && institution.internal_use_id == 'duoc_uc'"
        id="evaluation_criteria_micro"
        v-model="$v.rubric_criteria.evaluation_criteria_micro.$model"
        aria-describedby="evaluation_criteria_micro-feedback"
        :state="validateState('evaluation_criteria_micro')"
        size="sm"
      >
        <!-- <template #first>
          <b-form-select-option
            :value="null"
            v-if="institution && institution.internal_use_id != 'duoc_uc'"
          >
            --- Seleccione una de las opciones ---
          </b-form-select-option>
        </template> -->
        <b-form-select-option-group
          v-for="item in studyUnits"
          :key="item.id"
          :label="'N°' + item.order + ': ' + item.name"
        >
          <b-form-select-option
            v-for="ecm in evaluationCriteriaMicros.filter(
              (x) => x.study_unit == item.id
            )"
            :value="ecm.id"
            :key="ecm.id"
            :title="ecm.name"
          >
            {{
              truncateText(
                item.order + "." + ecm.order + " " + ecm.full_sentence,
                120
              )
            }}
          </b-form-select-option>
        </b-form-select-option-group>
      </b-form-select>
      <b-form-invalid-feedback id="evaluation_criteria_micro-feedback"
        >Este campo es obligatorio.</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group
      label="Ponderación"
      label-for="input-weighing"
      label-cols="4"
      label-cols-sm="3"
    >
      <b-form-input
        id="input-weighing"
        v-model="$v.rubric_criteria.weighing.$model"
        :state="validateState('weighing')"
        aria-describedby="input-weighing-feedback"
        size="sm"
        type="number"
      ></b-form-input>
      <div class="weighing-aviable">
        Ponderación disponible:
        <span
          :class="{
            'colorup-weighing-available': maxWeighingRubric > 0,
            'colordown-weighing-available': maxWeighingRubric <= 0,
          }"
          >{{ maxWeighingRubric > 0 ? maxWeighingRubric : 0 }}</span
        >
      </div>
      <b-form-invalid-feedback id="input-weighing-feedback">
        <span
          v-if="
            rubric_criteria.weighing > max_weighing ||
            rubric_criteria.weighing < 0
          "
        >
          <span v-if="max_weighing > 0">
            Este campo debe ser un valor entre 0 y
            {{ max_weighing }}.
          </span>
          <span v-else> Este campo debe ser 0 </span>
        </span>
        <span v-else> Este campo debe ser un número mayor a 0. </span>
      </b-form-invalid-feedback>
    </b-form-group>
    <div class="row">
      <div
        v-if="show_delete_button && !isNaN(rubric_criteria.id)"
        class="col"
        style="text-align: left"
      >
        <b-button
          class="mr-1"
          size="sm"
          variant="danger"
          @click="deleteRubricCriteria"
          >Eliminar</b-button
        >
      </div>
      <div class="col" style="text-align: right">
        <b-button class="mr-1" size="sm" @click="saveRubricCritera"
          >Guardar</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import {
  required,
  minValue,
  maxValue,
  integer,
} from "vuelidate/lib/validators";
export default {
  name: "RubricCriteriaForm",
  mixins: [validationMixin],

  components: {
    SentenceForm: () => import("@/components/reusable/SentenceForm"),
  },
  props: {
    rubric_id: {
      type: Number,
      required: true,
    },
    order: {
      type: Number,
    },
    // max_weighing: {
    //   type: Number,
    //   default: 100,
    // },
    selected_evaluation_criteria_micro: {
      type: Object,
      default: null,
    },
    evaluation_criteria_micro_ids: {
      type: Array,
      default: () => {
        return [];
      },
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    RubricCriteria: {
      type: Object,
      default: function () {
        return {
          id: generateUniqueId(),
          verb: null,
          action: "",
          content: "",
          condition: "",
          full_sentence: "",
          weighing: 0,
          order: this.order,
          rubric: this.rubric_id,
          evaluation_criteria_micro: this.selected_evaluation_criteria_micro
            ? this.selected_evaluation_criteria_micro.id
            : null,
          evaluation_criterias_micro: [],
        };
      },
    },
  },
  data() {
    return {
      rubric_criteria: { ...this.RubricCriteria },
      base_sentence_toggle: this.RubricCriteria
        ? this.RubricCriteria.content
          ? true
          : false
        : true,
    };
  },
  validations() {
    return {
      rubric_criteria: {
        weighing: {
          required,
          minValue: minValue(0),
          integer,
          maxValue: maxValue(this.max_weighing >= 0 ? this.max_weighing : 0),
        },
        evaluation_criteria_micro: {
          isValid() {
            if (
              this.institution &&
              this.institution.internal_use_id == "duoc_uc" &&
              this.evaluation_criteria_micro_ids.length > 0
            ) {
              if (this.rubric_criteria.evaluation_criteria_micro != null)
                return true;
              else return false;
            } else return true;
          },
        },
        evaluation_criterias_micro: {
          isValid() {
            if (
              this.institution &&
              this.institution.internal_use_id != "duoc_uc" &&
              this.evaluation_criteria_micro_ids.length > 0
            ) {
              if (this.rubric_criteria.evaluation_criterias_micro != null)
                return true;
              else return false;
            } else return true;
          },
        },
        rubric: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      evaluations: names.NEW_EVALUATIONS,
      tests: names.NEW_TESTS,
      rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      competence_units: names.COMPETENCE_UNITS,
      study_units: names.STUDY_UNITS,
      evaluation_criteria_micros: names.EVALUATION_CRITERIAS,
      rubrics_criterias: names.NEW_RUBRIC_CRITERIAS,
      test_evaluation_criteria_micros:
        names.NEW_TEST_EVALUATION_CRITERIA_MICROS,
    }),
    rubric() {
      return this.rubrics.find((x) => x.id == this.rubric_id);
    },
    test() {
      if (!this.rubric) return null;
      return this.tests.find((x) => x.id == this.rubric.test);
    },
    testEvaluationCriteriaMicros() {
      if (!this.test) return [];
      return this.test_evaluation_criteria_micros.filter(
        (x) =>
          x.test == this.test.id &&
          this.evaluation_criteria_micro_ids.includes(
            x.evaluation_criteria_micro
          )
      );
    },
    max_weighing() {
      let weighing = 0;
      if (
        this.institution &&
        this.institution.internal_use_id == "duoc_uc" &&
        this.rubric_criteria.evaluation_criteria_micro != null
      ) {
        weighing += parseFloat(
          this.rubrics_criterias
            .filter(
              (x) =>
                x.rubric == this.rubric_id &&
                x.id != this.rubric_criteria.id &&
                this.rubric_criteria.evaluation_criteria_micro ==
                  x.evaluation_criteria_micro
            )
            .reduce((acc, x) => {
              return (acc += parseFloat(x.weighing));
            }, 0)
        );
        weighing =
          parseFloat(
            this.testEvaluationCriteriaMicros
              .filter(
                (x) =>
                  x.evaluation_criteria_micro ==
                  this.rubric_criteria.evaluation_criteria_micro
              )
              .reduce((acc, x) => {
                return acc + parseFloat(x.weighing);
              }, 0)
          ) - weighing;
      } else {
        weighing += parseFloat(
          this.rubrics_criterias
            .filter(
              (x) =>
                x.rubric == this.rubric_id && x.id != this.rubric_criteria.id
            )
            .reduce((acc, x) => {
              return (acc += parseFloat(x.weighing));
            }, 0)
        );
        weighing = 100 - weighing;
      }
      return weighing;
    },
    maxWeighingRubric() {
      let max_weighing = this.max_weighing;
      if (
        this.rubric_criteria.weighing &&
        parseFloat(this.rubric_criteria.weighing) > 0
      )
        max_weighing -= parseFloat(this.rubric_criteria.weighing);
      return max_weighing;
    },
    evaluation() {
      if (!this.test) return null;
      return this.evaluations.find((x) => x.id == this.test.evaluation);
    },
    evaluationCriteriaMicros() {
      return this.evaluation_criteria_micros.filter(
        (x) =>
          this.evaluation_criteria_micro_ids.includes(x.id) ||
          this.rubric_criteria.evaluation_criteria_micro == x.id ||
          this.rubric_criteria.evaluation_criterias_micro.includes(x.id)
      );
    },
    studyUnits() {
      const study_unit_ids = [
        ...new Set(this.evaluationCriteriaMicros.map((x) => x.study_unit)),
      ];
      return this.study_units
        .filter((x) => study_unit_ids.includes(x.id))
        .map((x) => {
          const competence_unit = this.competence_units.find(
            (c) => c.id == x.temp_competence_unit
          );
          return {
            ...x,
            competence_unit_order: competence_unit
              ? competence_unit.order
              : 999,
          };
        })
        .sort(function (a, b) {
          if (a.competence_unit_order > b.competence_unit_order) return 1;
          if (a.competence_unit_order < b.competence_unit_order) return -1;
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
  },
  methods: {
    truncateText(text, maxLength) {
      const convert_text = text;
      if (convert_text.length > maxLength) {
        return convert_text.slice(0, maxLength) + "...";
      }
      return convert_text;
    },
    slotUpdateSentence(sentence) {
      this.rubric_criteria.verb = sentence.verb;
      this.rubric_criteria.action = sentence.first_content;
      this.rubric_criteria.content = sentence.second_content;
      this.rubric_criteria.condition = sentence.third_content;
      this.rubric_criteria.full_sentence = sentence.full_sentence;
      this.base_sentence_toggle = sentence.base_sentence_toggle;
    },
    validateState(key) {
      const { $dirty, $error } = this.$v.rubric_criteria[key];
      return $dirty ? !$error : null;
    },
    saveRubricCritera() {
      this.$v.rubric_criteria.$touch();
      if (this.$v.rubric_criteria.$anyError) {
        return;
      }
      if (this.base_sentence_toggle) {
        this.rubric_criteria.full_sentence = String(
          this.rubric_criteria.action +
            " " +
            this.rubric_criteria.content +
            " " +
            this.rubric_criteria.condition
        );
        if (isNaN(this.rubric_criteria.id)) this.createRubricCritera();
        else this.updateRubricCriteria();
      } else {
        if (
          this.rubric_criteria.full_sentence ||
          !this.rubric_criteria.full_sentence.trim() == ""
        ) {
          if (isNaN(this.rubric_criteria.id)) this.createRubricCritera();
          else this.updateRubricCriteria();
        }
      }
    },
    createRubricCritera() {
      this.$store
        .dispatch(names.POST_NEW_RUBRIC_CRITERIA, this.rubric_criteria)
        .then((response) => {
          this.rubric_criteria = response;
          this.$emit("created", response);
          toast(
            this.$getVisibleNames("manual.dimension", false, "Dimensión") +
              " creada."
          );
          this.$store.dispatch(names.FETCH_NEW_TEST, {
            test_id: this.test.id,
            forceFetch: true,
          });
        });
    },
    updateRubricCriteria() {
      this.$store
        .dispatch(names.UPDATE_NEW_RUBRIC_CRITERIA, this.rubric_criteria)
        .then((response) => {
          this.rubric_criteria = response;
          this.$emit("updated", response);
          toast(
            this.$getVisibleNames("manual.dimension", false, "Dimensión") +
              " actualizada."
          );
          this.$store.dispatch(names.FETCH_NEW_TEST, {
            test_id: this.test.id,
            forceFetch: true,
          });
        });
    },
    deleteRubricCriteria() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar la ${this.$getVisibleNames(
          "manual.dimension",
          false,
          "Dimensión"
        )}?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch(names.DELETE_NEW_RUBRIC_CRITERIA, this.rubric_criteria.id)
            .then(() => {
              this.$emit("deleted", this.rubric_criteria);
              toast(
                this.$getVisibleNames("manual.dimension", false, "Dimensión") +
                  " eliminada."
              );
              this.$store.dispatch(names.FETCH_NEW_TEST, {
                test_id: this.test.id,
                forceFetch: true,
              });
            });
        }
      });
    },
  },
  created() {
    if (
      !this.evaluation_criteria_micro_ids.includes(
        this.rubric_criteria.evaluation_criteria_micro
      )
    )
      this.rubric_criteria.evaluation_criteria_micro = null;
  },
};
</script>

<style scoped>
.weighing-aviable {
  font-size: 10pt;
}
.colorup-weighing-available {
  color: #28a745;
}
.colordown-weighing-available {
  color: red;
}
</style>

