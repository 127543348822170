var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.evaluator_view && !_vm.evaluatee_view && !_vm.preview_evaluation)?_c('div',{staticClass:"noprint"},[_c('div',[(
        _vm.test.is_valid == true &&
        _vm.test.evaluative_agent != null &&
        _vm.test.evaluation_situation != null
      )?_c('div',{class:{
        'alert alert-success': _vm.test.manual_is_valid == true,
        'alert alert-info': _vm.test.manual_is_valid == false,
      }},[_c('div',{staticClass:"d-flex justify-content-center"},[(_vm.test.manual_is_valid == false)?_c('div',{staticClass:"align-content-center"},[_c('b-icon-unlock-fill',{staticClass:"icon-lock-margin"}),_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+" ")]),_vm._v(" listo para cerrar. "),(_vm.evaluation && _vm.evaluation.scope == 1)?_c('InfoTooltip',{attrs:{"tooltip_text":`Esta ${_vm.$getVisibleNames(
              'evaluations2.test',
              false,
              'Instrumento'
            )} es válida.
            Está lista para ser publicada.`}}):_vm._e(),(_vm.evaluation && _vm.evaluation.scope == 3)?_c('InfoTooltip',{attrs:{"tooltip_text":`Al cerrar la ${_vm.$getVisibleNames(
              'evaluations2.test',
              false,
              'Instrumento'
            )} se podrá cerrar la Asignatura, copiar la ${_vm.$getVisibleNames(
              'evaluations2.test',
              false,
              'Instrumento'
            )} y crear nuevas secciones.`}}):_vm._e()],1):_vm._e(),(_vm.test.manual_is_valid == true)?_c('div',{staticClass:"align-content-center"},[_c('b-icon-lock-fill',{staticClass:"icon-lock-margin"}),_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+" ")]),_vm._v(" cerrado. ")],1):_vm._e(),(_vm.allows_crud && _vm.test.manual_is_valid == false)?_c('b-button',{staticClass:"ml-3",attrs:{"variant":"info","size":"sm","disabled":_vm.disabled_button},on:{"click":function($event){return _vm.changeStateManualIsValid()}}},[_vm._v(" Cerrar "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+" ")]):_vm._e(),(_vm.allows_crud && _vm.test.manual_is_valid == true)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive.hover",value:(
            `Si desea volver a abrir la ${_vm.$getVisibleNames(
              'evaluations2.test',
              false,
              'Instrumento'
            )} presione este botón`
          ),expression:"\n            `Si desea volver a abrir la ${$getVisibleNames(\n              'evaluations2.test',\n              false,\n              'Instrumento'\n            )} presione este botón`\n          ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true,"hover":true}}],staticClass:"ml-3",attrs:{"variant":"success","size":"sm"},on:{"click":function($event){return _vm.changeStateManualIsValid()}}},[_vm._v(" Abrir "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumento"))+" ")]):_vm._e()],1)]):_vm._e()]),(
      _vm.test.is_valid[1] ||
      _vm.test.evaluative_agent == null ||
      _vm.test.evaluation_situation == null
    )?_c('div',{staticClass:"alert alert-danger"},[_c('div',{staticClass:"d-flex justify-content-center"},[_vm._m(0),_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
            `modal-close-instrument-${_vm.test.id}-${_vm.container_instrument_id}`
          )}}},[_vm._v(" Información de errores ")])],1)]):_vm._e(),_c('b-modal',{attrs:{"id":`modal-close-instrument-${_vm.test.id}-${_vm.container_instrument_id}`,"title":`Información ${_vm.$getVisibleNames(
      'evaluations2.test',
      false,
      'Instrumento'
    )}`,"hide-footer":"","size":"xl"}},[_c('InfoTestTable',{attrs:{"test":_vm.test}})],1)],1):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"align-content-center"},[_c('strong',[_vm._v("Problemas encontrados:")])])
}]

export { render, staticRenderFns }