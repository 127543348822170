var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.studyUnitsFilter.length > 0)?_c('b-table-simple',{attrs:{"small":"","responsive":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-align-center evaluation-header"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "teaching.ramicro", false, "RA Micro" ).toUpperCase())+" ")]),_c('b-th',{staticClass:"text-align-center evaluation-header"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.criterio_de_evaluacion", true, "Criterios de Evaluación" ).toUpperCase())+" ")]),_c('b-th',{staticClass:"text-align-center evaluation-header"},[_vm._v(" MENCIONES ")])],1)],1),_c('b-tbody',[_vm._l((_vm.studyUnitsFilter),function(study_unit){return [_vm._l((_vm.ECMicroByStudyUnits(study_unit.id)),function(e_c_micro,index){return _c('b-tr',{key:`matter-${_vm.matter_id}-study_unit-${study_unit.id}-e_c_micro-${e_c_micro.id}`},[(index == 0)?_c('b-td',{attrs:{"rowspan":_vm.ECMicroByStudyUnits(study_unit.id).length}},[_c('div',{staticClass:"d-flex ml-1"},[_c('span',[_vm._v(" "+_vm._s(study_unit.order)+".")]),_c('SentenceContainer',{attrs:{"unique_id":`s_u-matter-${_vm.matter_id}-study_unit-${study_unit.id}-e_c_micro-${e_c_micro.id}`,"Sentence":study_unit,"boundedTextContainer":_vm.short_view,"single_line":_vm.short_view}})],1)]):_vm._e(),_c('b-td',{class:{
              'text-danger': _vm.getListByECMicro(e_c_micro.id).length == 0,
            }},[_c('div',{staticClass:"d-flex ml-1 mt-1"},[_c('span',[_vm._v(" "+_vm._s(study_unit.order)+"."+_vm._s(e_c_micro.order))]),_c('SentenceContainer',{attrs:{"unique_id":`ecm-matter-${_vm.matter_id}-study_unit-${study_unit.id}-e_c_micro-${e_c_micro.id}`,"Sentence":e_c_micro,"boundedTextContainer":_vm.short_view,"single_line":_vm.short_view}})],1)]),_c('b-td',{staticClass:"text-center align-center"},[_c('b-badge',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-danger.top.noninteractive.hover",value:(
                _vm.getListByECMicro(e_c_micro.id).length == 0
                  ? 'No está siendo mencionado en ninguna Actividad/Evaluación'
                  : ''
              ),expression:"\n                getListByECMicro(e_c_micro.id).length == 0\n                  ? 'No está siendo mencionado en ninguna Actividad/Evaluación'\n                  : ''\n              ",modifiers:{"v-danger":true,"top":true,"noninteractive":true,"hover":true}}],attrs:{"id":`e_c_micro-popover-${e_c_micro.id}`,"variant":_vm.getListByECMicro(e_c_micro.id).length == 0
                  ? 'danger'
                  : _vm.filterMentionsEvaluations(e_c_micro.id).length > 0 &&
                    _vm.filterMentionsActivities(e_c_micro.id).length > 0
                  ? 'success'
                  : _vm.filterMentionsEvaluations(e_c_micro.id).length > 0 ||
                    _vm.filterMentionsActivities(e_c_micro.id).length > 0
                  ? 'warning'
                  : 'secondary'}},[_vm._v(" "+_vm._s(_vm.getListByECMicro(e_c_micro.id).length)+" ")]),(
                _vm.filterMentionsEvaluations(e_c_micro.id).length > 0 ||
                _vm.filterMentionsActivities(e_c_micro.id).length > 0
              )?_c('b-popover',{attrs:{"target":`e_c_micro-popover-${e_c_micro.id}`,"placement":"left","triggers":"hover click","variant":"dark"}},[_c('div',[(_vm.filterMentionsEvaluations(e_c_micro.id).length > 0)?_c('div',[(_vm.filterMentionsEvaluations(e_c_micro.id).length > 1)?_c('strong',[_vm._v(_vm._s(_vm.$getVisibleNames( "evaluations2.evaluation", true, "Evaluaciones" ))+":")]):_vm._e(),(_vm.filterMentionsEvaluations(e_c_micro.id).length == 1)?_c('strong',[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "evaluations2.evaluation", false, "Evaluación" ))+": ")]):_vm._e()]):_vm._e(),_vm._l((_vm.filterMentionsEvaluations(e_c_micro.id)),function(filter_evaluations,index){return _c('li',{key:index + 'filter_mentions_evaluations'},[_vm._v(" "+_vm._s(filter_evaluations)+" ")])})],2),(_vm.filterMentionsActivities(e_c_micro.id).length > 0)?_c('div',[_c('div',[(_vm.filterMentionsActivities(e_c_micro.id).length > 1)?_c('strong',[_vm._v("Actividades:")]):_vm._e(),(_vm.filterMentionsActivities(e_c_micro.id).length == 1)?_c('strong',[_vm._v("Actividad:")]):_vm._e()]),_vm._l((_vm.filterMentionsActivities(e_c_micro.id)),function(filter_activities,index){return _c('li',{key:index + 'filter_mentions_activies'},[_vm._v(" "+_vm._s(filter_activities)+" ")])})],2):_vm._e()]):_vm._e()],1)],1)}),(_vm.ECMicroByStudyUnits(study_unit.id).length == 0)?[_c('b-tr',{key:`matter-${_vm.matter_id}-study_unit-${study_unit.id}`},[_c('b-td',[_c('div',{staticClass:"d-flex ml-1 text-danger"},[_c('span',[_vm._v(" "+_vm._s(study_unit.order)+".")]),_c('SentenceContainer',{attrs:{"unique_id":`s_u-matter-${_vm.matter_id}-study_unit-${study_unit.id}`,"Sentence":study_unit,"boundedTextContainer":_vm.short_view,"single_line":_vm.short_view}})],1)]),_c('b-td',{staticClass:"text-center text-danger"},[_vm._v(" - ")]),_c('b-td',{staticClass:"text-center text-danger"},[_c('b-badge',{attrs:{"variant":"danger"}},[_vm._v("0")])],1)],1)]:_vm._e()]})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }