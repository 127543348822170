<template>
  <span class="noprint">
    <template v-if="show_button">
      <b-button
        v-if="
          allows_crud &&
          user &&
          (user.groups.includes(1) || user.is_superuser) &&
          alternativeName
        "
        @click="$bvModal.show(`edit-alternative-name-modal-${modal_ids}`)"
        v-b-tooltip.v-secondary.noninteractive="'Modificar nombre alternativo.'"
        variant="outline-primary"
        size="sm"
        class="btn-alternative-name"
      >
        <b-icon-asterisk class="icon-btn-alternative-name"></b-icon-asterisk
      ></b-button>
    </template>
    <template
      v-else-if="
        allows_crud &&
        user &&
        (user.groups.includes(1) || user.is_superuser) &&
        alternativeName
      "
    >
      <span
        class="href"
        @click="$bvModal.show(`edit-alternative-name-modal-${modal_ids}`)"
      >
        {{ $getVisibleNames(table_name, is_plural, text_default) }}
      </span>
    </template>
    <template v-else>
      <span> {{ $getVisibleNames(table_name, is_plural, text_default) }}</span>
    </template>
    <b-modal
      :id="`edit-alternative-name-modal-${modal_ids}`"
      title="Sustituir nombre alternativo."
      size="md"
      hide-footer
    >
      <AlternativeNameForm
        :AlternativeName="alternativeName"
        @updated="$bvModal.hide(`edit-alternative-name-modal-${modal_ids}`)"
      ></AlternativeNameForm>
    </b-modal>
  </span>
</template>

<script>
import { generateUniqueId } from "@/utils/utils";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "AlternativeNameButton",
  components: {
    AlternativeNameForm: () =>
      import(
        "@/components/mesh/MethodologicalResources/AlternativeNames/AlternativeNameForm"
      ),
  },
  props: {
    modal_ids: {
      type: String,
      default: generateUniqueId(),
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    table_name: {
      type: String,
      required: true,
    },
    show_button: {
      type: Boolean,
      default: true,
    },
    is_plural: {
      type: Boolean,
      default: false,
    },
    text_default: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      alternative_names: names.ALTERNATIVE_NAMES,
    }),
    alternativeName() {
      return this.alternative_names.find((x) =>
        this.$equals(x.table_name, this.table_name)
      );
    },
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.btn-alternative-name {
  border: none !important;
  padding: 4px;
}
.icon-btn-alternative-name {
  width: 13px;
  height: 13px;
  margin-bottom: 2px;
}
.href {
  color: #007bff;
  text-decoration: none;
}
.href:hover {
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}
</style>