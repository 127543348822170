var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('b-form-group',{staticClass:"label m-0",attrs:{"label-cols-sm":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
            !_vm.old_matter.is_closed &&
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            ).toLowerCase()} vinculadas`
          ),expression:"\n            `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n              'mesh.egressprofilematter',\n              true,\n              'Asignaturas'\n            ).toLowerCase()} vinculadas`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"mr-1"},[_vm._v("⚠️")]):_vm._e(),_c('span',{staticClass:"font-size-form ml-1"},[_vm._v("Nombre")])]},proxy:true}])},[_c('b-form-input',{staticClass:"name-input",class:{ 'matter-name': _vm.SameMatter },attrs:{"id":`name-input-${_vm.old_matter.id}`,"name":"name-input","disabled":_vm.old_matter.is_closed,"state":_vm.validateState('name') && !_vm.SameMatter,"aria-describedby":"input-name-live-feedback","size":"sm"},on:{"update":(value) => {
            _vm.old_matter.name = value.toUpperCase();
          }},model:{value:(_vm.$v.old_matter.name.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.name, "$model", $$v)},expression:"$v.old_matter.name.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-name-live-feedback"}},[(_vm.old_matter.name.length < 5)?_c('div',[_vm._v(" Este campo debe tener al menos 5 caracteres. ")]):_vm._e(),(_vm.SameMatter)?_c('div',{staticClass:"text-alert"},[_vm._v(" Ya se cuenta con una "+_vm._s(_vm.$getVisibleNames("teaching.matter", false, "Asignatura Base"))+" con este nombre. ")]):_vm._e()]),(_vm.SameMatter)?_c('b-tooltip',{attrs:{"target":`name-input-${_vm.old_matter.id}`,"variant":"secondary","placement":"right","noninteractive":true}},[(_vm.SameMatter)?_c('div',[_vm._v(" "+_vm._s(_vm.filterSameMatter.length > 0 ? _vm.$getVisibleNames( "mesh.egressprofilematter", true, "Asignaturas" ) : _vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" ))+" Existente"+_vm._s(_vm.filterSameMatter.length > 0 ? "s" : "")+": "),_vm._l((_vm.filterSameMatter),function(matter){return _c('div',{key:matter.id},[_c('p',[_vm._v(" "+_vm._s(matter.name)+" ")])])})],2):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"row-code-old-matter pb-3"},[_c('div',{staticClass:"w-25"},[_c('label',{staticClass:"label-code-old-matter",attrs:{"for":"input-code"}},[(
              !_vm.old_matter.is_closed &&
              !(
                (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
                _vm.$debug_change_duoc
              )
            )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
              `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
                'mesh.egressprofilematter',
                true,
                'Asignaturas'
              ).toLowerCase()} vinculadas`
            ),expression:"\n              `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n                'mesh.egressprofilematter',\n                true,\n                'Asignaturas'\n              ).toLowerCase()} vinculadas`\n            ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v("⚠️")]):_vm._e(),_c('span',{staticClass:"font-size-form ml-1"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("manual.matter_code", false, "Código"))+" ")])])]),_c('div',{staticClass:"w-75"},[_c('b-form-group',{staticClass:"label-old-matter custom-input-old-matter"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"w-100"},[_c('b-form-input',{staticClass:"input-code-old-matter",attrs:{"id":"input-code","disabled":_vm.old_matter.is_closed,"state":_vm.validateState('code'),"aria-describedby":"input-code-live-feedback","size":"sm"},on:{"update":(value) => {
                    _vm.old_matter.code = value.toUpperCase();
                  },"input":() => {
                    _vm.verified_code = false;
                  }},model:{value:(_vm.$v.old_matter.code.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.code, "$model", $$v)},expression:"$v.old_matter.code.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-code-live-feedback"}},[(_vm.verified_code == false)?_c('div',{class:{
                    'ml-1': _vm.old_matter.code.length > 0,
                  }},[_vm._v(" Revisar "+_vm._s(_vm.$getVisibleNames("manual.matter_code", false, "Código"))+" de la "+_vm._s(_vm.$getVisibleNames( "teaching.matter", false, "Asignatura Base" ))+". ")]):_vm._e(),(_vm.code_used == true && _vm.verified_code == true)?_c('div',{class:{
                    'ml-1': _vm.old_matter.code.length > 0,
                  }},[_vm._v(" Esta "+_vm._s(_vm.$getVisibleNames("manual.matter_code", false, "Código"))+" ya está siendo utilizada. ")]):_vm._e()])],1),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `${
                  _vm.verified_code == true && _vm.code_used == false
                    ? ``
                    : _vm.verified_code == false
                    ? `Verifique que la ${_vm.$getVisibleNames(
                        'manual.matter_code',
                        false,
                        'Código'
                      )} este disponible en la plataforma.`
                    : _vm.code_used == true
                    ? `${_vm.$getVisibleNames(
                        'manual.matter_code',
                        false,
                        'Código'
                      )} ocupada.`
                    : ``
                }`
              ),expression:"\n                `${\n                  verified_code == true && code_used == false\n                    ? ``\n                    : verified_code == false\n                    ? `Verifique que la ${$getVisibleNames(\n                        'manual.matter_code',\n                        false,\n                        'Código'\n                      )} este disponible en la plataforma.`\n                    : code_used == true\n                    ? `${$getVisibleNames(\n                        'manual.matter_code',\n                        false,\n                        'Código'\n                      )} ocupada.`\n                    : ``\n                }`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-check-code-old-matter ml-1",on:{"click":function($event){return _vm.checkCode()}}},[_c('b-icon',{attrs:{"icon":"arrow-clockwise"}})],1)],1)])],1)]),_c('b-form-group',{staticClass:"label m-0",attrs:{"label-cols":"0","label-cols-sm":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"weighing-label"},[_c('div',{staticClass:"label-content"},[(
                !_vm.old_matter.is_closed &&
                !(
                  (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
                  _vm.$debug_change_duoc
                )
              )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
                  'mesh.egressprofilematter',
                  true,
                  'Asignaturas'
                ).toLowerCase()} vinculadas`
              ),expression:"\n                `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n                  'mesh.egressprofilematter',\n                  true,\n                  'Asignaturas'\n                ).toLowerCase()} vinculadas`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"mr-1"},[_vm._v("⚠️")]):_vm._e(),_c('span',{staticClass:"font-size-form ml-1"},[_vm._v(" Ponderación Equivalente")]),_c('span',[_c('InfoTooltip',{staticClass:"info-icon",attrs:{"tooltip_text":'Utilizar una ponderación equivalente para los ' +
                  _vm.$getVisibleNames('teaching.ramicro', false, 'RA Micro') +
                  ' y ' +
                  _vm.$getVisibleNames(
                    'teaching.evaluationcriteriamacro',
                    true,
                    'Criterios de Evaluación Macro'
                  )}})],1)])])]},proxy:true}])},[_c('div',{attrs:{"id":"credit"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"toggle-types noprint mt-1"},[_c('span',{staticClass:"font-size-form",class:{
                'font-weight-bold': _vm.old_matter.automatic_achievement_weighing,
              }},[_vm._v("Sí")]),_c('b-button',{staticClass:"check-type",class:{ 'check-type-disabled': _vm.old_matter.is_closed },attrs:{"variant":"primary"},on:{"click":() => {
                  if (!_vm.old_matter.is_closed)
                    _vm.old_matter.automatic_achievement_weighing =
                      !_vm.old_matter.automatic_achievement_weighing;
                }}},[_c('b-icon',{staticClass:"icon-check",class:{
                  'icon-check-active':
                    !_vm.old_matter.automatic_achievement_weighing,
                },attrs:{"icon":"circle-fill","scale":"0.7"}})],1),_c('span',{staticClass:"font-size-form",class:{
                'font-weight-bold':
                  !_vm.old_matter.automatic_achievement_weighing,
              }},[_vm._v("No")])],1)])])]),_c('b-form-group',{staticClass:"label mt-1",attrs:{"label-cols":"0","label-cols-sm":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"weighing-label"},[_c('div',{staticClass:"label-content"},[(
                !_vm.old_matter.is_closed &&
                !(
                  (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
                  _vm.$debug_change_duoc
                )
              )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
                `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
                  'mesh.egressprofilematter',
                  true,
                  'Asignaturas'
                ).toLowerCase()} vinculadas`
              ),expression:"\n                `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n                  'mesh.egressprofilematter',\n                  true,\n                  'Asignaturas'\n                ).toLowerCase()} vinculadas`\n              ",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"mr-1"},[_vm._v("⚠️")]):_vm._e(),_c('span',{staticClass:"font-size-form ml-1"},[_vm._v(" Es seleccionable en "+_vm._s(_vm.$getVisibleNames( "mesh.basematterwrapper", false, "Módulo Electivo Genérico" ))+" ")]),_c('span',[_c('InfoTooltip',{staticClass:"info-icon",attrs:{"tooltip_text":'Necesario para que pueda aparecer para seleccionar en ' +
                  _vm.$getVisibleNames(
                    'mesh.basematterwrapper',
                    false,
                    'Módulo Electivo Genérico'
                  )}})],1)])])]},proxy:true}])},[_c('div',{attrs:{"id":"credit"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"toggle-types noprint mt-1"},[_c('span',{staticClass:"font-size-form",class:{
                'font-weight-bold': _vm.old_matter.is_elective,
              }},[_vm._v("Sí")]),_c('b-button',{staticClass:"check-type",class:{
                'check-type-disabled':
                  _vm.old_matter.is_closed || _vm.BaseMatterWrapperForm,
              },attrs:{"variant":"primary"},on:{"click":() => {
                  if (!_vm.old_matter.is_closed && !_vm.BaseMatterWrapperForm)
                    _vm.old_matter.is_elective = !_vm.old_matter.is_elective;
                }}},[_c('b-icon',{staticClass:"icon-check",class:{
                  'icon-check-active': !_vm.old_matter.is_elective,
                },attrs:{"icon":"circle-fill","scale":"0.7"}})],1),_c('span',{staticClass:"font-size-form",class:{
                'font-weight-bold': !_vm.old_matter.is_elective,
              }},[_vm._v("No")])],1)])])]),_c('b-form-group',{staticClass:"m-0 mt-1 font-size-form",attrs:{"label-cols":"0","label-cols-sm":"3","label":_vm.$getVisibleNames('mesh.formationarea', false, 'Área De Formación'),"label-for":"input-formation-area"}},[_c('b-form-select',{attrs:{"id":"input-formation-area","options":_vm.formationAreas,"value-field":"id","text-field":"name","state":_vm.validateState('formation_area'),"type":"number","disabled":!_vm.allow_crud,"aria-describedby":"input-formation-area-feedback","size":"sm"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":null}},[_vm._v("N/A ")])]},proxy:true}]),model:{value:(_vm.$v.old_matter.formation_area.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.formation_area, "$model", $$v)},expression:"$v.old_matter.formation_area.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-formation-area-feedback"}},[_vm._v("Este campo no puede ser vacío.")])],1),_c('b-form-group',{staticClass:"label m-0 mt-1",attrs:{"label-cols":"0","label-cols-sm":"3","label-for":"input-modality"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
            !_vm.old_matter.is_closed &&
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            ).toLowerCase()}`
          ),expression:"\n            `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n              'mesh.egressprofilematter',\n              true,\n              'Asignaturas'\n            ).toLowerCase()}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v("⚠️")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.modality", false, "Modalidad de Asignatura"))+" ")]},proxy:true}])},[_c('v-select',{attrs:{"id":"input-modalities","options":_vm.modalities,"reduce":(modalities) => modalities.id,"state":_vm.validateState('modalities'),"disabled":_vm.old_matter.is_closed,"label":"name","track-by":"id","multiple":"","size":"sm"},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontró resultados para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_vm._e()]}},{key:"option",fn:function(option){return [_vm._v(" "+_vm._s(option.name)+" ")]}}]),model:{value:(_vm.$v.old_matter.modalities.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.modalities, "$model", $$v)},expression:"$v.old_matter.modalities.$model"}})],1),_c('b-form-group',{staticClass:"label m-0 mt-3",attrs:{"label-cols":"0","label-cols-sm":"3","label":_vm.$getVisibleNames('mesh.mattertype', false, 'Tipo De Asignatura'),"label-for":"input-old_matter-type"}},[_c('v-select',{attrs:{"id":"input-old_matter-type","options":_vm.matterTypes,"reduce":(matterTypes) => matterTypes.id,"multiple":"","placeholder":`Seleccione uno o varios ${_vm.$getVisibleNames(
          'mesh.mattertype',
          true,
          'Tipo De Asignatura'
        )}`,"state":_vm.validateState('matter_types'),"label":"name","track-by":"id","size":"sm"},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontró resultados para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_vm._e()]}}]),model:{value:(_vm.$v.old_matter.matter_types.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.matter_types, "$model", $$v)},expression:"$v.old_matter.matter_types.$model"}})],1),(_vm.institution && _vm.institution.show_matter_contribution)?_c('b-form-group',{staticClass:"label m-0 mt-3",attrs:{"label-cols":"0","label-cols-sm":"3","label":_vm.$getVisibleNames(
          'mesh.mattercontribution',
          false,
          'Estrategia Curricular'
        ),"label-for":"input-old_matter-contribution"}},[_c('v-select',{attrs:{"id":"input-old_matter-contribution","options":_vm.matterContributions,"reduce":(matterContributions) => matterContributions.id,"multiple":"","placeholder":`Seleccione una o varias ${_vm.$getVisibleNames(
          'mesh.mattercontribution',
          true,
          'Estrategias Curriculares'
        )}`,"state":_vm.validateState('matter_contributions'),"label":"name","track-by":"id","size":"sm"},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontró resultados para "),_c('em',[_vm._v(_vm._s(search))]),_vm._v(". ")]:_vm._e()]}},{key:"option",fn:function(option){return [_c('strong',[_vm._v(_vm._s(option.name)+": ")]),_vm._v(" "+_vm._s(option.description)+" ")]}}],null,false,2364507635),model:{value:(_vm.$v.old_matter.matter_contributions.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.matter_contributions, "$model", $$v)},expression:"$v.old_matter.matter_contributions.$model"}})],1):_vm._e(),_c('b-form-group',{staticClass:"label m-0 mt-3",attrs:{"label-cols":"0","label-cols-sm":"3","label-for":"input-study_environments"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
            !_vm.old_matter.is_closed &&
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            ).toLowerCase()}`
          ),expression:"\n            `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n              'mesh.egressprofilematter',\n              true,\n              'Asignaturas'\n            ).toLowerCase()}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v("⚠️")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.studyenvironment", false, "Ambiente de Aprendizaje" ))+" ")]},proxy:true}])},[_c('v-select',{attrs:{"id":"input-study_environments","options":_vm.studyEnvironments,"disabled":_vm.old_matter.is_closed,"reduce":(studyEnvironments) => studyEnvironments.id,"placeholder":`${
          _vm.studyEnvironments.length > 0
            ? String(
                'Seleccione uno o varios ' +
                  _vm.$getVisibleNames(
                    'mesh.studyenvironment',
                    false,
                    'Ambiente de Aprendizaje'
                  ).toLowerCase()
              )
            : String(
                'Este ' +
                  _vm.$getVisibleNames(
                    'mesh.studyenvironment',
                    false,
                    'Ambiente de Aprendizaje'
                  ).toLowerCase() +
                  ' no cuenta con ' +
                  _vm.$getVisibleNames(
                    'mesh.studyenvironment',
                    false,
                    'Ambiente de Aprendizaje'
                  ).toLowerCase() +
                  ' creadas'
              )
        }`,"multiple":"","dropdown-should-open":_vm.dropdownShouldOpen2,"state":_vm.validateState('study_environments'),"label":"name","track-by":"id","size":"sm"},scopedSlots:_vm._u([{key:"no-options",fn:function({ search, searching }){return [(searching)?[_vm._v(" No se encontraron resultados para: \""),_c('em',[_vm._v(_vm._s(search))]),_vm._v("\" ")]:_vm._e()]}}]),model:{value:(_vm.$v.old_matter.study_environments.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.study_environments, "$model", $$v)},expression:"$v.old_matter.study_environments.$model"}}),_c('b-tooltip',{attrs:{"target":"input-study_environments","variant":"secondary","noninteractive":true,"triggers":"hover"}},[(_vm.studyEnvironments.length == 0)?_c('div',[_vm._v(" Diríjase a Definiciones Curriculares para poder crear "+_vm._s(_vm.$getVisibleNames( "mesh.studyenvironment", false, "Ambiente de Aprendizaje" ).toLowerCase())+". ")]):_vm._e()])],1),_c('b-form-group',{staticClass:"label m-0 mt-3",attrs:{"label-cols":"0","label-cols-sm":"3","label-for":"input-weeks"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
            !_vm.old_matter.is_closed &&
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            ).toLowerCase()}`
          ),expression:"\n            `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n              'mesh.egressprofilematter',\n              true,\n              'Asignaturas'\n            ).toLowerCase()}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v("⚠️")]):_vm._e(),_vm._v(" Semanas ")]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"input-weeks","type":"number","min":"0","disabled":_vm.old_matter.is_closed,"state":_vm.validateState('weeks'),"aria-describedby":"input-weeks-feedback","size":"sm"},on:{"update":_vm.slotWeekUpdated},model:{value:(_vm.$v.old_matter.weeks.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.weeks, "$model", $$v)},expression:"$v.old_matter.weeks.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-weeks-feedback"}},[_vm._v("Este campo debe ser entero mayor a mayor 0 y menor o igual a 52.")])],1),_c('MatterTimeAllocationComponent',{staticClass:"mt-3",attrs:{"oldMatter":_vm.old_matter},on:{"updated":_vm.slotUpdateMatterHours}}),_c('b-form-group',{staticClass:"label mt-2",attrs:{"label-cols":"0","label-cols-sm":"3","label-for":"input-credits"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
            !_vm.old_matter.is_closed &&
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            ).toLowerCase()}`
          ),expression:"\n            `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n              'mesh.egressprofilematter',\n              true,\n              'Asignaturas'\n            ).toLowerCase()}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v("⚠️")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$getVisibleNames("manual.credits", true, "Créditos SCT Totales"))+" ")]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"input-credits","type":"number","min":"0","disabled":_vm.old_matter.is_closed,"state":_vm.validateState('credits'),"aria-describedby":"input-credits-feedback","size":"sm"},model:{value:(_vm.$v.old_matter.credits.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.credits, "$model", $$v)},expression:"$v.old_matter.credits.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-credits-feedback"}},[_vm._v("Este campo debe ser mayor o igual a cero.")])],1),(
        (_vm.institution &&
          (_vm.institution.internal_use_id == 'unab' ||
            _vm.institution.internal_use_id == 'duoc_uc')) ||
        _vm.$debug_change_unab ||
        _vm.$debug_change_duoc
      )?_c('b-form-group',{staticClass:"label mt-3",attrs:{"label-cols":"0","label-cols-sm":"3","label-for":"input-institutional_credits"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
            !_vm.old_matter.is_closed &&
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            ).toLowerCase()}`
          ),expression:"\n            `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n              'mesh.egressprofilematter',\n              true,\n              'Asignaturas'\n            ).toLowerCase()}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v("⚠️")]):_vm._e(),_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.institutional_credits", true, "Créditos Institucionales" ))+" ")]},proxy:true}],null,false,2101363215)},[_c('b-form-input',{attrs:{"id":"input-institutional_credits","type":"number","min":"0","disabled":_vm.old_matter.is_closed,"state":_vm.validateState('institutional_credits'),"aria-describedby":"input-institutional_credits-feedback","size":"sm"},model:{value:(_vm.$v.old_matter.institutional_credits.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.institutional_credits, "$model", $$v)},expression:"$v.old_matter.institutional_credits.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-institutional_credits-feedback"}},[_vm._v("Este campo debe ser mayor o igual a cero.")])],1):_vm._e(),_c('b-form-group',{staticClass:"label mt-3",attrs:{"label-cols":"0","label-cols-sm":"3","label-for":"input-suggested_students"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
            !_vm.old_matter.is_closed &&
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            ).toLowerCase()}`
          ),expression:"\n            `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n              'mesh.egressprofilematter',\n              true,\n              'Asignaturas'\n            ).toLowerCase()}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v("⚠️")]):_vm._e(),_vm._v(" N° Estudiantes ")]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"input-suggested_students","type":"number","min":"0","disabled":_vm.old_matter.is_closed,"state":_vm.validateState('suggested_students'),"aria-describedby":"input-suggested_students-feedback","size":"sm"},model:{value:(_vm.$v.old_matter.suggested_students.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.suggested_students, "$model", $$v)},expression:"$v.old_matter.suggested_students.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-suggested_students-feedback"}},[_vm._v("Este campo debe ser mayor o igual a cero.")])],1),_c('b-form-group',{staticClass:"label mt-3",attrs:{"label-cols":"0","label-cols-sm":"3","label-for":"input-suggested_teachers"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
            !_vm.old_matter.is_closed &&
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            ).toLowerCase()}`
          ),expression:"\n            `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n              'mesh.egressprofilematter',\n              true,\n              'Asignaturas'\n            ).toLowerCase()}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v("⚠️")]):_vm._e(),_vm._v(" N° Docentes ")]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"input-suggested_teachers","type":"number","min":"0","disabled":_vm.old_matter.is_closed,"state":_vm.validateState('suggested_teachers'),"aria-describedby":"input-suggested_teachers-feedback","size":"sm"},model:{value:(_vm.$v.old_matter.suggested_teachers.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.suggested_teachers, "$model", $$v)},expression:"$v.old_matter.suggested_teachers.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-suggested_teachers-feedback"}},[_vm._v("Este campo debe ser mayor o igual a cero.")])],1),_c('b-form-group',{staticClass:"label mt-3",attrs:{"label-cols":"0","label-cols-sm":"3","label-for":"input-suggested_assistants"},scopedSlots:_vm._u([{key:"label",fn:function(){return [(
            !_vm.old_matter.is_closed &&
            !(
              (_vm.institution && _vm.institution.internal_use_id == 'duoc_uc') ||
              _vm.$debug_change_duoc
            )
          )?_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:(
            `Los cambios en este campo se verán reflejados en todas las ${_vm.$getVisibleNames(
              'mesh.egressprofilematter',
              true,
              'Asignaturas'
            ).toLowerCase()}`
          ),expression:"\n            `Los cambios en este campo se verán reflejados en todas las ${$getVisibleNames(\n              'mesh.egressprofilematter',\n              true,\n              'Asignaturas'\n            ).toLowerCase()}`\n          ",modifiers:{"v-secondary":true,"noninteractive":true}}]},[_vm._v("⚠️")]):_vm._e(),_vm._v(" N° Ayudantes ")]},proxy:true}])},[_c('b-form-input',{attrs:{"id":"input-suggested_assistants","type":"number","min":"0","disabled":_vm.old_matter.is_closed,"state":_vm.validateState('suggested_assistants'),"aria-describedby":"input-suggested_assistants-feedback","size":"sm"},model:{value:(_vm.$v.old_matter.suggested_assistants.$model),callback:function ($$v) {_vm.$set(_vm.$v.old_matter.suggested_assistants, "$model", $$v)},expression:"$v.old_matter.suggested_assistants.$model"}}),_c('b-form-invalid-feedback',{attrs:{"id":"input-suggested_assistants-feedback"}},[_vm._v("Este campo debe ser mayor o igual a cero.")])],1)],1),(
      (_vm.user_position &&
        [1, 2].includes(_vm.user_position.position) &&
        [1, 2].includes(_vm.user.groups[0])) ||
      _vm.user.is_superuser
    )?_c('div',{staticClass:"row mt-4"},[(_vm.show_delete_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(_vm.user.is_superuser)?_c('b-button',{directives:[{name:"can",rawName:"v-can",value:('teaching.delete_matter'),expression:"'teaching.delete_matter'"}],staticClass:"mr-1",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteMatter(_vm.old_matter.id)}}},[_vm._v("Eliminar")]):_vm._e()],1):_vm._e(),(_vm.show_save_button)?_c('div',{staticClass:"col",staticStyle:{"text-align":"right"}},[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm"},on:{"click":_vm.save}},[_vm._v("Guardar")])],1):_vm._e()]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }