import restful from '@/utils/restful';
import * as names from '@/store/names';
import * as get from '@/store/store';

const state = {
    new_evaluations: [],
    new_evaluations_url: '/evaluations2/evaluation',
    // Choices de EvaluationTypes
    evaluation_types: [],
    evaluation_types_url: '/evaluations2/evaluation-type-default-choices',
    // Choices de EvaluationScope
    evaluation_scopes: [],
    evaluation_scopes_url: '/evaluations2/evaluation-scope-default-choices',
    // Choices de EvaluationCategory
    evaluation_categories: [],
    evaluation_categories_url: '/evaluations2/evaluation-category-default-choices',
    new_tests: [],
    new_tests_url: '/evaluations2/test',
    // Choices de TestType
    new_test_types: [],
    new_test_types_url: '/teaching/evaluative-agent',
    // Choices de TestExaminerType
    test_examiner_types: [],
    test_examiner_types_url: '/evaluations2/examiner-types',
    // Choices de TestWorkType
    test_work_types: [],
    test_work_types_url: '/evaluations2/work-types',
    new_test_descriptors: [],
    new_test_descriptors_url: '/evaluations2/test-descriptor',
    new_test_evaluation_criteria_micros: [],
    new_test_evaluation_criteria_micros_url: '/evaluations2/test-evaluation-criteria-micro',
    new_test_groups: [],
    new_test_groups_url: '/evaluations2/student-group',
}

const getters = {
    [names.NEW_EVALUATIONS]: state => {
        if (!get.store.state.user) return [];
        return state.new_evaluations.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.NEW_EVALUATION_TYPES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.evaluation_types;
    },
    [names.NEW_EVALUATION_SCOPES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.evaluation_scopes;
    },
    [names.NEW_EVALUATION_CATEGORIES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.evaluation_categories;
    },
    [names.NEW_TESTS]: state => {
        if (!get.store.state.user) return [];
        return state.new_tests.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.id - b.id);
    },
    [names.NEW_TEST_TYPES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.new_test_types;
    },
    [names.TEST_EXAMINER_TYPES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.test_examiner_types;
    },
    [names.TEST_WORK_TYPES]: state => {
        // TODO: No cuenta con filtro dado que es un dato estático en el Backend
        return state.test_work_types;
    },
    [names.NEW_TEST_DESCRIPTORS]: state => {
        if (!get.store.state.user) return [];
        return state.new_test_descriptors.filter((x) => x.school == get.store.state.user.school).sort((a, b) => a.order - b.order);
    },
    [names.NEW_TEST_EVALUATION_CRITERIA_MICROS]: state => {
        if (!get.store.state.user) return [];
        return state.new_test_evaluation_criteria_micros.filter((x) => x.school == get.store.state.user.school);
    },
    [names.NEW_TEST_GROUPS]: state => {
        if (!get.store.state.user) return [];
        return state.new_test_groups.filter((x) => x.school == get.store.state.user.school);
    },
}

const mutations = {
    // New Evaluations
    [names.MUTATE_NEW_EVALUATIONS]: (state, new_evaluations) => {
        new_evaluations.forEach(element => {
            let index = state.new_evaluations.findIndex(x => x.id == element.id);
            if (index != -1) state.new_evaluations.splice(index, 1, element);
            else state.new_evaluations.push(element);
        });
    },
    [names.MUTATE_NEW_EVALUATION]: (state, new_evaluation) => {
        let index = state.new_evaluations.findIndex(x => x.id == new_evaluation.id);
        if (index != -1) state.new_evaluations.splice(index, 1, new_evaluation);
        else state.new_evaluations.push(new_evaluation);
    },
    [names.MUTATE_DELETE_NEW_EVALUATION]: (state, new_evaluation_id) => {
        let index = state.new_evaluations.findIndex(x => x.id == new_evaluation_id);
        if (index != -1) state.new_evaluations.splice(index, 1);
    },
    [names.MUTATE_NEW_EVALUATION_TYPES]: (state, evaluation_types) => {
        evaluation_types.forEach(element => {
            let index = state.evaluation_types.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluation_types.splice(index, 1, element);
            else state.evaluation_types.push(element);
        });
    },
    ["MUTATE_CHANGE_NEW_EVALUATION_BY_MATTER_EVALUATION"]: (state, payload) => {
        const index = state.new_evaluations.findIndex(x => x.id == payload.new_evaluation_id);
        if (index != -1) {
            state.new_evaluations[index].matter_evaluation = payload.matter_evaluation_id
        }
    },
    ["MUTATE_CHANGE_NEW_EVALUATION_BY_EP_MATTER_EVALUATION"]: (state, payload) => {
        const index = state.new_evaluations.findIndex(x => x.id == payload.new_evaluation_id);
        if (index != -1) {
            state.new_evaluations[index].egress_profile_matter_evaluation = payload.egress_profile_matter_evaluation_id
        }
    },
    ["MUTATE_CHANGE_NEW_EVALUATION_TYPE_BY_NEW_EVALUATION"]: (state, evaluation_type) => {
        const index = state.new_evaluations.findIndex(x => x.id == evaluation_type.evaluation);
        if (index != -1) {
            state.new_evaluations[index].evaluation_type_default = evaluation_type.id
            state.new_evaluations[index].type = evaluation_type.type
        }
    },
    [names.MUTATE_NEW_EVALUATION_SCOPES]: (state, evaluation_scopes) => {
        evaluation_scopes.forEach(element => {
            let index = state.evaluation_scopes.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluation_scopes.splice(index, 1, element);
            else state.evaluation_scopes.push(element);
        });
    },
    ["MUTATE_CHANGE_NEW_EVALUATION_SCOPE_BY_NEW_EVALUATION"]: (state, evaluation_scope) => {
        const index = state.new_evaluations.findIndex(x => x.id == evaluation_scope.evaluation);
        if (index != -1) {
            state.new_evaluations[index].evaluation_scope_default = evaluation_scope.id
            state.new_evaluations[index].scope = evaluation_scope.scope
            state.new_evaluations[index].scope_id = evaluation_scope.scope_id
        }
    },
    [names.MUTATE_NEW_EVALUATION_CATEGORIES]: (state, evaluation_categories) => {
        evaluation_categories.forEach(element => {
            let index = state.evaluation_categories.findIndex(x => x.id == element.id);
            if (index != -1) state.evaluation_categories.splice(index, 1, element);
            else state.evaluation_categories.push(element);
        });
    },
    ["MUTATE_CHANGE_NEW_EVALUATION_CATEGORY_BY_NEW_EVALUATION"]: (state, evaluation_category) => {
        const index = state.new_evaluations.findIndex(x => x.id == evaluation_category.evaluation);
        if (index != -1) {
            state.new_evaluations[index].evaluation_category_default = evaluation_category.id
            state.new_evaluations[index].category = evaluation_category.category
        }
    },
    ["MUTATE_CHANGE_NEW_EVALUATION_USERS_BY_NEW_EVALUATION"]: (state, evaluation_user) => {
        const index = state.new_evaluations.findIndex(x => x.id == evaluation_user.evaluation);
        if (index != -1) {
            state.new_evaluations[index].evaluation_users_default = evaluation_user.id
            state.new_evaluations[index].evaluators = evaluation_user.evaluators
            state.new_evaluations[index].evaluatees = evaluation_user.evaluatees
        }
    },
    [names.CLEAR_EVALUATIONS_AND_TEST]: (state, payload) => {
        const remove_evaluation_ids = state.new_evaluations.filter(x => x.scope == payload.scoped_id && x.scope_id == payload.scoped_object_id).map(x => x.id)
        state.new_evaluations = state.new_evaluations.filter(x => !remove_evaluation_ids.includes(x.id))
        state.new_tests = state.new_tests.filter(x => !remove_evaluation_ids.includes(x.evaluation))
    },
    // New Test
    [names.MUTATE_NEW_TESTS]: (state, new_tests) => {
        new_tests.forEach(element => {
            let index = state.new_tests.findIndex(x => x.id == element.id);
            if (index != -1) state.new_tests.splice(index, 1, element);
            else state.new_tests.push(element);
        });
    },
    [names.MUTATE_NEW_TEST]: (state, new_test) => {
        let index = state.new_tests.findIndex(x => x.id == new_test.id);
        if (index != -1) state.new_tests.splice(index, 1, new_test);
        else state.new_tests.push(new_test);
    },
    [names.MUTATE_DELETE_NEW_TEST]: (state, test_id) => {
        let index = state.new_tests.findIndex(x => x.id == test_id);
        if (index != -1) state.new_tests.splice(index, 1);
    },
    [names.MUTATE_TEST_EXAMINER_TYPES]: (state, test_examiner_types) => {
        test_examiner_types.forEach(element => {
            let index = state.test_examiner_types.findIndex(x => x.id == element.id);
            if (index != -1) state.test_examiner_types.splice(index, 1, element);
            else state.test_examiner_types.push(element);
        });
    },
    [names.MUTATE_TEST_WORK_TYPES]: (state, test_work_types) => {
        test_work_types.forEach(element => {
            let index = state.test_work_types.findIndex(x => x.id == element.id);
            if (index != -1) state.test_work_types.splice(index, 1, element);
            else state.test_work_types.push(element);
        });
    },
    [names.MUTATE_NEW_TEST_TYPES]: (state, new_test_types) => {
        new_test_types.forEach(element => {
            let index = state.new_test_types.findIndex(x => x.id == element.id);
            if (index != -1) state.new_test_types.splice(index, 1, element);
            else state.new_test_types.push(element);
        });
    },
    ["MUTATE_CHANGE_NEW_TEST_TYPES_BY_NEW_TEST"]: (state, test_type) => {
        const index = state.new_tests.findIndex(x => x.id == test_type.test);
        if (index != -1) {
            state.new_tests[index].test_type_default = test_type.id
            state.new_tests[index].type = test_type.type
            state.new_tests[index].evaluative_agent = test_type.evaluative_agent
        }
    },
    // New TEST_DESCRIPTOR
    [names.MUTATE_NEW_TEST_DESCRIPTORS]: (state, new_test_descriptors) => {
        new_test_descriptors.forEach(element => {
            let index = state.new_test_descriptors.findIndex(x => x.id == element.id);
            if (index != -1) state.new_test_descriptors.splice(index, 1, element);
            else state.new_test_descriptors.push(element);
        });
    },
    [names.MUTATE_NEW_TEST_DESCRIPTOR]: (state, new_test_descriptor) => {
        let index = state.new_test_descriptors.findIndex(x => x.id == new_test_descriptor.id);
        if (index != -1) state.new_test_descriptors.splice(index, 1, new_test_descriptor);
        else state.new_test_descriptors.push(new_test_descriptor);
    },
    [names.MUTATE_DELETE_NEW_TEST_DESCRIPTOR]: (state, new_test_descriptor_id) => {
        let index = state.new_test_descriptors.findIndex(x => x.id == new_test_descriptor_id);
        if (index != -1) state.new_test_descriptors.splice(index, 1);
    },
    // New TEST_EVALUATION_CRITERIA_MICRO
    [names.MUTATE_NEW_TEST_EVALUATION_CRITERIA_MICROS]: (state, new_test_evaluation_criteria_micros) => {
        new_test_evaluation_criteria_micros.forEach(element => {
            let index = state.new_test_evaluation_criteria_micros.findIndex(x => x.id == element.id);
            if (index != -1) state.new_test_evaluation_criteria_micros.splice(index, 1, element);
            else state.new_test_evaluation_criteria_micros.push(element);
        });
    },
    [names.MUTATE_NEW_TEST_EVALUATION_CRITERIA_MICRO]: (state, new_test_evaluation_criteria_micro) => {
        let index = state.new_test_evaluation_criteria_micros.findIndex(x => x.id == new_test_evaluation_criteria_micro.id);
        if (index != -1) state.new_test_evaluation_criteria_micros.splice(index, 1, new_test_evaluation_criteria_micro);
        else state.new_test_evaluation_criteria_micros.push(new_test_evaluation_criteria_micro);
    },
    [names.MUTATE_DELETE_NEW_TEST_EVALUATION_CRITERIA_MICRO]: (state, new_test_evaluation_criteria_micro_id) => {
        let index = state.new_test_evaluation_criteria_micros.findIndex(x => x.id == new_test_evaluation_criteria_micro_id);
        if (index != -1) state.new_test_evaluation_criteria_micros.splice(index, 1);
    },
    // New NEW_TEST_GROUP
    [names.MUTATE_NEW_TEST_GROUPS]: (state, new_test_groups) => {
        new_test_groups.forEach(element => {
            let index = state.new_test_groups.findIndex(x => x.id == element.id);
            if (index != -1) state.new_test_groups.splice(index, 1, element);
            else state.new_test_groups.push(element);
        });
    },
    [names.MUTATE_NEW_TEST_GROUP]: (state, new_test_group) => {
        let index = state.new_test_groups.findIndex(x => x.id == new_test_group.id);
        if (index != -1) state.new_test_groups.splice(index, 1, new_test_group);
        else state.new_test_groups.push(new_test_group);
    },
    [names.MUTATE_DELETE_NEW_TEST_GROUP]: (state, new_test_group_id) => {
        let index = state.new_test_groups.findIndex(x => x.id == new_test_group_id);
        if (index != -1) state.new_test_groups.splice(index, 1);
    },
}

const actions = {
    ["MUTATE_CHANGE_NEW_INSTRUMENT_USERS_BY_NEW_EVALUATION"]: ({ dispatch, state }, instrument_user) => {
        const index = state.new_tests.findIndex(x => x.id == instrument_user.test);
        if (index != -1) {
            state.new_tests[index].test_users_default = instrument_user.id
            state.new_tests[index].evaluators = instrument_user.evaluators
            state.new_tests[index].evaluatees = instrument_user.evaluatees
            // En caso de que se quite un evaluado en el test, que automáticamente se remueva de los test_groups si que existe en ese test.
            let index2 = state.new_test_groups.findIndex(x => x.test == instrument_user.test && x.students.some(s => !instrument_user.evaluatees.includes(s)))
            while (index2 != -1) {
                let index3 = state.new_test_groups[index2].students.findIndex(x => !instrument_user.evaluatees.includes(x))
                while (index3 != -1) {
                    state.new_test_groups[index2].students.splice(index3, 1);
                    index3 = state.new_test_groups[index2].students.findIndex(x => !instrument_user.evaluatees.includes(x))
                }
                dispatch(names.UPDATE_NEW_TEST_GROUP, state.new_test_groups[index2]);
                index2 = state.new_test_groups.findIndex(x => x.test == instrument_user.test && x.students.some(s => !instrument_user.evaluatees.includes(s)))
            }
        }
    },
    // New Evaluation
    [names.FETCH_NEW_EVALUATION]: ({
        commit,
        state
    }, new_evaluation_id) => {
        return new Promise((resolve) => {
            const instance = state.new_evaluations.find(x => x.id == new_evaluation_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_evaluations_url}/${new_evaluation_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_EVALUATIONS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            if (!payload && state.new_evaluations.length > 0) {
                resolve(state.new_evaluations);
                return;
            }
            let url_append = '?all_data=True';
            // Estructura del payload.scoped_object_id = 1
            // Estructura del payload.scoped_object_ids = [1,2,3]
            // Estructura del payload.evaluations_ids = [1,2,3]
            if (payload) {
                if (payload.scoped_id)
                    url_append += '&evaluation_scope_default__scope=' + payload.scoped_id
                if (payload.scoped_object_id) url_append += '&evaluation_scope_default__scope_id=' + payload.scoped_object_id
                else if (payload.scoped_object_ids) {
                    if (payload.scoped_object_ids.length > 0)
                        url_append += '&evaluation_scope_default__scope_id__in=' + payload.scoped_object_ids.join(",")
                    else return resolve([])
                }
                if (payload.parent_evaluation_id) url_append += '&parent_evaluation__id=' + payload.parent_evaluation_id
                else if (payload.parent_evaluation_ids) {
                    if (payload.parent_evaluation_ids.length > 0)
                        url_append += '&parent_evaluation__id__in=' + payload.parent_evaluation_ids.join(",")
                    else return resolve([])
                }
                if (payload.evaluations_ids)
                    url_append += '&id__in=' + payload.evaluations_ids.join(",")
            }
            restful.Get(`${state.new_evaluations_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_EVALUATIONS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_EVALUATION]: ({
        commit,
        state
    }, new_evaluation) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_evaluations_url}/`, new_evaluation)
                .then(response => {
                    commit(names.MUTATE_NEW_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_EVALUATION]: ({
        commit,
        state
    }, new_evaluation) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_evaluations_url}/${new_evaluation.id}/`, new_evaluation)
                .then(response => {
                    commit(names.MUTATE_NEW_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_EVALUATION]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_evaluations_url}/${payload.new_evaluation_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_EVALUATION, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_EVALUATION]: ({
        commit,
        state
    }, new_evaluation_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_evaluations_url}/${new_evaluation_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_EVALUATION, new_evaluation_id);
                    resolve(response);
                });
        });
    },
    // New Test
    [names.FETCH_NEW_TEST]: ({
        commit,
        state
    }, { test_id, forceFetch = false }) => {
        return new Promise((resolve) => {
            // Si forceFetch es true, omite la búsqueda en el estado y va directo a la API
            if (!forceFetch) {
                const instance = state.new_tests.find(x => x.id == test_id);
                if (instance) {
                    resolve(instance);
                    return;
                }
            }
            restful.Get(`${state.new_tests_url}/${test_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_TESTS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.evaluation_id = 1
            // Estructura del payload.evaluations_ids = [1,2,3]
            if (payload) {
                if (payload.evaluation_id) url_append += '&evaluation__id=' + payload.evaluation_id
                else if (payload.evaluations_ids) {
                    if (payload.evaluations_ids.length > 0)
                        url_append += '&evaluation__id__in=' + payload.evaluations_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_tests_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_TESTS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_TEST]: ({
        commit,
        state
    }, new_test) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_tests_url}/`, new_test)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_TEST]: ({
        commit,
        state
    }, new_test) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_tests_url}/${new_test.id}/`, new_test)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_TEST]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_tests_url}/${payload.new_test_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_TEST]: ({
        commit,
        state
    }, new_test_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_tests_url}/${new_test_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_TEST, new_test_id);
                    resolve(response);
                });
        });
    },
    // New Evaluation Types
    [names.FETCH_NEW_EVALUATION_TYPES]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.evaluation_types.length > 0) {
                resolve(state.evaluation_types);
                return;
            }
            restful.Get(`${state.evaluation_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_EVALUATION_TYPES, response);
                    resolve(response);
                });
        });
    },
    // New Evaluation Scopes
    [names.FETCH_NEW_EVALUATION_SCOPES]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.evaluation_scopes.length > 0) {
                resolve(state.evaluation_scopes);
                return;
            }
            restful.Get(`${state.evaluation_scopes_url}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_EVALUATION_SCOPES, response);
                    resolve(response);
                });
        });
    },
    // New Evaluation Categories
    [names.FETCH_NEW_EVALUATION_CATEGORIES]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.evaluation_categories.length > 0) {
                resolve(state.evaluation_categories);
                return;
            }
            restful.Get(`${state.evaluation_categories_url}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_EVALUATION_CATEGORIES, response);
                    resolve(response);
                });
        });
    },
    // New Evaluation Types
    [names.FETCH_NEW_TEST_TYPES]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.new_test_types.length > 0) {
                resolve(state.new_test_types);
                return;
            }
            restful.Get(`${state.new_test_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_TYPES, response);
                    resolve(response);
                });
        });
    },
    // New Evaluation Types
    [names.FETCH_TEST_EXAMINER_TYPES]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.test_examiner_types.length > 0) {
                resolve(state.test_examiner_types);
                return;
            }
            restful.Get(`${state.test_examiner_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_TEST_EXAMINER_TYPES, response);
                    resolve(response);
                });
        });
    },
    // New Evaluation Types
    [names.FETCH_TEST_WORK_TYPES]: ({
        commit,
        state
    },) => {
        return new Promise((resolve) => {
            if (state.test_work_types.length > 0) {
                resolve(state.test_work_types);
                return;
            }
            restful.Get(`${state.test_work_types_url}/`)
                .then(response => {
                    commit(names.MUTATE_TEST_WORK_TYPES, response);
                    resolve(response);
                });
        });
    },
    // New Test Descriptor
    [names.FETCH_NEW_TEST_DESCRIPTOR]: ({
        commit,
        state
    }, test_descriptor_id) => {
        return new Promise((resolve) => {
            const instance = state.new_test_descriptors.find(x => x.id == test_descriptor_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_test_descriptors_url}/${test_descriptor_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_TEST_DESCRIPTORS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_test_descriptors_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_DESCRIPTORS, response.results);
                    resolve(response.results);
                });
        });
    },
    [names.POST_NEW_TEST_DESCRIPTOR]: ({
        commit,
        state
    }, new_test_descriptor) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_test_descriptors_url}/`, new_test_descriptor)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_TEST_DESCRIPTOR]: ({
        commit,
        state
    }, new_test_descriptor) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_test_descriptors_url}/${new_test_descriptor.id}/`, new_test_descriptor)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_TEST_DESCRIPTOR]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_test_descriptors_url}/${payload.new_test_descriptor_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_DESCRIPTOR, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_TEST_DESCRIPTOR]: ({
        commit,
        state
    }, new_test_descriptor_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_test_descriptors_url}/${new_test_descriptor_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_TEST_DESCRIPTOR, new_test_descriptor_id);
                    resolve(response);
                });
        });
    },
    // New Test Evaluation Critera Micro
    [names.FETCH_NEW_TEST_EVALUATION_CRITERIA_MICRO]: ({
        commit,
        state
    }, test_descriptor_id) => {
        return new Promise((resolve) => {
            const instance = state.new_test_evaluation_criteria_micros.find(x => x.id == test_descriptor_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_test_evaluation_criteria_micros_url}/${test_descriptor_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_EVALUATION_CRITERIA_MICRO, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_TEST_EVALUATION_CRITERIA_MICROS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_test_evaluation_criteria_micros_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_EVALUATION_CRITERIA_MICROS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_NEW_TEST_EVALUATION_CRITERIA_MICRO]: ({
        commit,
        state
    }, new_test_evaluation_criteria_micro) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_test_evaluation_criteria_micros_url}/`, new_test_evaluation_criteria_micro)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_EVALUATION_CRITERIA_MICRO, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_TEST_EVALUATION_CRITERIA_MICRO]: ({
        commit,
        state
    }, new_test_evaluation_criteria_micro) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_test_evaluation_criteria_micros_url}/${new_test_evaluation_criteria_micro.id}/`, new_test_evaluation_criteria_micro)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_EVALUATION_CRITERIA_MICRO, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_TEST_EVALUATION_CRITERIA_MICRO]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_test_evaluation_criteria_micros_url}/${payload.new_test_evaluation_criteria_micro_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_EVALUATION_CRITERIA_MICRO, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_TEST_EVALUATION_CRITERIA_MICRO]: ({
        commit,
        state
    }, new_test_evaluation_criteria_micro_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_test_evaluation_criteria_micros_url}/${new_test_evaluation_criteria_micro_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_TEST_EVALUATION_CRITERIA_MICRO, new_test_evaluation_criteria_micro_id);
                    resolve(response);
                });
        });
    },
    // New NEW_TEST_GROUP
    [names.FETCH_NEW_TEST_GROUP]: ({
        commit,
        state
    }, new_test_group_id) => {
        return new Promise((resolve) => {
            const instance = state.new_test_groups.find(x => x.id == new_test_group_id);
            if (instance) {
                resolve(instance);
                return;
            }
            restful.Get(`${state.new_test_groups_url}/${new_test_group_id}/`)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_GROUP, response);
                    resolve(response);
                });
        });
    },
    [names.FETCH_NEW_TEST_GROUPS]: ({
        commit,
        state,
    }, payload) => {
        return new Promise((resolve) => {
            let url_append = '?all_data=True';
            // Estructura del payload.test_id = 1
            // Estructura del payload.tests_ids = [1,2,3]
            if (payload) {
                if (payload.test_id) url_append += '&test__id=' + payload.test_id
                else if (payload.tests_ids) {
                    if (payload.tests_ids.length > 0)
                        url_append += '&test__id__in=' + payload.tests_ids.join(",")
                    else return resolve([])
                }
            }
            restful.Get(`${state.new_test_groups_url}/${url_append}`)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_GROUPS, response);
                    resolve(response);
                });
        });
    },
    [names.POST_NEW_TEST_GROUP]: ({
        commit,
        state
    }, new_test_group) => {
        return new Promise((resolve) => {
            restful.Post(`${state.new_test_groups_url}/`, new_test_group)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_GROUP, response);
                    resolve(response);
                });
        });
    },
    [names.UPDATE_NEW_TEST_GROUP]: ({
        commit,
        state
    }, new_test_group) => {
        return new Promise((resolve) => {
            restful.Put(`${state.new_test_groups_url}/${new_test_group.id}/`, new_test_group)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_GROUP, response);
                    resolve(response);
                });
        });
    },
    [names.PATCH_NEW_TEST_GROUP]: ({
        commit,
        state
    }, payload) => {
        return new Promise((resolve) => {
            restful.Patch(`${state.new_test_groups_url}/${payload.new_test_group_id}/`, payload.item)
                .then(response => {
                    commit(names.MUTATE_NEW_TEST_GROUP, response);
                    resolve(response);
                });
        });
    },
    [names.DELETE_NEW_TEST_GROUP]: ({
        commit,
        state
    }, new_test_group_id) => {
        return new Promise((resolve) => {
            restful.Delete(`${state.new_test_groups_url}/${new_test_group_id}/`)
                .then(response => {
                    commit(names.MUTATE_DELETE_NEW_TEST_GROUP, new_test_group_id);
                    resolve(response);
                });
        });
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
};