var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.test.is_valid == true &&
      _vm.test.manual_is_valid == false &&
      _vm.test.evaluative_agent != null &&
      _vm.test.evaluation_situation != null
    )?_c('div',[_c('b-button',{staticClass:"mb-2",attrs:{"size":"sm","disabled":_vm.disabled_button},on:{"click":function($event){return _vm.ManualIsValidInTest()}}},[_vm._v("Cerrar "+_vm._s(_vm.$getVisibleNames("evaluations2.test", false, "Instrumentos"))+" ")])],1):_vm._e(),_c('b-table-simple',{attrs:{"bordered":""}},[_c('b-thead',[_c('b-tr',[_c('b-th',{staticClass:"text-center",staticStyle:{"width":"45%"}},[_vm._v("Criterio")]),_c('b-th',{staticClass:"text-center",staticStyle:{"width":"10%"}},[_vm._v("Cumple")]),_c('b-th',{staticClass:"text-center"},[_vm._v("Problema(s)")])],1)],1),_c('b-tbody',[(_vm.test.is_valid[1] && _vm.test.is_valid[1].has_rubric)?_vm._l((_vm.filteredTestIsValid),function(value,key){return _c('b-tr',{key:key},[_c('b-td',[_vm._v(_vm._s(_vm.nameErrorTest(key)))]),_c('b-td',{staticClass:"align-middle"},[_c('div',{staticClass:"text-center"},[(
                  (!Array.isArray(value) && !value) ||
                  (Array.isArray(value) && value.length > 0)
                )?_c('b-badge',{staticClass:"p-2 badge-test",attrs:{"variant":"danger"}},[_vm._v(" No ")]):_vm._e(),(
                  (Array.isArray(value) && value.length == 0) || value == true
                )?_c('b-badge',{staticClass:"p-2 badge-test",attrs:{"variant":"success"}},[_vm._v(" Sí ")]):_vm._e()],1)]),_c('b-td',{staticClass:"td-inconsistencia p-0 align-middle"},[(!Array.isArray(value) && !value)?_c('div',{staticClass:"mx-2"},[(
                  !Array.isArray(value) &&
                  key == 'test_evaluation_criteria_micro_weighing_sum_is_100'
                )?_c('div',[_vm._v(" Revisar las ponderaciones de los "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationcriteriamicro", true, "Criterio de Evaluación Micro" ))+" ")]):_vm._e(),(
                  !Array.isArray(value) &&
                  key == 'rubric_criteria_weighing_sum_is_100'
                )?_c('div',[_vm._v(" Revisar las ponderaciones de las "+_vm._s(_vm.$getVisibleNames("manual.dimension", true, "Dimensión"))+". ")]):_vm._e()]):_vm._e(),(Array.isArray(value))?_c('div',{staticClass:"text-justify"},_vm._l((value),function(detail){return _c('div',{key:detail},[(key == 'rubric_criteria_weighing_0')?_c('div',{staticClass:"p-0 mx-2 height-criteria"},[_c('BoundedTextContainer',{attrs:{"Text":_vm.getRubricCriterias(detail),"tooltip_text":_vm.getRubricCriterias(detail),"single_line":true}})],1):(
                    key ==
                    'rubric_criteria_weighing_sum_does_not_match_test_evaluation_criteria_micro'
                  )?_c('div',{staticClass:"p-0 mx-2 height-criteria"},[_c('BoundedTextContainer',{attrs:{"Text":_vm.getRubricCriteriasMicro(detail),"tooltip_text":_vm.getRubricCriteriasMicro(detail),"single_line":true}})],1):_vm._e()])}),0):_vm._e()])],1)}):_vm._e(),(_vm.test.is_valid[1] && !_vm.test.is_valid[1].has_rubric)?[_c('b-td',[_vm._v(" "+_vm._s(_vm.$getVisibleNames("evaluations2.rubric", false, "Pauta"))+" existente. ")]),_c('b-td',[_c('div',{staticClass:"text-center"},[_c('b-badge',{staticClass:"p-2",attrs:{"variant":"danger"}},[_vm._v(" No ")])],1)]),_c('b-td',[_vm._v("Se debe crear una "+_vm._s(_vm.$getVisibleNames("evaluations2.rubric", false, "Pauta"))+".")])]:_vm._e(),(
          _vm.test.evaluative_agent == null || _vm.test.evaluation_situation == null
        )?[_c('tr',[_c('b-td',[_vm._v(" Posee el campo "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationsituation", false, "Situación Evaluativa" ))+" seleccionado.")]),_c('b-td',[_c('div',{staticClass:"text-center"},[_c('b-badge',{staticClass:"p-2",attrs:{"variant":"danger"}},[_vm._v(" No ")]),(_vm.test.evaluation_situation != null)?_c('b-badge',{staticClass:"p-2 badge-test",attrs:{"variant":"success"}},[_vm._v(" Sí ")]):_vm._e()],1)]),_c('b-td',[_vm._v(" Se debe seleccionar una "+_vm._s(_vm.$getVisibleNames( "teaching.evaluationsituation", false, "Situación Evaluativa" ))+" en el formulario. ")])],1)]:_vm._e(),(_vm.test.evaluative_agent == null)?[_c('tr',[_c('b-td',[_vm._v("Posee el campo "+_vm._s(_vm.$getVisibleNames( "teaching.evaluativeagent", false, "Agente Evaluativo" ))+" seleccionado.")]),_c('b-td',[_c('div',{staticClass:"text-center"},[_c('b-badge',{staticClass:"p-2",attrs:{"variant":"danger"}},[_vm._v(" No ")]),(_vm.test.evaluative_agent != null)?_c('b-badge',{staticClass:"p-2 badge-test",attrs:{"variant":"success"}},[_vm._v(" Sí ")]):_vm._e()],1)]),_c('b-td',[_vm._v(" Se debe seleccionar un "+_vm._s(_vm.$getVisibleNames( "teaching.evaluativeagent", false, "Agente Evaluativo" ))+" en el formulario. ")])],1)]:_vm._e(),(
          _vm.test.is_valid === true &&
          _vm.test.evaluative_agent != null &&
          _vm.test.evaluation_situation != null
        )?[_c('b-tr',[_c('b-td',{staticClass:"text-center",attrs:{"colspan":"3"}},[_vm._v("No existen inconsistencias.")])],1)]:_vm._e()],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }