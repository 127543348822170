<template>
  <div>
    <div
      v-if="
        test.is_valid == true &&
        test.manual_is_valid == false &&
        test.evaluative_agent != null &&
        test.evaluation_situation != null
      "
    >
      <b-button
        size="sm"
        class="mb-2"
        @click="ManualIsValidInTest()"
        :disabled="disabled_button"
        >Cerrar
        {{ $getVisibleNames("evaluations2.test", false, "Instrumentos") }}
      </b-button>
    </div>
    <b-table-simple bordered>
      <b-thead>
        <b-tr>
          <b-th class="text-center" style="width: 45%">Criterio</b-th>
          <b-th class="text-center" style="width: 10%">Cumple</b-th>
          <b-th class="text-center">Problema(s)</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <!-- Template para cuando has_rubric es false y los demas campos tienen datos false y arrays -->
        <template v-if="test.is_valid[1] && test.is_valid[1].has_rubric">
          <b-tr v-for="(value, key) in filteredTestIsValid" :key="key">
            <b-td>{{ nameErrorTest(key) }}</b-td>
            <b-td class="align-middle">
              <div class="text-center">
                <b-badge
                  v-if="
                    (!Array.isArray(value) && !value) ||
                    (Array.isArray(value) && value.length > 0)
                  "
                  variant="danger"
                  class="p-2 badge-test"
                >
                  No
                </b-badge>
                <b-badge
                  v-if="
                    (Array.isArray(value) && value.length == 0) || value == true
                  "
                  variant="success"
                  class="p-2 badge-test"
                >
                  Sí
                </b-badge>
              </div>
            </b-td>
            <b-td class="td-inconsistencia p-0 align-middle">
              <div v-if="!Array.isArray(value) && !value" class="mx-2">
                <div
                  v-if="
                    !Array.isArray(value) &&
                    key == 'test_evaluation_criteria_micro_weighing_sum_is_100'
                  "
                >
                  Revisar las ponderaciones de los
                  {{
                    $getVisibleNames(
                      "teaching.evaluationcriteriamicro",
                      true,
                      "Criterio de Evaluación Micro"
                    )
                  }}
                </div>
                <div
                  v-if="
                    !Array.isArray(value) &&
                    key == 'rubric_criteria_weighing_sum_is_100'
                  "
                >
                  Revisar las ponderaciones de las
                  {{ $getVisibleNames("manual.dimension", true, "Dimensión") }}.
                </div>
              </div>
              <div v-if="Array.isArray(value)" class="text-justify">
                <div v-for="detail in value" :key="detail">
                  <div
                    v-if="key == 'rubric_criteria_weighing_0'"
                    class="p-0 mx-2 height-criteria"
                  >
                    <BoundedTextContainer
                      :Text="getRubricCriterias(detail)"
                      :tooltip_text="getRubricCriterias(detail)"
                      :single_line="true"
                    ></BoundedTextContainer>
                  </div>
                  <div
                    v-else-if="
                      key ==
                      'rubric_criteria_weighing_sum_does_not_match_test_evaluation_criteria_micro'
                    "
                    class="p-0 mx-2 height-criteria"
                  >
                    <BoundedTextContainer
                      :Text="getRubricCriteriasMicro(detail)"
                      :tooltip_text="getRubricCriteriasMicro(detail)"
                      :single_line="true"
                    ></BoundedTextContainer>
                  </div>
                </div>
              </div>
            </b-td>
          </b-tr>
        </template>
        <!-- Template para cuando has_rubric es true y los demas campos son null -->
        <template v-if="test.is_valid[1] && !test.is_valid[1].has_rubric">
          <b-td>
            {{
              $getVisibleNames("evaluations2.rubric", false, "Pauta")
            }}
            existente.
          </b-td>
          <b-td>
            <div class="text-center">
              <b-badge variant="danger" class="p-2"> No </b-badge>
            </div>
          </b-td>
          <b-td
            >Se debe crear una
            {{ $getVisibleNames("evaluations2.rubric", false, "Pauta") }}.</b-td
          >
        </template>
        <!-- template para mostrar que no existe Situacion Evaluativa -->
        <template
          v-if="
            test.evaluative_agent == null || test.evaluation_situation == null
          "
        >
          <tr>
            <b-td>
              Posee el campo
              {{
                $getVisibleNames(
                  "teaching.evaluationsituation",
                  false,
                  "Situación Evaluativa"
                )
              }}
              seleccionado.</b-td
            >
            <b-td>
              <div class="text-center">
                <b-badge variant="danger" class="p-2"> No </b-badge>
                <b-badge
                  v-if="test.evaluation_situation != null"
                  variant="success"
                  class="p-2 badge-test"
                >
                  Sí
                </b-badge>
              </div>
            </b-td>
            <b-td>
              Se debe seleccionar una
              {{
                $getVisibleNames(
                  "teaching.evaluationsituation",
                  false,
                  "Situación Evaluativa"
                )
              }}
              en el formulario.
            </b-td>
          </tr>
        </template>
        <!-- template para mostrar que no existe Agente Evaluativo -->
        <template v-if="test.evaluative_agent == null">
          <tr>
            <b-td
              >Posee el campo
              {{
                $getVisibleNames(
                  "teaching.evaluativeagent",
                  false,
                  "Agente Evaluativo"
                )
              }}
              seleccionado.</b-td
            >
            <b-td>
              <div class="text-center">
                <b-badge variant="danger" class="p-2"> No </b-badge>
                <b-badge
                  v-if="test.evaluative_agent != null"
                  variant="success"
                  class="p-2 badge-test"
                >
                  Sí
                </b-badge>
              </div>
            </b-td>
            <b-td>
              Se debe seleccionar un
              {{
                $getVisibleNames(
                  "teaching.evaluativeagent",
                  false,
                  "Agente Evaluativo"
                )
              }}
              en el formulario.
            </b-td>
          </tr>
        </template>

        <!-- Mensaje para cuando no existen inconsistencias -->
        <template
          v-if="
            test.is_valid === true &&
            test.evaluative_agent != null &&
            test.evaluation_situation != null
          "
        >
          <b-tr>
            <b-td class="text-center" colspan="3"
              >No existen inconsistencias.</b-td
            >
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "InfoTestTable",
  components: {
    BoundedTextContainer: () =>
      import("@/components/reusable/BoundedTextContainer"),
  },
  props: {
    test: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      disabled_button: false,
    };
  },
  computed: {
    ...mapGetters({
      rubricInstances: names.NEW_RUBRIC_CRITERIAS,
      evaluation_criteria_micros: names.EVALUATION_CRITERIAS,
    }),
    filteredTestIsValid() {
      const testIsValid = this.test.is_valid[1];
      const filteredTestIsValid = {};
      Object.keys(testIsValid).forEach((x) => {
        if (x != "has_rubric") {
          filteredTestIsValid[x] = testIsValid[x];
        }
      });
      return filteredTestIsValid;
    },
    error_message() {
      return [
        {
          key: "has_rubric",
          name: `Existe ${this.$getVisibleNames(
            "evaluations2.rubric",
            false,
            "Pauta"
          )}.`,
        },
        {
          key: "test_evaluation_criteria_micro_weighing_sum_is_100",
          name: `La ponderación de los ${this.$getVisibleNames(
            "teaching.evaluationcriteriamicro",
            true,
            "Criterio de Evaluación Micro"
          )} suma 100%.`,
        },
        {
          key: "rubric_criteria_weighing_sum_is_100",
          name: `La ponderación de los ${this.$getVisibleNames(
            "manual.dimension",
            false,
            "Dimensión"
          )} suma 100%.`,
        },
        {
          key: "rubric_criteria_weighing_0",
          name: `Todos los ${this.$getVisibleNames(
            "manual.dimension",
            true,
            "Dimensión"
          )} contribuyen a la ${this.$getVisibleNames(
            "evaluations2.test",
            false,
            "Instrumento"
          )}. `,
        },
        {
          key: "rubric_criteria_weighing_sum_does_not_match_test_evaluation_criteria_micro",
          name: `La suma de las ponderaciones de los ${this.$getVisibleNames(
            "manual.dimension",
            true,
            "Dimensión"
          )} no concuerda con los ${this.$getVisibleNames(
            "teaching.evaluationcriteriamicro",
            true,
            "Criterio de Evaluación Micro"
          )}.`,
        },
      ];
    },
  },
  methods: {
    getRubricCriterias(criteria) {
      const rubric_criteria = this.rubricInstances.find(
        (x) => x.id == criteria
      );
      if (rubric_criteria) return rubric_criteria.full_sentence;
      else return "";
    },
    getRubricCriteriasMicro(criteria) {
      const criteria_micro = this.evaluation_criteria_micros.find(
        (x) => x.id == criteria
      );
      if (criteria_micro) return criteria_micro.full_sentence;
      else return "";
    },
    nameErrorTest(key) {
      const errorMessage = this.error_message.find((x) => x.key == key);
      return errorMessage ? errorMessage.name : "";
    },
    ManualIsValidInTest() {
      this.disabled_button = true;
      this.$store
        .dispatch(names.PATCH_NEW_TEST, {
          new_test_id: this.test.id,
          item: { manual_is_valid: true },
        })
        .then(() => {
          this.disabled_button = false;
        });
    },
  },
  created() {},
};
</script>

<style scoped>
th {
  background-color: var(--kl-menu-color) !important;
  color: white;
}
.badge-test {
  width: 32px;
  height: 29px;
}
.td-inconsistencia {
  max-width: 150px;
}
.height-criteria {
  align-content: center;
}
</style>

