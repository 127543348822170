var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-header-rubric"},[_c('div',{staticClass:"title-rubric"},[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"title-rubric"},[_vm._v(" "+_vm._s(_vm.rubric.title)+" ")]),(_vm.allows_crud || _vm.test.manual_is_valid)?_c('div',{staticClass:"align-items-center ml-2 d-flex"},[_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
            _vm.test.manual_is_valid
              ? `La ${_vm.$getVisibleNames(
                  'evaluations2.rubric',
                  false,
                  'Pauta'
                )} se encuentra desbloqueada y cerrada.`
              : `Bloquear ${_vm.$getVisibleNames(
                  'evaluations2.rubric',
                  false,
                  'Pauta'
                )}`
          ),expression:"\n            test.manual_is_valid\n              ? `La ${$getVisibleNames(\n                  'evaluations2.rubric',\n                  false,\n                  'Pauta'\n                )} se encuentra desbloqueada y cerrada.`\n              : `Bloquear ${$getVisibleNames(\n                  'evaluations2.rubric',\n                  false,\n                  'Pauta'\n                )}`\n          ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}]},[(
              (!_vm.rubric.is_locked &&
                _vm.allows_crud &&
                _vm.user &&
                !_vm.user.groups.includes(6)) ||
              (_vm.test.manual_is_valid &&
                !_vm.rubric.is_locked &&
                !_vm.preview_evaluation &&
                _vm.user &&
                !_vm.user.groups.includes(6))
            )?_c('b-button',{staticClass:"lock-btn mb-2 p-0 noprint",attrs:{"variant":"none","size":"sm","disabled":_vm.test.manual_is_valid},on:{"click":function($event){return _vm.changeStateRubric()}}},[_c('b-icon-unlock-fill')],1):_vm._e()],1),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
            _vm.test.manual_is_valid
              ? `La ${_vm.$getVisibleNames(
                  'evaluations2.rubric',
                  false,
                  'Pauta'
                )} se encuentra sbloqueada y cerrada.`
              : `Desbloquear ${_vm.$getVisibleNames(
                  'evaluations2.rubric',
                  false,
                  'Pauta'
                )}`
          ),expression:"\n            test.manual_is_valid\n              ? `La ${$getVisibleNames(\n                  'evaluations2.rubric',\n                  false,\n                  'Pauta'\n                )} se encuentra sbloqueada y cerrada.`\n              : `Desbloquear ${$getVisibleNames(\n                  'evaluations2.rubric',\n                  false,\n                  'Pauta'\n                )}`\n          ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}]},[(
              (_vm.rubric.is_locked &&
                _vm.allows_crud &&
                _vm.user &&
                !_vm.user.groups.includes(6)) ||
              (_vm.test.manual_is_valid &&
                _vm.rubric.is_locked &&
                !_vm.preview_evaluation &&
                _vm.user &&
                !_vm.user.groups.includes(6))
            )?_c('b-button',{staticClass:"lock-btn p-0 mb-2 noprint",attrs:{"variant":"none","size":"sm","disabled":_vm.test.manual_is_valid},on:{"click":function($event){return _vm.changeStateRubric()}}},[_c('b-icon-lock-fill')],1):_vm._e()],1),(_vm.allows_crud && !_vm.rubric.is_locked && _vm.allow_to_copy_rubric)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
            `Copiar  ${_vm.$getVisibleNames(
              'evaluations2.rubric',
              false,
              'Pauta'
            )}`
          ),expression:"\n            `Copiar  ${$getVisibleNames(\n              'evaluations2.rubric',\n              false,\n              'Pauta'\n            )}`\n          ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"edit-btn mr-1 p-0",attrs:{"variant":"none","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(
              `modal-copy-rubric-${_vm.instrument_id}-${_vm.rubric.id}-${_vm.container_instrument_id}`
            )}}},[_c('b-icon',{attrs:{"icon":"files"}})],1):_vm._e(),_c('b-modal',{attrs:{"id":`modal-copy-rubric-${_vm.instrument_id}-${_vm.rubric.id}-${_vm.container_instrument_id}`,"title":`Copiar ${_vm.$getVisibleNames(
            'evaluations2.rubric',
            false,
            'Pauta'
          )}`,"size":"lg","hide-footer":""},on:{"hide":() => {
              _vm.selected_copy_rubric_test_id = null;
            }}},[_c('b-form-group',{staticClass:"m-0 p-0 mb-2",attrs:{"label":`Seleccione donde se aplicará la copia:`,"label-for":"selected_copy_rubric_test_id","label-cols":"0","label-cols-sm":"auto","label-cols-md":"auto"}},[_c('b-form-select',{staticClass:"mt-1",attrs:{"id":"selected_copy_rubric_test_id","options":_vm.testsWithoutRubric,"value-field":"id","text-field":"title","aria-describedby":"selected_copy_rubric_test_id-feedback","size":"sm"},model:{value:(_vm.selected_copy_rubric_test_id),callback:function ($$v) {_vm.selected_copy_rubric_test_id=$$v},expression:"selected_copy_rubric_test_id"}})],1),_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"ml-auto mr-0",attrs:{"size":"sm","disabled":_vm.selected_copy_rubric_test_id == null},on:{"click":_vm.copyRubric}},[_vm._v("Copiar Rubrica")])],1)],1),(_vm.allows_crud && !_vm.rubric.is_locked)?_c('button-edit',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
            `Editar ${_vm.$getVisibleNames(
              'evaluations2.rubric',
              false,
              'Pauta'
            )}`
          ),expression:"\n            `Editar ${$getVisibleNames(\n              'evaluations2.rubric',\n              false,\n              'Pauta'\n            )}`\n          ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"edit-btn action-button",on:{"click":function($event){return _vm.$bvModal.show(
              `modal-update-rubric-${_vm.instrument_id}-${_vm.rubric.id}-${_vm.container_instrument_id}`
            )}}}):_vm._e(),_c('b-modal',{attrs:{"id":`modal-update-rubric-${_vm.instrument_id}-${_vm.rubric.id}-${_vm.container_instrument_id}`,"title":`Editar ${_vm.$getVisibleNames(
            'evaluations2.rubric',
            false,
            'Pauta'
          )}`,"size":"lg","hide-footer":"","no-enforce-focus":""}},[_c('NewRubricForm',{attrs:{"Rubric":_vm.rubric,"test_id":_vm.instrument_id},on:{"reset_rubric_coevaluators":function($event){return _vm.$emit('reset_rubric_coevaluators')},"closeModal":function($event){return _vm.$bvModal.hide(
                `modal-update-rubric-${_vm.instrument_id}-${_vm.rubric.id}-${_vm.container_instrument_id}`
              )}}})],1),(_vm.allows_crud && !_vm.rubric.is_locked)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
            `Eliminar ${_vm.$getVisibleNames(
              'evaluations2.rubric',
              false,
              'Pauta'
            )}`
          ),expression:"\n            `Eliminar ${$getVisibleNames(\n              'evaluations2.rubric',\n              false,\n              'Pauta'\n            )}`\n          ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"btn-delete-option p-0",attrs:{"variant":"none"},on:{"click":function($event){return _vm.askForDeteleRubric()}}},[_c('b-icon-trash')],1):_vm._e()],1):_vm._e()])]),_c('div',{staticClass:"description-rubric rich-text-content",domProps:{"innerHTML":_vm._s(_vm.rubric.description)}})])
}
var staticRenderFns = []

export { render, staticRenderFns }