<template>
  <div class="mb-4 mt-3">
    <div class="text-left">
      <!-- <span class="number-title-evaluative-situation">01. </span> -->
      <span class="title-evaluative-situation">
        {{
          $getVisibleNames(
            "teaching.evaluationsituation",
            false,
            "Situación Evaluativa"
          )
        }}
      </span>
    </div>
    <div class="container-evaluative-situation mt-3">
      <template v-if="matter_evaluation != null && show_by_matter_evaluation">
        <div
          v-for="evaluative_situation in evaluation_situations"
          :key="'test-type-' + evaluative_situation.id"
          class="container-card mr-3 mb-3"
        >
          <div class="secundary-cell">
            <template
              v-if="
                matter_evaluation.evaluation_situations.includes(
                  evaluative_situation.id
                )
              "
              >X</template
            >
          </div>
          <div class="primary-cell">
            {{ evaluative_situation.name.toUpperCase() }}
          </div>
        </div>
      </template>
      <template v-else-if="evaluation_id == null && test">
        <div
          v-for="evaluative_situation in evaluation_situations"
          :key="'test-type-' + evaluative_situation.id"
          class="container-card mr-3 mb-3"
        >
          <div class="secundary-cell">
            <template
              v-if="test.evaluation_situation == evaluative_situation.id"
              >X</template
            >
          </div>
          <div class="primary-cell">
            {{ evaluative_situation.name.toUpperCase() }}
          </div>
        </div>
      </template>
      <template v-else>
        <div
          v-for="evaluative_situation in evaluation_situations"
          :key="'test-type-' + evaluative_situation.id"
          class="container-card mr-3 mb-3"
        >
          <div class="secundary-cell">
            <template
              v-if="
                evaluation_situations_by_tests.includes(evaluative_situation.id)
              "
              >X</template
            >
          </div>
          <div class="primary-cell">
            {{ evaluative_situation.name.toUpperCase() }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";

export default {
  name: "EvaluativeSituation",
  props: {
    instrument_id: {
      type: Number,
    },
    evaluation_id: {
      type: Number,
    },
    evaluation_situations: {
      type: Array,
      required: true,
    },
    matter_evaluation: {
      type: Object,
      default: null,
    },
    show_by_matter_evaluation: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      tests: names.NEW_TESTS,
    }),
    test() {
      if (!this.instrument_id) return null;
      return this.tests.find((x) => x.id == this.instrument_id);
    },
    evaluation_situations_by_tests() {
      if (!this.evaluation_id) return [];
      let evaluation_situations_ids = this.tests
        .filter((x) => x.evaluation == this.evaluation_id)
        .map((x) => x.evaluation_situation);
      return [...new Set(evaluation_situations_ids)];
    },
  },
  methods: {},
};
</script>

<style scoped>
.number-title-evaluative-situation {
  font-size: 32px;
  color: rgb(255, 153, 0);
}
.title-evaluative-situation {
  font-size: 32px;
}
.container-evaluative-situation {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  font-size: 10pt;
}
.container-card,
.container-written-test,
.container-practical-execution,
.container-package,
.container-presentation {
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.primary-cell {
  padding: 7px;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: var(--kl-menu-color) !important;
  color: white;
  font-weight: bold;
  text-align: start;
}
.secundary-cell {
  padding: 7px;
  min-width: 50px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* font-weight: 500; */
}
</style>