<template>
  <div>
    <b-button
      :variant="variant"
      class="btn-error"
      :id="`${target_popover}-${id_popover}`"
      @click="$bvModal.show(`${click_modal}`)"
    >
      <b-icon icon="info-circle-fill" scale="0.9"></b-icon>
      <!-- Ver más -->
    </b-button>
    <b-popover
      :target="`${target_popover}-${id_popover}`"
      placement="top"
      variant="info"
      triggers="hover"
    >
      {{ popover_text }}
    </b-popover>
  </div>
</template>

<script>
// import * as names from "@/store/names";
// import { mapGetters } from "vuex";
export default {
  name: "ButtonErrorInstrument",
  components: {},
  props: {
    popover_text: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      default: "danger",
    },
    target_popover: {
      type: String,
      required: true,
    },
    id_popover: {
      type: Number,
      required: true,
    },
    click_modal: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    // ...mapGetters({}),
  },
  methods: {},
  created() {},
};
</script>

<style scoped>
.btn-error {
  padding: 1.2px !important;
  width: 23px !important;
  height: 23px !important;
  font-size: 10pt;
}
</style>

