<template>
  <div>
    <b-tabs content-class="mt-3" v-model="tab_index">
      <b-tab>
        <template #title>
          {{ $getVisibleNames("teaching.ramicro", false, "RA Micro") }}
          <b-button
            v-if="
              !isNaN(study_unit.id) &&
              !study_unit.study_unit_locked_view &&
              matter &&
              !matter.is_closed &&
              isClosedOldMatter
            "
            variant="none"
            size="sm"
            class="p-0 mb-1"
            v-b-tooltip.top.noninteractive.v-secondary="
              isClosedOldMatter
                ? 'Bloquear ' +
                  $getVisibleNames('teaching.ramicro', false, 'RA Micro')
                : 'Actualmente el ' +
                  $getVisibleNames('teaching.ramicro', false, 'RA Micro') +
                  ' se encuentra desbloqueado'
            "
            @click="
              changeLockedStudyUnit({
                study_unit_locked_view: !study_unit.study_unit_locked_view,
              })
            "
          >
            <b-icon-unlock-fill variant="primary"></b-icon-unlock-fill>
          </b-button>
          <b-button
            v-if="
              !isNaN(study_unit.id) &&
              study_unit.study_unit_locked_view &&
              matter &&
              !matter.is_closed
            "
            variant="none"
            size="sm"
            class="p-0 mb-1"
            v-b-tooltip.top.noninteractive.v-secondary="
              isClosedOldMatter
                ? 'Desbloquear ' +
                  $getVisibleNames('teaching.ramicro', false, 'RA Micro')
                : 'Actualmente el ' +
                  $getVisibleNames('teaching.ramicro', false, 'RA Micro') +
                  ' se encuentra bloqueado'
            "
            @click="
              changeLockedStudyUnit({
                study_unit_locked_view: !study_unit.study_unit_locked_view,
              })
            "
          >
            <b-icon-lock-fill variant="primary"></b-icon-lock-fill>
          </b-button>
        </template>
        <SentenceForm
          :key="keySentenceForm"
          :first_content="study_unit.action"
          :second_content="study_unit.content"
          :third_content="study_unit.condition"
          :full_sentence="study_unit.full_sentence"
          :verb="study_unit.verb"
          third_content_comment
          :allows_crud="!study_unit.study_unit_locked_view"
          @update="slotUpdateSentence"
        ></SentenceForm>
      </b-tab>
      <b-tab title="Detalles">
        <b-form-group
          v-if="
            !(
              (institution &&
                (institution.internal_use_id == 'duoc_uc' ||
                  institution.internal_use_id == 'ciisa_uss')) ||
              $debug_change_duoc
            )
          "
          label-cols="0"
          label-cols-lg="2"
          label-size="sm"
          label="Nombre:"
          label-for="input-name"
          :description="`Puede Agregarle un nombre al ${$getVisibleNames(
            'teaching.ramicro',
            false,
            'RA Micro'
          )}.`"
          class="m-0 p-0"
        >
          <b-form-input
            v-if="study_unit"
            type="text"
            id="input-name"
            v-model="$v.study_unit.name.$model"
            :disabled="study_unit.study_unit_locked_view"
            :state="validateState('name')"
            aria-describedby="input-name-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-name-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <!-- <b-form-group
          label="Descripción:"
          label-for="input-description"
          label-cols="4"
          label-cols-sm="2"
          class="label"
        >
          <b-form-textarea
            id="input-description"
            name="input-description"
            v-model="$v.study_unit.description.$model"
            :state="validateState('description')"
            aria-describedby="input-description-live-feedback"
            size="md"
            rows="3"
            max-rows="8"
          ></b-form-textarea>
          <b-form-invalid-feedback id="input-description-live-feedback"
            >Este campo debe tener al menos 5
            caracteres.</b-form-invalid-feedback
          >
        </b-form-group> -->
        <!-- <b-form-group
          label-cols="0"
          label-cols-lg="2"
          label-size="sm"
          label="Semanas"
          label-for="input-weeks"
          description="Especificar las semanas que tomará de la Unidad de Aprendizaje."
          class="m-0 p-0"
        >
          <b-form-input
            type="number"
            id="input-weeks"
            min="1"
            max="18"
            v-model="$v.study_unit.weeks.$model"
            :state="validateState('weeks')"
            aria-describedby="input-weeks-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-weeks-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group> -->
        <b-form-group
          v-if="
            matter &&
            institution &&
            ((institution.internal_use_id != 'ciisa_uss' &&
              !matter.automatic_achievement_weighing) ||
              (institution.internal_use_id == 'ciisa_uss' &&
                competenceUnit &&
                !competenceUnit.automatic_achievement_weighing))
          "
          label-cols="0"
          label-cols-lg="2"
          label-size="sm"
          label="Ponderación:"
          label-for="input-weighing"
          :description="`Especifica el porcentaje de tributación para la ${
            institution && institution.internal_use_id == 'ciisa_uss'
              ? $getVisibleNames(
                  'teaching.tempcompetenceunit',
                  false,
                  'Unidad de competencia'
                )
              : $getVisibleNames(
                  'mesh.egressprofilematter',
                  false,
                  'Asignatura'
                )
          }.`"
          class="m-0 p-0"
        >
          <b-form-input
            type="number"
            id="input-weighing"
            min="0"
            max="100"
            v-model="$v.study_unit.weighing.$model"
            :disabled="
              study_unit.study_unit_locked_view ||
              (theTestsHaveSomeCorrect && profileMatter != null)
            "
            :state="validateState('weighing')"
            aria-describedby="input-weighing-feedback"
            size="sm"
          ></b-form-input>
          <div
            v-if="
              WeighingAvailable > 0 &&
              study_unit.weighing != '' &&
              study_unit.weighing < WeighingAvailable
            "
            class="mb-2 alert-student-weighing"
          >
            La ponderación disponible es de:
            <strong>{{ WeighingAvailable - study_unit.weighing }}%</strong>
          </div>
          <b-form-invalid-feedback id="input-weighing-feedback"
            >Este campo es obligatorio y no debe exceder
            {{ WeighingAvailable }}%.
          </b-form-invalid-feedback>
          <div
            v-if="
              theTestsHaveSomeCorrect &&
              !study_unit.study_unit_locked_view &&
              profileMatter != null
            "
            class="text-info"
          >
            No puede cambiar la Ponderación Equivalente dado que existen
            evaluaciones ya cerradas.
            <div>
              Si aún así se desea cambiar la Ponderación presione en
              <span
                class="link-modal-evaluations"
                @click="$bvModal.show(`new-evaluation-correct-list-modal`)"
                >ver más</span
              >
              y abra las evaluaciones que se indicarán en la ventana.
            </div>
          </div>
        </b-form-group>
        <b-form-group
          v-if="show_temp_competence_unit && competenceUnits.length > 0"
          label-cols="0"
          label-cols-lg="2"
          :label="
            $getVisibleNames(
              'teaching.tempcompetenceunit',
              false,
              'Unidad de competencia'
            ) + ':'
          "
          label-for="input-temp_competence_unit"
          class="label mt-1 mb-2"
        >
          <b-form-select
            id="input-temp_competence_unit"
            value-field="id"
            text-field="full_sentence"
            v-model="$v.study_unit.temp_competence_unit.$model"
            :state="validateState('temp_competence_unit')"
            :options="competenceUnits"
            type="number"
            :disabled="study_unit.study_unit_locked_view"
            aria-describedby="input-temp_competence_unit-feedback"
            size="sm"
          >
            <template #first>
              <b-form-select-option :value="null">
                -- Seleccione una de las opciones --
              </b-form-select-option>
            </template>
          </b-form-select>
          <b-form-invalid-feedback id="input-temp_competence_unit-feedback"
            >Debe seleccionar un
            {{
              $getVisibleNames(
                "teaching.tempcompetenceunit",
                false,
                "Unidad de competencia"
              )
            }}.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label-cols="0"
          label-cols-lg="2"
          label-size="sm"
          label="Orden:"
          label-for="input-order"
          description="Puede especificar la posición o el orden."
          class="m-0 p-0"
        >
          <b-form-input
            type="number"
            id="input-order"
            v-model="$v.study_unit.order.$model"
            :disabled="study_unit.study_unit_locked_view"
            :state="validateState('order')"
            aria-describedby="input-order-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-order-feedback"
            >Este campo es obligatorio.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label-cols="0"
          label-cols-sm="2"
          :label="
            $getVisibleNames(
              'mesh.studyenvironment',
              false,
              'Ambiente de Aprendizaje'
            ) + ':'
          "
          label-for="input-study_environments"
          class="label my-2 mx-0"
        >
          <v-select
            id="input-study_environments"
            v-model="$v.study_unit.study_environments.$model"
            :options="studyEnvironments"
            :reduce="(studyEnvironments) => studyEnvironments.id"
            :disabled="study_unit.study_unit_locked_view"
            :placeholder="`${
              studyEnvironments.length > 0
                ? String(
                    'Seleccione uno o varios ' +
                      $getVisibleNames(
                        'mesh.studyenvironment',
                        false,
                        'Ambiente de Aprendizaje'
                      ).toLowerCase()
                  )
                : String(
                    'No cuenta con ' +
                      $getVisibleNames(
                        'mesh.studyenvironment',
                        false,
                        'Ambiente de Aprendizaje'
                      ).toLowerCase() +
                      ' creadas'
                  )
            }`"
            multiple
            :dropdown-should-open="dropdownShouldOpen"
            :state="validateState('study_environments')"
            label="name"
            track-by="id"
            size="sm"
          >
            <template v-slot:no-options="{ search, searching }">
              <template v-if="searching">
                No se encontraron resultados para: "<em>{{ search }}</em
                >"
              </template>
            </template>
          </v-select>
          <b-tooltip
            target="input-study_environments"
            variant="secondary"
            :noninteractive="true"
            triggers="hover"
          >
            <div v-if="studyEnvironments.length == 0">
              Diríjase a Definiciones Curriculares para poder crear
              {{
                $getVisibleNames(
                  "mesh.studyenvironment",
                  false,
                  "Ambiente de Aprendizaje"
                ).toLowerCase()
              }}.
            </div>
          </b-tooltip>
        </b-form-group>
        <b-form-group
          label-cols="0"
          label-cols-sm="2"
          label="Horas Sugeridas:"
          label-for="input-suggested-hours"
          class="label my-2 mx-0"
        >
          <b-form-input
            id="input-suggested-hours"
            type="number"
            min="0"
            v-model="$v.study_unit.hours.$model"
            :disabled="study_unit.study_unit_locked_view"
            :state="validateState('hours')"
            aria-describedby="input-suggested-hours-feedback"
            size="sm"
          ></b-form-input>
          <b-form-invalid-feedback id="input-suggested-hours-feedback"
            >Este campo debe ser mayor o igual a cero.</b-form-invalid-feedback
          >
        </b-form-group>
        <b-form-group
          label-cols="0"
          label-cols-lg="2"
          label-size="sm"
          :label="`${$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )}:`"
        >
          <b-form-input
            v-if="matter"
            v-model="matter.name"
            type="text"
            disabled
            size="sm"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="!isNaN(this.study_unit.id)"
          label-for="input-modification-date"
          label-cols="0"
          label-cols-sm="0"
          class="p-0 m-0 mt-2"
        >
          <ModificationDate :Information="study_unit"></ModificationDate>
        </b-form-group>
      </b-tab>
      <b-tab
        v-if="show_evaluation_criterias"
        :title="`${$getVisibleNames(
          'manual.indicador_de_logro',
          true,
          'Indicadores de Logro'
        )}`"
      >
        <b-table
          hover
          :items="evaluation_criteria_items"
          :fields="table_fields"
          :tbody-tr-class="rowClass"
          sticky-header
        >
          <template v-slot:cell(actions)="row">
            <button-edit
              v-if="row.item.is_evaluation_criteria && row.item.allows_edit"
              @click="editEvaluationCriteria(row.item.id)"
              :title="`Editar ${$getVisibleNames(
                'manual.indicador_de_logro',
                false,
                'Indicador de Logro'
              )}`"
            ></button-edit>
            <button-delete
              v-if="row.item.is_evaluation_criteria"
              @click="askForDeleteEvaluationCriteria(row.item.id)"
              :title="`Eliminar ${$getVisibleNames(
                'manual.indicador_de_logro',
                false,
                'Indicador de Logro'
              )}`"
            ></button-delete>
            <button-delete
              v-else
              @click="askForDeletePerformanceIndicator(row.item.id)"
              title="Eliminar Indicador de Desempeño"
            ></button-delete>
          </template>
          <template v-slot:cell(is_evaluation_criteria)="row">
            <template v-if="row.item.is_evaluation_criteria">
              <div
                :title="
                  row.item.is_evaluation_criteria
                    ? String(
                        $getVisibleNames(
                          'manual.criterio_de_evaluacion',
                          false,
                          'Criterio de Evaluación'
                        )
                      )
                    : 'Indicador de Desempeño de Competencia Genérica'
                "
              >
                {{ row.item.weighing }} %
              </div>
            </template>
          </template>
        </b-table>
      </b-tab>
      <b-tab
        v-if="
          (institution && institution.internal_use_id == 'duoc_uc') ||
          $debug_change_duoc
        "
        :disabled="isNaN(study_unit.id)"
      >
        <template #title>
          {{
            $getVisibleNames("teaching.studyunitcontent", true, "Contenidos")
          }}
          <b-button
            v-if="
              !isNaN(study_unit.id) &&
              !study_unit.study_unit_content_locked_view &&
              matter &&
              !matter.is_closed &&
              isClosedOldMatter
            "
            variant="none"
            size="sm"
            class="p-0 mb-1"
            v-b-tooltip.top.noninteractive.v-secondary="
              isClosedOldMatter
                ? `Bloquear los ${$getVisibleNames(
                    'teaching.studyunitcontent',
                    true,
                    'Contenidos'
                  )}`
                : `Actualmente los ${$getVisibleNames(
                    'teaching.studyunitcontent',
                    true,
                    'Contenidos'
                  )} se encuentran desbloqueados`
            "
            @click="
              changeLockedStudyUnit({
                study_unit_content_locked_view:
                  !study_unit.study_unit_content_locked_view,
              })
            "
          >
            <b-icon-unlock-fill variant="primary"></b-icon-unlock-fill>
          </b-button>
          <b-button
            v-if="
              !isNaN(study_unit.id) &&
              study_unit.study_unit_content_locked_view &&
              matter &&
              !matter.is_closed
            "
            variant="none"
            size="sm"
            class="p-0 mb-1"
            v-b-tooltip.top.noninteractive.v-secondary="
              isClosedOldMatter
                ? `Desbloquear los ${$getVisibleNames(
                    'teaching.studyunitcontent',
                    true,
                    'Contenidos'
                  )}`
                : `Actualmente los ${$getVisibleNames(
                    'teaching.studyunitcontent',
                    true,
                    'Contenidos'
                  )} se encuentran bloqueados`
            "
            @click="
              changeLockedStudyUnit({
                study_unit_content_locked_view:
                  !study_unit.study_unit_content_locked_view,
              })
            "
          >
            <b-icon-lock-fill variant="primary"></b-icon-lock-fill>
          </b-button>
        </template>
        <b-table-simple v-if="!isNaN(study_unit.id)">
          <b-thead>
            <b-th :colspan="2"
              ><b-button
                v-if="!study_unit.study_unit_content_locked_view"
                v-b-tooltip.v-secondary.noninteractive="
                  `Agregue los ${$getVisibleNames(
                    'teaching.studyunitcontent',
                    true,
                    'Contenidos'
                  )} por separado`
                "
                @click="
                  $bvModal.show(
                    `create-content-modal-study_unit-${study_unit.id}`
                  )
                "
                variant="secondary"
                size="sm"
                class="my-1 mr-1"
              >
                Agregar
                {{
                  $getVisibleNames(
                    "teaching.studyunitcontent",
                    false,
                    "Contenido"
                  )
                }}
              </b-button>
              <b-modal
                :id="`create-content-modal-study_unit-${study_unit.id}`"
                :title="`Crear ${$getVisibleNames(
                  'teaching.studyunitcontent',
                  false,
                  'Contenido'
                )}`"
                size="lg"
                hide-footer
                no-enforce-focus
              >
                <NewRichTextEditor
                  @save="createContent"
                  @close="
                    $bvModal.hide(
                      `create-content-modal-study_unit-${study_unit.id}`
                    )
                  "
                ></NewRichTextEditor>
              </b-modal>
            </b-th>
          </b-thead>
          <draggable
            v-model="draggableList"
            :disabled="!allows_crud"
            tag="b-tbody"
            handle=".handle"
          >
            <!-- Contenidos con datos -->
            <template v-if="draggableList.length > 0">
              <b-tr v-for="content in draggableList" :key="content.id">
                <b-td
                  class="text-center handle"
                  :class="{ grabbable: allows_crud }"
                  v-if="
                    allows_crud && !study_unit.study_unit_content_locked_view
                  "
                  ><b-icon
                    v-if="allows_crud"
                    icon="arrows-expand"
                    scale="1.2"
                  ></b-icon>
                </b-td>
                <b-td :id="`content-${content.id}`">
                  <div class="content-div">
                    <div
                      class="rich-text-content"
                      v-html="content.content"
                    ></div>
                  </div>
                </b-td>
                <b-td>
                  <div v-if="allows_crud && content.id" class="text-center">
                    <button-edit
                      v-if="!study_unit.study_unit_content_locked_view"
                      tabindex="-1"
                      @click="
                        $bvModal.show(
                          `edit-content-modal-study-unit-${content.id}`
                        )
                      "
                      v-b-tooltip.v-secondary.noninteractive="
                        `Editar ${$getVisibleNames(
                          'teaching.studyunitcontent',
                          false,
                          'Contenido'
                        )}`
                      "
                    >
                    </button-edit>
                    <button-delete
                      v-if="!study_unit.study_unit_content_locked_view"
                      tabindex="-1"
                      @click="deleteContent(content.id)"
                      v-b-tooltip.v-secondary.noninteractive="
                        `Eliminar ${$getVisibleNames(
                          'teaching.studyunitcontent',
                          false,
                          'Contenido'
                        )}`
                      "
                    ></button-delete>
                    <b-modal
                      :id="`edit-content-modal-study-unit-${content.id}`"
                      :title="`Editar ${$getVisibleNames(
                        'teaching.studyunitcontent',
                        false,
                        'Contenido'
                      )}`"
                      size="lg"
                      hide-footer
                      no-enforce-focus
                    >
                      Copie y edite los
                      {{
                        $getVisibleNames(
                          "teaching.studyunitcontent",
                          true,
                          "Contenidos"
                        )
                      }}
                      por separado
                      <NewRichTextEditor
                        :Object="content"
                        :Text="content.content"
                        @save="updateContent"
                        @close="
                          $bvModal.hide(
                            `edit-content-modal-study-unit-${content.id}`
                          )
                        "
                      ></NewRichTextEditor>
                    </b-modal>
                  </div>
                </b-td>
              </b-tr>
            </template>
            <!-- Contenidos sin datos -->
            <b-tr v-else>
              <b-td class="text-center" colspan="3"
                >No hay
                {{
                  $getVisibleNames(
                    "teaching.studyunitcontent",
                    true,
                    "Contenidos"
                  )
                }}
                para mostrar.</b-td
              >
            </b-tr>
          </draggable>
        </b-table-simple>
      </b-tab>
      <b-tab
        v-if="show_evaluation_criterias"
        :title="`Agregar ${$getVisibleNames(
          'manual.indicador_de_logro',
          false,
          'Indicador de Logro'
        )}`"
      >
        <div v-for="criteria in evaluationCriteriaList" :key="criteria.id">
          <EvaluationCriteriaForm
            v-if="current_evaluation_criteria_id == criteria.id"
            :EvaluationCriteria="criteria"
            :allows_crud="true"
            :shows_save_button="true"
            :tabs_mode="false"
            :max_weighing="evaluationCriteriaWeighingAvailable"
            @updated="slotUpdatedEvaluationCriteria"
          ></EvaluationCriteriaForm>
        </div>
        <div v-if="current_evaluation_criteria_id == -1 && tab_index == 3">
          <EvaluationCriteriaForm
            v-if="learningResult"
            :study_unit_id="study_unit.id"
            :allows_crud="true"
            :show_save_button="true"
            :tabs_mode="false"
            :max_weighing="evaluationCriteriaWeighingAvailable"
            @create="slotCreateEvaluationCriteria"
            @update="slotUpdateEvaluationCriteria"
            @created="slotCreatedEvaluationCriteria"
            @updated="slotUpdatedEvaluationCriteria"
          ></EvaluationCriteriaForm>
          <EvaluationCriteriaForm
            v-else
            :allows_crud="true"
            :show_save_button="true"
            :tabs_mode="false"
            :max_weighing="evaluationCriteriaWeighingAvailable"
            @create="slotCreateEvaluationCriteria"
            @update="slotUpdateEvaluationCriteria"
            @created="slotCreatedEvaluationCriteria"
            @updated="slotUpdatedEvaluationCriteria"
          ></EvaluationCriteriaForm>
        </div>
      </b-tab>
    </b-tabs>
    <div
      class="row mt-2"
      :class="{ 'justify-content-end': isNaN(study_unit.id) }"
    >
      <div class="col d-flex" style="text-align: left">
        <b-button
          v-if="show_delete_button && !study_unit.study_unit_locked_view"
          class="mr-1"
          size="sm"
          variant="danger"
          @click="remove"
          :class="{
            'btn-disabled': theTestsHaveSomeCorrect,
          }"
          :disabled="theTestsHaveSomeCorrect"
        >
          Eliminar
        </b-button>
        <template
          v-if="
            theTestsHaveSomeCorrect &&
            matter &&
            !study_unit.study_unit_locked_view
          "
        >
          <span class="ml-2">
            <ButtonErrorInstrument
              variant="danger"
              :popover_text="`No se puede eliminar el ${$getVisibleNames(
                'teaching.ramicro',
                false,
                'RA Micro'
              )} dado que existen Evaluaciones ya cerradas. Si aún así desea eliminar el ${$getVisibleNames(
                'teaching.ramicro',
                false,
                'RA Micro'
              )} presione el botón y abra las Evaluaciones que se indicarán en la ventana.`"
              :id_popover="matter.id"
              target_popover="disabled-create-evaluation-criteria"
              click_modal="new-evaluation-correct-list-modal"
            ></ButtonErrorInstrument>
          </span>
        </template>
      </div>
      <div class="d-flex" style="text-align: right">
        <template v-if="profileMatter">
          <span
            v-if="
              ((institution && institution.show_base_ra) ||
                $debug_change_duoc) &&
              profileMatter &&
              profileMatter.competences.length > 0 &&
              !isNaN(study_unit.id)
            "
          >
            <!-- <b-button
            v-if="
              profileMatter.competences.length == 1 &&
              !includeCompetenceInEPMatter
            "
            class="mr-3"
            size="sm"
            v-b-tooltip.v-secondary.noninteractive="
              `Vincular ${$getVisibleNames(
                'teaching.ramicro',
                false,
                'RA Micro'
              )} creado a la competencia.`
            "
            @click="launchSelectedCompetenceModal()"
          >
            Vincular
            {{ $getVisibleNames("teaching.ramicro", false, "RA Micro") }}
          </b-button> -->
            <LinkingCompetenceToRa
              v-if="
                egress_profile_matter_id &&
                !isNaN(study_unit.id) &&
                !study_unit.study_unit_locked_view
              "
              class="noprint"
              :study_unit_id="study_unit.id"
              :egress_profile_matter_id="egress_profile_matter_id"
              :allows_crud="allows_crud"
            ></LinkingCompetenceToRa>
          </span>
          <span
            v-if="
              (institution && institution.show_base_ra) || $debug_change_duoc
            "
            :id="`ra-base-popover`"
            :key="`ra-base-popover`"
          >
            <b-button
              v-if="!apply_ra_base && isNaN(study_unit.id)"
              class="mr-3"
              size="sm"
              v-b-tooltip.v-secondary.noninteractive="
                `Seleccionar un ${$getVisibleNames(
                  'teaching.basera',
                  false,
                  'RA Base'
                )} ya creado de la ${$getVisibleNames(
                  'mesh.competence',
                  false,
                  'Competencia'
                )}.`
              "
              @click="$bvModal.show(`modal-base-ra`)"
              :disabled="raBases.length == 0"
            >
              Seleccionar
              {{ $getVisibleNames("teaching.basera", false, "RA Base") }}
            </b-button>
            <!-- <b-button
            v-if="apply_ra_base"
            class="mr-3"
            size="sm"
            v-b-tooltip.v-secondary.noninteractive="
              `${
                applyRaBase
                  ? 'Cambiar vinculo del ' +
                    $getVisibleNames('teaching.basera', false, 'RA Base') +
                    ' aplicado: ' +
                    applyRaBase.full_sentence
                  : ''
              }`
            "
            @click="changeProfileRaBase"
          >
            Cambiar {{ $getVisibleNames("teaching.basera", false, "RA Base") }}
          </b-button> -->
          </span>
        </template>
        <b-button
          v-if="show_save_button && !study_unit.study_unit_locked_view"
          class="mr-3 ml-3"
          size="sm"
          @click="save"
          >Guardar</b-button
        >
      </div>
    </div>
    <b-popover
      v-if="raBases.length == 0"
      triggers="hover"
      :target="`ra-base-popover`"
      :key="`ra-base-tooltip`"
    >
      <template #title>{{
        $getVisibleNames("teaching.basera", false, "RA Base")
      }}</template>
      <template>
        No cuenta con ningún
        {{ $getVisibleNames("teaching.basera", false, "RA Base") }}
        en las
        {{ $getVisibleNames("mesh.competence", true, "Competencias") }}
        seleccionada para esa
        {{ $getVisibleNames("mesh.egressprofilematter", false, "Asignatura") }}.
      </template>
    </b-popover>
    <b-modal
      :id="`modal-selected-competence`"
      hide-footer
      :title="
        'Seleccione ' +
        $getVisibleNames('mesh.competence', false, 'Competencia')
      "
      size="lg"
    >
      <b-form-group
        :label="`${$getVisibleNames('mesh.competence', true, 'Competencias')}`"
        label-for="select-career"
        label-cols="0"
        label-cols-sm="3"
        class="p-0 m-0"
      >
        <v-select
          id="input-selected_competence"
          v-model="selected_competence"
          :options="matterCompetence"
          :reduce="(competences) => competences.id"
          :placeholder="`${String(
            'Seleccione la ' +
              $getVisibleNames(
                'mesh.competence',
                false,
                'Competencia'
              ).toLowerCase()
          )}`"
          :dropdown-should-open="dropdownShouldOpen2"
          label="full_sentence"
          track-by="id"
        >
          <template v-slot:no-options="{ search, searching }">
            <template v-if="searching">
              No se encontraron resultados para: "<em>{{ search }}</em
              >"
            </template>
          </template>
        </v-select>
      </b-form-group>
      <div class="row mt-2">
        <div class="col" style="text-align: left">
          <b-button
            class="mr-1"
            size="sm"
            variant="secondary"
            @click="$bvModal.hide(`modal-selected-competence`)"
            >Cancelar</b-button
          >
        </div>
        <div class="col" style="text-align: right">
          <template v-if="selected_competence == null">
            <b-button size="sm" variant="secondary" disabled>Aplicar </b-button>
          </template>
          <template v-else-if="isNaN(study_unit.id)">
            <b-button
              size="sm"
              variant="secondary"
              @click="CreateStudyUnitBaseRA(selected_competence)"
              >Aplicar
            </b-button>
          </template>
          <template v-else-if="!isNaN(study_unit.id)">
            <b-button
              size="sm"
              variant="secondary"
              @click="ConectStudyUnitBaseRA(selected_competence)"
              >Aplicar
            </b-button>
          </template>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-if="profileMatter != null"
      :id="`new-evaluation-correct-list-modal`"
      hide-footer
      size="lg"
      :title="`Evaluaciones cerradas`"
    >
      <InfoEvaluations
        :matter_id="profileMatter.matter"
        :egress_profile_matter_id="profileMatter.id"
        :is_valid="true"
        @close_modal="$bvModal.hide(`new-evaluation-correct-list-modal`)"
      ></InfoEvaluations>
    </b-modal>
    <b-modal
      :id="`modal-base-ra`"
      hide-footer
      :title="
        'Seleccione ' + $getVisibleNames('teaching.basera', false, 'RA Base')
      "
      size="lg"
    >
      <!-- <v-select
        id="input-ra-base"
        :options="raBases"
        v-model="selected_ra_base"
        :reduce="(raBases) => raBases.id"
        label="full_sentence"
        track-by="id"
      >
        <template v-slot:no-options="{ search, searching }">
          <template v-if="searching">
            No se encontró resultados para <em>{{ search }}</em
            >.
          </template>
        </template>
        <template v-slot:option="option">
          {{ option.full_sentence }}
        </template>
      </v-select> -->
      <b-form-select
        v-model="selected_ra_base"
        :select-size="10"
        size="sm"
        class="select-form"
      >
        <b-form-select-option-group
          v-for="item in fillRaBaseList"
          :key="item.id"
          :label="item.name"
        >
          <b-select-option
            class="select-option"
            v-for="(ecm, index) in item.options"
            :value="ecm.id"
            :key="ecm.id"
            :title="ecm.name"
            :class="{ 'disabled-select': ecm.disabled }"
            :disabled="ecm.disabled"
          >
            {{ item.order }}.{{ item.cycle ? item.cycle + "." : ""
            }}{{ index + 1 }} {{ ecm.full_sentence }}
            {{
              ecm.matter_name.trim() == "" ? "" : "- (" + ecm.matter_name + ")"
            }}
          </b-select-option>
        </b-form-select-option-group>
      </b-form-select>
      <div class="row mt-2">
        <div class="col" style="text-align: left">
          <b-button
            class="mr-1"
            size="sm"
            variant="secondary"
            @click="$bvModal.hide(`modal-base-ra`)"
            >Cancelar</b-button
          >
        </div>
        <div class="col" style="text-align: right">
          <b-button size="sm" variant="secondary" @click="slotUpdateStudyUnit"
            >Aplicar
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { generateUniqueId, areEqualsObjects, toast } from "@/utils/utils";
import { validationMixin } from "vuelidate";
import {
  required,
  minValue,
  // maxValue,
  numeric,
} from "vuelidate/lib/validators";
import vSelect from "vue-select";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "vue-select/dist/vue-select.css";

export default {
  name: "StudyUnitForm",
  mixins: [validationMixin],
  components: {
    vSelect,
    draggable,
    LinkingCompetenceToRa: () =>
      import("@/components/mesh/Matrix2/LinkingCompetenceToRa"),
    EvaluationCriteriaForm: () => import("./EvaluationCriteriaForm"),
    SentenceForm: () => import("./SentenceForm"),
    NewRichTextEditor: () => import("@/components/reusable/NewRichTextEditor"),
    ButtonErrorInstrument: () =>
      import("@/components/reusable/ButtonErrorInstrument"),
    InfoEvaluations: () =>
      import("@/components/new-evaluations/Evaluation/InfoEvaluations.vue"),
    ModificationDate: () => import("@/components/reusable/ModificationDate"),
  },
  props: {
    egress_profile_matter_id: {
      type: [Number, null],
    },
    matter_id: {
      type: Number,
      required: true,
    },
    StudyUnit: {
      type: Object,
    },
    temp_competence_unit: {
      type: [Number, null],
      default: null,
    },
    show_save_button: {
      type: Boolean,
      default: true,
    },
    show_delete_button: {
      type: Boolean,
      default: false,
    },
    tabs_mode: {
      type: Boolean,
      default: true,
    },
    allows_crud: {
      type: Boolean,
      default: true,
    },
    show_temp_competence_unit: {
      type: Boolean,
      default: false,
    },
    show_evaluation_criterias: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      study_unit: {
        id: this.StudyUnit ? this.StudyUnit.id : generateUniqueId(),
        name: this.StudyUnit ? this.StudyUnit.name : "",
        // description: this.StudyUnit ? this.StudyUnit.description : "",
        order: this.StudyUnit ? this.StudyUnit.order : 1,
        // weeks: this.StudyUnit ? this.StudyUnit.weeks : 1,
        weighing: this.StudyUnit ? this.StudyUnit.weighing : 0,
        hours: this.StudyUnit ? this.StudyUnit.hours : 0,
        matter: this.StudyUnit ? this.StudyUnit.matter : this.matter_id,
        methodological_strategies: this.StudyUnit
          ? this.StudyUnit.methodological_strategies
          : [],
        study_environments: this.StudyUnit
          ? this.StudyUnit.study_environments
          : [],
        performance_indicators: this.StudyUnit
          ? this.StudyUnit.performance_indicators
          : [],
        action: this.StudyUnit ? this.StudyUnit.action : "",
        content: this.StudyUnit ? this.StudyUnit.content : "",
        condition: this.StudyUnit ? this.StudyUnit.condition : "",
        full_sentence: this.StudyUnit ? this.StudyUnit.full_sentence : "",
        automatic_achievement_weighing: this.StudyUnit
          ? this.StudyUnit.automatic_achievement_weighing
          : true,
        verb: this.StudyUnit ? this.StudyUnit.verb : null,
        study_unit_locked_view: this.StudyUnit
          ? this.StudyUnit.study_unit_locked_view
          : false,
        temp_competence_unit: this.StudyUnit
          ? this.StudyUnit.temp_competence_unit
          : this.temp_competence_unit,
        study_unit_content_locked_view: this.StudyUnit
          ? this.StudyUnit.study_unit_content_locked_view
          : false,
        updated_by: this.StudyUnit ? this.StudyUnit.updated_by : null,
        update_date: this.StudyUnit ? this.StudyUnit.update_date : null,
      },
      keySentenceForm: 0,
      selected_ra_base: null,
      base_sentence_toggle: this.StudyUnit
        ? this.StudyUnit.content
          ? true
          : false
        : true,
      current_evaluation_criteria_id: -1,
      evaluation_criteria_list: [],
      evaluation_criteria_items: [],
      apply_ra_base: false,
      selected_competence: null,
      table_fields: [
        {
          key: "sentence",
          label: "Redacción",
          sortable: true,
        },
        { key: "is_evaluation_criteria", label: "Ponderación" },
        { key: "actions", label: "" },
      ],
      tab_index: 0,
    };
  },
  validations() {
    return {
      study_unit: {
        name: {},
        // description: {},
        hours: { minVlaue: minValue(0), required, numeric },
        // action: { required },
        // content: { required },
        // condition: { required },
        study_environments: {},
        weighing: {
          isValid(value) {
            if (
              this.matter &&
              this.institution &&
              ((this.institution.internal_use_id != "ciisa_uss" &&
                this.matter.automatic_achievement_weighing) ||
                (this.institution.internal_use_id == "ciisa_uss" &&
                  this.competenceUnit &&
                  this.competenceUnit.automatic_achievement_weighing))
            ) {
              return true;
            } else {
              if (value < this.WeighingAvailable) return true;
            }
            return false;
          },
        },
        order: { required, minVlaue: minValue(1) },
        temp_competence_unit: {
          isValid(value) {
            if (
              this.institution &&
              this.institution.internal_use_id == "ciisa_uss" &&
              value != null
            ) {
              return true;
            } else if (
              this.institution &&
              this.institution.internal_use_id == "ciisa_uss" &&
              value == null
            )
              return false;
            return true;
          },
        },
        // weeks: { required, minVlaue: minValue(1), maxVlaue: maxValue(18) },
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      matters: names.MATTERS,
      ra_bases: names.RA_BASES,
      profile_ra_bases: names.EGR_PROFILE_RA_BASES,
      egressProfileMatters: names.EGRESS_PROFILES_MATTERS,
      evaluationCriteriasMacros: names.EVALUATION_CRITERIAS_MACROS,
      learningResults: names.LEARNING_RESULTS,
      performanceIndicators: names.PERFORMANCE_INDICATORS,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
      studyUnits: names.STUDY_UNITS,
      competences: names.COMPETENCES,
      profile_cycles: names.PROFILE_CYCLES,
      profile_competences: names.PROFILE_COMPETENCES,
      contents: names.CONTENTS,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      evaluations: names.NEW_EVALUATIONS,
      tests: names.NEW_TESTS,
      competence_units: names.COMPETENCE_UNITS,
    }),
    matter() {
      return this.matters.find((x) => x.id == this.matter_id);
    },
    competenceUnit() {
      if (!this.study_unit.temp_competence_unit) return null;
      return this.competence_units.find(
        (x) => x.id == this.study_unit.temp_competence_unit
      );
    },
    competenceUnits() {
      if (!this.matter_id) return [];
      return this.competence_units.filter((x) => x.matter == this.matter_id);
    },
    isClosedOldMatter() {
      if (!this.matter) return false;
      if (
        this.user.is_superuser ||
        (this.$hasObjectPermission(
          "special.close_base_matter",
          "special",
          this.matter.id
        ) &&
          !this.matter.is_closed) ||
        (this.$hasObjectPermission(
          "special.open_base_matter",
          "special",
          this.matter.id
        ) &&
          this.matter.is_closed)
        // ||
        // (this.user_access && this.user_access.position == 1)
      ) {
        return true;
      } else return false;
    },
    contentsList() {
      return this.contents.filter((x) => x.study_unit == this.study_unit.id);
    },
    draggableList: {
      get() {
        let list = this.contentsList;
        list.sort(function (a, b) {
          if (a.order < b.order) return -1;
          if (a.order > b.order) return 1;
          return 0;
        });
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) element.order = order;
          order += 1;
        });
        return list;
      },
      set(list) {
        let order = 1;
        list.forEach((element) => {
          if (element.order != order) {
            element.order = order;
            if (!isNaN(element.id)) {
              this.updateOrderContent({
                content_id: element.id,
                item: { order: element.order },
              });
            }
          }
          order += 1;
        });
      },
    },
    matterStudyUnits() {
      return this.studyUnits
        .filter(
          (x) =>
            x.matter == this.matter_id &&
            x.temp_competence_unit == this.study_unit.temp_competence_unit
        )
        .sort((a, b) => a.order - b.order);
    },
    profileMatter() {
      if (!this.egress_profile_matter_id) return null;
      return this.egressProfileMatters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    matterCompetence() {
      if (!this.profileMatter) return [];
      else {
        const competences_ids = [
          ...new Set(
            this.profile_competences
              .filter(
                (x) => x.egress_profile == this.profileMatter.egress_profile
              )
              .map((a) => a.competence)
          ),
        ];
        return this.competences.filter(
          (x) =>
            this.profileMatter.competences.includes(x.id) &&
            competences_ids.includes(x.id)
        );
      }
    },
    profileRaBase() {
      if (!this.profileMatter) return null;
      if (isNaN(this.study_unit.id)) return null;
      return this.profile_ra_bases.find(
        (x) =>
          x.study_unit == this.study_unit.id &&
          x.egress_profile == this.profileMatter.egress_profile
      );
    },
    applyRaBase() {
      if (this.apply_ra_base && this.profileRaBase) {
        return this.ra_bases.find((x) => x.id == this.profileRaBase.base_ra);
      } else return null;
    },
    includeCompetenceInEPMatter() {
      if (!this.applyRaBase && !this.profileMatter) return false;
      else {
        if (
          this.profileMatter &&
          this.applyRaBase &&
          this.profileMatter.competences.filter((x) =>
            this.applyRaBase.competences.includes(x)
          ).length > 0
        )
          return true;
        else return false;
      }
    },
    filterRaBase() {
      let list = [];
      if (!this.profileMatter) return list;
      const study_units_ids = [
        ...new Set(this.matterStudyUnits.map((x) => x.id)),
      ];
      this.raBases.forEach((x) => {
        let item = x;
        const ra_base = this.profile_ra_bases.filter(
          (pra) =>
            pra.egress_profile == this.profileMatter.egress_profile &&
            pra.base_ra == x.id
        );
        if (ra_base.length > 0)
          item.matter_name = ra_base.map((x) => x.matter_name).join(", ");
        else item.matter_name = "";
        if (
          (ra_base.length > 0 && !this.profileMatter.is_integrative) ||
          ra_base.filter(
            (a) => study_units_ids.includes(a.study_unit) && a.base_ra == x.id
          ).length > 0
        ) {
          item.disabled = true;
        } else {
          item.disabled = false;
        }
        list.push(item);
      });
      return list;
    },
    fillRaBaseList() {
      if (!this.profileMatter) return [];
      let list = [];
      this.competences.forEach((competence) => {
        const profile_competence = this.profile_competences.find(
          (x) =>
            x.competence == competence.id &&
            x.egress_profile == this.profileMatter.egress_profile &&
            this.profileMatter.competences.includes(x.competence)
        );
        let raBasesList = this.filterRaBase.filter((x) =>
          x.competences.includes(competence.id)
        );
        if (raBasesList.length > 0 && profile_competence)
          list.push({
            id: competence.id,
            name: String(
              profile_competence.order +
                "." +
                (profile_competence.cycle != null
                  ? this.cycleOrder(profile_competence.cycle) + "."
                  : "") +
                competence.full_sentence
            ),
            order: profile_competence.order,
            cycle: this.cycleOrder(profile_competence.cycle),
            options: raBasesList.sort(function (a, b) {
              if (a.id > b.id) return 1;
              if (a.id < b.id) return -1;
              return a.full_sentence
                .toLowerCase()
                .localeCompare(b.full_sentence.toLowerCase());
            }),
          });
      });
      return list.sort((a, b) => a.order - b.order);
    },
    raBases() {
      if (!this.profileMatter) return [];
      else
        return this.ra_bases.filter(
          (x) =>
            this.profileMatter.competences.filter((c) =>
              x.competences.includes(c)
            ).length > 0
        );
    },
    egressProfileMatter() {
      if (!this.studyUnit) return null;
      let profile_matter = this.egressProfileMatters.find(
        (x) => x.matter == this.studyUnit.matter
      );
      return profile_matter ? profile_matter.id : null;
    },
    evaluationCriteriaMacroList() {
      if (!this.profileMatter) return [];
      return this.evaluationCriteriasMacros
        .filter((x) => x.matter == this.profileMatter.matter)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    // performanceIndicatorList() {
    //   let list = [];
    //   this.study_unit.performance_indicators.forEach((element) => {
    //     let instance = this.performanceIndicators.find((x) => x.id == element);
    //     if (instance) list.push(instance);
    //   });
    //   return list;
    // },
    evaluationCriteriaList() {
      return this.evaluationCriterias.filter(
        (x) => x.study_unit == this.study_unit.id
      );
    },
    WeighingAvailable() {
      let weighing = 100;
      this.evaluationCriteriaMacroList.forEach((element) => {
        weighing -= element.weighing;
      });
      this.matterStudyUnits.forEach((element) => {
        if (element.id != this.study_unit.id) {
          weighing -= element.weighing;
        }
      });
      return weighing;
    },
    evaluationCriteriaWeighingAvailable() {
      let weighing = 100;
      this.evaluation_criteria_items.forEach((element) => {
        if (
          element.id != this.current_evaluation_criteria_id &&
          element.weighing
        )
          weighing -= element.weighing;
      });
      return weighing;
    },
    filterEvaluationsByMatter() {
      // scope==3 para base_matter
      if (this.matter)
        return this.evaluations.filter(
          (x) => x.scope == 3 && x.scope_id == this.matter.id
        );
      else return [];
    },
    filterTestsByMatter() {
      const evaluations_ids = this.filterEvaluationsByMatter.map((x) => x.id);
      return this.tests.filter((x) => evaluations_ids.includes(x.evaluation));
    },
    theTestsHaveSomeCorrect() {
      if (
        this.institution &&
        this.institution.internal_use_id == "duoc_uc" &&
        this.institution.kl_assessment
      ) {
        if (
          this.filterTestsByMatter.some(
            (x) => x.is_valid == true && x.manual_is_valid == true
          )
        )
          return true;
      }
      return false;
    },
  },
  methods: {
    changeLockedStudyUnit(item) {
      if (!isNaN(this.study_unit.id) && this.isClosedOldMatter) {
        this.$store
          .dispatch(names.PATCH_STUDY_UNIT, {
            study_unit_id: this.study_unit.id,
            item: item,
          })
          .then((response) => {
            this.study_unit = response;
          });
      }
    },
    slotUpdateSentence(item) {
      this.study_unit.verb = item.verb;
      this.study_unit.action = item.first_content;
      this.study_unit.content = item.second_content;
      this.study_unit.condition = item.third_content;
      this.study_unit.full_sentence = item.full_sentence;
      this.base_sentence_toggle = item.base_sentence_toggle;
    },
    createContent(text) {
      // if (!args[0] || args[0] == "") return;
      if (text != "") {
        let item = {
          study_unit: this.study_unit.id,
          content: text,
          order: this.contentsList.length + 1,
        };
        this.$store.dispatch(names.POST_CONTENT, item).then(() => {
          toast(
            `${this.$getVisibleNames(
              "teaching.studyunitcontent",
              false,
              "Contenido"
            )} creado.`
          );
        });
      } else toast("No se puede guardar si está vacío.");
    },
    updateOrderContent(payload) {
      this.$store.dispatch(names.PATCH_CONTENT, payload);
    },
    updateContent(content, text) {
      if (text != "") {
        const payload = {
          content_id: content.id,
          item: {
            content: text,
          },
        };
        this.$store.dispatch(names.PATCH_CONTENT, payload).then(() => {
          toast(
            `${this.$getVisibleNames(
              "teaching.studyunitcontent",
              false,
              "Contenido"
            )} actualizado.`
          );
        });
      } else toast("No se puede guardar si está vacío.");
    },
    deleteContent(content_id) {
      this.$store.dispatch(names.DELETE_CONTENT, content_id).then(() => {
        toast(
          `${this.$getVisibleNames(
            "teaching.studyunitcontent",
            false,
            "Contenido"
          )} eliminado.`
        );
      });
    },
    dropdownShouldOpen(VueSelect) {
      if (this.studyEnvironments.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    dropdownShouldOpen2(VueSelect) {
      if (this.matterCompetence.length > 0) {
        return VueSelect.open;
      }
      return VueSelect.search.length !== 0 && VueSelect.open;
    },
    cycleOrder(cycle_id) {
      if (!cycle_id && !this.profileMatter) return null;
      let cycleOrder = this.profile_cycles.find(
        (x) =>
          x.cycle == cycle_id &&
          x.egress_profile == this.profileMatter.egress_profile
      );
      return cycleOrder ? cycleOrder.order : null;
    },
    slotUpdateStudyUnit() {
      if (this.selected_ra_base) {
        const ra_base = this.raBases.find((x) => x.id == this.selected_ra_base);
        this.study_unit.verb = ra_base.verb;
        this.study_unit.action = ra_base.action;
        this.study_unit.content = ra_base.content;
        this.study_unit.condition = ra_base.condition;
        this.study_unit.full_sentence = ra_base.full_sentence;
        this.apply_ra_base = true;
        if (
          String(ra_base.content).trim() == "" &&
          String(ra_base.condition).trim() == ""
        )
          this.base_sentence_toggle = false;
        else this.base_sentence_toggle = true;
        this.keySentenceForm += 1;
        this.$bvModal.hide(`modal-base-ra`);
      }
    },
    validateState(key) {
      const { $dirty, $error } = this.$v.study_unit[key];
      return $dirty ? !$error : null;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      if (item.is_evaluation_criteria) return "table-success";
    },
    save() {
      if (this.base_sentence_toggle) {
        if (
          this.study_unit.action.trim() == "" ||
          this.study_unit.content.trim() == "" ||
          this.study_unit.condition.trim() == ""
        ) {
          this.tab_index = 0;
          return;
        }
        this.$v.study_unit.$touch();
        if (this.$v.study_unit.$anyError) {
          this.tab_index = 1;
          this.$swal.fire(
            "Faltan campos",
            "Por favor, asegúrese de completar los campos.",
            "warning"
          );
          return;
        }
        if (!this.allows_crud) {
          this.$emit("saveStudyUnit", this.study_unit);
          return;
        }
        this.checkOrder().then(() => {
          this.study_unit.full_sentence = String(
            this.study_unit.action +
              " " +
              this.study_unit.content +
              " " +
              this.study_unit.condition
          );
          if (isNaN(this.study_unit.id)) this.createStudyUnit();
          else this.updateStudyUnit();
        });
      } else {
        if (
          this.study_unit.full_sentence ||
          this.study_unit.full_sentence.trim() != ""
        ) {
          this.$v.study_unit.$touch();
          if (this.$v.study_unit.$anyError) {
            this.tab_index = 1;
            this.$swal.fire(
              "Faltan campos",
              "Por favor, asegúrese de completar los campos.",
              "warning"
            );
            return;
          }
          if (!this.allows_crud) {
            this.$emit("saveStudyUnit", this.study_unit);
            return;
          }
          this.checkOrder().then(() => {
            if (isNaN(this.study_unit.id)) this.createStudyUnit();
            else this.updateStudyUnit();
          });
        }
      }
    },
    remove() {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "teaching.ramicro",
          false,
          "RA Micro"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          if (!this.allows_crud) {
            this.$emit("delete", this.study_unit);
            return;
          }
          this.$store
            .dispatch(names.DELETE_STUDY_UNIT, this.study_unit.id)
            .then(() => {
              if (this.profileMatter) {
                if (
                  this.institution &&
                  this.institution.internal_use_id != "ciisa_uss"
                )
                  this.$restful
                    .Get(
                      `teaching/toggle_matter_automatic_achievement_weighing/?matter=${this.profileMatter.matter}`
                    )
                    .then(() => {
                      this.$store.dispatch(
                        names.FETCH_EVALUATION_CRITERIAS_MACROS,
                        this.profileMatter.matter
                      );
                      this.$store.dispatch(names.FETCH_COMPETENCE_UNITS, {
                        matter__id: this.profileMatter.matter,
                        force: true,
                      });
                      this.matterStudyUnits.forEach((element, index) => {
                        if (element.id != this.study_unit.id) {
                          let payload = {
                            study_unit_id: element.id,
                            item: {
                              order: index + 1,
                            },
                          };
                          this.$store.dispatch(names.PATCH_STUDY_UNIT, payload);
                        }
                      });
                    });
                if (
                  this.institution &&
                  this.institution.internal_use_id == "ciisa_uss" &&
                  this.competenceUnit
                )
                  this.$restful
                    .Get(
                      `teaching/toggle_temp_competence_unit_automatic_achievement_weighing/?temp_competence_unit=${this.competenceUnit.id}`
                    )
                    .then(() => {
                      this.$store.dispatch(
                        names.FETCH_STUDY_UNITS,
                        this.profileMatter.matter
                      );
                    });
              }
              this.$emit("deleted", this.study_unit);
              toast(
                this.$getVisibleNames("teaching.ramicro", false, "RA Micro") +
                  " eliminado."
              );
            });
        }
      });
    },
    checkOrder() {
      return new Promise((resolve) => {
        let flag = true;
        if (this.matterStudyUnits.length == 0) resolve(true);
        this.matterStudyUnits.forEach((element) => {
          if (
            this.study_unit.id != element.id &&
            this.study_unit.order == element.order &&
            this.study_unit.temp_competence_unit == element.temp_competence_unit
          ) {
            flag = false;
            this.$swal({
              title:
                "El orden de la Unidad está repetido ¿desea continuar guardando?",
              text: "¡Esta acción provocará que se desajuste el orden de las Unidades!",
              type: "warning",
              showCancelButton: true,
            }).then((result) => {
              if (result.value) {
                resolve(result.value);
              }
            });
          }
        });
        if (flag) resolve();
      });
    },
    createStudyUnit() {
      if (
        this.selected_ra_base == null &&
        ((this.institution && this.institution.show_base_ra) ||
          this.$debug_change_duoc) &&
        this.profileMatter &&
        this.profileMatter.competences.length > 0
      ) {
        if (this.profileMatter.competences.length > 1)
          this.$bvModal.show("modal-selected-competence");
        else this.CreateStudyUnitBaseRA(this.profileMatter.competences[0]);
      } else {
        if (
          this.matter &&
          !(
            (this.institution &&
              this.institution.internal_use_id == "duoc_uc") ||
            this.$debug_change_duoc
          )
        ) {
          this.study_unit.methodological_strategies =
            this.matter.methodological_strategies;
        }
        this.$store
          .dispatch(names.POST_STUDY_UNIT, this.study_unit)
          .then((response) => {
            this.study_unit = response;
            if (this.profileMatter) {
              if (
                (this.selected_ra_base != null ||
                  !isNaN(this.selected_ra_base)) &&
                this.apply_ra_base == true &&
                this.profileRaBase == null
              ) {
                const payload = {
                  egress_profile: this.profileMatter.egress_profile,
                  base_ra: this.selected_ra_base,
                  study_unit: response.id,
                };
                this.$store.dispatch(names.POST_EGR_PROFILE_RA_BASE, payload);
              }
              if (
                this.institution &&
                this.institution.internal_use_id != "ciisa_uss"
              )
                this.$restful
                  .Get(
                    `teaching/toggle_matter_automatic_achievement_weighing/?matter=${this.profileMatter.matter}`
                  )
                  .then(() => {
                    this.$store.dispatch(
                      names.FETCH_EVALUATION_CRITERIAS_MACROS,
                      this.profileMatter.matter
                    );
                    this.$store.dispatch(
                      names.FETCH_STUDY_UNITS,
                      this.profileMatter.matter
                    );
                    this.$store.dispatch(names.FETCH_COMPETENCE_UNITS, {
                      matter__id: this.profileMatter.matter,
                      force: true,
                    });
                  });
              if (
                this.institution &&
                this.institution.internal_use_id == "ciisa_uss" &&
                this.competenceUnit
              )
                this.$restful
                  .Get(
                    `teaching/toggle_temp_competence_unit_automatic_achievement_weighing/?temp_competence_unit=${this.competenceUnit.id}`
                  )
                  .then(() => {
                    this.$store.dispatch(
                      names.FETCH_STUDY_UNITS,
                      this.profileMatter.matter
                    );
                  });
            }
            this.saveEvaluationCriterias();
            if (
              (this.institution &&
                this.institution.internal_use_id == "duoc_uc") ||
              this.$debug_change_duoc
            )
              this.$nextTick(() => {
                this.tab_index = 2;
              });
            else this.$emit("created", response);

            toast(
              this.$getVisibleNames("teaching.ramicro", false, "RA Micro") +
                " creado."
            );
          });
      }
    },
    launchSelectedCompetenceModal() {
      if (this.profileMatter.competences.length > 1)
        this.$bvModal.show("modal-selected-competence");
      else this.ConectStudyUnitBaseRA(this.profileMatter.competences[0]);
    },
    ConectStudyUnitBaseRA(competence_id) {
      if (this.applyRaBase) {
        let competences_ids = this.applyRaBase.competences;
        competences_ids.push(competence_id);
        this.$store.dispatch(names.PATCH_RA_BASE, {
          ra_base_id: this.applyRaBase.id,
          item: {
            competences: competences_ids,
          },
        });
      } else if (
        !isNaN(this.study_unit.id) &&
        this.profileMatter &&
        !this.applyRaBase
      ) {
        this.$store
          .dispatch(names.POST_RA_BASE, {
            verb: this.study_unit.verb,
            competences: [competence_id],
            action: this.study_unit.action,
            content: this.study_unit.content,
            condition: this.study_unit.condition,
            full_sentence: this.study_unit.full_sentence,
            order:
              this.ra_bases.filter((x) => x.competences.includes(competence_id))
                .length > 0
                ? this.ra_bases
                    .filter((x) => x.competences.includes(competence_id))
                    .sort((a, b) => b.order - a.order)[0].order + 1
                : 1,
          })
          .then((ra_base) => {
            const payload = {
              egress_profile: this.profileMatter.egress_profile,
              base_ra: ra_base.id,
              study_unit: this.study_unit.id,
            };
            this.$store
              .dispatch(names.POST_EGR_PROFILE_RA_BASE, payload)
              .then(() => {
                this.selected_ra_base = ra_base.id;
                this.apply_ra_base = true;
                this.$store.dispatch(names.FETCH_RA_BASE, ra_base.id);
              });
          });
      }
      this.selected_competence = null;
      this.$bvModal.hide("modal-selected-competence");
    },
    CreateStudyUnitBaseRA(competence_id) {
      if (
        this.matter &&
        !(
          (this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc
        )
      ) {
        this.study_unit.methodological_strategies =
          this.matter.methodological_strategies;
      }
      this.$store
        .dispatch(names.POST_STUDY_UNIT, this.study_unit)
        .then((response) => {
          this.study_unit = response;
          if (this.profileMatter) {
            this.$store
              .dispatch(names.POST_RA_BASE, {
                verb: this.study_unit.verb,
                competences: [competence_id],
                action: this.study_unit.action,
                content: this.study_unit.content,
                condition: this.study_unit.condition,
                full_sentence: this.study_unit.full_sentence,
                order:
                  this.ra_bases.filter((x) =>
                    x.competences.includes(competence_id)
                  ).length > 0
                    ? this.ra_bases
                        .filter((x) => x.competences.includes(competence_id))
                        .sort((a, b) => b.order - a.order)[0].order + 1
                    : 1,
              })
              .then((ra_base) => {
                const payload = {
                  egress_profile: this.profileMatter.egress_profile,
                  base_ra: ra_base.id,
                  study_unit: response.id,
                };
                this.$store.dispatch(names.POST_EGR_PROFILE_RA_BASE, payload);
              });
            if (
              this.institution &&
              this.institution.internal_use_id != "ciisa_uss"
            )
              this.$restful
                .Get(
                  `teaching/toggle_matter_automatic_achievement_weighing/?matter=${this.profileMatter.matter}`
                )
                .then(() => {
                  this.$store.dispatch(
                    names.FETCH_EVALUATION_CRITERIAS_MACROS,
                    this.profileMatter.matter
                  );
                  this.$store.dispatch(
                    names.FETCH_STUDY_UNITS,
                    this.profileMatter.matter
                  );
                  this.$store.dispatch(names.FETCH_COMPETENCE_UNITS, {
                    matter__id: this.profileMatter.matter,
                    force: true,
                  });
                });
            if (
              this.institution &&
              this.institution.internal_use_id == "ciisa_uss" &&
              this.competenceUnit
            )
              this.$restful
                .Get(
                  `teaching/toggle_temp_competence_unit_automatic_achievement_weighing/?temp_competence_unit=${this.competenceUnit.id}`
                )
                .then(() => {
                  this.$store.dispatch(
                    names.FETCH_STUDY_UNITS,
                    this.profileMatter.matter
                  );
                });
          }
          this.saveEvaluationCriterias();
          if (
            (this.institution &&
              this.institution.internal_use_id == "duoc_uc") ||
            this.$debug_change_duoc
          )
            this.$nextTick(() => {
              this.tab_index = 2;
            });
          else this.$emit("created", response);
          toast(
            this.$getVisibleNames("teaching.ramicro", false, "RA Micro") +
              " creado."
          );
        });
      this.$bvModal.hide("modal-selected-competence");
    },
    updateStudyUnit() {
      this.$store
        .dispatch(names.UPDATE_STUDY_UNIT, this.study_unit)
        .then((response) => {
          if (
            (this.selected_ra_base != null || !isNaN(this.selected_ra_base)) &&
            this.apply_ra_base == true &&
            this.profileRaBase == null &&
            this.profileMatter
          ) {
            const payload = {
              egress_profile: this.profileMatter.egress_profile,
              base_ra: this.selected_ra_base,
              study_unit: response.id,
            };
            this.$store.dispatch(names.POST_EGR_PROFILE_RA_BASE, payload);
          }
          if (!isNaN(this.study_unit.id) && !this.StudyUnit) {
            this.$emit("close_modal");
          }
          this.$emit("updated", response);
          this.saveEvaluationCriterias();
          toast(
            this.$getVisibleNames("teaching.ramicro", false, "RA Micro") +
              " actualizado."
          );
          if (
            (this.selected_ra_base != null || !isNaN(this.selected_ra_base)) &&
            this.profileMatter &&
            this.profile_ra_bases.filter(
              (x) =>
                x.base_ra == this.selected_ra_base &&
                x.egress_profile == this.profileMatter.egress_profile
            ).length > 1
          )
            this.$store.dispatch(names.FETCH_STUDY_UNITS_BY_CICLE_AND_PROFILE, {
              egress_profile_id: this.profileMatter.egress_profile,
            });
        });
    },
    saveEvaluationCriterias() {
      this.evaluation_criteria_list.forEach((element) => {
        if (isNaN(this.study_unit.id)) {
          this.$swal({
            title: `"No se pueden guardar los ${this.$getVisibleNames(
              "manual.indicador_de_logro",
              true,
              "Indicadores de Logro"
            )}"`,
            text: `"Los ${this.$getVisibleNames(
              "teaching.ramicro",
              false,
              "RA Micro"
            )} todavía no ha sido guardada."`,
            type: "info",
          });
          return;
        }
        element.study_unit = this.study_unit.id;
        if (isNaN(element.id))
          this.$store.dispatch(names.POST_EVALUATION_CRITERIA, element);
        else this.$store.dispatch(names.UPDATE_EVALUATION_CRITERIA, element);
      });
    },
    slotCreateEvaluationCriteria(evaluation_criteria) {
      this.evaluation_criteria_list.push(evaluation_criteria);
      this.tableItems();
      this.tab_index = 2;
      this.current_evaluation_criteria_id = -1;
    },
    slotUpdateEvaluationCriteria(evaluation_criteria) {
      const index = this.evaluation_criteria_list.findIndex(
        (x) => x.id == evaluation_criteria.id
      );
      if (index != -1)
        this.evaluation_criteria_list.splice(index, 1, evaluation_criteria);
      this.tab_index = 2;
      this.current_evaluation_criteria_id = -1;
    },
    slotCreatedEvaluationCriteria(/*evaluation_criteria*/) {
      this.tab_index = 2;
      this.current_evaluation_criteria_id = -1;
    },
    slotUpdatedEvaluationCriteria(evaluation_criteria) {
      let tmp = this.evaluationCriteriaList.find(
        (x) => x.id == evaluation_criteria.id
      );
      if (tmp && !areEqualsObjects(tmp, evaluation_criteria)) {
        this.$forceUpdate();
        this.tableItems();
        let index = this.evaluation_criteria_items.findIndex(
          (x) => x.id == evaluation_criteria.id
        );
        if (index != -1) {
          this.evaluation_criteria_items[index].action =
            evaluation_criteria.action;
          this.evaluation_criteria_items[index].content =
            evaluation_criteria.content;
          this.evaluation_criteria_items[index].condition =
            evaluation_criteria.condition;
        }
      }
      this.tab_index = 2;
      this.current_evaluation_criteria_id = -1;
    },
    editEvaluationCriteria(evaluation_criteria_id) {
      this.current_evaluation_criteria_id = evaluation_criteria_id;
      this.tab_index = 3;
    },
    askForDeleteEvaluationCriteria(evaluation_criteria_id) {
      const index = this.evaluation_criteria_list.findIndex(
        (x) => x.id == evaluation_criteria_id
      );
      if (index != -1) {
        this.evaluation_criteria_list.splice(index, 1);
        this.tableItems();
        return;
      }
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "manual.indicador_de_logro",
          false,
          "Indicador de Logro"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value)
          this.$store
            .dispatch(names.DELETE_EVALUATION_CRITERIA, evaluation_criteria_id)
            .then(() => {
              toast(
                String(
                  this.$getVisibleNames(
                    "manual.indicador_de_logro",
                    false,
                    "Indicador de Logro"
                  )
                ) + " eliminado."
              );
            });
      });
    },
    askForDeletePerformanceIndicator(performance_indicator_id) {
      this.$swal({
        title: `¿Está seguro de que desea eliminar esta instancia de "${this.$getVisibleNames(
          "manual.indicador_de_logro",
          false,
          "Indicador de Logro"
        )}"?`,
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          let index = this.study_unit.performance_indicators.findIndex(
            (x) => x == performance_indicator_id
          );
          if (index != -1) {
            this.study_unit.performance_indicators.splice(index, 1);
            const payload = {
              study_unit_id: this.study_unit.id,
              item: {
                performance_indicators: this.study_unit.performance_indicators,
              },
            };
            this.$store.dispatch(names.PATCH_STUDY_UNIT, payload);
          }
        }
      });
    },
    tableItems() {
      this.evaluation_criteria_items = [];
      this.evaluationCriteriaList.forEach((element) => {
        this.evaluation_criteria_items.push({
          id: element.id,
          action: element.action,
          content: element.content,
          condition: element.condition,
          sentence:
            element.action + " " + element.content + " " + element.condition,
          weighing: element.weighing,
          is_evaluation_criteria: true,
          allows_edit: true,
        });
      });
      this.evaluation_criteria_list.forEach((element) => {
        this.evaluation_criteria_items.push({
          id: element.id,
          action: element.action,
          content: element.content,
          condition: element.condition,
          sentence:
            element.action + " " + element.content + " " + element.condition,
          weighing: element.weighing,
          is_evaluation_criteria: true,
          allows_edit: false,
        });
      });
      // this.performanceIndicatorList.forEach((element) => {
      //   this.evaluation_criteria_items.push({
      //     id: element.id,
      //     action: element.action,
      //     content: element.content,
      //     condition: element.condition,
      //     sentence:
      //       element.action + " " + element.content + " " + element.condition,
      //     is_evaluation_criteria: false,
      //     allows_edit: false,
      //   });
      // });
    },
    changeProfileRaBase() {
      if (this.profileRaBase && this.selected_ra_base && this.apply_ra_base) {
        this.$swal({
          title: `¿Está seguro de quitar el vínculo con el ${this.$getVisibleNames(
            "teaching.basera",
            false,
            "RA Base"
          )} de la ${this.$getVisibleNames(
            "mesh.competence",
            false,
            "Competencia"
          )}?`,
          text: `Al aplicar esto, el  ${this.$getVisibleNames(
            "teaching.ramicro",
            false,
            "RA Micro"
          )} 
          pasa a ser independiente de la ${this.$getVisibleNames(
            "mesh.competence",
            false,
            "Competencia"
          )}. En caso que se modifique este último, no se vería reflejado el cambio en el  ${this.$getVisibleNames(
            "teaching.ramicro",
            false,
            "RA Micro"
          )}`,
          type: "warning",
          showCancelButton: true,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch(names.DELETE_EGR_PROFILE_RA_BASE, this.profileRaBase.id)
              .then(() => {
                this.selected_ra_base = null;
                this.apply_ra_base = false;
                // this.$bvModal.show(`modal-base-ra`);
                // }
              });
          }
        });
      } else if (this.selected_ra_base != null && this.apply_ra_base) {
        this.selected_ra_base = null;
        this.apply_ra_base = false;
        // this.$bvModal.show(`modal-base-ra`);
      }
    },
    fetchInitEvaluations() {
      if (this.matter_id != null) {
        // scoped_id:3 para base_matter
        this.$store
          .dispatch(names.FETCH_NEW_EVALUATIONS, {
            scoped_id: 3,
            scoped_object_id: this.matter_id,
          })
          .then((evaluations) => {
            this.$store.dispatch(names.FETCH_NEW_TESTS, {
              evaluations_ids: evaluations.map((x) => x.id),
            });
          });
      }
    },
  },
  created() {
    if (!isNaN(this.study_unit.id)) {
      this.$store.dispatch(names.FETCH_CONTENTS, this.study_unit.id);
    }
  },
  mounted() {
    this.fetchInitEvaluations();
    if (this.study_unit.hours == null || this.study_unit.hours == "")
      this.study_unit.hours = 0;
    if (!this.StudyUnit) {
      let order = 0;
      this.studyUnits
        .filter(
          (x) =>
            x.matter == this.matter_id &&
            x.temp_competence_unit == this.study_unit.temp_competence_unit
        )
        .forEach((x) => {
          if (order < x.order) order = x.order;
        });
      this.study_unit.order = order + 1;
    }
    if (this.profileMatter) {
      // let arr = [];
      // arr.push("egress_profile=" + this.profileMatter.egress_profile);
      // if (!isNaN(this.study_unit)) arr.push("study_unit=" + this.study_unit.id);
      // let partial_url = "";
      // if (arr.length > 0) partial_url = "?" + arr.join("&&");
      this.$store.dispatch(names.FETCH_RA_BASES, {
        egress_profile_id: this.profileMatter.egress_profile,
      });
      if (!isNaN(this.study_unit.id)) {
        this.$store
          .dispatch(names.FETCH_EGR_PROFILE_RA_BASES, {
            egress_profile_id: this.profileMatter.egress_profile,
            study_unit_id: this.study_unit.id,
          })
          .then((response) => {
            response.forEach((x) => {
              if (
                x.study_unit == this.study_unit.id &&
                x.egress_profile == this.profileMatter.egress_profile
              ) {
                this.selected_ra_base = x.base_ra;
                this.apply_ra_base = true;
                this.$store.dispatch(names.FETCH_RA_BASE, x.base_ra);
              }
            });
          });
      }
    }
    this.tableItems();
  },
  watch: {
    evaluationCriteriaList: function () {
      this.tableItems();
    },
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>
<style scoped>
.btn-disabled {
  background-color: var(--secondary-color);
  opacity: 0.7;
  border: 1px solid var(--secondary-color);
}
.grabbable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.grabbable:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
.alert-student-weighing {
  font-size: 10pt;
}
.select-form {
  display: block !important;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.select-option {
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 0 0.25em;
  margin-left: 10px;
  text-indent: -16px;
}
.disabled-select {
  background-color: #e2e2e2;
}
.link-modal-evaluations {
  text-decoration: underline; /* Aplica subrayado */
  cursor: pointer; /* Cambia el puntero a una mano */
  color: #0000ee; /* Color azul típico de los enlaces */
}
.link-modal-evaluations:hover {
  font-weight: bold; /* Aplica negrita al hacer hover */
  color: #551a8b; /* Cambia el color al hacer hover, a un morado oscuro similar al de los enlaces visitados */
}
</style>