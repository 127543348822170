<template>
  <div>
    <template
      v-if="
        filterTestsByMatter.filter(
          (x) =>
            (x.is_valid == true &&
              is_valid === false &&
              x.manual_is_valid === false) ||
            (x.is_valid[0] == is_valid && is_valid === false) ||
            x.evaluative_agent == null ||
            x.evaluation_situation == null
        ).length == 0 &&
        filterTestsByMatter.length > 0 &&
        excludeECMicro.length == 0 &&
        !is_valid
      "
    >
      <div>
        <span>
          Todas las
          {{
            $getVisibleNames("evaluations2.evaluation", true, "Evaluaciones")
          }}
          están completas.
        </span>
      </div>
      <div class="text-right">
        <b-button variant="primary" size="sm" @click="closeModal">
          Aceptar
        </b-button>
      </div>
    </template>
    <b-tabs
      v-if="
        (filterTestsByMatter.some(
          (x) =>
            (x.is_valid[0] == is_valid && is_valid === false) ||
            (x.is_valid == true &&
              is_valid === false &&
              x.manual_is_valid === false) ||
            x.evaluative_agent == false ||
            x.evaluation_situation == false
        ) ||
          excludeECMicro.length > 0) &&
        !is_valid
      "
      content-class="mt-3"
    >
      <template #tabs-start>
        <b-button
          v-if="!is_valid"
          variant="primary"
          size="sm"
          class="ml-2 btn-update-data"
          @click="fetchInitEvaluations"
          v-b-tooltip.v-secondary.top.noninteractive.hover="
            'Haz clic para actualizar los datos.'
          "
        >
          <b-icon icon="arrow-clockwise"></b-icon>
        </b-button>
      </template>
      <!-- Rojos -->
      <b-tab
        active
        :disabled="
          filterTestsByMatter.filter(
            (x) =>
              (x.is_valid[0] == is_valid &&
                is_valid === false &&
                x.manual_is_valid === false) ||
              x.evaluative_agent == null ||
              x.evaluation_situation == null
          ).length == 0
        "
      >
        <template v-slot:title>
          {{
            $getVisibleNames("evaluations2.evaluation", true, "Evaluaciones")
          }}
          Incompletas
          <b-icon
            v-if="
              filterTestsByMatter.filter(
                (x) =>
                  (x.is_valid[0] == is_valid &&
                    is_valid === false &&
                    x.manual_is_valid === false) ||
                  x.evaluative_agent == null ||
                  x.evaluation_situation == null
              ).length > 0
            "
            icon="exclamation-lg"
            scale="0.7"
            style="color: red"
            v-b-tooltip.v-secondary.top.noninteractive.hover="
              `Requiere completar estas ${$getVisibleNames(
                'evaluations2.evaluation',
                true,
                'Evaluaciones'
              )}.`
            "
          ></b-icon>
          <b-icon
            v-if="
              filterTestsByMatter.filter(
                (x) =>
                  (x.is_valid[0] == is_valid &&
                    is_valid === false &&
                    x.manual_is_valid === false) ||
                  x.evaluative_agent == null ||
                  x.evaluation_situation == null
              ).length == 0
            "
            scale="0.7"
            icon="check-lg"
            style="color: green"
          ></b-icon>
        </template>
        <div v-if="is_loading">
          <b-card>
            <b-skeleton animation width="85%"></b-skeleton>
            <b-skeleton animation width="55%"></b-skeleton>
            <b-skeleton animation width="70%"></b-skeleton>
          </b-card>
        </div>
        <div
          v-else-if="
            filterTestsByMatter.filter(
              (x) =>
                (x.is_valid[0] == is_valid &&
                  is_valid === false &&
                  x.manual_is_valid === false) ||
                x.evaluative_agent == null ||
                x.evaluation_situation == null
            ).length > 0
          "
        >
          <div
            v-for="evaluation in filterEvaluationsByMatter"
            :key="evaluation.id"
          >
            <template
              v-if="
                filterTestsByMatter.some(
                  (x) =>
                    x.evaluation == evaluation.id &&
                    ((x.is_valid[0] == is_valid && is_valid === false) ||
                      x.evaluative_agent == null ||
                      x.evaluation_situation == null)
                )
              "
            >
              <div class="d-flex mb-2 mt-1">
                <h5 class="mt-auto mb-auto">
                  {{ evaluation.title }}
                </h5>
              </div>
              <template>
                <b-card
                  class="mb-1"
                  v-for="test in filterTestsByMatter.filter(
                    (x) =>
                      x.evaluation == evaluation.id &&
                      ((x.is_valid[0] == is_valid && is_valid === false) ||
                        x.is_valid == is_valid ||
                        x.evaluative_agent == null ||
                        x.evaluation_situation == null)
                  )"
                  :key="evaluation.id + '-' + test.id"
                  no-body
                >
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="d-flex">
                      <template
                        v-if="
                          (!is_valid && test.is_valid[0] == false) ||
                          test.evaluative_agent == null ||
                          test.evaluation_situation == null
                        "
                      >
                        <b-button
                          v-b-toggle="
                            `${evaluation.id}-accordion-test-${test.id}`
                          "
                          block
                          variant="danger"
                        >
                          {{ test.title }}
                        </b-button>
                      </template>
                      <b-button
                        class="ml-1 p-1"
                        variant="none"
                        v-b-tooltip.top.noninteractive.v-secondary="
                          `Ir directamente a ${test.title}`
                        "
                        @click="redirectToEvaluation(test.evaluation, test.id)"
                      >
                        <b-icon
                          variant="info"
                          icon="box-arrow-up-right"
                        ></b-icon>
                      </b-button>
                    </div>
                  </b-card-header>
                  <b-collapse
                    :id="`${evaluation.id}-accordion-test-${test.id}`"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <InfoTestTable :test="test"></InfoTestTable>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </template>
            </template>
          </div>
        </div>
      </b-tab>
      <!-- Azules -->
      <b-tab
        :disabled="
          filterTestsByMatter.filter(
            (x) =>
              x.is_valid == true &&
              is_valid === false &&
              x.manual_is_valid === false &&
              x.evaluative_agent != null &&
              x.evaluation_situation != null
          ).length == 0
        "
      >
        <template v-slot:title>
          {{ $getVisibleNames("evaluations2.test", true, "Instrumentos") }} por
          Cerrar
          <b-icon
            v-if="
              filterTestsByMatter.filter(
                (x) =>
                  x.is_valid == true &&
                  is_valid === false &&
                  x.manual_is_valid === false &&
                  x.evaluative_agent != null &&
                  x.evaluation_situation != null
              ).length > 0
            "
            icon="exclamation-lg"
            scale="0.7"
            style="color: red"
            v-b-tooltip.v-secondary.top.noninteractive.hover="
              `Debe cerrar estas ${$getVisibleNames(
                'evaluations2.test',
                true,
                'Instrumentos'
              )}.`
            "
          ></b-icon>
          <b-icon
            v-if="
              filterTestsByMatter.filter(
                (x) =>
                  x.is_valid == true &&
                  is_valid === false &&
                  x.manual_is_valid === false &&
                  x.evaluative_agent != null &&
                  x.evaluation_situation != null
              ).length == 0
            "
            scale="0.7"
            icon="check-lg"
            style="color: green"
          ></b-icon>
        </template>
        <div v-if="is_loading">
          <b-card>
            <b-skeleton animation width="85%"></b-skeleton>
            <b-skeleton animation width="55%"></b-skeleton>
            <b-skeleton animation width="70%"></b-skeleton>
          </b-card>
        </div>
        <div
          v-else-if="
            filterTestsByMatter.filter(
              (x) =>
                x.is_valid == true &&
                is_valid === false &&
                x.manual_is_valid === false &&
                x.evaluative_agent != null &&
                x.evaluation_situation != null
            ).length > 0
          "
        >
          <b-button
            v-if="!is_valid"
            size="sm"
            class="mb-2"
            @click="applyManualIsValidInTest()"
            :disabled="disabled_button"
            >Cerrar
            {{ $getVisibleNames("evaluations2.test", true, "Instrumentos") }}
          </b-button>
          <div
            v-for="evaluation in filterEvaluationsByMatter"
            :key="evaluation.id"
          >
            <template
              v-if="
                filterTestsByMatter.some(
                  (x) =>
                    (x.is_valid[0] == !is_valid ||
                      (x.is_valid == true && x.manual_is_valid === false)) &&
                    x.evaluation == evaluation.id &&
                    x.evaluative_agent != null &&
                    x.evaluation_situation != null
                )
              "
            >
              <div class="d-flex mb-2 mt-1">
                <h5 class="mt-auto mb-auto">
                  {{ evaluation.title }}
                </h5>
              </div>
              <template>
                <b-card
                  class="mb-1"
                  v-for="test in filterTestsByMatter.filter(
                    (x) =>
                      x.evaluation == evaluation.id &&
                      (((x.is_valid[0] == !is_valid ||
                        (x.is_valid == true && x.manual_is_valid === false)) &&
                        is_valid === false) ||
                        (x.is_valid == is_valid &&
                          is_valid === true &&
                          x.manual_is_valid === true)) &&
                      x.evaluative_agent != null &&
                      x.evaluation_situation != null
                  )"
                  :key="evaluation.id + '-' + test.id"
                  no-body
                >
                  <b-card-header header-tag="header" class="p-1" role="tab">
                    <div class="d-flex">
                      <template v-if="!is_valid && test.is_valid == true">
                        <b-button
                          v-b-toggle="
                            `${evaluation.id}-accordion-test-${test.id}`
                          "
                          block
                          variant="info"
                          disabled
                        >
                          {{ test.title }}
                        </b-button>
                      </template>
                      <b-button
                        class="ml-1 p-1"
                        variant="none"
                        v-b-tooltip.top.noninteractive.v-secondary="
                          `Ir directamente a ${test.title}`
                        "
                        @click="redirectToEvaluation(test.evaluation, test.id)"
                      >
                        <b-icon
                          variant="info"
                          icon="box-arrow-up-right"
                        ></b-icon>
                      </b-button>
                    </div>
                  </b-card-header>
                  <b-collapse
                    :id="`${evaluation.id}-accordion-test-${test.id}`"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <InfoTestTable :test="test"></InfoTestTable>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </template>
            </template>
          </div>
        </div>
      </b-tab>
      <!-- Inconsistencias -->
      <b-tab :disabled="excludeECMicro.length == 0">
        <template v-slot:title>
          IL no mencionados
          <b-icon
            v-if="excludeECMicro.length > 0"
            icon="exclamation-lg"
            scale="0.7"
            style="color: red"
            v-b-tooltip.v-secondary.top.noninteractive.hover="
              `Debe incluir todos los ${$getVisibleNames(
                'teaching.evaluationcriteriamicro',
                true,
                'Criterios de Evaluación Micro'
              )} en las Actividades/${$getVisibleNames(
                'evaluations2.evaluation',
                true,
                'Evaluaciones'
              )}.`
            "
          ></b-icon>
          <b-icon
            v-if="excludeECMicro.length == 0"
            scale="0.7"
            icon="check-lg"
            style="color: green"
          ></b-icon>
        </template>
        <div v-if="is_loading">
          <b-card>
            <b-skeleton animation width="85%"></b-skeleton>
            <b-skeleton animation width="55%"></b-skeleton>
            <b-skeleton animation width="70%"></b-skeleton>
          </b-card>
        </div>
        <div v-else-if="excludeECMicro.length > 0 && !is_valid">
          <div
            v-if="filterEvaluationsByMatter.length == 0"
            class="text-danger d-flex mb-2"
          >
            <span class="mt-auto mb-auto">
              La
              {{
                $getVisibleNames(
                  "mesh.egressprofilematter",
                  false,
                  "Asignatura"
                )
              }}
              no cuenta con ninguna evaluación creada.
            </span>
            <b-button
              v-if="egress_profile_matter_id != null"
              class="ml-1 p-1"
              variant="none"
              v-b-tooltip.top.noninteractive.v-secondary="
                `Ir directamente al ${$getVisibleNames(
                  'manual.matter_program',
                  false,
                  'Programa de la Asignatura'
                )}`
              "
              @click="redirectToMatter()"
            >
              <b-icon variant="info" icon="box-arrow-up-right"></b-icon>
            </b-button>
          </div>
          <ECMInfoTable
            :key="key_ecm_info_table"
            :matter_id="matter_id"
            :matter_program_view="true"
            :ecm_no_mention="true"
            :MattersEvaluations="matters_evaluations"
            :matter_from_form="true"
          ></ECMInfoTable>
        </div>
      </b-tab>
    </b-tabs>
    <!-- Verdes -->
    <div v-if="is_valid">
      <b-button
        size="sm"
        class="mb-2"
        @click="removeManualIsValidInTest()"
        :disabled="disabled_button"
        >Abrir {{ $getVisibleNames("evaluations2.test", true, "Instrumentos") }}
      </b-button>
    </div>
    <div v-if="is_loading">
      <b-card>
        <b-skeleton animation width="85%"></b-skeleton>
        <b-skeleton animation width="55%"></b-skeleton>
        <b-skeleton animation width="70%"></b-skeleton>
      </b-card>
    </div>
    <div v-else>
      <template
        v-if="
          filterTestsByMatter.some(
            (x) =>
              (x.is_valid[0] == is_valid && is_valid === false) ||
              (x.is_valid == true &&
                is_valid === false &&
                x.manual_is_valid === false)
          ) === false
        "
      >
        <b-button
          v-if="
            filterTestsByMatter.filter(
              (x) =>
                (x.is_valid == false &&
                  is_valid === false &&
                  x.manual_is_valid === false) ||
                (x.is_valid[0] == is_valid && is_valid === false)
            ).length == 0 &&
            filterTestsByMatter.length == 0 &&
            excludeECMicro.length == 0
          "
          variant="primary"
          size="sm"
          @click="fetchInitEvaluations"
          v-b-tooltip.v-secondary.top.noninteractive.hover="
            'Haz clic para actualizar los datos.'
          "
        >
          <b-icon icon="arrow-clockwise"></b-icon>
          Actualizar Datos
        </b-button>
      </template>
      <div v-for="evaluation in filterEvaluationsByMatter" :key="evaluation.id">
        <template
          v-if="
            (filterTestsByMatter.filter((x) => x.evaluation == evaluation.id)
              .length === 0 &&
              !is_valid) ||
            filterTestsByMatter.some(
              (x) =>
                x.is_valid == is_valid &&
                is_valid === true &&
                x.manual_is_valid === true &&
                x.evaluation == evaluation.id
            )
          "
        >
          <div class="d-flex mb-2 mt-1">
            <h5 class="mt-auto mb-auto">
              {{ evaluation.title }}
            </h5>
          </div>
          <template>
            <b-card
              class="mb-1"
              v-for="test in filterTestsByMatter.filter(
                (x) =>
                  x.evaluation == evaluation.id &&
                  (((x.is_valid[0] == is_valid ||
                    (x.is_valid == true && x.manual_is_valid === false)) &&
                    is_valid === false) ||
                    (x.is_valid == is_valid &&
                      is_valid === true &&
                      x.manual_is_valid === true))
              )"
              :key="evaluation.id + '-' + test.id"
              no-body
            >
              <b-card-header header-tag="header" class="p-1" role="tab">
                <div class="d-flex">
                  <template v-if="is_valid && test.is_valid == true">
                    <b-button block variant="success" disabled>
                      {{ test.title }}
                    </b-button>
                  </template>
                  <b-button
                    class="ml-1 p-1"
                    variant="none"
                    v-b-tooltip.top.noninteractive.v-secondary="
                      `Ir directamente a ${test.title}`
                    "
                    @click="redirectToEvaluation(test.evaluation, test.id)"
                  >
                    <b-icon variant="info" icon="box-arrow-up-right"></b-icon>
                  </b-button>
                </div>
              </b-card-header>
              <b-collapse
                :id="`${evaluation.id}-accordion-test-${test.id}`"
                role="tabpanel"
              >
                <b-card-body>
                  <InfoTestTable :test="test"></InfoTestTable>
                </b-card-body>
              </b-collapse>
            </b-card>
          </template>
          <div
            v-if="
              filterTestsByMatter.filter((x) => x.evaluation == evaluation.id)
                .length == 0
            "
            class="text-danger d-flex"
          >
            <span class="mt-auto mb-auto">
              La evaluación no cuenta con
              {{
                $getVisibleNames(
                  "evaluations2.tefilterTestsByMatterst",
                  false,
                  "Instrumento"
                )
              }}.
            </span>
            <b-button
              class="ml-1 p-1"
              variant="none"
              v-b-tooltip.top.noninteractive.v-secondary="
                `Ir directamente a ${evaluation.title}`
              "
              @click="redirectToEvaluation(evaluation.id)"
            >
              <b-icon variant="info" icon="box-arrow-up-right"></b-icon>
            </b-button>
          </div>
        </template>
      </div>
      <div
        v-if="
          filterEvaluationsByMatter.length == 0 &&
          excludeECMicro.length == 0 &&
          !is_valid
        "
        class="text-danger d-flex"
      >
        <span class="mt-auto mb-auto">
          La
          {{
            $getVisibleNames("mesh.egressprofilematter", false, "Asignatura")
          }}
          no cuenta con ninguna evaluación creada.
        </span>
        <b-button
          v-if="egress_profile_matter_id != null"
          class="ml-1 p-1"
          variant="none"
          v-b-tooltip.top.noninteractive.v-secondary="
            `Ir directamente al ${$getVisibleNames(
              'manual.matter_program',
              false,
              'Programa de la Asignatura'
            )}`
          "
          @click="redirectToMatter()"
        >
          <b-icon variant="info" icon="box-arrow-up-right"></b-icon>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "InfoEvaluations",
  components: {
    InfoTestTable: () =>
      import("@/components/new-evaluations/Evaluation/InfoTestTable"),
    ECMInfoTable: () =>
      import("@/components/teaching/MatterProgram/ECMInfoTable"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    egress_profile_matter_id: {
      type: [Number, null],
    },
    is_valid: {
      type: Boolean,
      default: false,
    },
    MattersEvaluations: {
      type: Array,
      default: function () {
        return [];
      },
      required: false,
    },
    matter_from_form: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      disabled_button: false,
      key_ecm_info_table: 0,
      is_loading: false,
      matters_evaluations: [
        ...this.MattersEvaluations.sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        }),
      ],
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      evaluations: names.NEW_EVALUATIONS,
      tests: names.NEW_TESTS,
      studyUnits: names.STUDY_UNITS,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
    }),
    filterEvaluationsByMatter() {
      // scope==3 para base_matter
      if (this.matter_id != null)
        return this.evaluations
          .filter((x) => x.scope == 3 && x.scope_id == this.matter_id)
          .sort((a, b) => a.title.localeCompare(b.title));
      else return [];
    },
    filterTestsByMatter() {
      const evaluations_ids = this.filterEvaluationsByMatter.map((x) => x.id);
      return this.tests.filter((x) => evaluations_ids.includes(x.evaluation));
    },
    studyUnitsFilterByMatter() {
      return this.studyUnits
        .filter((x) => x.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    ECMicroFilterByStudyUnit() {
      const study_unit_ids = this.studyUnitsFilterByMatter.map((x) => x.id);
      return this.evaluationCriterias
        .filter((x) => study_unit_ids.includes(x.study_unit))
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    excludeECMicro() {
      const evaluation_criterio_micro_ids = [
        ...new Set(
          this.matters_evaluations
            .map((x) => x.evaluation_criterias_micro)
            .flat()
        ),
      ];
      return this.ECMicroFilterByStudyUnit.filter(
        (x) => !evaluation_criterio_micro_ids.includes(x.id)
      );
    },
    studyUnitsFilter() {
      if (!this.ecm_no_mention) return this.studyUnitsFilterByMatter;
      else {
        const study_units_ids = [
          ...new Set(this.excludeECMicro.map((x) => x.study_unit)),
        ];
        return this.studyUnitsFilterByMatter.filter((x) =>
          study_units_ids.includes(x.id)
        );
      }
    },
    ECMicroFilter() {
      if (!this.ecm_no_mention) return this.ECMicroFilterByStudyUnit;
      else return this.excludeECMicro;
    },
    // theTestsHaveSomeErrors() {
    //   if (
    //     this.institution &&
    //     this.institution.internal_use_id == "duoc_uc" &&
    //     this.institution.kl_assessment
    //   ) {
    //     if (
    //       this.filterTestsByMatter.some(
    //         (x) => x.is_valid[0] == this.is_valid && this.is_valid === false
    //       ) ||
    //       (this.filterTestsByMatter.length == 0 && !this.is_valid)
    //     )
    //       return true;
    //   }
    //   return false;
    // },
    // theTestsHaveSomeCorrect() {
    //   if (
    //     this.institution &&
    //     this.institution.internal_use_id == "duoc_uc" &&
    //     this.institution.kl_assessment
    //   ) {
    //     if (
    //       this.filterTestsByMatter.some(
    //         (x) =>
    //           x.is_valid == this.is_valid &&
    //           this.is_valid === true &&
    //           x.manual_is_valid === true
    //       )
    //     )
    //       return true;
    //   }
    //   return false;
    // },
  },
  methods: {
    async removeManualIsValidInTest() {
      this.disabled_button = true;
      let test_ids = [];
      await this.$store
        .dispatch(names.FETCH_NEW_EVALUATIONS, {
          scoped_id: 3,
          scoped_object_id: this.matter_id,
        })
        .then((evaluations) => {
          this.$store
            .dispatch(names.FETCH_NEW_TESTS, {
              evaluations_ids: evaluations.map((x) => x.id),
            })
            .then((test) => {
              test_ids = test.map((x) => x.id);
              for (let test of this.filterTestsByMatter.filter(
                (x) =>
                  test_ids.includes(x.id) &&
                  x.is_valid == this.is_valid &&
                  this.is_valid === true &&
                  x.manual_is_valid === true &&
                  x.evaluative_agent != null &&
                  x.evaluation_situation != null
              )) {
                this.$store.dispatch(names.PATCH_NEW_TEST, {
                  new_test_id: test.id,
                  item: { manual_is_valid: false },
                });
              }
              this.disabled_button = false;
              this.$emit("close_modal");
            });
        });
    },
    async applyManualIsValidInTest() {
      this.disabled_button = true;
      let test_ids = [];
      await this.$store
        .dispatch(names.FETCH_NEW_EVALUATIONS, {
          scoped_id: 3,
          scoped_object_id: this.matter_id,
        })
        .then((evaluations) => {
          this.$store
            .dispatch(names.FETCH_NEW_TESTS, {
              evaluations_ids: evaluations.map((x) => x.id),
            })
            .then((test) => {
              test_ids = test.map((x) => x.id);
              for (let test of this.filterTestsByMatter.filter(
                (x) =>
                  test_ids.includes(x.id) &&
                  x.is_valid == true &&
                  this.is_valid === false &&
                  x.manual_is_valid === false &&
                  x.evaluative_agent != null &&
                  x.evaluation_situation != null
              )) {
                this.$store.dispatch(names.PATCH_NEW_TEST, {
                  new_test_id: test.id,
                  item: { manual_is_valid: true },
                });
              }
              this.disabled_button = false;
            });
        });
    },
    fetchMattersEvaluation(force_fetch = false) {
      if (this.matter_id && (this.matter_from_form == false || force_fetch))
        this.$restful
          .Get(`/teaching/matter-evaluation/?matter=${this.matter_id}`)
          .then((response) => {
            this.matters_evaluations = response.sort(function (a, b) {
              if (a.order > b.order) return 1;
              if (a.order < b.order) return -1;
              return 0;
            });
            this.$emit("fetch_matters_evaluations", this.matters_evaluations);
            this.key_ecm_info_table += 1;
          });
    },
    fetchECM(matter_id) {
      this.$store.dispatch(names.FETCH_STUDY_UNITS, matter_id);
      this.$store.dispatch(names.FETCH_EVALUATION_CRITERIAS, {
        matter_id: matter_id,
      });
    },
    redirectToEvaluation(evaluation_id, instrument_id = null) {
      const url = this.$router.resolve({
        name: "NewEvaluationView",
        params: {
          evaluation_id: evaluation_id,
          instrument_id: instrument_id,
        },
      }).href;
      window.open(url, "_blank");
    },
    closeModal() {
      this.$emit("close_modal");
    },
    redirectToMatter() {
      if (this.egress_profile_matter_id != null) {
        const url = this.$router.resolve({
          name: "MatterProgramView",
          params: {
            matter_id: this.egress_profile_matter_id,
          },
        }).href;
        window.open(url, "_blank");
      }
    },
    fetchInitEvaluations() {
      // scoped_id:3 para base_matter
      this.is_loading = true;
      this.$store.commit(names.CLEAR_EVALUATIONS_AND_TEST, {
        scoped_id: 3,
        scoped_object_id: this.matter_id,
      });
      this.$store
        .dispatch(names.FETCH_NEW_EVALUATIONS, {
          scoped_id: 3,
          scoped_object_id: this.matter_id,
        })
        .then((evaluations) => {
          this.$store
            .dispatch(names.FETCH_NEW_TESTS, {
              evaluations_ids: evaluations.map((x) => x.id),
            })
            .then(() => {
              this.fetchMattersEvaluation(true);
              this.fetchECM(this.matter_id);
            })
            .then(() => {
              this.is_loading = false;
            });
        });
    },
  },
  created() {
    if (this.matter_id) {
      this.fetchECM(this.matter_id);
      this.fetchMattersEvaluation();
    }
  },
};
</script>

<style scoped>
.btn-update-data {
  width: 30px;
  height: 30px;
  padding: 1px 1px 1px 1px;
  margin-top: 5px;
}
</style>

