var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(
      _vm.filterTestsByMatter.filter(
        (x) =>
          (x.is_valid == true &&
            _vm.is_valid === false &&
            x.manual_is_valid === false) ||
          (x.is_valid[0] == _vm.is_valid && _vm.is_valid === false) ||
          x.evaluative_agent == null ||
          x.evaluation_situation == null
      ).length == 0 &&
      _vm.filterTestsByMatter.length > 0 &&
      _vm.excludeECMicro.length == 0 &&
      !_vm.is_valid
    )?[_c('div',[_c('span',[_vm._v(" Todas las "+_vm._s(_vm.$getVisibleNames("evaluations2.evaluation", true, "Evaluaciones"))+" están completas. ")])]),_c('div',{staticClass:"text-right"},[_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.closeModal}},[_vm._v(" Aceptar ")])],1)]:_vm._e(),(
      (_vm.filterTestsByMatter.some(
        (x) =>
          (x.is_valid[0] == _vm.is_valid && _vm.is_valid === false) ||
          (x.is_valid == true &&
            _vm.is_valid === false &&
            x.manual_is_valid === false) ||
          x.evaluative_agent == false ||
          x.evaluation_situation == false
      ) ||
        _vm.excludeECMicro.length > 0) &&
      !_vm.is_valid
    )?_c('b-tabs',{attrs:{"content-class":"mt-3"},scopedSlots:_vm._u([{key:"tabs-start",fn:function(){return [(!_vm.is_valid)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive.hover",value:(
          'Haz clic para actualizar los datos.'
        ),expression:"\n          'Haz clic para actualizar los datos.'\n        ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true,"hover":true}}],staticClass:"ml-2 btn-update-data",attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.fetchInitEvaluations}},[_c('b-icon',{attrs:{"icon":"arrow-clockwise"}})],1):_vm._e()]},proxy:true}],null,false,2237019154)},[_c('b-tab',{attrs:{"active":"","disabled":_vm.filterTestsByMatter.filter(
          (x) =>
            (x.is_valid[0] == _vm.is_valid &&
              _vm.is_valid === false &&
              x.manual_is_valid === false) ||
            x.evaluative_agent == null ||
            x.evaluation_situation == null
        ).length == 0},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$getVisibleNames("evaluations2.evaluation", true, "Evaluaciones"))+" Incompletas "),(
            _vm.filterTestsByMatter.filter(
              (x) =>
                (x.is_valid[0] == _vm.is_valid &&
                  _vm.is_valid === false &&
                  x.manual_is_valid === false) ||
                x.evaluative_agent == null ||
                x.evaluation_situation == null
            ).length > 0
          )?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive.hover",value:(
            `Requiere completar estas ${_vm.$getVisibleNames(
              'evaluations2.evaluation',
              true,
              'Evaluaciones'
            )}.`
          ),expression:"\n            `Requiere completar estas ${$getVisibleNames(\n              'evaluations2.evaluation',\n              true,\n              'Evaluaciones'\n            )}.`\n          ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true,"hover":true}}],staticStyle:{"color":"red"},attrs:{"icon":"exclamation-lg","scale":"0.7"}}):_vm._e(),(
            _vm.filterTestsByMatter.filter(
              (x) =>
                (x.is_valid[0] == _vm.is_valid &&
                  _vm.is_valid === false &&
                  x.manual_is_valid === false) ||
                x.evaluative_agent == null ||
                x.evaluation_situation == null
            ).length == 0
          )?_c('b-icon',{staticStyle:{"color":"green"},attrs:{"scale":"0.7","icon":"check-lg"}}):_vm._e()]},proxy:true}],null,false,258854044)},[(_vm.is_loading)?_c('div',[_c('b-card',[_c('b-skeleton',{attrs:{"animation":"","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"","width":"70%"}})],1)],1):(
          _vm.filterTestsByMatter.filter(
            (x) =>
              (x.is_valid[0] == _vm.is_valid &&
                _vm.is_valid === false &&
                x.manual_is_valid === false) ||
              x.evaluative_agent == null ||
              x.evaluation_situation == null
          ).length > 0
        )?_c('div',_vm._l((_vm.filterEvaluationsByMatter),function(evaluation){return _c('div',{key:evaluation.id},[(
              _vm.filterTestsByMatter.some(
                (x) =>
                  x.evaluation == evaluation.id &&
                  ((x.is_valid[0] == _vm.is_valid && _vm.is_valid === false) ||
                    x.evaluative_agent == null ||
                    x.evaluation_situation == null)
              )
            )?[_c('div',{staticClass:"d-flex mb-2 mt-1"},[_c('h5',{staticClass:"mt-auto mb-auto"},[_vm._v(" "+_vm._s(evaluation.title)+" ")])]),_vm._l((_vm.filterTestsByMatter.filter(
                  (x) =>
                    x.evaluation == evaluation.id &&
                    ((x.is_valid[0] == _vm.is_valid && _vm.is_valid === false) ||
                      x.is_valid == _vm.is_valid ||
                      x.evaluative_agent == null ||
                      x.evaluation_situation == null)
                )),function(test){return _c('b-card',{key:evaluation.id + '-' + test.id,staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('div',{staticClass:"d-flex"},[(
                        (!_vm.is_valid && test.is_valid[0] == false) ||
                        test.evaluative_agent == null ||
                        test.evaluation_situation == null
                      )?[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                          `${evaluation.id}-accordion-test-${test.id}`
                        ),expression:"\n                          `${evaluation.id}-accordion-test-${test.id}`\n                        "}],attrs:{"block":"","variant":"danger"}},[_vm._v(" "+_vm._s(test.title)+" ")])]:_vm._e(),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                        `Ir directamente a ${test.title}`
                      ),expression:"\n                        `Ir directamente a ${test.title}`\n                      ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-1",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToEvaluation(test.evaluation, test.id)}}},[_c('b-icon',{attrs:{"variant":"info","icon":"box-arrow-up-right"}})],1)],2)]),_c('b-collapse',{attrs:{"id":`${evaluation.id}-accordion-test-${test.id}`,"role":"tabpanel"}},[_c('b-card-body',[_c('InfoTestTable',{attrs:{"test":test}})],1)],1)],1)})]:_vm._e()],2)}),0):_vm._e()]),_c('b-tab',{attrs:{"disabled":_vm.filterTestsByMatter.filter(
          (x) =>
            x.is_valid == true &&
            _vm.is_valid === false &&
            x.manual_is_valid === false &&
            x.evaluative_agent != null &&
            x.evaluation_situation != null
        ).length == 0},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$getVisibleNames("evaluations2.test", true, "Instrumentos"))+" por Cerrar "),(
            _vm.filterTestsByMatter.filter(
              (x) =>
                x.is_valid == true &&
                _vm.is_valid === false &&
                x.manual_is_valid === false &&
                x.evaluative_agent != null &&
                x.evaluation_situation != null
            ).length > 0
          )?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive.hover",value:(
            `Debe cerrar estas ${_vm.$getVisibleNames(
              'evaluations2.test',
              true,
              'Instrumentos'
            )}.`
          ),expression:"\n            `Debe cerrar estas ${$getVisibleNames(\n              'evaluations2.test',\n              true,\n              'Instrumentos'\n            )}.`\n          ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true,"hover":true}}],staticStyle:{"color":"red"},attrs:{"icon":"exclamation-lg","scale":"0.7"}}):_vm._e(),(
            _vm.filterTestsByMatter.filter(
              (x) =>
                x.is_valid == true &&
                _vm.is_valid === false &&
                x.manual_is_valid === false &&
                x.evaluative_agent != null &&
                x.evaluation_situation != null
            ).length == 0
          )?_c('b-icon',{staticStyle:{"color":"green"},attrs:{"scale":"0.7","icon":"check-lg"}}):_vm._e()]},proxy:true}],null,false,3259188579)},[(_vm.is_loading)?_c('div',[_c('b-card',[_c('b-skeleton',{attrs:{"animation":"","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"","width":"70%"}})],1)],1):(
          _vm.filterTestsByMatter.filter(
            (x) =>
              x.is_valid == true &&
              _vm.is_valid === false &&
              x.manual_is_valid === false &&
              x.evaluative_agent != null &&
              x.evaluation_situation != null
          ).length > 0
        )?_c('div',[(!_vm.is_valid)?_c('b-button',{staticClass:"mb-2",attrs:{"size":"sm","disabled":_vm.disabled_button},on:{"click":function($event){return _vm.applyManualIsValidInTest()}}},[_vm._v("Cerrar "+_vm._s(_vm.$getVisibleNames("evaluations2.test", true, "Instrumentos"))+" ")]):_vm._e(),_vm._l((_vm.filterEvaluationsByMatter),function(evaluation){return _c('div',{key:evaluation.id},[(
              _vm.filterTestsByMatter.some(
                (x) =>
                  (x.is_valid[0] == !_vm.is_valid ||
                    (x.is_valid == true && x.manual_is_valid === false)) &&
                  x.evaluation == evaluation.id &&
                  x.evaluative_agent != null &&
                  x.evaluation_situation != null
              )
            )?[_c('div',{staticClass:"d-flex mb-2 mt-1"},[_c('h5',{staticClass:"mt-auto mb-auto"},[_vm._v(" "+_vm._s(evaluation.title)+" ")])]),_vm._l((_vm.filterTestsByMatter.filter(
                  (x) =>
                    x.evaluation == evaluation.id &&
                    (((x.is_valid[0] == !_vm.is_valid ||
                      (x.is_valid == true && x.manual_is_valid === false)) &&
                      _vm.is_valid === false) ||
                      (x.is_valid == _vm.is_valid &&
                        _vm.is_valid === true &&
                        x.manual_is_valid === true)) &&
                    x.evaluative_agent != null &&
                    x.evaluation_situation != null
                )),function(test){return _c('b-card',{key:evaluation.id + '-' + test.id,staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('div',{staticClass:"d-flex"},[(!_vm.is_valid && test.is_valid == true)?[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(
                          `${evaluation.id}-accordion-test-${test.id}`
                        ),expression:"\n                          `${evaluation.id}-accordion-test-${test.id}`\n                        "}],attrs:{"block":"","variant":"info","disabled":""}},[_vm._v(" "+_vm._s(test.title)+" ")])]:_vm._e(),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                        `Ir directamente a ${test.title}`
                      ),expression:"\n                        `Ir directamente a ${test.title}`\n                      ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-1",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToEvaluation(test.evaluation, test.id)}}},[_c('b-icon',{attrs:{"variant":"info","icon":"box-arrow-up-right"}})],1)],2)]),_c('b-collapse',{attrs:{"id":`${evaluation.id}-accordion-test-${test.id}`,"role":"tabpanel"}},[_c('b-card-body',[_c('InfoTestTable',{attrs:{"test":test}})],1)],1)],1)})]:_vm._e()],2)})],2):_vm._e()]),_c('b-tab',{attrs:{"disabled":_vm.excludeECMicro.length == 0},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" IL no mencionados "),(_vm.excludeECMicro.length > 0)?_c('b-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive.hover",value:(
            `Debe incluir todos los ${_vm.$getVisibleNames(
              'teaching.evaluationcriteriamicro',
              true,
              'Criterios de Evaluación Micro'
            )} en las Actividades/${_vm.$getVisibleNames(
              'evaluations2.evaluation',
              true,
              'Evaluaciones'
            )}.`
          ),expression:"\n            `Debe incluir todos los ${$getVisibleNames(\n              'teaching.evaluationcriteriamicro',\n              true,\n              'Criterios de Evaluación Micro'\n            )} en las Actividades/${$getVisibleNames(\n              'evaluations2.evaluation',\n              true,\n              'Evaluaciones'\n            )}.`\n          ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true,"hover":true}}],staticStyle:{"color":"red"},attrs:{"icon":"exclamation-lg","scale":"0.7"}}):_vm._e(),(_vm.excludeECMicro.length == 0)?_c('b-icon',{staticStyle:{"color":"green"},attrs:{"scale":"0.7","icon":"check-lg"}}):_vm._e()]},proxy:true}],null,false,2481348383)},[(_vm.is_loading)?_c('div',[_c('b-card',[_c('b-skeleton',{attrs:{"animation":"","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"","width":"70%"}})],1)],1):(_vm.excludeECMicro.length > 0 && !_vm.is_valid)?_c('div',[(_vm.filterEvaluationsByMatter.length == 0)?_c('div',{staticClass:"text-danger d-flex mb-2"},[_c('span',{staticClass:"mt-auto mb-auto"},[_vm._v(" La "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofilematter", false, "Asignatura" ))+" no cuenta con ninguna evaluación creada. ")]),(_vm.egress_profile_matter_id != null)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
              `Ir directamente al ${_vm.$getVisibleNames(
                'manual.matter_program',
                false,
                'Programa de la Asignatura'
              )}`
            ),expression:"\n              `Ir directamente al ${$getVisibleNames(\n                'manual.matter_program',\n                false,\n                'Programa de la Asignatura'\n              )}`\n            ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-1",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToMatter()}}},[_c('b-icon',{attrs:{"variant":"info","icon":"box-arrow-up-right"}})],1):_vm._e()],1):_vm._e(),_c('ECMInfoTable',{key:_vm.key_ecm_info_table,attrs:{"matter_id":_vm.matter_id,"matter_program_view":true,"ecm_no_mention":true,"MattersEvaluations":_vm.matters_evaluations,"matter_from_form":true}})],1):_vm._e()])],1):_vm._e(),(_vm.is_valid)?_c('div',[_c('b-button',{staticClass:"mb-2",attrs:{"size":"sm","disabled":_vm.disabled_button},on:{"click":function($event){return _vm.removeManualIsValidInTest()}}},[_vm._v("Abrir "+_vm._s(_vm.$getVisibleNames("evaluations2.test", true, "Instrumentos"))+" ")])],1):_vm._e(),(_vm.is_loading)?_c('div',[_c('b-card',[_c('b-skeleton',{attrs:{"animation":"","width":"85%"}}),_c('b-skeleton',{attrs:{"animation":"","width":"55%"}}),_c('b-skeleton',{attrs:{"animation":"","width":"70%"}})],1)],1):_c('div',[(
        _vm.filterTestsByMatter.some(
          (x) =>
            (x.is_valid[0] == _vm.is_valid && _vm.is_valid === false) ||
            (x.is_valid == true &&
              _vm.is_valid === false &&
              x.manual_is_valid === false)
        ) === false
      )?[(
          _vm.filterTestsByMatter.filter(
            (x) =>
              (x.is_valid == false &&
                _vm.is_valid === false &&
                x.manual_is_valid === false) ||
              (x.is_valid[0] == _vm.is_valid && _vm.is_valid === false)
          ).length == 0 &&
          _vm.filterTestsByMatter.length == 0 &&
          _vm.excludeECMicro.length == 0
        )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive.hover",value:(
          'Haz clic para actualizar los datos.'
        ),expression:"\n          'Haz clic para actualizar los datos.'\n        ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true,"hover":true}}],attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.fetchInitEvaluations}},[_c('b-icon',{attrs:{"icon":"arrow-clockwise"}}),_vm._v(" Actualizar Datos ")],1):_vm._e()]:_vm._e(),_vm._l((_vm.filterEvaluationsByMatter),function(evaluation){return _c('div',{key:evaluation.id},[(
          (_vm.filterTestsByMatter.filter((x) => x.evaluation == evaluation.id)
            .length === 0 &&
            !_vm.is_valid) ||
          _vm.filterTestsByMatter.some(
            (x) =>
              x.is_valid == _vm.is_valid &&
              _vm.is_valid === true &&
              x.manual_is_valid === true &&
              x.evaluation == evaluation.id
          )
        )?[_c('div',{staticClass:"d-flex mb-2 mt-1"},[_c('h5',{staticClass:"mt-auto mb-auto"},[_vm._v(" "+_vm._s(evaluation.title)+" ")])]),_vm._l((_vm.filterTestsByMatter.filter(
              (x) =>
                x.evaluation == evaluation.id &&
                (((x.is_valid[0] == _vm.is_valid ||
                  (x.is_valid == true && x.manual_is_valid === false)) &&
                  _vm.is_valid === false) ||
                  (x.is_valid == _vm.is_valid &&
                    _vm.is_valid === true &&
                    x.manual_is_valid === true))
            )),function(test){return _c('b-card',{key:evaluation.id + '-' + test.id,staticClass:"mb-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1",attrs:{"header-tag":"header","role":"tab"}},[_c('div',{staticClass:"d-flex"},[(_vm.is_valid && test.is_valid == true)?[_c('b-button',{attrs:{"block":"","variant":"success","disabled":""}},[_vm._v(" "+_vm._s(test.title)+" ")])]:_vm._e(),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
                    `Ir directamente a ${test.title}`
                  ),expression:"\n                    `Ir directamente a ${test.title}`\n                  ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-1",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToEvaluation(test.evaluation, test.id)}}},[_c('b-icon',{attrs:{"variant":"info","icon":"box-arrow-up-right"}})],1)],2)]),_c('b-collapse',{attrs:{"id":`${evaluation.id}-accordion-test-${test.id}`,"role":"tabpanel"}},[_c('b-card-body',[_c('InfoTestTable',{attrs:{"test":test}})],1)],1)],1)}),(
            _vm.filterTestsByMatter.filter((x) => x.evaluation == evaluation.id)
              .length == 0
          )?_c('div',{staticClass:"text-danger d-flex"},[_c('span',{staticClass:"mt-auto mb-auto"},[_vm._v(" La evaluación no cuenta con "+_vm._s(_vm.$getVisibleNames( "evaluations2.tefilterTestsByMatterst", false, "Instrumento" ))+". ")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
              `Ir directamente a ${evaluation.title}`
            ),expression:"\n              `Ir directamente a ${evaluation.title}`\n            ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-1",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToEvaluation(evaluation.id)}}},[_c('b-icon',{attrs:{"variant":"info","icon":"box-arrow-up-right"}})],1)],1):_vm._e()]:_vm._e()],2)}),(
        _vm.filterEvaluationsByMatter.length == 0 &&
        _vm.excludeECMicro.length == 0 &&
        !_vm.is_valid
      )?_c('div',{staticClass:"text-danger d-flex"},[_c('span',{staticClass:"mt-auto mb-auto"},[_vm._v(" La "+_vm._s(_vm.$getVisibleNames("mesh.egressprofilematter", false, "Asignatura"))+" no cuenta con ninguna evaluación creada. ")]),(_vm.egress_profile_matter_id != null)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top.noninteractive.v-secondary",value:(
          `Ir directamente al ${_vm.$getVisibleNames(
            'manual.matter_program',
            false,
            'Programa de la Asignatura'
          )}`
        ),expression:"\n          `Ir directamente al ${$getVisibleNames(\n            'manual.matter_program',\n            false,\n            'Programa de la Asignatura'\n          )}`\n        ",modifiers:{"top":true,"noninteractive":true,"v-secondary":true}}],staticClass:"ml-1 p-1",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToMatter()}}},[_c('b-icon',{attrs:{"variant":"info","icon":"box-arrow-up-right"}})],1):_vm._e()],1):_vm._e()],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }