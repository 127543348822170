<template>
  <div>
    <div class="h-auto">
      <div style="display: flex">
        <div class="col" style="text-align: left">
          <button
            v-can="'mesh.add_career'"
            v-if="
              allows_crud &&
              ((user_position &&
                [1, 2].includes(user_position.position) &&
                [1, 2].includes(user.groups[0])) ||
                user.is_superuser ||
                $hasObjectPermission('mesh.faculty', 'add') ||
                $hasObjectPermission('mesh.career', 'add'))
            "
            class="btn btn-secondary btn-sm btn-add"
            v-b-modal.new-career-modal
          >
            Agregar
            {{ $getVisibleNames("manual.programa", false, "Programa") }}
            <!-- Programa -->
          </button>
        </div>
      </div>
      <h4 class="mt-0">
        {{ $getVisibleNames("mesh.career", true, "Programas") }}
        <AlternativeNameButton
          :table_name="'mesh.career'"
          :allows_crud="allows_crud"
        ></AlternativeNameButton>
      </h4>
      <b-form-select
        name="type-select"
        class="w-25 noprint"
        v-model="profile_type_id"
        text-field="name"
        value-field="id"
      >
        <!-- :options="profileTypes" -->
        <option :value="null" selected>
          -- Seleccione Tipo de
          {{ $getVisibleNames("mesh.career", false, "Programa") }} --
        </option>
        <option v-for="type in profileTypes" :key="type.id" :value="type.id">
          {{ type.name }}
        </option>
      </b-form-select>
      <div
        class="d-flex"
        v-if="
          user &&
          ((user_position &&
            [1].includes(user_position.position) &&
            [1].includes(user.groups[0])) ||
            user.is_superuser)
        "
      >
        <template>
          <div class="ml-auto mr-0 noprint">
            <b-button
              class="mb-2 ml-3 noprint"
              size="sm"
              @click="redirectToCareerReport()"
              ><b-icon icon="box-arrow-up-right"></b-icon> Reporte de
              {{ $getVisibleNames("mesh.career", true, "Programas") }}
            </b-button>
          </div>
        </template>
      </div>
      <div class="table-responsive mt-2">
        <GenericBTable
          v-if="careerList"
          :items="careerList"
          :fields="fields"
          :filter="input_search"
          :filterCustom="filterCustom"
          primary-key="id"
          :show_pagination="true"
          :pagination="careerList.length > 20 ? careerList.length : 20"
          :search_filter="true"
          :columns_display="true"
          :allows_crud="allows_crud"
          :default_striped="false"
          :default_bordered="false"
          :default_borderless="false"
          :default_hover="true"
          :display_id="'career'"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @emitChangeSearchField="changeInputSearch"
          :sticky-header="stickyHeaderSize"
        >
          <template v-slot:cell(faculty)="row">
            <div class="tdClass">
              {{ getFacultyName(row.item.faculty) }}
            </div>
          </template>
          <template v-slot:cell(name)="row">
            <div class="tdClass">
              {{ row.item.name }}
            </div>
          </template>
          <template v-slot:cell(type)="row">
            <div class="tdClass">
              {{ getCareerType(row.item.egress_profile_type) }}
            </div>
          </template>
          <template v-slot:head(actions)>
            <b-button
              class="p-auto"
              variant="none"
              v-b-tooltip.v-secondary.top.noninteractive="
                `
                ${
                  select_all ? 'Ocultar todos' : 'Mostrar todos'
                } los ${$getVisibleNames(
                  'mesh.egressprofile',
                  true,
                  'Perfiles de Egreso'
                )}
              `
              "
              @click="
                () => {
                  select_all = !select_all;
                  selectAllViews();
                }
              "
            >
              <b-icon-chevron-double-up
                v-if="select_all"
              ></b-icon-chevron-double-up>
              <b-icon-chevron-double-down
                v-else-if="!select_all"
              ></b-icon-chevron-double-down>
            </b-button>
          </template>
          <template v-slot:cell(actions)="row">
            <div
              class="noprint"
              :class="{
                'd-flex justify-content-end':
                  row.item.egress_profiles.length != 0,
              }"
            >
              <template v-if="row.item.egress_profiles.length == 0">
                <button-add
                  v-if="
                    allows_crud &&
                    ((user_position &&
                      [1, 2].includes(user_position.position) &&
                      [1, 2].includes(user.groups[0])) ||
                      user.is_superuser ||
                      $hasObjectPermission(
                        'mesh.faculty',
                        'change',
                        row.item.faculty
                      ) ||
                      $hasObjectPermission(
                        'mesh.career',
                        'change',
                        row.item.id
                      ))
                  "
                  v-b-tooltip.v-secondary.top.noninteractive="
                    `Crear ${$getVisibleNames(
                      'mesh.egressprofile',
                      false,
                      'Perfil de Egreso'
                    )}`
                  "
                  size="sm"
                  @click="slotCreateEgressProfile(row.item)"
                >
                </button-add>
              </template>
              <template v-else>
                <div>
                  <b-button
                    v-if="row.item.egress_profiles_list.length > 1"
                    size="sm"
                    @click="openDetails(row)"
                    v-b-tooltip.v-secondary.top.noninteractive="
                      $getVisibleNames(
                        'mesh.egressprofile',
                        true,
                        'Perfiles de Egreso'
                      )
                    "
                    variant="light"
                    class="border-button-class mr-2"
                  >
                    <h5 class="p-0 m-0">
                      <b-badge variant="none">{{
                        row.item.egress_profile_curricular_number.length
                      }}</b-badge>
                    </h5>
                  </b-button>
                  <template
                    v-else-if="row.item.egress_profiles_list.length == 1"
                  >
                    <ButtonWrapper
                      class="mr-2"
                      :item_id="row.item.id"
                      :tooltip_text="'Dirigirse a...'"
                    >
                      <template v-slot:btn-slot>
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile(
                            row.item.egress_profiles_list.map((x) => x.id)
                          )"
                          @click="redirectToEgressProfile(egress_profile_id)"
                          class="dropdown-class"
                          :key="'perfil' + egress_profile_id"
                        >
                          {{
                            $getVisibleNames(
                              "mesh.egressprofile",
                              false,
                              "Perfil de Egreso"
                            )
                          }}
                        </b-button>
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile(
                            row.item.egress_profiles_list.map((x) => x.id)
                          )"
                          @click="redirectToMatrix1(egress_profile_id)"
                          class="dropdown-class"
                          :key="'Matriz 1' + egress_profile_id"
                        >
                          {{
                            $getVisibleNames(
                              "manual.matriz_1",
                              false,
                              "Matriz 1"
                            )
                          }}
                        </b-button>
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile(
                            row.item.egress_profiles_list.map((x) => x.id)
                          )"
                          :key="'Matriz 2' + egress_profile_id"
                          @click="redirectToMatrix2(egress_profile_id)"
                          class="dropdown-class"
                        >
                          {{
                            $getVisibleNames(
                              "manual.matriz_2",
                              false,
                              "Matriz 2"
                            )
                          }}
                        </b-button>
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile(
                            row.item.egress_profiles_list.map((x) => x.id)
                          )"
                          :key="'Matriz 3' + egress_profile_id"
                          @click="redirectToMatrix3(egress_profile_id)"
                          class="dropdown-class"
                        >
                          {{
                            $getVisibleNames(
                              "manual.matriz_3",
                              false,
                              "Matriz 3"
                            )
                          }}
                        </b-button>
                        <!-- TODO: OCULTADO TABLA CAPACIDADES -->
                        <!-- <template
                          v-if="
                            (institution &&
                              institution.internal_use_id == 'duoc_uc') ||
                            $debug_change_duoc
                          "
                        >
                          <b-button
                            variant="none"
                            v-for="egress_profile_id in filterEgressProfile(
                              row.item.egress_profiles_list.map((x) => x.id)
                            )"
                            :key="'Matriz 4' + egress_profile_id"
                            @click="redirectToMatrix4(egress_profile_id)"
                            class="dropdown-class"
                          >
                            {{
                              $getVisibleNames(
                                "manual.matriz_4",
                                false,
                                "Matriz 4"
                              )
                            }}
                          </b-button>
                        </template> -->
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile(
                            row.item.egress_profiles_list.map((x) => x.id)
                          )"
                          :key="'Plan de Estudio' + egress_profile_id"
                          @click="redirectToStudyPlan(egress_profile_id)"
                          class="dropdown-class"
                        >
                          Plan de Estudio
                        </b-button>
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile(
                            row.item.egress_profiles_list.map((x) => x.id),
                            true
                          )"
                          :key="'Malla Curricular' + egress_profile_id"
                          @click="redirectToCurricularMesh(egress_profile_id)"
                          class="dropdown-class"
                        >
                          Malla Curricular
                        </b-button>
                      </template>
                    </ButtonWrapper>
                  </template>
                </div>
              </template>
              <button-edit
                v-if="
                  allows_crud &&
                  ((user_position &&
                    [1, 2].includes(user_position.position) &&
                    [1, 2].includes(user.groups[0])) ||
                    user.is_superuser ||
                    $hasObjectPermission(
                      'mesh.faculty',
                      'change',
                      row.item.faculty
                    ) ||
                    $hasObjectPermission('mesh.career', 'change', row.item.id))
                "
                v-can="'mesh.change_career'"
                @click="$bvModal.show(`edit-career-modal-${row.item.id}`)"
                v-b-tooltip.v-secondary.topright.noninteractive="
                  `
                      Editar ${$getVisibleNames(
                        'mesh.career',
                        false,
                        'Programa'
                      )}
                    `
                "
              ></button-edit>
              <button-edit
                v-else-if="allows_crud"
                v-can="'mesh.change_career'"
                :disabled="true"
              ></button-edit>
              <button-delete
                v-if="
                  allows_crud &&
                  ((user_position &&
                    [1, 2].includes(user_position.position) &&
                    [1, 2].includes(user.groups[0])) ||
                    user.is_superuser ||
                    $hasObjectPermission(
                      'mesh.faculty',
                      'change',
                      row.item.faculty
                    ) ||
                    $hasObjectPermission('mesh.career', 'delete', row.item.id))
                "
                v-can="'mesh.delete_career'"
                @click="askForDeleteCareer(row.item.id)"
                v-b-tooltip.v-secondary.topright.noninteractive="
                  `
                      Eliminar ${$getVisibleNames(
                        'mesh.career',
                        false,
                        'Programa'
                      )}
                    `
                "
              ></button-delete>
              <button-delete
                v-else-if="allows_crud"
                v-can="'mesh.change_career'"
                :disabled="true"
              ></button-delete>
            </div>
            <b-modal
              :id="`edit-career-modal-${row.item.id}`"
              :title="`Editar ${$getVisibleNames(
                'mesh.career',
                false,
                'Programa'
              )}
                    `"
              size="lg"
              hide-footer
            >
              <CareerForm
                :Career="row.item"
                @updated="slotUpdatedCareer"
                :show_title="false"
              ></CareerForm>
            </b-modal>
          </template>
          <template #row-details="row">
            <b-card v-if="row.item.egress_profiles_list.length > 1" class="p-0">
              <div>
                <GenericBTable
                  :key="input_search"
                  :items="row.item.egress_profiles_list"
                  :fields="fieldsEgressProfile"
                  :per_page="row.item.egress_profiles_list.length"
                  :insert_search="input_search"
                  :filter="input_search"
                  :filterCustom="filterCustom2"
                  :show_pagination="false"
                  :search_filter="false"
                  :default_striped="false"
                  :default_bordered="false"
                  :default_borderless="true"
                  :default_hover="true"
                  :hidden_table_header="false"
                  :show_skeleton_efect="false"
                  :columns_display="false"
                >
                  <template v-slot:cell(actions)="data">
                    <ButtonWrapper
                      :item_id="data.item.id"
                      :tooltip_text="'Dirigirse a...'"
                    >
                      <template v-slot:btn-slot>
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile([
                            data.item.id,
                          ])"
                          v-b-tooltip.v-secondary.top.noninteractive="
                            'Ir al perfil'
                          "
                          @click="redirectToEgressProfile(egress_profile_id)"
                          class="dropdown-class"
                          :key="'perfil' + egress_profile_id"
                        >
                          {{
                            $getVisibleNames(
                              "mesh.egressprofile",
                              false,
                              "Perfil de Egreso"
                            )
                          }}
                        </b-button>
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile([
                            data.item.id,
                          ])"
                          v-b-tooltip.v-secondary.top.noninteractive="
                            'Ir a la ' +
                            $getVisibleNames(
                              'manual.matriz_1',
                              false,
                              'Matriz 1'
                            )
                          "
                          @click="redirectToMatrix1(egress_profile_id)"
                          class="dropdown-class"
                          :key="'Matriz 1' + egress_profile_id"
                        >
                          {{
                            $getVisibleNames(
                              "manual.matriz_1",
                              false,
                              "Matriz 1"
                            )
                          }}
                        </b-button>
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile([
                            data.item.id,
                          ])"
                          v-b-tooltip.v-secondary.top.noninteractive="
                            'Ir a la ' +
                            $getVisibleNames(
                              'manual.matriz_2',
                              false,
                              'Matriz 2'
                            )
                          "
                          :key="'Matriz 2' + egress_profile_id"
                          @click="redirectToMatrix2(egress_profile_id)"
                          class="dropdown-class"
                        >
                          {{
                            $getVisibleNames(
                              "manual.matriz_2",
                              false,
                              "Matriz 2"
                            )
                          }}
                        </b-button>
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile([
                            data.item.id,
                          ])"
                          :key="'Matriz 3' + egress_profile_id"
                          v-b-tooltip.v-secondary.top.noninteractive="
                            'Ir a la ' +
                            $getVisibleNames(
                              'manual.matriz_3',
                              false,
                              'Matriz 3'
                            )
                          "
                          @click="redirectToMatrix3(egress_profile_id)"
                          class="dropdown-class"
                        >
                          {{
                            $getVisibleNames(
                              "manual.matriz_3",
                              false,
                              "Matriz 3"
                            )
                          }}
                        </b-button>
                        <!-- TODO: OCULTADO TABLA CAPACIDADES -->
                        <!-- <template
                          v-if="
                            ((institution &&
                              institution.internal_use_id == 'duoc_uc') ||
                              $debug_change_duoc) &&
                            filterEgressProfile([data.item.id]).length > 0
                          "
                        >
                          <b-button
                            variant="none"
                            v-for="egress_profile_id in filterEgressProfile([
                              data.item.id,
                            ])"
                            :key="'Matriz 4' + egress_profile_id"
                            v-b-tooltip.v-secondary.top.noninteractive="
                              'Ir a la ' +
                              $getVisibleNames(
                                'manual.matriz_4',
                                false,
                                'Matriz 4'
                              )
                            "
                            @click="redirectToMatrix4(egress_profile_id)"
                            class="dropdown-class"
                          >
                            {{
                              $getVisibleNames(
                                "manual.matriz_4",
                                false,
                                "Matriz 4"
                              )
                            }}
                          </b-button>
                        </template> -->
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile([
                            data.item.id,
                          ])"
                          :key="'Plan de Estudio' + egress_profile_id"
                          v-b-tooltip.v-secondary.top.noninteractive="
                            'Ir al plan de estudio'
                          "
                          @click="redirectToStudyPlan(egress_profile_id)"
                          class="dropdown-class"
                        >
                          Plan de Estudio
                        </b-button>
                        <b-button
                          variant="none"
                          v-for="egress_profile_id in filterEgressProfile(
                            [data.item.id],
                            true
                          )"
                          :key="'Malla Curricular' + egress_profile_id"
                          v-b-tooltip.v-secondary.top.noninteractive="
                            'Ir a la malla curricular'
                          "
                          @click="redirectToCurricularMesh(egress_profile_id)"
                          class="dropdown-class"
                        >
                          Malla Curricular
                        </b-button>
                      </template>
                    </ButtonWrapper>
                  </template>
                </GenericBTable>
              </div>
            </b-card>
          </template>
        </GenericBTable>
      </div>
    </div>
    <div>
      <b-modal
        id="new-career-modal"
        :title="`Crear ${$getVisibleNames('mesh.career', false, 'Programa')}`"
        size="lg"
        hide-footer
      >
        <CareerForm
          @created="slotCreatedCareer"
          :show_title="false"
        ></CareerForm>
      </b-modal>
    </div>
    <b-modal
      id="modal-create-egress-profile"
      :title="`Crear ${$getVisibleNames(
        'mesh.egressprofile',
        false,
        'Perfil de Egreso'
      )}`"
      size="lg"
      hide-footer
    >
      <EgressProfileForm
        :default_max_semester_amount="institution.max_semester_amount"
        :career_id="selected_career_id"
        @created="slotCreatedEgreesProfile"
      ></EgressProfileForm>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "CareerListView",
  components: {
    CareerForm: () => import("./Career/CareerForm"),
    EgressProfileForm: () =>
      import("@/components/mesh/EgressProfile/EgressProfileForm"),
    GenericBTable: () => import("@/components/reusable/GenericBTable"),
    AlternativeNameButton: () =>
      import("@/components/reusable/AlternativeNameButton"),
    ButtonWrapper: () => import("@/components/reusable/ButtonWrapper"),
  },
  data() {
    return {
      is_hidden: true,
      isDropdown2Visible: false,
      input_search: "",
      profile_type_id: null,
      selected_career_id: null,
      selected_tab: 8,
      checked: false,
      sortBy: "",
      sortDesc: false,
      fully_visible_professor_egress_profiles: [],
      select_all: false,
      stickyHeaderSize: "600px",
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      institution: "getInstitution",
      user_position: names.USER_POSITION,
      careers: names.CAREERS,
      cycles: names.CYCLES,
      egressProfiles: names.EGRESS_PROFILES,
      egressProfileTypes: names.PROFILE_TYPES,
      faculties: names.FACULTIES,
      profileCompetences: names.PROFILE_COMPETENCES,
      profileTypes: names.PROFILE_TYPES,
      modalities_egress_profiles: names.MODALITIES_EGRESS_PROFILE,
    }),
    allows_crud() {
      const has_permission = this.$hasPermissions("mesh.add_career");
      if (has_permission) return has_permission;
      else return false;
    },
    careerList() {
      let list = [];
      list = this.careers.map((x) => {
        const egress_profiles_list = this.egressProfiles
          .filter((e) => e.career == x.id)
          .map((e) => {
            return {
              ...e,
              career_name: x.name,
              faculty_name: this.getFacultyName(x.faculty),
              egress_profile_type_name: this.getCareerType(
                x.egress_profile_type
              ),
            };
          })
          .sort((a, b) => new Date(b.init_date) - new Date(a.init_date));
        return {
          ...x,
          egress_profile_type_name: this.getCareerType(x.egress_profile_type),
          faculty_name: this.getFacultyName(x.faculty),
          egress_profile_year: [
            ...new Set(
              egress_profiles_list.map((e) => {
                if (e.init_date != null)
                  return parseInt(String(e.init_date).substr(0, 4));
              })
            ),
          ],
          egress_profile_curricular_number: egress_profiles_list.map(
            (e) => e.code
          ),
          egress_profile_modality: [
            ...new Set(
              egress_profiles_list.map((e) => this.getModalityName(e.modality))
            ),
          ],
          egress_profile_professional_title: [
            ...new Set(egress_profiles_list.map((e) => e.professional_title)),
          ],
          _showDetails: false,
          egress_profiles_list: egress_profiles_list,
        };
      });
      if (!this.profile_type_id) return list;
      return list.filter((x) => x.egress_profile_type == this.profile_type_id);
    },
    fields() {
      return [
        {
          key: "faculty",
          label: String(
            this.$getVisibleNames("mesh.faculty", true, "Facultades")
          ),
          sortable: true,
          display_column: true,
          thStyle: {
            background: "var(--primary-color) !important",
            position: "sticky !important",
          },
        },
        {
          key: "egress_profile_year",
          label: "Año",
          sortable: true,
          display_column: true,
          thStyle: {
            background: "var(--primary-color) !important",
            position: "sticky !important",
          },
          formatter: (value) => {
            return value.join(", ");
          },
        },
        {
          key: "name",
          label: String(
            this.$getVisibleNames("mesh.career", true, "Programas")
          ),
          sortable: true,
          display_column: true,
          thStyle: {
            background: "var(--primary-color) !important",
            position: "sticky !important",
          },
        },
        {
          key: "egress_profile_curricular_number",
          label: "Número de Currículum",
          sortable: true,
          display_column: true,
          thStyle: {
            background: "var(--primary-color) !important",
            position: "sticky !important",
          },
          formatter: (value) => {
            return value.join(", ");
          },
        },
        {
          key: "egress_profile_modality",
          label: "Modalidad",
          sortable: true,
          display_column: true,
          thStyle: {
            background: "var(--primary-color) !important",
            position: "sticky !important",
          },
          formatter: (value) => {
            return value.join(", ");
          },
        },
        {
          key: "type",
          label: String("Tipo de Programa"),
          sortable: true,
          display_column: true,
          thStyle: {
            background: "var(--primary-color) !important",
            position: "sticky !important",
          },
        },
        {
          key: "actions",
          label: "",
          display_column: true,
          thClass: "text-right noprint",
          tdClass: "text-right noprint",
          thStyle: {
            background: "var(--primary-color) !important",
            position: "sticky !important",
          },
        },
      ];
    },
    fieldsEgressProfile() {
      return [
        {
          key: "professional_title",
          label: this.$getVisibleNames("mesh.egressprofile", true, "Programas"),
          sortable: false,
          display_column: true,
          tdClass: "text-left",
          thStyle: {
            background: "var(--secondary-color) !important",
            color: "white",
          },
        },
        {
          key: "init_date",
          label: "Año",
          sortable: false,
          display_column: true,
          formatter: (value) => {
            if (value != null) return parseInt(String(value).substr(0, 4));
            else return "";
          },
          thStyle: {
            background: "var(--secondary-color) !important",
            color: "white",
          },
        },
        {
          key: "code",
          label: "Número de Currículum",
          sortable: false,
          display_column: true,
          tdClass: "text-left",
          thStyle: {
            background: "var(--secondary-color) !important",
            color: "white",
          },
        },
        {
          key: "modality",
          label: "Modalidad",
          sortable: false,
          display_column: true,
          formatter: (value) => {
            return this.getModalityName(value);
          },
          thStyle: {
            background: "var(--secondary-color) !important",
            color: "white",
          },
        },
        {
          key: "actions",
          label: "",
          sortable: false,
          display_column: true,
          tdClass: "text-right",
          thStyle: {
            background: "var(--secondary-color) !important",
            color: "white",
          },
        },
      ];
    },
  },
  methods: {
    filterCustom(row) {
      if (
        this.$filtered(row.name, this.input_search) ||
        this.$filtered(
          this.getCareerType(row.egress_profile_type),
          this.input_search
        ) ||
        this.$filtered(this.getFacultyName(row.faculty), this.input_search) ||
        this.$filtered(row.egress_profile_year.join(), this.input_search) ||
        this.$filtered(
          row.egress_profile_professional_title.join(),
          this.input_search
        ) ||
        this.$filtered(
          row.egress_profile_curricular_number.join(),
          this.input_search
        ) ||
        this.$filtered(row.egress_profile_modality.join(), this.input_search)
      ) {
        return true;
      } else {
        return false;
      }
    },
    filterCustom2(row) {
      if (
        this.$filtered(row.career_name, this.input_search) ||
        this.$filtered(row.faculty_name, this.input_search) ||
        this.$filtered(row.egress_profile_type_name, this.input_search) ||
        this.$filtered(row.professional_title, this.input_search) ||
        this.$filtered(row.code, this.input_search) ||
        this.$filtered(row.init_date, this.input_search) ||
        this.$filtered(this.getModalityName(row.modality), this.input_search)
      ) {
        return true;
      } else {
        return false;
      }
    },
    redirectToCareerReport() {
      const url = this.$router.resolve({
        name: "CareerReportView",
        params: {},
      }).href;
      window.open(url, "_blank");
    },
    updateStickyHeaderSize() {
      const viewportHeight = window.innerHeight;
      const newStickyHeaderSize = viewportHeight * 1 + "px"; // Por ejemplo, 100% de la altura del viewport
      this.stickyHeaderSize = newStickyHeaderSize;
    },
    getModalityName(modality_id) {
      const modality = this.modalities_egress_profiles.find(
        (x) => x.id == modality_id
      );
      return modality ? modality.name : "";
    },
    filterEgressProfile(egress_profiles_ids, all_egress_profiles = false) {
      if (all_egress_profiles) return egress_profiles_ids;
      if (this.user && this.user.groups.includes(6)) {
        return egress_profiles_ids.filter((x) =>
          this.fully_visible_professor_egress_profiles.includes(x)
        );
      } else if (this.user && this.user.groups.includes(7)) {
        if (
          this.user &&
          this.user.student_card &&
          this.user.student_card.egress_profile != null
        ) {
          return egress_profiles_ids.filter(
            (x) => x == this.user.student_card.egress_profile
          );
        } else return [];
      }
      return egress_profiles_ids;
    },
    getCareerType(type_id) {
      let profile_type = this.egressProfileTypes.find((x) => x.id == type_id);
      return profile_type ? profile_type.name : "";
    },
    getFacultyName(faculty_id) {
      const instance = this.faculties.find((x) => x.id == faculty_id);
      if (instance) return instance.name;
      return "...";
    },
    slotCreatedCareer(/*career*/) {
      this.$bvModal.hide("new-career-modal");
    },
    slotUpdatedCareer(career) {
      this.$bvModal.hide(`edit-career-modal-${career.id}`);
    },
    askForDeleteCareer(career_id) {
      this.$swal({
        title: "¿Está seguro de que desea eliminar?",
        text: "¡Esta acción no se podrá revertir!",
        type: "warning",
        showCancelButton: true,
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(names.DELETE_CAREER, career_id).then(() => {
            toast(
              String(this.$getVisibleNames("mesh.career", false, "Programa")) +
                " eliminado."
            );
          });
        }
      });
    },
    changeInputSearch(input_search) {
      this.input_search = input_search;
    },

    redirectToEgressProfile(egress_profile_id) {
      this.$router.push({
        name: "EgressProfileView",
        params: { egress_profile_id: egress_profile_id },
      });
    },
    redirectToMatrix1(egress_profile_id) {
      this.$router.push({
        name: "Matrix1View",
        params: { egress_profile_id: egress_profile_id },
      });
    },
    redirectToMatrix2(egress_profile_id) {
      this.$router.push({
        name: "Matrix2View",
        params: {
          egress_profile_id: egress_profile_id,
        },
      });
    },
    redirectToMatrix3(egress_profile_id) {
      this.$router.push({
        name: "Matrix3View",
        params: {
          egress_profile_id: egress_profile_id,
        },
      });
    },
    redirectToMatrix4(egress_profile_id) {
      this.$router.push({
        name: "Matrix4View",
        params: {
          egress_profile_id: egress_profile_id,
        },
      });
    },
    redirectToStudyPlan(egress_profile_id) {
      this.$router.push({
        name: "StudyPlanView",
        params: {
          egress_profile_id: egress_profile_id,
        },
      });
    },
    redirectToCurricularMesh(egress_profile_id) {
      this.$router.push({
        name: "CurricularMeshView",
        params: {
          egress_profile_id: egress_profile_id,
        },
      });
    },
    toggleCycles() {
      return (this.is_hidden = !this.is_hidden);
    },
    slotCreateEgressProfile(career) {
      this.selected_career_id = career.id;
      this.$bvModal.show("modal-create-egress-profile");
    },
    slotCreatedEgreesProfile(egress_profile) {
      this.$store.commit(names.MUTATE_DELETE_CAREER, egress_profile.career);
      this.$bvModal.hide("modal-create-egress-profile");
      this.$store.dispatch(names.FETCH_CAREER, egress_profile.career);
    },
    fullyVisibleProfessorEgressProfiles() {
      this.$restful
        .Get(`/mesh/fully_visible_professor_egress_profiles/`)
        .then((response) => {
          this.fully_visible_professor_egress_profiles = response;
        });
    },
    selectAllViews() {
      if (this.select_all) {
        this.careerList.forEach((item) => {
          item._showDetails = true;
        });
      } else {
        this.careerList.forEach((item) => {
          item._showDetails = false;
        });
      }
    },
    openDetails(row) {
      let career = this.careerList.find((x) => x.id == row.item.id);
      if (career) career._showDetails = !career._showDetails;
      if (this.select_all && !career._showDetails) this.select_all = false;
      else if (
        !this.select_all &&
        !this.careerList.some((x) => !x._showDetails)
      )
        this.select_all = true;
    },
  },
  created() {
    if (this.user && this.user.groups.includes(6)) {
      this.fullyVisibleProfessorEgressProfiles();
    }
    this.$store.dispatch(names.FETCH_CAREERS);
    this.$store.dispatch(names.FETCH_CYCLES);
    this.$store.dispatch(names.FETCH_FACULTIES);
    this.$store.dispatch(names.FETCH_EGRESS_PROFILES);
    this.$store.dispatch(names.FETCH_PROFILE_TYPES);
    this.$store.dispatch(names.FETCH_COMPETENCE_TYPES);
    this.$store.dispatch(names.FETCH_MODALITIES_EGRESS_PROFILE);
    this.$store.dispatch(names.FETCH_COMPETENCES);
    this.$store.dispatch(names.FETCH_TAXONOMIES);
  },
  mounted() {
    this.updateStickyHeaderSize();
    window.addEventListener("resize", this.updateStickyHeaderSize);
    this.$root.$on("bv::dropdown::show", (bvEvent) => {
      if (bvEvent.componentId === "dropdown-2") {
        this.isDropdown2Visible = true;
      }
    });
    this.$root.$on("bv::dropdown::hide", (bvEvent) => {
      if (bvEvent.componentId === "dropdown-2") {
        this.isDropdown2Visible = false;
      }

      if (this.isDropdown2Visible) {
        bvEvent.preventDefault();
      }
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateStickyHeaderSize);
  },
};
</script>

<style scoped>
.border-button-class {
  width: 28px !important;
  height: 30px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  color: #333;
  border: 1px solid #bdbdbd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 0;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}
.border-button-class:hover,
.border-button-class:focus {
  background-color: #f7f9fa;
  color: #0056b3;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}
.dropdown-class {
  width: 100%; /* Asegura que el botón ocupe todo el ancho disponible */
  height: 35px !important;
  margin-bottom: 4px;
  border-radius: 4px;
  background-color: #046c9c;
  color: white;
  border: 1px solid #bdbdbd;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  padding: 0;
  transition: background-color 0.3s, color 0.3s, box-shadow 0.3s;
}

.dropdown-class:hover {
  background-color: #f7f9fa;
  color: #046c9c;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  cursor: pointer; /* Cambia el cursor para indicar que es clickeable */
}
.tdClass {
  text-align: left !important;
}
/* .dropdown-class >>> .btn {
  background-color: var(--primary-color) !important;
  border: var(--primary-color) !important;
  color: #212b2e !important;
} */
.toggle-types {
  display: flex;
  float: right;
  margin-right: 1em;
  background: rgb(230, 230, 230);
  padding: 0.3em 4.8em;
  border-radius: 3px;
}
.small-table {
  min-height: 475px;
}
.dropdown-header {
  color: #212529;
  font-weight: 400;
  font-size: 1rem;
}
.hidden {
  display: none;
  padding-bottom: 10px;
}
.career-redirect-button-text {
  margin-left: 4px;
}
.career-redirect-button,
.career-m2-redirect-button {
  width: 38px;
  height: 38px;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.6s ease-in-out;
  margin: 2px;
  padding: 0 5px;
  border-radius: 8px;
  color: #6c757d;
}
.career-redirect-button:hover,
.career-m2-redirect-button:hover {
  width: 184px;
  padding-right: 100px !important;
  font-size: 16px !important;
  transition: 0.4s ease-in;
  color: #fff;
}
.career-redirect-button .navigation-icon,
.career-m2-redirect-button .navigation-icon {
  margin: 0 5px;
}

.edit {
  margin-top: 0.3rem;
}
.toggle-height {
  height: 100px !important;
}
.cycles {
  transition: 0.3s ease-in;
  width: 184px;
  text-align: left;
  border-radius: 6px;
  padding: 0 1px;
}
.cycles:hover {
  background-color: rgb(221, 221, 221);
  color: #3f3f3f;
  width: 184px;
  transition: 0.2s ease-in;
}
.add-career {
  margin-left: 76% !important;
  margin-bottom: -8%;
  padding-top: 1.3%;
}
.search-career {
  max-width: 60%;
  margin: 0 15%;
}
/* .table-responsive >>> .generic-table {
  overflow: inherit;
} */
.check-type {
  background: #007bff !important;
  margin-inline: 0.7em;
  height: 1.1em;
  width: 2.2em !important;
  margin-top: 2%;
  border-radius: 10px;
}
.icon-check {
  color: white;
  transform: translate(-70%, -43%);
  transition: 0.2s;
}
.icon-check-active {
  transform: translate(15%, -43%) !important;
}
@media (min-width: 1378px) {
  .add-career {
    margin-left: 78% !important;
  }
}
@media print {
  .egress-profile-scroll {
    max-height: 100% !important;
  }
}
@media (max-width: 800px) {
  .search-career {
    max-width: 50%;
    margin: 0;
  }
  .add-career {
    margin-left: 70% !important;
  }
}
.card >>> .card-body {
  padding: 0 !important;
}
.card-body >>> .b-table-container {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 1;
  margin-right: 1;
}
</style>