<template>
  <div>
    <b-table-simple small responsive v-if="studyUnitsFilter.length > 0">
      <b-thead>
        <b-tr>
          <b-th class="text-align-center evaluation-header">
            {{
              $getVisibleNames(
                "teaching.ramicro",
                false,
                "RA Micro"
              ).toUpperCase()
            }}
          </b-th>
          <b-th class="text-align-center evaluation-header">
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                true,
                "Criterios de Evaluación"
              ).toUpperCase()
            }}
          </b-th>
          <b-th class="text-align-center evaluation-header"> MENCIONES </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <template v-for="study_unit in studyUnitsFilter">
          <b-tr
            v-for="(e_c_micro, index) in ECMicroByStudyUnits(study_unit.id)"
            :key="`matter-${matter_id}-study_unit-${study_unit.id}-e_c_micro-${e_c_micro.id}`"
          >
            <b-td
              v-if="index == 0"
              :rowspan="ECMicroByStudyUnits(study_unit.id).length"
            >
              <div class="d-flex ml-1">
                <span> {{ study_unit.order }}.</span>
                <SentenceContainer
                  :unique_id="`s_u-matter-${matter_id}-study_unit-${study_unit.id}-e_c_micro-${e_c_micro.id}`"
                  :Sentence="study_unit"
                  :boundedTextContainer="short_view"
                  :single_line="short_view"
                ></SentenceContainer>
              </div>
            </b-td>
            <b-td
              :class="{
                'text-danger': getListByECMicro(e_c_micro.id).length == 0,
              }"
            >
              <div class="d-flex ml-1 mt-1">
                <span> {{ study_unit.order }}.{{ e_c_micro.order }}</span>
                <SentenceContainer
                  :unique_id="`ecm-matter-${matter_id}-study_unit-${study_unit.id}-e_c_micro-${e_c_micro.id}`"
                  :Sentence="e_c_micro"
                  :boundedTextContainer="short_view"
                  :single_line="short_view"
                ></SentenceContainer>
              </div>
            </b-td>
            <b-td class="text-center align-center">
              <b-badge
                :id="`e_c_micro-popover-${e_c_micro.id}`"
                :variant="
                  getListByECMicro(e_c_micro.id).length == 0
                    ? 'danger'
                    : filterMentionsEvaluations(e_c_micro.id).length > 0 &&
                      filterMentionsActivities(e_c_micro.id).length > 0
                    ? 'success'
                    : filterMentionsEvaluations(e_c_micro.id).length > 0 ||
                      filterMentionsActivities(e_c_micro.id).length > 0
                    ? 'warning'
                    : 'secondary'
                "
                v-b-tooltip.v-danger.top.noninteractive.hover="
                  getListByECMicro(e_c_micro.id).length == 0
                    ? 'No está siendo mencionado en ninguna Actividad/Evaluación'
                    : ''
                "
              >
                {{ getListByECMicro(e_c_micro.id).length }}
              </b-badge>
              <b-popover
                v-if="
                  filterMentionsEvaluations(e_c_micro.id).length > 0 ||
                  filterMentionsActivities(e_c_micro.id).length > 0
                "
                :target="`e_c_micro-popover-${e_c_micro.id}`"
                placement="left"
                triggers="hover click"
                variant="dark"
              >
                <div>
                  <div
                    v-if="filterMentionsEvaluations(e_c_micro.id).length > 0"
                  >
                    <strong
                      v-if="filterMentionsEvaluations(e_c_micro.id).length > 1"
                      >{{
                        $getVisibleNames(
                          "evaluations2.evaluation",
                          true,
                          "Evaluaciones"
                        )
                      }}:</strong
                    >
                    <strong
                      v-if="filterMentionsEvaluations(e_c_micro.id).length == 1"
                    >
                      {{
                        $getVisibleNames(
                          "evaluations2.evaluation",
                          false,
                          "Evaluación"
                        )
                      }}:
                    </strong>
                  </div>
                  <li
                    v-for="(
                      filter_evaluations, index
                    ) in filterMentionsEvaluations(e_c_micro.id)"
                    :key="index + 'filter_mentions_evaluations'"
                  >
                    {{ filter_evaluations }}
                  </li>
                </div>
                <div v-if="filterMentionsActivities(e_c_micro.id).length > 0">
                  <div>
                    <strong
                      v-if="filterMentionsActivities(e_c_micro.id).length > 1"
                      >Actividades:</strong
                    >
                    <strong
                      v-if="filterMentionsActivities(e_c_micro.id).length == 1"
                      >Actividad:</strong
                    >
                  </div>
                  <li
                    v-for="(
                      filter_activities, index
                    ) in filterMentionsActivities(e_c_micro.id)"
                    :key="index + 'filter_mentions_activies'"
                  >
                    {{ filter_activities }}
                  </li>
                </div>
              </b-popover>
            </b-td>
          </b-tr>
          <template v-if="ECMicroByStudyUnits(study_unit.id).length == 0">
            <b-tr :key="`matter-${matter_id}-study_unit-${study_unit.id}`">
              <b-td>
                <div class="d-flex ml-1 text-danger">
                  <span> {{ study_unit.order }}.</span>
                  <SentenceContainer
                    :unique_id="`s_u-matter-${matter_id}-study_unit-${study_unit.id}`"
                    :Sentence="study_unit"
                    :boundedTextContainer="short_view"
                    :single_line="short_view"
                  ></SentenceContainer>
                </div>
              </b-td>
              <b-td class="text-center text-danger"> - </b-td>
              <b-td class="text-center text-danger">
                <b-badge variant="danger">0</b-badge>
              </b-td>
            </b-tr>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  // EvaluationCriteriaMicroInfoTable
  name: "ECMInfoTable",
  components: {
    SentenceContainer: () => import("@/components/reusable/SentenceContainer"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    matter_program_view: {
      type: Boolean,
      default: false,
    },
    ecm_no_mention: {
      type: Boolean,
      default: false,
    },
    short_view: {
      type: Boolean,
      default: false,
    },
    MattersEvaluations: {
      type: Array,
      default: function () {
        return [];
      },
      required: false,
    },
    matter_from_form: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      // matters_evaluations: [
      //   ...this.MattersEvaluations.sort(function (a, b) {
      //     if (a.order > b.order) return 1;
      //     if (a.order < b.order) return -1;
      //     return 0;
      //   }),
      // ],
    };
  },
  computed: {
    ...mapGetters({
      studyUnits: names.STUDY_UNITS,
      evaluationCriterias: names.EVALUATION_CRITERIAS,
      matterEvaluations: names.MATTER_EVALUATIONS,
      epm_evaluation_types: names.EPM_EVALUATION_TYPES,
    }),
    filterMatterEvaluations() {
      if (!this.matter_id) return [];
      return this.matterEvaluations
        .filter((x) => x.matter == this.matter_id)
        .sort((a, b) => a.order - b.order);
    },
    studyUnitsFilterByMatter() {
      return this.studyUnits
        .filter((x) => x.matter == this.matter_id)
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    ECMicroFilterByStudyUnit() {
      const study_unit_ids = this.studyUnitsFilterByMatter.map((x) => x.id);
      return this.evaluationCriterias
        .filter((x) => study_unit_ids.includes(x.study_unit))
        .sort(function (a, b) {
          if (a.order > b.order) return 1;
          if (a.order < b.order) return -1;
          return 0;
        });
    },
    excludeECMicro() {
      const evaluation_criterio_micro_ids = [
        ...new Set(
          this.filterMatterEvaluations
            .map((x) => x.evaluation_criterias_micro)
            .flat()
        ),
      ];
      return this.ECMicroFilterByStudyUnit.filter(
        (x) => !evaluation_criterio_micro_ids.includes(x.id)
      );
    },
    studyUnitsFilter() {
      if (!this.ecm_no_mention) return this.studyUnitsFilterByMatter;
      else {
        const study_units_ids = [
          ...new Set(this.excludeECMicro.map((x) => x.study_unit)),
        ];
        return this.studyUnitsFilterByMatter.filter((x) =>
          study_units_ids.includes(x.id)
        );
      }
    },
    ECMicroFilter() {
      if (!this.ecm_no_mention) return this.ECMicroFilterByStudyUnit;
      else return this.excludeECMicro;
    },
  },
  methods: {
    ECMicroByStudyUnits(study_unit_id) {
      return this.ECMicroFilter.filter((x) => x.study_unit == study_unit_id);
    },
    // fetchMattersEvaluation() {
    //   if (
    //     this.matter_id &&
    //     this.matter_program_view &&
    //     this.matter_from_form == false
    //   )
    //     this.$restful
    //       .Get(`/teaching/matter-evaluation/?matter=${this.matter_id}`)
    //       .then((response) => {
    //         this.matters_evaluations = response.sort(function (a, b) {
    //           if (a.order > b.order) return 1;
    //           if (a.order < b.order) return -1;
    //           return 0;
    //         });
    //       });
    // },
    getListByECMicro(e_c_micro_id) {
      if (this.matter_program_view) {
        return this.filterMatterEvaluations
          .filter((x) => x.evaluation_criterias_micro.includes(e_c_micro_id))
          .sort((a, b) => a.name.localeCompare(b.name));
      }
      return [];
    },
    nameTypes(id_type) {
      let name = this.epm_evaluation_types.find((x) => x.id == id_type);
      return name ? name.value : "";
    },
    filterMentionsEvaluations(e_c_micro_id) {
      return this.getListByECMicro(e_c_micro_id)
        .filter((x) => x.type != 4)
        .map((y) => y.name + " (" + this.nameTypes(y.type) + ")")
        .sort((a, b) => a.localeCompare(b));
    },
    filterMentionsActivities(e_c_micro_id) {
      return this.getListByECMicro(e_c_micro_id)
        .filter((x) => x.type == 4)
        .map((y) => y.name)
        .sort((a, b) => a.localeCompare(b));
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_EPM_EVALUATION_TYPES);
    // this.fetchMattersEvaluation();
  },
};
</script>

<style scoped>
table th {
  vertical-align: middle;
  background-color: var(--primary-color);
  color: white;
  font-size: var(--primary-font-size);
  padding: 5px 5px;
}
.evaluation-header {
  color: black !important;
  background: var(--primary-color) !important;
  border: 1px solid #000 !important;
}
table td {
  border: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: var(--secondary-font-size);
  padding: 2px 2px;
}
.badge {
  margin: 0.2rem;
  font-size: 100% !important;
  margin-left: 0.5rem;
  vertical-align: middle;
}
@media print {
  .badge {
    border: none;
  }
}
</style>

