<template>
  <div
    v-if="!evaluator_view && !evaluatee_view && !preview_evaluation"
    class="noprint"
  >
    <!-- Test Correcto -->
    <div>
      <div
        v-if="
          test.is_valid == true &&
          test.evaluative_agent != null &&
          test.evaluation_situation != null
        "
        :class="{
          'alert alert-success': test.manual_is_valid == true,
          'alert alert-info': test.manual_is_valid == false,
        }"
      >
        <div class="d-flex justify-content-center">
          <div
            v-if="test.manual_is_valid == false"
            class="align-content-center"
          >
            <b-icon-unlock-fill class="icon-lock-margin"></b-icon-unlock-fill>
            <strong>
              {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
            </strong>
            listo para cerrar.
            <!-- Tooltip para Secciones -->
            <InfoTooltip
              v-if="evaluation && evaluation.scope == 1"
              :tooltip_text="`Esta ${$getVisibleNames(
                'evaluations2.test',
                false,
                'Instrumento'
              )} es válida.
              Está lista para ser publicada.`"
            ></InfoTooltip>
            <!-- Tooltip para los Administrativos -->
            <InfoTooltip
              v-if="evaluation && evaluation.scope == 3"
              :tooltip_text="`Al cerrar la ${$getVisibleNames(
                'evaluations2.test',
                false,
                'Instrumento'
              )} se podrá cerrar la Asignatura, copiar la ${$getVisibleNames(
                'evaluations2.test',
                false,
                'Instrumento'
              )} y crear nuevas secciones.`"
            ></InfoTooltip>
          </div>
          <div v-if="test.manual_is_valid == true" class="align-content-center">
            <b-icon-lock-fill class="icon-lock-margin"></b-icon-lock-fill>
            <strong>
              {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
            </strong>
            cerrado.
          </div>
          <b-button
            v-if="allows_crud && test.manual_is_valid == false"
            variant="info"
            size="sm"
            class="ml-3"
            @click="changeStateManualIsValid()"
            :disabled="disabled_button"
          >
            Cerrar
            {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
          </b-button>
          <b-button
            v-if="allows_crud && test.manual_is_valid == true"
            variant="success"
            size="sm"
            class="ml-3"
            @click="changeStateManualIsValid()"
            v-b-tooltip.v-secondary.top.noninteractive.hover="
              `Si desea volver a abrir la ${$getVisibleNames(
                'evaluations2.test',
                false,
                'Instrumento'
              )} presione este botón`
            "
          >
            Abrir
            {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
          </b-button>
        </div>
      </div>
    </div>
    <!-- Test  con Fallos -->
    <div
      v-if="
        test.is_valid[1] ||
        test.evaluative_agent == null ||
        test.evaluation_situation == null
      "
      class="alert alert-danger"
    >
      <div class="d-flex justify-content-center">
        <div class="align-content-center">
          <strong>Problemas encontrados:</strong>
        </div>
        <b-button
          variant="danger"
          size="sm"
          class="ml-2"
          @click="
            $bvModal.show(
              `modal-close-instrument-${test.id}-${container_instrument_id}`
            )
          "
        >
          Información de errores
        </b-button>
      </div>
    </div>

    <!-- Modal Cerrar instrumento -->
    <b-modal
      :id="`modal-close-instrument-${test.id}-${container_instrument_id}`"
      :title="`Información ${$getVisibleNames(
        'evaluations2.test',
        false,
        'Instrumento'
      )}`"
      hide-footer
      size="xl"
    >
      <InfoTestTable :test="test"></InfoTestTable>
    </b-modal>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { toast } from "../../../utils/utils";
import { mapGetters } from "vuex";

export default {
  name: "InfoTest",
  components: {
    InfoTestTable: () =>
      import("@/components/new-evaluations/Evaluation/InfoTestTable"),
    InfoTooltip: () => import("@/components/reusable/InfoTooltip"),
  },
  props: {
    test: {
      type: Object,
      required: true,
    },
    evaluation_id: {
      type: Number,
      required: true,
    },
    container_instrument_id: {
      type: String,
    },
    evaluatee_view: {
      type: Boolean,
    },
    evaluator_view: {
      type: Boolean,
    },
    preview_evaluation: {
      type: Boolean,
    },
    allows_crud: {
      type: Boolean,
    },
  },
  data() {
    return {
      disabled_button: false,
    };
  },
  computed: {
    ...mapGetters({
      // rubricInstances: names.NEW_RUBRIC_CRITERIAS,
      // evaluation_criteria_micros: names.EVALUATION_CRITERIAS,
      evaluations: names.NEW_EVALUATIONS,
    }),
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
  },
  methods: {
    changeStateManualIsValid() {
      if (this.evaluation && this.evaluation.scope == 1) {
        if (this.test.manual_is_valid == false) {
          this.$swal({
            title: `<p>Cerrar ${this.$getVisibleNames(
              "evaluations2.test",
              false,
              "Instrumento"
            )}</p>`,
            text: `Esto provocará que la ${this.$getVisibleNames(
              "evaluations2.test",
              false,
              "Instrumento"
            )} se encuentre disponible para los evaluados`,
            type: "warning",
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              this.disabled_button = true;
              this.$store
                .dispatch(names.PATCH_NEW_TEST, {
                  new_test_id: this.test.id,
                  item: {
                    manual_is_valid: !this.test.manual_is_valid,
                    visible_by_evaluatees: !this.test.visible_by_evaluatees
                      ? !this.test.visible_by_evaluatees
                      : this.test.visible_by_evaluatees,
                  },
                })
                .then(() => {
                  toast(
                    "Se cerró exitosamente la " +
                      this.$getVisibleNames(
                        "evaluations2.test",
                        false,
                        "Instrumento"
                      )
                  );
                  this.disabled_button = false;
                });
            }
          });
        } else if (this.test.manual_is_valid == true) {
          this.$swal({
            title: `<p>Abrir ${this.$getVisibleNames(
              "evaluations2.test",
              false,
              "Instrumento"
            )}</p>`,
            text: `Esto provocará que la ${this.$getVisibleNames(
              "evaluations2.test",
              false,
              "Instrumento"
            )} ya no esté disponible para los evaluados`,
            type: "warning",
            showCancelButton: true,
          }).then((result) => {
            if (result.value) {
              this.disabled_button = true;
              this.$store
                .dispatch(names.PATCH_NEW_TEST, {
                  new_test_id: this.test.id,
                  item: {
                    manual_is_valid: !this.test.manual_is_valid,
                    visible_by_evaluatees: !this.test.visible_by_evaluatees
                      ? this.test.visible_by_evaluatees
                      : !this.test.visible_by_evaluatees,
                  },
                })
                .then(() => {
                  toast(
                    "Se abrió exitosamente la " +
                      this.$getVisibleNames(
                        "evaluations2.test",
                        false,
                        "Instrumento"
                      )
                  );
                  this.disabled_button = false;
                });
            }
          });
        }
      } else {
        this.disabled_button = true;
        this.$store
          .dispatch(names.PATCH_NEW_TEST, {
            new_test_id: this.test.id,
            item: {
              manual_is_valid: !this.test.manual_is_valid,
            },
          })
          .then((response) => {
            if (response.manual_is_valid) {
              toast(
                "Se abrió exitosamente la " +
                  this.$getVisibleNames(
                    "evaluations2.test",
                    false,
                    "Instrumento"
                  )
              );
            } else {
              toast(
                "Se cerró exitosamente la " +
                  this.$getVisibleNames(
                    "evaluations2.test",
                    false,
                    "Instrumento"
                  )
              );
            }
            this.disabled_button = false;
          });
      }
    },
  },
  created() {},
};
</script>

<style scoped>
.icon-lock-margin {
  margin-bottom: 2px !important;
}
</style>