<template>
  <div>
    <h4 class="mt-4">
      {{
        $getVisibleNames(
          "manual.matter_program",
          false,
          "Programa de la Asignatura"
        ).toUpperCase()
      }}
      <button-edit
        v-can="'teaching.change_matter'"
        v-if="oldMatter && allows_crud"
        @click="$bvModal.show(`modal-edit-matter-${oldMatter.id}`)"
        v-b-tooltip.v-secondary.noninteractive="
          `Editar ${$getVisibleNames(
            'mesh.egressprofilematter',
            false,
            'Asignatura'
          )}`
        "
      ></button-edit>
    </h4>
    <b-modal
      v-if="oldMatter"
      :id="`modal-edit-matter-${oldMatter.id}`"
      hide-footer
      :title="`Editar ${$getVisibleNames(
        'teaching.matter',
        false,
        'Asignatura Base'
      )}`"
      size="lg"
    >
      <OldMatterForm
        :OldMatter="oldMatter"
        @updated="$bvModal.hide(`modal-edit-matter-${oldMatter.id}`)"
        :show_delete_button="false"
      >
      </OldMatterForm>
    </b-modal>
    <NewMatterPresentation
      v-if="oldMatter"
      :oldMatter="oldMatter"
    ></NewMatterPresentation>
    <DescriptorsContainer
      :ref_component="'Matter'"
      :Title_text="'Programas'"
      :Text_info_tooltip="'Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros Programas.'"
      :Title="egressProfileMatterDescriptor"
      :Descriptors="matterMiddleDescriptors"
      :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
      :Order="1"
      :VisibleOrder="false"
      :ProgramView="true"
      @updateOrder="updateOrderMatterMiddleDescriptors"
      @updateState="updateVisibleMatterMiddleDescriptors"
      @createDescription="createMatterMiddleDescriptors"
      @updateDescription="updateMatterMiddleDescriptors"
      @createTitle="createProfileMatterDescriptors"
      @updateTitle="updateProfileMatterDescriptors"
      @deleteDescriptors="deleteMatterMiddleDescriptors"
      @deleteTitle="deleteTitle"
    >
    </DescriptorsContainer>
    <EvaluationCriteriaMacroComponent
      v-if="oldMatter"
      :oldMatter="oldMatter"
      :allows_crud="allows_crud"
    ></EvaluationCriteriaMacroComponent>
    <template v-if="institution && institution.internal_use_id == 'ciisa_uss'">
      <b-button
        v-if="allows_crud && oldMatter && !oldMatter.is_closed"
        size="sm"
        class="secondary-button my-2"
        @click="$bvModal.show(`add-unit-competence-modal`)"
        ><b-icon-plus></b-icon-plus>Agregar
        {{
          $getVisibleNames(
            "teaching.tempcompetenceunit",
            false,
            "Unidad de competencia"
          )
        }}</b-button
      >
      <b-modal
        :id="`add-unit-competence-modal`"
        hide-footer
        :title="`Agregar ${$getVisibleNames(
          'teaching.tempcompetenceunit',
          false,
          'Unidad de competencia'
        )}`"
        size="lg"
      >
        <CompetenceUnitForm
          v-if="oldMatter"
          :matter_id="oldMatter.id"
          @created="$bvModal.hide(`add-unit-competence-modal`)"
          @close_modal="$bvModal.hide(`add-unit-competence-modal`)"
        ></CompetenceUnitForm>
      </b-modal>
      <template v-if="oldMatter && temp_competence_units.length > 0">
        <CompetenceUnits
          v-for="temp_competence_unit in temp_competence_units"
          :key="`unit-competence-${temp_competence_unit.id}`"
          :oldMatter="oldMatter"
          :tempCompetenceUnit="temp_competence_unit"
          :allows_crud="allows_crud"
        ></CompetenceUnits>
      </template>
    </template>
    <CompetenceUnits
      v-if="oldMatter"
      :oldMatter="oldMatter"
      :tempCompetenceUnit="null"
      :allows_crud="allows_crud"
    ></CompetenceUnits>
    <!-- <div v-if="studyUnitsList.length > 0 && oldMatter">
      <UnitSection
        v-for="study_unit in studyUnitsList"
        :study_unit_id="study_unit.id"
        :matter_id="oldMatter.id"
        :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
        :key="`unit-section-${study_unit.id}`"
        class="my-2"
      ></UnitSection>
    </div>
    <b-button
      v-if="allows_crud"
      size="sm"
      class="secondary-button my-2"
      @click="$bvModal.show(`add-study-unit-modal`)"
      ><b-icon-plus></b-icon-plus>Agregar
      {{ $getVisibleNames("teaching.ramicro", true, "RA Micro") }}</b-button
    > -->
    <div
      v-if="
        (institution && institution.internal_use_id == 'duoc_uc') ||
        $debug_change_duoc
      "
    >
      <!-- 'LearningExperience' + key_learning_experience + '-' -->
      <!-- @change_matter_evaluations="() => key_matter_program_evaluation++" -->
      <LearningExperience
        v-if="oldMatter"
        :key="+oldMatter.id"
        :matter_id="oldMatter.id"
        :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
      >
      </LearningExperience>
    </div>
    <!-- @update_matter_evaluations="() => key_learning_experience++" -->
    <!-- :key="'MatterProgramEvaluation' + key_matter_program_evaluation" -->
    <MatterProgramEvaluation
      v-if="oldMatter"
      :matter_id="oldMatter.id"
      :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
      @created_support_resource="createdSupportResource"
    ></MatterProgramEvaluation>
    <TeachingSupportResourcesComponent
      v-if="oldMatter"
      :key="support_resource_key"
      :oldMatter="oldMatter"
      :allows_crud="allows_crud"
      :supportResources="support_resources"
    ></TeachingSupportResourcesComponent>
    <BibliographicResourcesComponent
      v-if="oldMatter"
      :oldMatter="oldMatter"
      :allows_crud="allows_crud"
    ></BibliographicResourcesComponent>
    <ResourcesLearningEnvComponent
      v-if="oldMatter"
      :oldMatter="oldMatter"
      :allows_crud="allows_crud"
    ></ResourcesLearningEnvComponent>
    <TeachingProfileComponent
      v-if="oldMatter"
      :oldMatter="oldMatter"
      :allows_crud="allows_crud && !oldMatter.teacher_profile_locked_view"
    ></TeachingProfileComponent>
    <AssistantProfileComponent
      v-if="oldMatter"
      :oldMatter="oldMatter"
      :allows_crud="allows_crud && !oldMatter.assistant_profile_locked_view"
    ></AssistantProfileComponent>
    <!-- modal -->
    <!-- <b-modal
      :id="`add-study-unit-modal`"
      hide-footer
      :title="`Agregar ${$getVisibleNames(
        'teaching.ramicro',
        true,
        'RA Micro'
      )}`"
      size="lg"
    >
      <StudyUnitForm
        v-if="oldMatter"
        :matter_id="oldMatter.id"
        @created="$bvModal.hide('add-study-unit-modal')"
        @close_modal="$bvModal.hide('add-study-unit-modal')"
      ></StudyUnitForm>
    </b-modal> -->
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "OldMatterProgram",
  components: {
    NewMatterPresentation: () =>
      import("./Components/NewMatterPresentation.vue"),
    OldMatterForm: () => import("@/components/mesh/Matrix2/OldMatterForm"),
    DescriptorsContainer: () =>
      import("@/components/reusable/DescriptorsContainer"),
    EvaluationCriteriaMacroComponent: () =>
      import("./Components/EvaluationCriteriaMacroComponent"),
    // UnitSection: () => import("./Components/UnitSection"),
    // StudyUnitForm: () => import("@/components/reusable/StudyUnitForm"),
    MatterProgramEvaluation: () =>
      import("./MatterProgramEvaluation/MatterProgramEvaluation.vue"),
    BibliographicResourcesComponent: () =>
      import("./Components/BibliographicResourcesComponent.vue"),
    ResourcesLearningEnvComponent: () =>
      import("./Components/ResourcesLearningEnvComponent.vue"),
    TeachingSupportResourcesComponent: () =>
      import("./Components/TeachingSupportResourcesComponent"),
    LearningExperience: () => import("./Components/LearningExperience.vue"),
    TeachingProfileComponent: () =>
      import("./Components/TeachingProfileComponent.vue"),
    AssistantProfileComponent: () =>
      import("./Components/AssistantProfileComponent.vue"),
    CompetenceUnits: () => import("./Components/CompetenceUnits"),
    CompetenceUnitForm: () => import("./Components/CompetenceUnitForm"),
  },
  props: {
    matter_id: {
      type: Number,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      support_resources: [],
      support_resource_key: 0,
      // key_matter_program_evaluation: 0,
      // key_learning_experience: 0,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      user: "getUser",
      matters: names.MATTERS,
      egress_profile_matter_descriptors:
        names.EGRESS_PROFILE_MATTER_DESCRIPTORS,
      m_middle_descriptors: names.M_MIDDLE_DESCRIPTORS,
      studyUnits: names.STUDY_UNITS,
      competence_units: names.COMPETENCE_UNITS,
    }),
    oldMatter() {
      if (!this.matter_id) return null;
      return this.matters.find((x) => x.id == this.matter_id);
    },
    temp_competence_units() {
      if (!this.oldMatter) return [];
      return this.competence_units.filter((x) => x.matter == this.oldMatter.id);
    },
    matterMiddleDescriptors() {
      if (!this.matter_id) return [];
      return this.m_middle_descriptors.filter(
        (x) => x.matter == this.matter_id
      );
    },
    egressProfileMatterDescriptor() {
      const matter_middle = this.matterMiddleDescriptors.map(
        (x) => x.descriptor
      );
      return this.egress_profile_matter_descriptors.filter(
        (x) => x.is_default || matter_middle.includes(x.id)
      );
    },
    studyUnitsList() {
      if (!this.matter_id) return [];
      return this.studyUnits.filter((x) => x.matter == this.matter_id);
    },
  },
  methods: {
    createdSupportResource(response) {
      this.support_resources.push(response);
      this.support_resource_key += 1;
    },
    fetchSupportResource(matter_id) {
      this.$restful
        .Get(`/teaching/support-resource/?matter=${matter_id}`)
        .then((response) => {
          this.support_resources = response;
          this.support_resource_key += 1;
        });
    },
    createMatterMiddleDescriptors(text, object) {
      if (!isNaN(object.id) && this.matter) {
        let item = {
          matter: this.matter.matter,
          descriptor: object.id,
          description: text,
          order: 1 + this.matterMiddleDescriptors.length,
        };
        this.$store.dispatch(names.POST_M_MIDDLE_DESCRIPTORS, item);
      }
    },
    updateMatterMiddleDescriptors(object, text) {
      if (!isNaN(object.id)) {
        let payload = {
          id: object.id,
          item: {
            description: text,
          },
        };
        this.$store.dispatch(names.PATCH_M_MIDDLE_DESCRIPTORS, payload);
      }
    },
    updateOrderMatterMiddleDescriptors(item) {
      let order = 1;
      item.forEach((element) => {
        if (element.order != order) {
          element.order = order;
          if (
            !isNaN(element.id) &&
            this.m_middle_descriptors.findIndex((x) => x.id == element.id) != -1
          ) {
            let payload = {
              id: element.id,
              item: {
                order: element.order,
              },
            };
            this.$store.dispatch(names.PATCH_M_MIDDLE_DESCRIPTORS, payload);
          }
        }
        order += 1;
      });
    },
    updateVisibleMatterMiddleDescriptors(item) {
      if (!isNaN(item.id)) {
        let payload = {
          id: item.id,
          item: {
            is_visible: !item.is_visible,
          },
        };
        this.$store.dispatch(names.PATCH_M_MIDDLE_DESCRIPTORS, payload);
      }
    },
    createProfileMatterDescriptors(title) {
      if (isNaN(title.id)) {
        this.$store
          .dispatch(names.POST_EGRESS_PROFILE_MATTER_DESCRIPTORS, title)
          .then((response) => {
            this.createMatterMiddleDescriptors("", response);
          });
      }
    },
    updateProfileMatterDescriptors(title) {
      if (!isNaN(title.id)) {
        let payload = {
          id: title.id,
          item: {
            title: title.title,
            is_default: title.is_default,
          },
        };
        this.$store.dispatch(
          names.PATCH_EGRESS_PROFILE_MATTER_DESCRIPTORS,
          payload
        );
      }
    },
    deleteTitle(object) {
      this.$store
        .dispatch(names.FETCH_M_MIDDLE_DESCRIPTORS, {
          descriptor_id: object.id,
        })
        .then((response1) => {
          this.$store
            .dispatch(names.FETCH_EPM_MIDDLE_DESCRIPTORS, {
              descriptor_id: object.id,
            })
            .then((response2) => {
              const response = response1.concat(response2);
              this.$swal({
                title:
                  "¿Está seguro de que desea eliminar el título descriptivo?",
                text: `Esta acción no se podrá revertir y se eliminarán los objetos referenciados en la parte inferior.`,
                type: "warning",
                footer: `<ul class="w-100" style="max-height: 300px; overflow-y: auto;">
            <li>Título: ${object.title}</li>
            ${response
              .map(
                (item) =>
                  `<li>contenido: ${this.$htmlToPlainText(
                    item.description
                  )}</li>`
              )
              .join("")}
              </ul>`,
                showCancelButton: true,
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.value) {
                  this.$store.dispatch(
                    names.DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS,
                    object.id
                  );
                }
              });
            });
        });
    },
    deleteMatterMiddleDescriptors(matterMiddleDescriptor) {
      this.$swal({
        title:
          "¿Está seguro de que desea eliminar el contenido del título descriptivo?",
        text: `"Esta acción no se podrá revertir y podría afectar en otras ${this.$getVisibleNames(
          "mesh.egressprofilematter",
          true,
          "Asignaturas"
        )}."`,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_M_MIDDLE_DESCRIPTORS,
            matterMiddleDescriptor.id
          );
        }
      });
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_M_MIDDLE_DESCRIPTORS, {
      matter_id: this.matter_id,
    });
    this.$store.dispatch(
      names.FETCH_EVALUATION_CRITERIAS_MACROS,
      this.matter_id
    );
    this.$store.dispatch(names.FETCH_RA_MACROS, {
      matter_id: this.matter_id,
    });
    this.$store.dispatch(names.FETCH_EGRESS_PROFILE_MATTER_DESCRIPTORS, {
      matter_id: this.matter_id,
    });
    if (this.institution && this.institution.internal_use_id == "ciisa_uss")
      this.$store.dispatch(names.FETCH_COMPETENCE_UNITS, {
        matter__id: this.matter_id,
      });
    this.$store.dispatch(names.FETCH_STUDY_UNITS, this.matter_id);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS);
    this.$store.dispatch(names.FETCH_TIME_ALLOCATIONS);
    this.$store.dispatch(names.FETCH_MATTER_TIME_ALLOCATIONS, {
      matter_id: this.matter_id,
    });
    this.fetchSupportResource(this.matter_id);
    this.$store.dispatch(names.FETCH_SUPPORT_RESOURCE_TYPES);
    this.$store.dispatch(names.FETCH_TAXONOMIES);
  },
};
</script>

<style scoped>
</style>
