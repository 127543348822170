<template>
  <div>
    <div
      class="table-container"
      :class="{ 'table-container-odd': !isPairNumberTableHeader }"
    >
      <!-- Titulo -->
      <div class="table-cell d-flex">
        <div class="primary-cell">
          {{
            $getVisibleNames(
              "evaluations2.test",
              false,
              "Instrumento"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell title-instrument">
          <span v-if="test"> {{ test.title }} </span>
        </div>
      </div>
      <!-- Tipo -->
      <div
        v-if="
          !(
            (institution && institution.internal_use_id == 'duoc_uc') ||
            $debug_change_duoc
          )
        "
        class="table-cell d-flex"
      >
        <div class="primary-cell">
          {{
            $getVisibleNames(
              "manual.test.agente_evaluativo",
              false,
              "Tipo"
            ).toUpperCase()
          }}
        </div>
        <div class="secundary-cell">
          <span v-if="typeDefault">{{ typeDefault.name }}</span>
        </div>
      </div>
      <!-- Fecha de Creación -->
      <div class="table-cell d-flex">
        <div class="primary-cell">PONDERACIÓN</div>
        <div class="secundary-cell">
          <span v-if="test"> {{ test.weighing }}% </span>
        </div>
      </div>
      <!-- Max_Score -->
      <div class="table-cell d-flex">
        <div class="primary-cell">PUNTAJE</div>
        <div class="secundary-cell">
          <span v-if="test"> {{ test.max_score }} </span>
        </div>
      </div>
      <!-- Puntaje del Obtenido -->
      <div
        v-if="
          evaluateeTest &&
          evaluatee_view &&
          finished_test &&
          evaluateeTest.is_published
        "
        class="table-cell d-flex"
      >
        <div class="primary-cell">PUNTAJE DEL OBTENIDO</div>
        <div class="secundary-cell">
          <span>
            {{
              evaluateeTest.final_score != null
                ? evaluateeTest.final_score
                : evaluateeTest.manual_score != null
                ? evaluateeTest.manual_score
                : evaluateeTest.automated_score != null
                ? evaluateeTest.automated_score
                : institution.min_grade | Round
            }}
          </span>
        </div>
      </div>
      <!-- Creador -->
      <div class="table-cell d-flex" v-if="evaluation && evaluation.scope == 3">
        <div class="primary-cell">CREADO POR</div>
        <div class="secundary-cell">
          <span v-if="test"> {{ getUser(test.created_by) }} </span>
        </div>
      </div>
      <!-- Fecha de Creación -->
      <div class="table-cell d-flex" v-if="evaluation && evaluation.scope == 3">
        <div class="primary-cell">FECHA DE CREACIÓN</div>
        <div class="secundary-cell">
          <span v-if="test"> {{ test.creation_date | FormatToDateTime }} </span>
        </div>
      </div>
      <!-- Fecha de Inicio -->
      <!-- <div class="table-cell d-flex">
        <div class="primary-cell">FECHA DE INICIO</div>
        <div class="secundary-cell">
          <span v-if="test">{{ test.start_time | FormatToDateTime }}</span>
        </div>
      </div> -->

      <!-- Fila 3 -->
      <!-- Fecha de Entrega -->
      <!-- <div class="table-cell d-flex">
        <div class="primary-cell">FECHA DE ENTREGA</div>
        <div class="secundary-cell">
          <span v-if="test">{{ test.end_time | FormatToDateTime }}</span>
        </div>
      </div> -->
      <!-- Fecha de Publicación -->
      <!-- <div class="table-cell d-flex">
        <div class="primary-cell">FECHA DE PUBLICACIÓN</div>
        <div class="secundary-cell">
          <span v-if="test">{{ test.publish_date | FormatToDateTime }}</span>
        </div>
      </div> -->
    </div>
    <!-- Retroalimentación -->
    <div
      v-if="evaluateeTest && evaluateeTest.feedback"
      class="table-cell-description"
    >
      <div class="primary-cell-description">RETROALIMENTACIÓN</div>
      <div class="secundary-cell-description">
        <div class="rich-text-content" v-html="evaluateeTest.feedback"></div>
      </div>
    </div>
    <!-- Descripción -->
    <div
      v-if="test && institution && institution.internal_use_id != 'duoc_uc'"
      class="table-cell-description"
      :class="{ 'print-rubric': isPrintingRubrica }"
    >
      <div class="primary-cell-description">DESCRIPCIÓN</div>
      <div class="secundary-cell-description">
        <div class="rich-text-content" v-html="test.description"></div>
      </div>
    </div>
    <!-- Archivos Adjuntos Profesor-->
    <div
      v-if="
        ((test && test.files_for_professor.length > 0) || allows_crud) &&
        user &&
        !preview_evaluation &&
        !user.groups.includes(7)
      "
      class="table-cell-description"
      :class="{
        'print-hide': isPrintingRubrica || isPrintingInstrumento,
      }"
    >
      <div class="primary-cell-description">
        ANEXOS PARA EL DOCENTE
        <button-add
          v-if="allows_crud"
          class="add-button"
          :title="`Agregar Archivo`"
          v-b-tooltip.v-secondary.top.noninteractive="`Agregar Archivo`"
          @click="
            $bvModal.show(
              `modal-select-files_for_professor-${instrument_id}-${container_instrument_id}`
            )
          "
          size="sm"
        >
        </button-add>
      </div>
      <div class="secundary-cell-description table-left">
        <div>
          <FileContainer
            :Files="test.files_for_professor"
            :allows_crud="allows_crud"
            @deleteFile="slotDeleteFileProfessor"
          ></FileContainer>
        </div>
      </div>
    </div>
    <!-- Archivos Adjuntos Estudiante-->
    <div
      v-if="(test && test.files.length > 0) || allows_crud"
      class="table-cell-description"
      :class="{
        'print-hide': isPrintingRubrica || isPrintingInstrumento,
      }"
    >
      <div class="primary-cell-description">
        ANEXOS PARA EL ESTUDIANTE
        <button-add
          v-if="allows_crud"
          class="add-button"
          :title="`Agregar Archivo`"
          v-b-tooltip.v-secondary.top.noninteractive="`Agregar Archivo`"
          @click="
            $bvModal.show(
              `modal-select-file-${instrument_id}-${container_instrument_id}`
            )
          "
          size="sm"
        >
        </button-add>
      </div>
      <div class="secundary-cell-description table-left">
        <div>
          <FileContainer
            :Files="test.files"
            :allows_crud="allows_crud"
            @deleteFile="slotDeleteFile"
          ></FileContainer>
        </div>
      </div>
    </div>
    <b-modal
      :id="`modal-select-file-${instrument_id}-${container_instrument_id}`"
      title="Seleccionar Archivos"
      hide-footer
    >
      <FileSelector @created="slotCreatedFile"></FileSelector>
    </b-modal>
    <b-modal
      :id="`modal-select-files_for_professor-${instrument_id}-${container_instrument_id}`"
      title="Seleccionar Archivos"
      hide-footer
    >
      <FileSelector @created="slotCreatedFileProfessor"></FileSelector>
    </b-modal>
    <!-- Puntaje Flotante -->
    <div
      v-if="!evaluatee_view && !evaluator_view"
      class="fixed-row"
      :class="{ visible: showFixedRow }"
    >
      <div class="column column1">
        Puntaje del
        {{ $getVisibleNames("evaluations2.test", false, "Instrumento") }}
      </div>
      <div class="column column2">{{ test.max_score }}</div>
      <div class="column column1" v-if="questions.length > 0">
        Puntaje actual de las preguntas
      </div>
      <div
        class="column column2"
        v-if="questions.length > 0"
        :class="{
          'score-input-warning': questionsMaxScore > test.max_score,
          'score-input-warning-minor': questionsMaxScore < test.max_score,
        }"
      >
        <div class="subcolumn">
          <div
            class="subcolumn-item"
            v-b-tooltip.bottom.noninteractive.v-danger="
              `${
                questionsMaxScore > test.max_score
                  ? `El puntaje de las preguntas es mayor que el puntaje de la ${$getVisibleNames(
                      'evaluations2.test',
                      true,
                      'Instrumento'
                    )}`
                  : questionsMaxScore < test.max_score
                  ? `El puntaje de las preguntas es menor que el puntaje de la ${$getVisibleNames(
                      'evaluations2.test',
                      true,
                      'Instrumento'
                    )}`
                  : ''
              }`
            "
          >
            {{ questionsMaxScore }}
            <b-icon
              v-if="
                questionsMaxScore < test.max_score ||
                questionsMaxScore > test.max_score
              "
              icon="exclamation-circle"
              class="ml-2"
            ></b-icon>
          </div>
          <div>
            <b-button
              v-if="
                allows_crud &&
                institution &&
                institution.internal_use_id != 'duoc_uc'
              "
              class="icon-container"
              :class="{
                'icon-visibility':
                  questionsMaxScore > test.max_score ||
                  questionsMaxScore < test.max_score,
              }"
              size="sm"
              variant="danger"
              v-b-tooltip.bottom.noninteractive.v-danger="
                `${
                  questionsMaxScore > test.max_score ||
                  questionsMaxScore < test.max_score
                    ? `Igualar Puntaje total con respecto al Puntaje actual de las preguntas`
                    : ''
                }`
              "
              @click="upgradeScoreInstrument"
            >
              <b-icon icon="clipboard-check"></b-icon>
            </b-button>
          </div>
        </div>
      </div>
      <div class="column column2 columnView">
        <b-button
          variant="primary"
          size="sm"
          class="btn-preview-web"
          @click="ChangePreviewEvaluation"
          v-b-tooltip.v-secondary.bottom.noninteractive.hover="
            `${
              !preview_evaluation
                ? 'Revisar punto de vista del Estudiante'
                : 'Volver al punto de vista del Profesor'
            }`
          "
          >{{
            !preview_evaluation ? "Ver como Estudiante" : "Ver como Profesor"
          }}
        </b-button>
        <b-button
          variant="primary"
          size="sm"
          class="btn-preview-mobile"
          @click="ChangePreviewEvaluation"
        >
          <b-icon
            v-if="!preview_evaluation"
            class="icon-preview-on"
            icon="eye"
          ></b-icon>
          <b-icon v-else class="icon-preview-off" icon="eye-slash"></b-icon>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
import { toast } from "../../../utils/utils";

export default {
  name: "HeaderInstrument",
  components: {
    FileContainer: () => import("@/components/reusable/FileContainer"),
    FileSelector: () => import("@/components/reusable/FileSelector"),
    // Timer: () => import("@/components/reusable/Timer"),
  },
  props: {
    instrument_id: {
      required: true,
      type: Number,
    },
    evaluation_id: {
      required: false,
      type: Number,
    },
    allows_crud: {
      type: Boolean,
      required: true,
    },
    preview_evaluation: {
      type: Boolean,
      required: true,
    },
    isPrintingRubrica: {
      type: Boolean,
      required: true,
    },
    container_instrument_id: {
      type: String,
    },
    isPrintingInstrumento: {
      type: Boolean,
      required: true,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    user_id: {
      type: Number,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showFixedRow: false,
      //remainingTime: 3600,
      //timer: null,
    };
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      user: "getUser",
      tests: names.NEW_TESTS,
      evaluatee_tests: names.EVALUATEE_TESTS,
      typesDefault: names.NEW_TEST_TYPES,
      redaction_questions: names.NEW_REDACTION_QUESTIONS,
      multiple_choice_questions: names.NEW_MULTIPLE_CHOICE_QUESTIONS,
      filling_questions: names.NEW_FILLING_QUESTIONS,
      matching_questions: names.NEW_MATCHING_QUESTIONS,
      users: names.USERS,
      evaluations: names.NEW_EVALUATIONS,
      // rubrics: names.NEW_RUBRIC_INSTRUMENTS,
      // rubricAchievements: names.NEW_RUBRIC_ACHIEVEMENTS,
    }),
    test() {
      return this.tests.find((x) => x.id == this.instrument_id);
    },
    evaluation() {
      return this.evaluations.find((x) => x.id == this.evaluation_id);
    },
    // rubric() {
    //   return this.rubrics.find((x) => x.test == this.instrument_id);
    // },
    // rubricAchievementList() {
    //   if (!this.rubric) return [];
    //   return this.rubricAchievements
    //     .filter((x) => x.rubric == this.rubric.id)
    //     .sort(function (a, b) {
    //       return a.score > b.score ? -1 : 1;
    //     });
    // },
    // rubricMaxAchievementScore() {
    //   if (!this.rubric) return [];
    //   const achievementList = this.rubricAchievements
    //     .filter((x) => x.rubric == this.rubric.id)
    //     .map((x) => x.score);
    //   if (achievementList.length === 0) {
    //     return 0;
    //   }
    //   return Math.max(...achievementList);
    // },
    // rubricMaxAchievementTotalScore() {
    //   const maxAchievementScore = this.rubricMaxAchievementScore();
    //   const rubricCriteriaCount = this.rubric.rubric_criterias.length;

    //   return maxAchievementScore * rubricCriteriaCount;
    // },
    numberTableHeader() {
      let column = 3; // Titulo, Max_score, Ponderación
      if (
        !(
          (this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc
        )
      )
        column += 1; // Tipo
      if (
        this.evaluateeTest &&
        this.evaluatee_view &&
        this.finished_test &&
        this.evaluateeTest.is_published
      )
        column += 1; // Puntaje Obtenido,
      if (this.evaluation && this.evaluation.scope == 3) column += 2; // Creador, Fecha de Creación
      return column;
    },
    isPairNumberTableHeader() {
      return this.numberTableHeader % 2 == 0;
    },
    typeDefault() {
      if (!this.test) return null;
      return this.typesDefault.find((x) => x.id == this.test.evaluative_agent);
    },
    evaluateeTest() {
      return this.evaluatee_tests.find(
        (x) => x.evaluatee == this.user_id && x.test == this.test.id
      );
    },
    questionsMaxScore() {
      let score = 0;
      this.questions.forEach((element) => {
        score += parseFloat(element.max_score);
      });
      return parseFloat(score.toFixed(2));
    },
    questions() {
      let list = [];
      list = list.concat(
        this.redactionQuestions.map((question) => ({
          ...question,
          question_type: 1,
        }))
      );
      list = list.concat(
        this.multipleChoiceQuestions.map((question) => ({
          ...question,
          question_type: 2,
        }))
      );
      // list = list.concat(
      //   this.fillingQuestions.map((question) => ({
      //     ...question,
      //     question_type: 3,
      //   }))
      // );
      list = list.concat(
        this.matchingQuestions.map((question) => ({
          ...question,
          question_type: 4,
        }))
      );
      return list.sort((a, b) => a.order - b.order);
    },
    redactionQuestions() {
      return this.redaction_questions.filter(
        (x) => x.test == this.instrument_id
      );
    },
    multipleChoiceQuestions() {
      return this.multiple_choice_questions.filter(
        (x) => x.test == this.instrument_id
      );
    },
    fillingQuestions() {
      return this.filling_questions.filter((x) => x.test == this.instrument_id);
    },
    matchingQuestions() {
      return this.matching_questions.filter(
        (x) => x.test == this.instrument_id
      );
    },
    // formattedTime() {
    //   const hours = Math.floor(this.remainingTime / 3600);
    //   const minutes = Math.floor((this.remainingTime % 3600) / 60);
    //   const seconds = this.remainingTime % 60;
    //   return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
    //     .toString()
    //     .padStart(2, "0")}`;
    // },
  },
  methods: {
    getUser(user_id) {
      const user = this.users.find((x) => x.id == user_id);
      if (user) return user.first_name + " " + user.last_name;
      else return null;
    },
    ChangePreviewEvaluation() {
      this.$emit("change_preview_evaluation");
    },
    upgradeScoreInstrument() {
      this.$store.dispatch(names.PATCH_NEW_TEST, {
        new_test_id: this.instrument_id,
        item: { max_score: this.questionsMaxScore },
      });
      toast(
        "Se actualizó el puntaje del " +
          this.$getVisibleNames("evaluations2.test", false, "Instrumento")
      );
    },
    slotCreatedFile(file) {
      if (file) {
        const files = this.test.files.concat([file.id]);
        if (!isNaN(this.test.id)) {
          this.$store.dispatch(names.PATCH_NEW_TEST, {
            new_test_id: this.test.id,
            item: {
              files: files,
            },
          });
        }
      }
      this.$bvModal.hide(
        `modal-select-file-${this.instrument_id}-${this.container_instrument_id}`
      );
    },
    slotCreatedFileProfessor(file) {
      if (file) {
        const files_for_professor = this.test.files_for_professor.concat([
          file.id,
        ]);
        if (!isNaN(this.test.id)) {
          this.$store.dispatch(names.PATCH_NEW_TEST, {
            new_test_id: this.test.id,
            item: {
              files_for_professor: files_for_professor,
            },
          });
        }
      }
      this.$bvModal.hide(
        `modal-select-files_for_professor-${this.instrument_id}-${this.container_instrument_id}`
      );
    },
    slotDeleteFile(file_id) {
      const index = this.test.files.indexOf(file_id);
      if (index != -1) this.test.files.splice(index, 1);
    },
    slotDeleteFileProfessor(file_id) {
      const index = this.test.files_for_professor.indexOf(file_id);
      if (index != -1) this.test.files_for_professor.splice(index, 1);
    },
    handleScroll() {
      // Calcula la posición de la mitad de la vista
      const halfViewport = window.innerHeight / 1.5;
      // Verifica si la posición actual del desplazamiento está más abajo que la mitad de la vista
      this.showFixedRow = window.scrollY > halfViewport;
    },
  },
  watch: {},
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    // this.timer = setInterval(() => {
    //   if (this.remainingTime > 0) {
    //     this.remainingTime--;
    //   } else {
    //     clearInterval(this.timer);
    //     // Aquí puedes agregar acciones adicionales cuando el contador llega a 0.
    //   }
    // }, 1000);
  },
  // beforeDestroy() {
  //   clearInterval(this.timer);
  // },
  created() {
    this.$store
      .dispatch(names.FETCH_NEW_TEST, { test_id: this.instrument_id })
      .then((test) => {
        this.$store.dispatch(names.FETCH_USER, test.created_by);
      });
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.btn-preview-mobile {
  display: none;
}
.add-button {
  color: white;
}
.title-instrument {
  color: black;
  font-weight: bold;
}
.table-cell-description {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  border: 1px solid rgba(0, 0, 0, 0.5);
  font-size: 10pt;
}
.primary-cell-description {
  height: 100%;
  width: 100%;
  padding-left: 2em;
  align-items: center;
  background-color: var(--kl-menu-color) !important;
  color: white;
  font-weight: bold;
  text-align: center;
}
.secundary-cell-description {
  padding: 5px;
  width: 100%;
}
.table-cell-description-mb {
  margin-bottom: 20px;
}
.table-left {
  display: flex;
  justify-content: left;
}
.table-cell {
  border: 1px solid rgba(0, 0, 0, 0.5);
}
.primary-cell {
  height: 100%;
  display: flex;
  width: 40%;
  padding-left: 0.5em;
  padding-right: 0.5em;
  align-items: center;
  background-color: var(--kl-menu-color) !important;
  /* background-color: var(--primary-color); */
  color: white;
  font-weight: bold;
  text-align: start;
}
.secundary-cell {
  padding: 4px;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  /* background-color: var(--primary-color); */
  display: flex;
  width: 60%;
  flex-wrap: wrap;
  text-align: start;
  padding-left: 2em;
  align-items: center;
  /* font-weight: 500; */
}
.fixed-row {
  position: fixed;
  top: 5.6em;
  left: 0;
  width: 100%;
  color: #fff;
  display: flex;
  z-index: 9;
  display: none;
}
.visible {
  display: flex;
}
.column {
  flex: 1;
  padding: 5px;
}
.column1 {
  background-color: var(--kl-menu-color) !important;
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.column2 {
  background: white;
  color: black;
  border: 1px solid rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.columnView {
  max-width: 200px;
  border-left: none;
}
.subcolumn {
  display: flex;
  width: 100%;
}
.subcolumn-item {
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon-container {
  display: none;
}
.icon-visibility {
  padding: 2.5px;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}
.score-input-warning {
  border: 1px solid #dc3545;
  color: red;
  font-weight: bold;
}
.score-input-warning-minor {
  border: 1px solid #dc3545;
  color: red;
  font-weight: bold;
}
.table-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 10pt;
  margin: 10px 0px 0px 0px;
  gap: 0px;
}
.table-container-odd > :last-child {
  grid-column: span 2; /* Hace que el último hijo ocupe todas las columnas disponibles */
  display: flex;
  flex-direction: column;
}
.table-container-odd > :last-child .primary-cell,
.table-container-odd > :last-child .secundary-cell {
  width: 100%;
}
.table-container-odd > :last-child .primary-cell {
  justify-content: center;
}
/* .table-container-odd > :last-child {
  grid-column: span 2; 
  display: grid;
  grid-template-columns: repeat(1, 1fr) repeat(3, 1fr); 
}
.table-container-odd > :last-child .primary-cell {
  grid-column: span 1 !important;
}
.table-container-odd > :last-child .secundary-cell {
  grid-column: span 3 !important;
} */
/* .table-container-odd > :nth-last-child(1) .secundary-cell {
  justify-content: flex-start !important;
  padding-left: 0px !important;
  text-align: left !important;
} */
@media (max-width: 1100px) {
  .table-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 750px) {
  .table-container {
    grid-template-columns: repeat(1, 1fr);
  }
  .table-container-odd > :last-child {
    grid-column: span 1; /* Hace que el último hijo ocupe todas las columnas disponibles */
    display: grid;
    grid-template-columns: repeat(
      1,
      1fr
    ); /* Cada elemento ocupa una fila completa */
  }

  /* Estilos para primary-cell y secundary-cell del último hijo */
  .table-container-odd > :last-child .primary-cell,
  .table-container-odd > :last-child .secundary-cell {
    width: 100%; /* Ocupa todo el ancho disponible */
  }
  .primary-cell {
    width: 45%;
  }
  .secundary-cell {
    width: 55%;
  }
  .column1 {
    display: none;
    flex-basis: 75%;
    padding: 0px;
  }
  .column2 {
    flex-basis: 25%;
    border-left: none;
  }
  .icon-container {
    display: flex;
    margin-top: 4px;
    margin-right: 5px;
  }
  .btn-preview-web {
    display: none;
  }
  .btn-preview-mobile {
    display: flex;
  }
  .columnView {
    max-width: 50px;
  }
}
@media print {
  .fixed-row {
    display: none;
  }
  .print-rubric {
    margin-bottom: 20px;
  }
  .print-hide {
    display: none !important;
  }
  .table-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .table-container-odd > :last-child {
    display: grid !important;
    grid-column: span 2;
    grid-template-columns: repeat(2, 1fr) !important;
  }
  .table-container-odd > :last-child .primary-cell {
    grid-column: span 2;
  }
  .table-container-odd > :last-child .secundary-cell {
    padding-left: 10px !important;
    grid-column: span 2;
  }
}
</style>