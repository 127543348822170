var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"h-auto"},[_c('div',{staticStyle:{"display":"flex"}},[_c('div',{staticClass:"col",staticStyle:{"text-align":"left"}},[(
            _vm.allows_crud &&
            ((_vm.user_position &&
              [1, 2].includes(_vm.user_position.position) &&
              [1, 2].includes(_vm.user.groups[0])) ||
              _vm.user.is_superuser ||
              _vm.$hasObjectPermission('mesh.faculty', 'add') ||
              _vm.$hasObjectPermission('mesh.career', 'add'))
          )?_c('button',{directives:[{name:"can",rawName:"v-can",value:('mesh.add_career'),expression:"'mesh.add_career'"},{name:"b-modal",rawName:"v-b-modal.new-career-modal",modifiers:{"new-career-modal":true}}],staticClass:"btn btn-secondary btn-sm btn-add"},[_vm._v(" Agregar "+_vm._s(_vm.$getVisibleNames("manual.programa", false, "Programa"))+" ")]):_vm._e()])]),_c('h4',{staticClass:"mt-0"},[_vm._v(" "+_vm._s(_vm.$getVisibleNames("mesh.career", true, "Programas"))+" "),_c('AlternativeNameButton',{attrs:{"table_name":'mesh.career',"allows_crud":_vm.allows_crud}})],1),_c('b-form-select',{staticClass:"w-25 noprint",attrs:{"name":"type-select","text-field":"name","value-field":"id"},model:{value:(_vm.profile_type_id),callback:function ($$v) {_vm.profile_type_id=$$v},expression:"profile_type_id"}},[_c('option',{attrs:{"selected":""},domProps:{"value":null}},[_vm._v(" -- Seleccione Tipo de "+_vm._s(_vm.$getVisibleNames("mesh.career", false, "Programa"))+" -- ")]),_vm._l((_vm.profileTypes),function(type){return _c('option',{key:type.id,domProps:{"value":type.id}},[_vm._v(" "+_vm._s(type.name)+" ")])})],2),(
        _vm.user &&
        ((_vm.user_position &&
          [1].includes(_vm.user_position.position) &&
          [1].includes(_vm.user.groups[0])) ||
          _vm.user.is_superuser)
      )?_c('div',{staticClass:"d-flex"},[[_c('div',{staticClass:"ml-auto mr-0 noprint"},[_c('b-button',{staticClass:"mb-2 ml-3 noprint",attrs:{"size":"sm"},on:{"click":function($event){return _vm.redirectToCareerReport()}}},[_c('b-icon',{attrs:{"icon":"box-arrow-up-right"}}),_vm._v(" Reporte de "+_vm._s(_vm.$getVisibleNames("mesh.career", true, "Programas"))+" ")],1)],1)]],2):_vm._e(),_c('div',{staticClass:"table-responsive mt-2"},[(_vm.careerList)?_c('GenericBTable',{attrs:{"items":_vm.careerList,"fields":_vm.fields,"filter":_vm.input_search,"filterCustom":_vm.filterCustom,"primary-key":"id","show_pagination":true,"pagination":_vm.careerList.length > 20 ? _vm.careerList.length : 20,"search_filter":true,"columns_display":true,"allows_crud":_vm.allows_crud,"default_striped":false,"default_bordered":false,"default_borderless":false,"default_hover":true,"display_id":'career',"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"sticky-header":_vm.stickyHeaderSize},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"emitChangeSearchField":_vm.changeInputSearch},scopedSlots:_vm._u([{key:"cell(faculty)",fn:function(row){return [_c('div',{staticClass:"tdClass"},[_vm._v(" "+_vm._s(_vm.getFacultyName(row.item.faculty))+" ")])]}},{key:"cell(name)",fn:function(row){return [_c('div',{staticClass:"tdClass"},[_vm._v(" "+_vm._s(row.item.name)+" ")])]}},{key:"cell(type)",fn:function(row){return [_c('div',{staticClass:"tdClass"},[_vm._v(" "+_vm._s(_vm.getCareerType(row.item.egress_profile_type))+" ")])]}},{key:"head(actions)",fn:function(){return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
              `
              ${
                _vm.select_all ? 'Ocultar todos' : 'Mostrar todos'
              } los ${_vm.$getVisibleNames(
                'mesh.egressprofile',
                true,
                'Perfiles de Egreso'
              )}
            `
            ),expression:"\n              `\n              ${\n                select_all ? 'Ocultar todos' : 'Mostrar todos'\n              } los ${$getVisibleNames(\n                'mesh.egressprofile',\n                true,\n                'Perfiles de Egreso'\n              )}\n            `\n            ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"p-auto",attrs:{"variant":"none"},on:{"click":() => {
                _vm.select_all = !_vm.select_all;
                _vm.selectAllViews();
              }}},[(_vm.select_all)?_c('b-icon-chevron-double-up'):(!_vm.select_all)?_c('b-icon-chevron-double-down'):_vm._e()],1)]},proxy:true},{key:"cell(actions)",fn:function(row){return [_c('div',{staticClass:"noprint",class:{
              'd-flex justify-content-end':
                row.item.egress_profiles.length != 0,
            }},[(row.item.egress_profiles.length == 0)?[(
                  _vm.allows_crud &&
                  ((_vm.user_position &&
                    [1, 2].includes(_vm.user_position.position) &&
                    [1, 2].includes(_vm.user.groups[0])) ||
                    _vm.user.is_superuser ||
                    _vm.$hasObjectPermission(
                      'mesh.faculty',
                      'change',
                      row.item.faculty
                    ) ||
                    _vm.$hasObjectPermission(
                      'mesh.career',
                      'change',
                      row.item.id
                    ))
                )?_c('button-add',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                  `Crear ${_vm.$getVisibleNames(
                    'mesh.egressprofile',
                    false,
                    'Perfil de Egreso'
                  )}`
                ),expression:"\n                  `Crear ${$getVisibleNames(\n                    'mesh.egressprofile',\n                    false,\n                    'Perfil de Egreso'\n                  )}`\n                ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],attrs:{"size":"sm"},on:{"click":function($event){return _vm.slotCreateEgressProfile(row.item)}}}):_vm._e()]:[_c('div',[(row.item.egress_profiles_list.length > 1)?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                    _vm.$getVisibleNames(
                      'mesh.egressprofile',
                      true,
                      'Perfiles de Egreso'
                    )
                  ),expression:"\n                    $getVisibleNames(\n                      'mesh.egressprofile',\n                      true,\n                      'Perfiles de Egreso'\n                    )\n                  ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],staticClass:"border-button-class mr-2",attrs:{"size":"sm","variant":"light"},on:{"click":function($event){return _vm.openDetails(row)}}},[_c('h5',{staticClass:"p-0 m-0"},[_c('b-badge',{attrs:{"variant":"none"}},[_vm._v(_vm._s(row.item.egress_profile_curricular_number.length))])],1)]):(row.item.egress_profiles_list.length == 1)?[_c('ButtonWrapper',{staticClass:"mr-2",attrs:{"item_id":row.item.id,"tooltip_text":'Dirigirse a...'},scopedSlots:_vm._u([{key:"btn-slot",fn:function(){return [_vm._l((_vm.filterEgressProfile(
                          row.item.egress_profiles_list.map((x) => x.id)
                        )),function(egress_profile_id){return _c('b-button',{key:'perfil' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToEgressProfile(egress_profile_id)}}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofile", false, "Perfil de Egreso" ))+" ")])}),_vm._l((_vm.filterEgressProfile(
                          row.item.egress_profiles_list.map((x) => x.id)
                        )),function(egress_profile_id){return _c('b-button',{key:'Matriz 1' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToMatrix1(egress_profile_id)}}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.matriz_1", false, "Matriz 1" ))+" ")])}),_vm._l((_vm.filterEgressProfile(
                          row.item.egress_profiles_list.map((x) => x.id)
                        )),function(egress_profile_id){return _c('b-button',{key:'Matriz 2' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToMatrix2(egress_profile_id)}}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.matriz_2", false, "Matriz 2" ))+" ")])}),_vm._l((_vm.filterEgressProfile(
                          row.item.egress_profiles_list.map((x) => x.id)
                        )),function(egress_profile_id){return _c('b-button',{key:'Matriz 3' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToMatrix3(egress_profile_id)}}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.matriz_3", false, "Matriz 3" ))+" ")])}),_vm._l((_vm.filterEgressProfile(
                          row.item.egress_profiles_list.map((x) => x.id)
                        )),function(egress_profile_id){return _c('b-button',{key:'Plan de Estudio' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToStudyPlan(egress_profile_id)}}},[_vm._v(" Plan de Estudio ")])}),_vm._l((_vm.filterEgressProfile(
                          row.item.egress_profiles_list.map((x) => x.id),
                          true
                        )),function(egress_profile_id){return _c('b-button',{key:'Malla Curricular' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToCurricularMesh(egress_profile_id)}}},[_vm._v(" Malla Curricular ")])})]},proxy:true}],null,true)})]:_vm._e()],2)],(
                _vm.allows_crud &&
                ((_vm.user_position &&
                  [1, 2].includes(_vm.user_position.position) &&
                  [1, 2].includes(_vm.user.groups[0])) ||
                  _vm.user.is_superuser ||
                  _vm.$hasObjectPermission(
                    'mesh.faculty',
                    'change',
                    row.item.faculty
                  ) ||
                  _vm.$hasObjectPermission('mesh.career', 'change', row.item.id))
              )?_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_career'),expression:"'mesh.change_career'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.topright.noninteractive",value:(
                `
                    Editar ${_vm.$getVisibleNames(
                      'mesh.career',
                      false,
                      'Programa'
                    )}
                  `
              ),expression:"\n                `\n                    Editar ${$getVisibleNames(\n                      'mesh.career',\n                      false,\n                      'Programa'\n                    )}\n                  `\n              ",modifiers:{"v-secondary":true,"topright":true,"noninteractive":true}}],on:{"click":function($event){return _vm.$bvModal.show(`edit-career-modal-${row.item.id}`)}}}):(_vm.allows_crud)?_c('button-edit',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_career'),expression:"'mesh.change_career'"}],attrs:{"disabled":true}}):_vm._e(),(
                _vm.allows_crud &&
                ((_vm.user_position &&
                  [1, 2].includes(_vm.user_position.position) &&
                  [1, 2].includes(_vm.user.groups[0])) ||
                  _vm.user.is_superuser ||
                  _vm.$hasObjectPermission(
                    'mesh.faculty',
                    'change',
                    row.item.faculty
                  ) ||
                  _vm.$hasObjectPermission('mesh.career', 'delete', row.item.id))
              )?_c('button-delete',{directives:[{name:"can",rawName:"v-can",value:('mesh.delete_career'),expression:"'mesh.delete_career'"},{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.topright.noninteractive",value:(
                `
                    Eliminar ${_vm.$getVisibleNames(
                      'mesh.career',
                      false,
                      'Programa'
                    )}
                  `
              ),expression:"\n                `\n                    Eliminar ${$getVisibleNames(\n                      'mesh.career',\n                      false,\n                      'Programa'\n                    )}\n                  `\n              ",modifiers:{"v-secondary":true,"topright":true,"noninteractive":true}}],on:{"click":function($event){return _vm.askForDeleteCareer(row.item.id)}}}):(_vm.allows_crud)?_c('button-delete',{directives:[{name:"can",rawName:"v-can",value:('mesh.change_career'),expression:"'mesh.change_career'"}],attrs:{"disabled":true}}):_vm._e()],2),_c('b-modal',{attrs:{"id":`edit-career-modal-${row.item.id}`,"title":`Editar ${_vm.$getVisibleNames(
              'mesh.career',
              false,
              'Programa'
            )}
                  `,"size":"lg","hide-footer":""}},[_c('CareerForm',{attrs:{"Career":row.item,"show_title":false},on:{"updated":_vm.slotUpdatedCareer}})],1)]}},{key:"row-details",fn:function(row){return [(row.item.egress_profiles_list.length > 1)?_c('b-card',{staticClass:"p-0"},[_c('div',[_c('GenericBTable',{key:_vm.input_search,attrs:{"items":row.item.egress_profiles_list,"fields":_vm.fieldsEgressProfile,"per_page":row.item.egress_profiles_list.length,"insert_search":_vm.input_search,"filter":_vm.input_search,"filterCustom":_vm.filterCustom2,"show_pagination":false,"search_filter":false,"default_striped":false,"default_bordered":false,"default_borderless":true,"default_hover":true,"hidden_table_header":false,"show_skeleton_efect":false,"columns_display":false},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(data){return [_c('ButtonWrapper',{attrs:{"item_id":data.item.id,"tooltip_text":'Dirigirse a...'},scopedSlots:_vm._u([{key:"btn-slot",fn:function(){return [_vm._l((_vm.filterEgressProfile([
                          data.item.id,
                        ])),function(egress_profile_id){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                          'Ir al perfil'
                        ),expression:"\n                          'Ir al perfil'\n                        ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],key:'perfil' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToEgressProfile(egress_profile_id)}}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "mesh.egressprofile", false, "Perfil de Egreso" ))+" ")])}),_vm._l((_vm.filterEgressProfile([
                          data.item.id,
                        ])),function(egress_profile_id){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                          'Ir a la ' +
                          _vm.$getVisibleNames(
                            'manual.matriz_1',
                            false,
                            'Matriz 1'
                          )
                        ),expression:"\n                          'Ir a la ' +\n                          $getVisibleNames(\n                            'manual.matriz_1',\n                            false,\n                            'Matriz 1'\n                          )\n                        ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],key:'Matriz 1' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToMatrix1(egress_profile_id)}}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.matriz_1", false, "Matriz 1" ))+" ")])}),_vm._l((_vm.filterEgressProfile([
                          data.item.id,
                        ])),function(egress_profile_id){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                          'Ir a la ' +
                          _vm.$getVisibleNames(
                            'manual.matriz_2',
                            false,
                            'Matriz 2'
                          )
                        ),expression:"\n                          'Ir a la ' +\n                          $getVisibleNames(\n                            'manual.matriz_2',\n                            false,\n                            'Matriz 2'\n                          )\n                        ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],key:'Matriz 2' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToMatrix2(egress_profile_id)}}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.matriz_2", false, "Matriz 2" ))+" ")])}),_vm._l((_vm.filterEgressProfile([
                          data.item.id,
                        ])),function(egress_profile_id){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                          'Ir a la ' +
                          _vm.$getVisibleNames(
                            'manual.matriz_3',
                            false,
                            'Matriz 3'
                          )
                        ),expression:"\n                          'Ir a la ' +\n                          $getVisibleNames(\n                            'manual.matriz_3',\n                            false,\n                            'Matriz 3'\n                          )\n                        ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],key:'Matriz 3' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToMatrix3(egress_profile_id)}}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames( "manual.matriz_3", false, "Matriz 3" ))+" ")])}),_vm._l((_vm.filterEgressProfile([
                          data.item.id,
                        ])),function(egress_profile_id){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                          'Ir al plan de estudio'
                        ),expression:"\n                          'Ir al plan de estudio'\n                        ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],key:'Plan de Estudio' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToStudyPlan(egress_profile_id)}}},[_vm._v(" Plan de Estudio ")])}),_vm._l((_vm.filterEgressProfile(
                          [data.item.id],
                          true
                        )),function(egress_profile_id){return _c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.top.noninteractive",value:(
                          'Ir a la malla curricular'
                        ),expression:"\n                          'Ir a la malla curricular'\n                        ",modifiers:{"v-secondary":true,"top":true,"noninteractive":true}}],key:'Malla Curricular' + egress_profile_id,staticClass:"dropdown-class",attrs:{"variant":"none"},on:{"click":function($event){return _vm.redirectToCurricularMesh(egress_profile_id)}}},[_vm._v(" Malla Curricular ")])})]},proxy:true}],null,true)})]}}],null,true)})],1)]):_vm._e()]}}],null,false,2519976107)}):_vm._e()],1)],1),_c('div',[_c('b-modal',{attrs:{"id":"new-career-modal","title":`Crear ${_vm.$getVisibleNames('mesh.career', false, 'Programa')}`,"size":"lg","hide-footer":""}},[_c('CareerForm',{attrs:{"show_title":false},on:{"created":_vm.slotCreatedCareer}})],1)],1),_c('b-modal',{attrs:{"id":"modal-create-egress-profile","title":`Crear ${_vm.$getVisibleNames(
      'mesh.egressprofile',
      false,
      'Perfil de Egreso'
    )}`,"size":"lg","hide-footer":""}},[_c('EgressProfileForm',{attrs:{"default_max_semester_amount":_vm.institution.max_semester_amount,"career_id":_vm.selected_career_id},on:{"created":_vm.slotCreatedEgreesProfile}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }