<template>
  <div>
    <b-table-simple small responsive border>
      <b-thead>
        <b-tr>
          <b-th colspan="1">{{
            $getVisibleNames(
              "manual.matter_code",
              false,
              "Código"
            ).toUpperCase()
          }}</b-th>
          <b-td colspan="1">
            <span v-if="matter">{{
              oldMatter ? oldMatter.code.toUpperCase() : ""
            }}</span>
          </b-td>
          <b-th colspan="1">NOMBRE</b-th>
          <b-th class="program-section" colspan="2">
            <span v-if="matter">{{
              oldMatter ? oldMatter.name.toUpperCase() : ""
            }}</span>
          </b-th>
          <b-th colspan="1">
            {{
              $getVisibleNames("mesh.career", false, "Programa").toUpperCase()
            }}
            <!-- PROGRAMA -->
          </b-th>
          <b-th class="program-section" colspan="2">
            <span v-if="careerFilter">{{
              careerFilter.name.toUpperCase()
            }}</span>
          </b-th>
          <b-th colspan="1" rowspan="1">SEMANAS</b-th>
          <b-td colspan="1" rowspan="1">
            <div v-if="matter && matter.use_matter_values && oldMatter">
              <span>{{ oldMatter.weeks }}</span>
            </div>
            <div v-else>
              <span v-if="matter">{{ matter.weeks }}</span>
            </div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th colspan="2">
            {{
              $getVisibleNames("mesh.faculty", false, "Facultad").toUpperCase()
            }}
          </b-th>
          <b-td colspan="1">{{
            faculty ? faculty.name.toUpperCase() : ""
          }}</b-td>
          <b-th colspan="2">
            {{
              $getVisibleNames(
                "mesh.egressprofile",
                false,
                "Perfil de Egreso"
              ).toUpperCase()
            }}
          </b-th>
          <b-td colspan="3">
            <span v-if="getEgressProfile">{{ getEgressProfile }}</span>
          </b-td>
          <b-th colspan="1" rowspan="1">NIVEL DEL PLAN</b-th>
          <b-td colspan="1" rowspan="1">
            <span v-if="matter">{{ matter.plan_level }}</span>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th colspan="1" rowspan="1">{{
            $getVisibleNames("mesh.periodtype", false, "Régimen").toUpperCase()
          }}</b-th>
          <b-td
            :colspan="
              institution && institution.show_matter_contribution ? 1 : 3
            "
            rowspan="1"
            v-if="getRegimen"
          >
            <div class="campus-div">
              {{ getRegimen.name }}
            </div>
          </b-td>
          <b-th colspan="1" rowspan="1">
            {{
              $getVisibleNames(
                "mesh.formationline",
                false,
                "Línea De Formación"
              ).toUpperCase()
            }}
            <!-- LÍNEA DE FORMACIÓN -->
          </b-th>
          <b-td
            :colspan="
              institution && institution.show_matter_contribution ? 1 : 3
            "
            rowspan="1"
          >
            <template v-if="getFormationLine">
              <div class="campus-div">
                {{ getFormationLine.name }}
              </div>
            </template>
            <!-- <template v-if="getFormationLine.length > 0">
              <div
                v-for="item in getFormationLine"
                :key="item.id"
                class="campus-div"
              >
                {{ item.name }}
              </div>
            </template>
            <template v-else>
              <div class="campus-div">N/A</div>
            </template> -->
          </b-td>
          <b-th colspan="1" rowspan="1">{{
            $getVisibleNames(
              "mesh.mattertype",
              false,
              "Tipo De Asignatura"
            ).toUpperCase()
          }}</b-th>
          <b-td colspan="2" rowspan="1">
            <template v-if="getMatterType.length > 0">
              <div
                v-for="item in getMatterType"
                :key="item.id"
                class="campus-div"
              >
                {{ item.name }}
              </div>
            </template>
            <template v-else>
              <div class="campus-div">N/A</div>
            </template>
          </b-td>
          <b-th
            v-if="institution && institution.show_matter_contribution"
            colspan="1"
            rowspan="1"
            >{{
              $getVisibleNames(
                "mesh.mattercontribution",
                false,
                "ESTRATEGIA CURRICULAR"
              ).toUpperCase()
            }}</b-th
          >
          <b-td
            v-if="institution && institution.show_matter_contribution"
            colspan="2"
            rowspan="1"
          >
            <template v-if="getMatterContribution.length > 0">
              <div
                v-for="item in getMatterContribution"
                :key="item.id"
                class="campus-div"
              >
                {{ item.name }}
              </div>
            </template>
            <template v-else>
              <div class="campus-div">N/A</div>
            </template>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th colspan="1" rowspan="1">
            {{
              $getVisibleNames(
                "mesh.modality",
                false,
                "Modalidad de Asignatura"
              ).toUpperCase()
            }}
          </b-th>
          <b-td
            :colspan="
              institution && institution.show_study_environment_menu ? 1 : 3
            "
            rowspan="1"
          >
            <template v-if="getModalities.length > 0">
              <div
                v-for="item in getModalities"
                :key="item.id"
                class="campus-div"
              >
                {{ item.name }}
              </div>
            </template>
            <template v-else>
              <div class="campus-div">N/A</div>
            </template>
          </b-td>
          <b-th colspan="1" rowspan="1">{{
            $getVisibleNames(
              "mesh.formationarea",
              false,
              "Área De Formación"
            ).toUpperCase()
          }}</b-th>
          <b-td
            :colspan="
              institution && institution.show_study_environment_menu ? 1 : 2
            "
            rowspan="1"
          >
            <template v-if="getFormationAreas">
              <div class="campus-div">
                {{ getFormationAreas.name }}
              </div>
            </template>
          </b-td>
          <b-th
            v-if="institution && institution.show_study_environment_menu"
            colspan="1"
            rowspan="1"
            >{{
              $getVisibleNames(
                "mesh.studyenvironment",
                false,
                "Ambiente de Aprendizaje"
              ).toUpperCase()
            }}</b-th
          >
          <b-td
            colspan="2"
            rowspan="1"
            v-if="institution && institution.show_study_environment_menu"
          >
            <template v-if="getMatterStudyEnvironments.length > 0">
              <div
                v-for="item in getMatterStudyEnvironments"
                :key="item.id"
                class="campus-div"
              >
                {{ item.name }}
              </div>
            </template>
            <template v-else>
              <div class="campus-div">N/A</div>
            </template>
          </b-td>
          <b-th colspan="1" rowspan="1">N° ESTUDIANTES</b-th>
          <b-td colspan="2" rowspan="1">
            <span v-if="matter && matter.use_matter_values && oldMatter">
              {{
                oldMatter.suggested_students == null
                  ? "0"
                  : oldMatter.suggested_students
              }}
            </span>
            <span v-else>
              {{
                matter.suggested_students == null
                  ? "0"
                  : matter.suggested_students
              }}
            </span>
          </b-td>
        </b-tr>
        <!-- <b-tr>
          <b-td colspan="1" rowspan="2">
            <span v-if="matter">{{ matter.week_theoric_hours }}</span>
          </b-td>
          <b-td colspan="1" rowspan="2">
            <span v-if="matter">{{ matter.week_lab_hours }}</span>
          </b-td>
          <b-td colspan="1" rowspan="2">
            <span v-if="matter">{{ matter.week_ground_hours }}</span>
          </b-td>
          <b-td colspan="1" rowspan="2">
            <span v-if="matter">{{ matter.week_assistantship_hours }}</span>
          </b-td>
        </b-tr> -->
        <!-- <b-tr> </b-tr> -->
        <!-- <b-tr>
         -->
        <!-- <b-th colspan="3" rowspan="1"
            >EXIGENCIA DE ASISTENCIA POR ACTIVIDAD DOCENTE</b-th
          > -->
        <!-- <b-td class="td-teacher-requirement" colspan="1" rowspan="1">
            <b-input-group
              size="sm"
              append="%"
              v-if="matter"
              class="input-group-custom"
            >
              <b-form-input
                size="sm"
                type="number"
                v-model="matter.theoric_hours_assistance"
                min="0"
                max="100"
                :disabled="!allows_crud"
              ></b-form-input>
            </b-input-group>
          </b-td>
          <b-td class="td-teacher-requirement" colspan="1" rowspan="1">
            <b-input-group size="sm" append="%" v-if="matter">
              <b-form-input
                size="sm"
                type="number"
                v-model="matter.lab_hours_assistance"
                min="0"
                max="100"
                :disabled="!allows_crud"
              ></b-form-input>
            </b-input-group>
          </b-td>
          <b-td colspan="1" rowspan="1">
            <b-input-group size="sm" append="%" v-if="matter">
              <b-form-input
                size="sm"
                type="number"
                v-model="matter.ground_hours_assistance"
                min="0"
                max="100"
                :disabled="!allows_crud"
              ></b-form-input>
            </b-input-group>
          </b-td> -->
        <!-- <b-td class="td-teacher-requirement" colspan="1" rowspan="1">
            <b-input-group size="sm" append="%" v-if="matter">
              <b-form-input
                size="sm"
                type="number"
                v-model="matter.assistantship_hours_assistance"
                min="0"
                max="100"
                :disabled="!allows_crud"
              ></b-form-input>
            </b-input-group>
          </b-td> -->
        <!-- </b-tr> -->
        <b-tr>
          <b-th>
            {{
              $getVisibleNames(
                "manual.total_pedagogical_hours_to_chronological",
                true,
                "Total de Horas Pedagógicas (En Cronológicas)"
              ).toUpperCase()
            }}
          </b-th>
          <b-td>
            <span v-if="profileType && totalPedagogical">
              {{
                (totalPedagogical *
                  (parseFloat(profileType.module_minutes) / 60))
                  | Round
              }}
            </span>
          </b-td>
          <b-td colspan="6">
            <template
              v-if="
                matterHours.filter((x) => x.uses_module_minutes == true)
                  .length > 0
              "
            >
              <span
                v-for="item in matterHours.filter(
                  (x) => x.uses_module_minutes == true
                )"
                :key="item.id"
                :class="{
                  'hours-div': item.counts_towards_credits,
                  'hours-2-div': !item.counts_towards_credits,
                }"
              >
                <span
                  v-b-tooltip.v-secondary.noninteractive="
                    item.counts_towards_credits
                      ? `Se incluye en el ${$getVisibleNames(
                          'manual.total_pedagogical_hours',
                          true,
                          'Total de Horas Pedagógicas'
                        )} contables`
                      : `No se incluye en el ${$getVisibleNames(
                          'manual.total_pedagogical_hours',
                          true,
                          'Total de Horas Pedagógicas'
                        )} contables`
                  "
                >
                  {{ item.name }}:
                </span>
                <span v-b-tooltip.v-secondary.noninteractive="'Hora Pedagógica'"
                  >{{ item.hours }}
                </span>
                <span
                  v-if="
                    item.attendance_requirement != '' &&
                    item.attendance_requirement != null &&
                    item.attendance_requirement != 0
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    'Porcentaje de asistencia requerido'
                  "
                  >- {{ item.attendance_requirement }}%</span
                >
              </span>
            </template>
            <template v-else>
              <div class="hours-2-div">N/A</div>
            </template>
          </b-td>
          <b-th colspan="1" rowspan="1">N° DOCENTES</b-th>
          <b-td colspan="1" rowspan="1">
            <span v-if="matter && matter.use_matter_values && oldMatter">
              {{
                oldMatter.suggested_teachers == null
                  ? "0"
                  : oldMatter.suggested_teachers
              }}
            </span>
            <span v-else>
              {{
                matter.suggested_teachers == null
                  ? "0"
                  : matter.suggested_teachers
              }}
            </span>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th>
            {{
              $getVisibleNames(
                "manual.total_chronological_hours",
                true,
                "Total de Horas Cronológicas"
              ).toUpperCase()
            }}
          </b-th>
          <b-td>
            {{ totalChronological }}
          </b-td>
          <b-td colspan="4">
            <template
              v-if="
                matterHours.filter((x) => x.uses_module_minutes == false)
                  .length > 0
              "
            >
              <span
                v-for="item in matterHours.filter(
                  (x) => x.uses_module_minutes == false
                )"
                :key="item.id"
                :class="{
                  'hours-div': item.counts_towards_credits,
                  'hours-2-div': !item.counts_towards_credits,
                }"
              >
                <span
                  v-b-tooltip.v-secondary.noninteractive="
                    item.counts_towards_credits
                      ? `Se incluye en el ${$getVisibleNames(
                          'manual.total_chronological_hours',
                          true,
                          'Total de Horas Cronológicas'
                        )} contables`
                      : `No se incluye en el ${$getVisibleNames(
                          'manual.total_chronological_hours',
                          true,
                          'Total de Horas Cronológicas'
                        )} contables`
                  "
                >
                  {{ item.name }}:
                </span>
                <span
                  v-b-tooltip.v-secondary.noninteractive="'Hora Cronológica'"
                  >{{ item.hours }}
                </span>
                <span
                  v-if="
                    item.attendance_requirement != '' &&
                    item.attendance_requirement != null &&
                    item.attendance_requirement != 0
                  "
                  v-b-tooltip.v-secondary.noninteractive="
                    'Porcentaje de asistencia requerido'
                  "
                  >- {{ item.attendance_requirement }}%</span
                >
              </span>
            </template>
            <template v-else>
              <div class="hours-2-div">N/A</div>
            </template>
          </b-td>
          <b-th colspan="1" rowspan="1"> TOTAL DE HORAS CRONOLÓGICAS</b-th>
          <b-td colspan="1" rowspan="1">
            {{ totalHours | Round }}
          </b-td>
          <b-th colspan="1" rowspan="1">{{
            $getVisibleNames(
              "manual.credits",
              true,
              "Créditos SCT Totales"
            ).toUpperCase()
          }}</b-th>
          <b-td colspan="2" rowspan="1">
            <div v-if="matter && matter.use_matter_values && oldMatter">
              <span>{{ oldMatter.credits }}</span>
            </div>
            <div v-else>
              <span v-if="matter">
                {{ matter.credits }}
              </span>
            </div>
          </b-td>
        </b-tr>
        <b-tr>
          <b-th colspan="2" rowspan="1">PRERREQUISITO(S)</b-th>
          <b-td
            :colspan="
              (institution && institution.internal_use_id == 'unab') ||
              $debug_change_unab
                ? 6
                : 8
            "
            rowspan="1"
          >
            <p v-if="prerequisites.length == 0" class="prerequisites-p">
              No tiene
            </p>
            <p v-else class="prerequisites-p">{{ prerequisites.join(", ") }}</p>
          </b-td>
          <b-th
            v-if="
              (institution && institution.internal_use_id == 'unab') ||
              $debug_change_unab
            "
            colspan="1"
            rowspan="1"
            >{{
              $getVisibleNames(
                "manual.institutional_credits",
                true,
                "Créditos Institucionales"
              ).toUpperCase()
            }}</b-th
          >
          <b-td
            v-if="
              (institution && institution.internal_use_id == 'unab') ||
              $debug_change_unab
            "
            colspan="2"
            rowspan="1"
          >
            <div v-if="matter && matter.use_matter_values && oldMatter">
              <span>{{ oldMatter.institutional_credits }}</span>
            </div>
            <div v-else>
              <span v-if="matter">
                {{ matter.institutional_credits }}
              </span>
            </div>
          </b-td>
        </b-tr>
      </b-thead>
    </b-table-simple>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import { toast } from "@/utils/utils";

export default {
  name: "MatterPresentation",
  props: {
    Matter: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      matter: this.Matter,
    };
  },
  computed: {
    ...mapGetters({
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      time_allocations: names.TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      matter_time_allocations: names.MATTER_TIME_ALLOCATIONS,
      matters: names.MATTERS,
      user: "getUser",
      formationAreas: names.FORMATION_AREAS,
      formationLines: names.FORMATION_LINES,
      matterTypes: names.MATTER_TYPES,
      faculties: names.FACULTIES,
      matterContributions: names.MATTER_CONTRIBUTIONS,
      modalities: names.MODALITIES,
      egress_profiles: names.EGRESS_PROFILES,
      profile_types: names.PROFILE_TYPES,
      studyEnvironments: names.STUDY_ENVIRONMENTS,
      regimes: names.REGIMES,
      career: names.CAREERS,
      institution: "getInstitution",
    }),
    careerFilter() {
      return this.career.find((x) =>
        x.egress_profiles.includes(this.matter.egress_profile)
      );
    },
    faculty() {
      if (!this.careerFilter) return null;
      return this.faculties.find((x) => x.id == this.careerFilter.faculty);
    },
    egressProfile() {
      if (!this.matter.egress_profile) return [];
      return this.egress_profiles.find(
        (x) => x.id == this.matter.egress_profile
      );
    },
    matterHours() {
      let list = [];
      this.time_allocations.forEach((element) => {
        if (this.Matter.use_matter_values) {
          const matter_hour = this.matter_time_allocations.find(
            (x) =>
              x.matter == this.Matter.matter && x.time_allocation == element.id
          );
          if (matter_hour && this.oldMatter)
            list.push({
              id: matter_hour.id,
              time_allocation: matter_hour.time_allocation,
              name: element.name,
              order: element.order,
              uses_module_minutes: element.uses_module_minutes,
              matter: matter_hour.matter,
              counts_towards_credits: matter_hour.counts_towards_credits,
              attendance_requirement: matter_hour.attendance_requirement,
              hours: matter_hour.hours,
              tem_hours:
                parseFloat(this.oldMatter.weeks) > 0
                  ? Math.round(
                      parseFloat(matter_hour.hours) /
                        parseFloat(this.oldMatter.weeks)
                    )
                  : 0,
            });
        } else {
          const matter_hour = this.ep_matter_time_allocations.find(
            (x) =>
              x.egress_profile_matter == this.Matter.id &&
              x.time_allocation == element.id
          );
          if (matter_hour)
            list.push({
              id: matter_hour.id,
              time_allocation: matter_hour.time_allocation,
              name: element.name,
              order: element.order,
              uses_module_minutes: element.uses_module_minutes,
              egress_profile_matter: matter_hour.egress_profile_matter,
              counts_towards_credits: matter_hour.counts_towards_credits,
              attendance_requirement: matter_hour.attendance_requirement,
              hours: matter_hour.hours,
              tem_hours:
                parseFloat(this.Matter.weeks) > 0
                  ? Math.round(
                      parseFloat(matter_hour.hours) /
                        parseFloat(this.Matter.weeks)
                    )
                  : 0,
            });
        }
      });
      return list;
    },
    getEgressProfile() {
      const instance = this.egress_profiles.find(
        (x) => x.id == this.matter.egress_profile
      );
      let arr = [];
      if (instance) {
        if (instance.code.trim() != "") arr.push(instance.code);
        if (instance.init_date) arr.push(instance.init_date);
        if (instance.professional_title.trim() != "")
          arr.push(instance.professional_title);
        return arr.join(" - ");
      } else return null;
    },
    profileType() {
      if (!this.egressProfile) return [];
      return this.profile_types.find(
        (x) => x.id == this.egressProfile.egress_profile_type
      );
    },
    oldMatter() {
      if (!this.matter) return null;
      return this.matters.find((x) => x.id == this.matter.matter);
    },
    prerequisites() {
      let names = [];
      if (!this.matter) return names;
      this.matter.prerequisites.forEach((element) => {
        let egress_profile_matter = this.egress_profiles_matters.find(
          (x) => x.id == element
        );
        if (egress_profile_matter) {
          let instance = this.matters.find(
            (x) => x.id == egress_profile_matter.matter
          );
          if (instance) names.push(instance.name);
        }
      });
      return names;
    },
    getFormationLine() {
      if (!this.matter) return [];
      const matter_formation_line = this.formationLines.find(
        (x) => this.matter.formation_line == x.id
      );
      return matter_formation_line ? matter_formation_line : { name: "N/A" };
    },
    getMatterType() {
      if (!this.matter) return [];
      const get_matter_types = this.matterTypes.filter((x) =>
        this.matter.matter_types.includes(x.id)
      );
      return get_matter_types ? get_matter_types : [];
    },
    getMatterContribution() {
      if (!this.matter) return [];
      const get_matter_contributions = this.matterContributions.filter((x) =>
        this.matter.matter_contributions.includes(x.id)
      );
      return get_matter_contributions ? get_matter_contributions : [];
    },
    getFormationAreas() {
      if (!this.matter) return [];
      const get_formation_area = this.formationAreas.find(
        (x) => x.id == this.matter.formation_area
      );
      return get_formation_area ? get_formation_area : { name: "N/A" };
    },
    getModalities() {
      if (!this.matter || !this.oldMatter) return [];
      let modalities_ids = [];
      if (this.matter.use_matter_values)
        modalities_ids = this.oldMatter.modalities;
      else modalities_ids = this.matter.modalities;
      return this.modalities.filter((x) => modalities_ids.includes(x.id));
    },
    getMatterStudyEnvironments() {
      if (!this.matter || !this.oldMatter) return [];
      let study_environments_ids = [];
      if (this.matter.use_matter_values)
        study_environments_ids = this.oldMatter.study_environments;
      else study_environments_ids = this.matter.study_environments;
      return this.studyEnvironments.filter((x) =>
        study_environments_ids.includes(x.id)
      );
    },
    getRegimen() {
      if (!this.matter) return [];
      const get_regime = this.regimes.find((x) => x.id == this.matter.regime);
      return get_regime ? get_regime : { name: "N/A" };
    },
    totalPedagogical() {
      let count = 0;
      this.matterHours
        .filter((x) => x.uses_module_minutes == true)
        .forEach((element) => {
          if (
            element.counts_towards_credits &&
            element.hours &&
            element.hours >= 0
          ) {
            count += parseFloat(element.hours);
          }
        });
      return count;
    },
    totalChronological() {
      let count = 0;
      this.matterHours
        .filter((x) => x.uses_module_minutes == false)
        .forEach((element) => {
          if (
            element.counts_towards_credits &&
            element.hours &&
            element.hours >= 0
          ) {
            count += parseFloat(element.hours);
          }
        });
      return count;
    },
    totalHours() {
      if (!this.profileType) return 0;
      const total_hours =
        this.totalPedagogical * (this.profileType.module_minutes / 60) +
        this.totalChronological;
      return total_hours ? total_hours : 0;
    },
    // moduleClassHours() {
    //   const module_class_hours = Math.round(
    //     (parseFloat(this.matter.week_theoric_hours) +
    //       parseFloat(this.matter.week_lab_hours) +
    //       parseFloat(this.matter.week_ground_hours) +
    //       parseFloat(this.matter.week_assistantship_hours)) *
    //       parseFloat(this.matter.weeks)
    //   );
    //   return module_class_hours ? module_class_hours : 0;
    // },
    // totalHours() {
    //   if (!this.profileType) return 0;
    //   return (
    //     (parseFloat(this.matter.week_theoric_hours) +
    //       parseFloat(this.matter.week_lab_hours) +
    //       parseFloat(this.matter.week_ground_hours) +
    //       parseFloat(this.matter.week_assistantship_hours)) *
    //       parseFloat(this.matter.weeks) *
    //       (this.profileType.module_minutes / 60) +
    //     parseFloat(this.matter.total_autonomous_hours)
    //   );
    // },
    // classHours() {
    //   if (!this.profileType) return 0;
    //   return (
    //     (parseFloat(this.matter.week_theoric_hours) +
    //       parseFloat(this.matter.week_lab_hours) +
    //       parseFloat(this.matter.week_ground_hours) +
    //       parseFloat(this.matter.week_assistantship_hours)) *
    //     parseFloat(this.matter.weeks) *
    //     (this.profileType.module_minutes / 60)
    //   );
    // },
    // creditSctChile() {
    //   if (!this.profileType) return 0;
    //   return Math.round(
    //     ((parseFloat(this.matter.week_theoric_hours) +
    //       parseFloat(this.matter.week_lab_hours) +
    //       parseFloat(this.matter.week_ground_hours) +
    //       parseFloat(this.matter.week_assistantship_hours)) *
    //       parseFloat(this.matter.weeks) *
    //       (this.profileType.module_minutes / 60) +
    //       parseFloat(this.matter.total_autonomous_hours)) /
    //       parseFloat(this.institution.hours_per_credit)
    //   );
    // },
    // regimeBiannual: {
    //   get() {
    //     if (this.matter && this.matter.regime == 1) return true;
    //     else return false;
    //   },
    //   set(value) {
    //     if (value) this.matter.regime = 1;
    //   },
    // },
    // regimeAnnual: {
    //   get() {
    //     if (this.matter && this.matter.regime == 2) return true;
    //     else return false;
    //   },
    //   set(value) {
    //     if (value) this.matter.regime = 2;
    //   },
    // },
    // formationAreaBasic: {
    //   get() {
    //     if (this.matter && this.matter.formation_area == 1) return true;
    //     else return false;
    //   },
    //   set(value) {
    //     if (value) this.matter.formation_area = 1;
    //   },
    // },
    // formationAreaProfessional: {
    //   get() {
    //     if (this.matter && this.matter.formation_area == 2) return true;
    //     else return false;
    //   },
    //   set(value) {
    //     if (value) this.matter.formation_area = 2;
    //   },
    // },
    // formationAreaGeneral: {
    //   get() {
    //     if (this.matter && this.matter.formation_area == 3) return true;
    //     else return false;
    //   },
    //   set(value) {
    //     if (value) this.matter.formation_area = 3;
    //   },
    // },
  },
  methods: {
    getMatterName(matter_id) {
      const matter = this.matters.find((x) => x.id == matter_id);
      return matter ? matter.name : "";
    },
    patchMatter(item) {
      if (this.timeout) clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const payload = {
          egress_profile_matter_id: this.matter.id,
          item: item,
        };
        this.$store
          .dispatch(names.PATCH_EGRESS_PROFILE_MATTER, payload)
          .then(() => {
            toast(
              this.$getVisibleNames(
                "mesh.egressprofilematter",
                false,
                "Asignatura"
              ) + " actualizada."
            );
          });
      }, 3000);
    },
  },
  watch: {
    "matter.assistantship_hours_assistance": function () {
      this.patchMatter({
        assistantship_hours_assistance:
          this.matter.assistantship_hours_assistance,
      });
    },
    "matter.theoric_hours_assistance": function () {
      this.patchMatter({
        theoric_hours_assistance: this.matter.theoric_hours_assistance,
      });
    },
    "matter.lab_hours_assistance": function () {
      this.patchMatter({
        lab_hours_assistance: this.matter.lab_hours_assistance,
      });
    },
    "matter.ground_hours_assistance": function () {
      this.patchMatter({
        ground_hours_assistance: this.matter.ground_hours_assistance,
      });
    },
  },
  mounted() {
    if (!isNaN(this.Matter.id)) {
      this.$store.dispatch(names.FETCH_EP_MATTER_TIME_ALLOCATIONS, {
        egress_profile_matter_id: this.Matter.id,
      });
      this.$store.dispatch(names.FETCH_MATTER_TIME_ALLOCATIONS, {
        matter_id: this.Matter.matter,
      });
    }
  },
  created() {
    this.$store
      .dispatch(names.FETCH_EGRESS_PROFILE, this.matter.egress_profile)
      .then((response) => {
        this.$store
          .dispatch(names.FETCH_CAREER, response.career)
          .then((career) => {
            this.$store.dispatch(names.FETCH_FACULTY, career.faculty);
          });
      });
    this.matter.prerequisites.forEach((prerequisite_id) => {
      if (!this.matters.find((x) => x.id == prerequisite_id))
        this.$store
          .dispatch(names.FETCH_EGRESS_PROFILE_MATTER, prerequisite_id)
          .then((response) => {
            this.$store.dispatch(names.FETCH_MATTER, response.matter);
          });
    });
    this.$store.dispatch(names.FETCH_PROFILE_TYPES);
    this.$store.dispatch(names.FETCH_STUDY_ENVIRONMENTS);
    this.$store.dispatch(names.FETCH_FORMATION_LINES);
    this.$store.dispatch(names.FETCH_FORMATION_AREAS);
    this.$store.dispatch(names.FETCH_MATTER_CONTRIBUTIONS);
    this.$store.dispatch(names.FETCH_MATTER_TYPES);
    this.$store.dispatch(names.FETCH_MODALITIES);
  },
};
</script>

<style scoped>
.table td {
  border-bottom: 1px solid black;
  text-align: left;
  vertical-align: middle;
  font-size: 10pt;
  margin-left: 5px;
}
.table th {
  vertical-align: middle;
  text-align: left;
  padding-left: 1rem;
  border-bottom: 1px solid black;
  background-color: var(--kl-menu-color) !important;
  color: white;
  font-size: 10pt;
}
.table td input[type="text"] {
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
  width: 100%;
  min-height: 100%;
}
.table .program-section {
  background-color: var(--kl-fourth-color) !important;
}
p {
  text-align: justify;
  padding-left: 3px;
}
.input-group {
  min-width: max-content;
}
.campus-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--thirth-font-size);
  text-align: center;
}
.hours-div {
  background-color: var(--primary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--secondary-font-size);
  /* text-align: center; */
}
.hours-2-div {
  background-color: var(--secondary-color);
  color: white;
  max-width: fit-content;
  border-radius: 3px;
  margin: 0.1em 0.1em;
  padding: 1px 4px;
  flex-grow: 1;
  font-size: var(--secondary-font-size);
  /* text-align: center; */
}
.prerequisites-p {
  vertical-align: middle;
  margin: 7px 10px;
}
.input-group {
  min-width: auto;
}
ul {
  padding-left: 0;
}
/* tr > .td-teacher-requirement {
  padding: 0 0.1em !important;
}
.input-group-custom {
  box-sizing: border-box;
  margin-left: auto;
}
@media print {
  .td-teacher-requirement {
    padding: 0 1em !important;
    left: 0;
  }
} */
</style>