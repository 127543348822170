var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"noprint"},[(_vm.show_button)?[(
        _vm.allows_crud &&
        _vm.user &&
        (_vm.user.groups.includes(1) || _vm.user.is_superuser) &&
        _vm.alternativeName
      )?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.v-secondary.noninteractive",value:('Modificar nombre alternativo.'),expression:"'Modificar nombre alternativo.'",modifiers:{"v-secondary":true,"noninteractive":true}}],staticClass:"btn-alternative-name",attrs:{"variant":"outline-primary","size":"sm"},on:{"click":function($event){return _vm.$bvModal.show(`edit-alternative-name-modal-${_vm.modal_ids}`)}}},[_c('b-icon-asterisk',{staticClass:"icon-btn-alternative-name"})],1):_vm._e()]:(
      _vm.allows_crud &&
      _vm.user &&
      (_vm.user.groups.includes(1) || _vm.user.is_superuser) &&
      _vm.alternativeName
    )?[_c('span',{staticClass:"href",on:{"click":function($event){return _vm.$bvModal.show(`edit-alternative-name-modal-${_vm.modal_ids}`)}}},[_vm._v(" "+_vm._s(_vm.$getVisibleNames(_vm.table_name, _vm.is_plural, _vm.text_default))+" ")])]:[_c('span',[_vm._v(" "+_vm._s(_vm.$getVisibleNames(_vm.table_name, _vm.is_plural, _vm.text_default)))])],_c('b-modal',{attrs:{"id":`edit-alternative-name-modal-${_vm.modal_ids}`,"title":"Sustituir nombre alternativo.","size":"md","hide-footer":""}},[_c('AlternativeNameForm',{attrs:{"AlternativeName":_vm.alternativeName},on:{"updated":function($event){return _vm.$bvModal.hide(`edit-alternative-name-modal-${_vm.modal_ids}`)}}})],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }