import { render, staticRenderFns } from "./HeaderRubric.vue?vue&type=template&id=4817c45f&scoped=true"
import script from "./HeaderRubric.vue?vue&type=script&lang=js"
export * from "./HeaderRubric.vue?vue&type=script&lang=js"
import style0 from "@/utils/rich_text_editor.css?vue&type=style&index=0&id=4817c45f&prod&scoped=true&lang=css&external"
import style1 from "./HeaderRubric.vue?vue&type=style&index=1&id=4817c45f&prod&scope=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4817c45f",
  null
  
)

export default component.exports